import { Select } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import { SelectProps } from 'antd/lib/select';

interface CategoryPickerProps extends PropsWithChildren<SelectProps<string[]>> {
  placeholder?: string;
  value: any;
  overriddenValue?: any;
  options: any[];
  prioritizeValue?: boolean;
}

export default function CategoryPicker({
  placeholder,
  value,
  overriddenValue,
  options,
  mode,
  prioritizeValue,
  ...other
}: CategoryPickerProps) {
  const [searchText, setSearchText] = useState('');
  const selectValue = overriddenValue || value || (mode === 'multiple' ? [] : undefined);
  return (
    <Select
      placeholder={placeholder || ''}
      className="w-100"
      mode={mode === undefined ? undefined : mode || 'multiple'}
      value={selectValue}
      autoClearSearchValue
      onSearch={input => setSearchText(input)}
      filterOption={(input, option) => {
        if (!input) {
          return true;
        }
        return option?.children?.toLowerCase().includes(input.toLowerCase());
      }}
      {...other}
    >
      {(options || []).map(item => (
        <Select.Option
          key={item.value}
          value={prioritizeValue ? item.value || item?.label : item?.label || item.value}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
