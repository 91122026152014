import { Tag } from 'antd';
import Tooltip from 'antd/es/tooltip';
import React from 'react';
import { BookingTag } from '../../container/Events';
import { maxTaglength } from '../../../constants';

interface RenderBookingTagsProps {
  tags: BookingTag[];
}

const RenderBookingTags = ({ tags }: RenderBookingTagsProps) => {
  const truncateTag = (tag: BookingTag) => {
    if (tag.name.length > maxTaglength) {
      return (
        <Tooltip title={tag.name}>
          <Tag className="m-1 rounded" style={{ borderRadius: '10px' }} color={tag.color}>
            {tag.name.slice(0, maxTaglength)}...
          </Tag>
        </Tooltip>
      );
    }
    return (
      <Tag className="m-1 rounded" color={tag.color}>
        {tag.name}
      </Tag>
    );
  };

  if (tags.length > 3) {
    const visibleTags = tags.slice(0, 3);
    const remainingTags = tags.slice(3);

    return (
      <>
        {visibleTags.map((tag: BookingTag, index: number) => (
          <React.Fragment key={index}>{truncateTag(tag)}</React.Fragment>
        ))}
        <Tooltip
          title={remainingTags.map((tag: BookingTag, index: number) => (
            <Tag key={index} className="m-1 rounded" color={tag.color}>
              {tag.name}
            </Tag>
          ))}
        >
          <Tag className="m-1 rounded" color="blue">
            +{remainingTags.length} mehr
          </Tag>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {tags.map((tag: BookingTag, index: number) => (
        <React.Fragment key={index}>{truncateTag(tag)}</React.Fragment>
      ))}
    </>
  );
};

export default RenderBookingTags;
