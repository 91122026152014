import React, { useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
// @ts-ignore
import ColorPickerWithGradient from 'react-best-gradient-color-picker';
import { colorPickerConfig } from '../../../Builder/config/builderSettingsColor';
import { PossibleFunnelThemeKeys } from '../../../Builder/interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../../Builder/hooks/redux/getter/useCurrentlyEditingBuilderTheme';

interface ColorPickerProps {
  label?: string;
  description?: string;
  color: string;
  onChange(hey: string): any;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  palletteClassName?: string;
  showColorTypeBtns?: boolean;
  onOpen?: (disabled: boolean) => void;
  isAgencyCustomer?: boolean;
}

function ColorPicker({
  color,
  onChange,
  label,
  description,
  onClose,
  className,
  children,
  showColorTypeBtns,
  onOpen,
  isAgencyCustomer
}: ColorPickerProps) {
  const [open, setOpen] = useState(false);

  const colorTheme = useCurrentlyEditingBuilderTheme();

  if (!color) {
    color = '';
  }

  const presetColorsWithTitle: {
    title: string;
    hex: string;
    colorKey: PossibleFunnelThemeKeys;
  }[] = [
    'headlineColor',
    'textColor',
    'accentColor',
    'backgroundColor',
    'cardBackgroundColor',
    'cardIconColor'
  ].map(_colorKey => ({
    title:
      colorPickerConfig.find(({ colorKey }) => colorKey === _colorKey)?.label ||
      'Undefinierte Farbe',
    // @ts-ignore
    hex: colorTheme[_colorKey as PossibleFunnelThemeKeys],
    colorKey: _colorKey as PossibleFunnelThemeKeys
  }));

  const presetColorsOnlyHex = presetColorsWithTitle
    // to prevent confusion we hide all other colors which are equal to accentColor
    .filter(
      ({ colorKey }) =>
        // @ts-ignore
        colorTheme[colorKey] !== colorTheme['accentColor'] || colorKey === 'accentColor'
    )
    .map(({ hex }) => hex);

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <div className={`color-picker`}>
      <div
        className="color-circle"
        onClick={() => {
          isAgencyCustomer !== null || undefined
            ? !isAgencyCustomer && setOpen(true)
            : setOpen(true);
          isAgencyCustomer !== null || undefined
            ? !isAgencyCustomer && onOpen?.(true)
            : onOpen?.(true);
        }}
        style={ isAgencyCustomer ? { cursor: 'auto' }: {}}
      >
        {children || (
          <>
            <div
              className={'color-circle__circle color-circle__circle__small'}
              style={{ background: color }}
            ></div>
            <span>
              <b>{label}</b>
              {description && (
                <React.Fragment>
                  <br />
                  {description}
                </React.Fragment>
              )}
            </span>
          </>
        )}
      </div>
      {open ? (
        <>
          <div id="react-color-picker" className={`${className}`}>
            <div className="color-picker__overlay">
              <div onClick={handleClose} className="color-picker__cover" />
              <div className="color-picker-popup">
                <div className="color-picker__no-background-styles">
                  <ColorPickerWithGradient
                    className={''}
                    height={160}
                    width={220}
                    hideGradientStop
                    hideAdvancedSliders
                    hideColorGuide
                    hideOpacity
                    hideInputType
                    hideColorTypeBtns={!showColorTypeBtns}
                    presets={presetColorsOnlyHex}
                    value={color}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ColorPicker;
