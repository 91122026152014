import { defaultColorTheme } from '../config/builderSettingsColor';
import { BuilderPageDataType, FunnelThemeType } from '../interfaces/builderSliceTypes';
import { getUniqueId } from './craftJs';
import { defaultCraftState } from './defaultCraftStates';

export const defaultCustomJS = `<script>\n\n\n\n</script>`;

export const contactPageType = "CONTACT";
export const thankyouPageType = 'THANK_YOU';
export const questionPageType = 'QUESTION';
export const commonPageType = 'COMMON';

export const disqualificationPageType = 'DISQUALIFICATION';

export const defaultFunnelTheme: FunnelThemeType = {
  ...defaultColorTheme,
  fontFamily: 'Open Sans',
  name: '',
  id: 0,
  isCustom: false
};

export const getRichTextEditorRawState = (question = 'Frage') => {
  return {
    blocks: [
      {
        key: '9nmpt',
        text: question,
        type: 'center',
        depth: 0,
        inlineStyleRanges: [
          { offset: 0, length: question.length, style: 'CUSTOM_FONT_SIZE_2.5em' }
        ],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  };
};
export enum DraftElementsEnum {
  HEADING = 'HEADING',
  SUB_HEADING = 'SUB_HEADING',
  PARAGRAPH = 'PARAGRAPH',
  CAPTION = 'CAPTION'
}

export const stylingAgainstDraftElements = {
  [DraftElementsEnum.PARAGRAPH]: 'unstyled',
  [DraftElementsEnum.CAPTION]: 'CUSTOM_FONT_SIZE_1.5em',
  [DraftElementsEnum.SUB_HEADING]: 'CUSTOM_FONT_SIZE_2.5em',
  [DraftElementsEnum.HEADING]: 'CUSTOM_FONT_SIZE_3.5em'
};

export const getDraftStateFromBlockArray = (state: { type: DraftElementsEnum; text: string }[], color?: string) => {
  return {
    blocks: state.map(({ type, text }) => {
      return {
        key: getUniqueId(),
        text,
        type: 'center',
        depth: 0,
        inlineStyleRanges: [{ offset: 0, length: text.length, style: stylingAgainstDraftElements[type] },
        ...(color ? [{ offset: 0, length: text.length, style: `CUSTOM_COLOR_${color}` }] : [])
        ],
        entityRanges: [],
        data: {}
      };
    }),
    entityMap: {}
  };
};

export const defaultBuilderPages: BuilderPageDataType[] = [
  {
    name: 'Leere Seite',
    craftState: defaultCraftState,
    id: -1,
    pageOrder: 0,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    redirectURL: '',
    customJs: ''
  },
  {
    name: 'Dankesseite',
    craftState: defaultCraftState,
    id: -2,
    pageOrder: -1,
    type: thankyouPageType,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    customJs: '',
    redirectURL: ''
  },
  {
    name: 'Disqualifizierung',
    craftState: defaultCraftState,
    id: -3,
    pageOrder: -2,
    type: disqualificationPageType,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    customJs: '',
    redirectURL: ''
  }
];
