import React from 'react';
import { Card, Col, Row } from 'antd';
import { Collapse } from 'antd';
import { FaCheckSquare, FaHandPointer, FaImage, FaQuestionCircle, FaVideo, FaFileAudio } from 'react-icons/fa';
import { BiCarousel } from 'react-icons/bi';
import SettingsGB from '../Settings/SettingsGB';
import {
  ButtonPresetsSettingsItems,
  ChoiceComponentPresetsSettingsItems,
  FAQComponentPresetsSettingsItems,
  ImageCarousalPresetsSettingsItems,
  ImagePresetsSettingsItems,
  VideoPResetsSettingsItems,
  VoiceMessagePresetsSettingsItems
} from './PresetsSettingsTemplates';
import { setElementsPresets, useElementsPresets } from '../../redux/builderSlice';
import { builderPresetElements } from '../../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { getKeyByValue, getValueByKey } from '../../helper/craftJs';
import { ButtonAvailableBorderRadius } from '../../interfaces/ButtonSizeInterface';
import {
  ImageAvailableBorderRadius,
  ImageAvailableSizes
} from '../../interfaces/ImageSizeInterface';
import { VoiceMessageSizes } from '../../interfaces/VoiceMessageSizes';
import {
  buttonPresetsInitialValues,
  choicePresetsInitialValues,
  faqPresetsInitialValues,
  imageCarousalPresetsInitialValues,
  imagePresetsInitialValues,
  videoPresetsInitialValues,
  voiceMessagePresetsInitialValues
} from '../../interfaces/presetsInititalValues';
import { ChoiceAvailableBorderRadius } from '../../interfaces/ChoiceSizeInterface';
const { Panel } = Collapse;

const ElementsPresets = () => {
  const dispatch = useAppDispatch();

  const buttonValues =
    useElementsPresets(builderPresetElements.BUTTON) || buttonPresetsInitialValues;
  const imageValues = useElementsPresets(builderPresetElements.IMAGE) || imagePresetsInitialValues;
  const imageCarousalValues =
    useElementsPresets(builderPresetElements.CAROUSEL) || imageCarousalPresetsInitialValues;
  const choiceCarousalValues =
    useElementsPresets(builderPresetElements.CHOICE) || choicePresetsInitialValues;
  const videoValues = useElementsPresets(builderPresetElements.VIDEO) || videoPresetsInitialValues;
  const faqValues =
    useElementsPresets(builderPresetElements.FAQ) || faqPresetsInitialValues;
  const voiceMessageValues =
    useElementsPresets(builderPresetElements.VOICE_MESSAGE) || voiceMessagePresetsInitialValues;

  const presetMap: Record<builderPresetElements, any> = {
    [builderPresetElements.BUTTON]: buttonValues,
    [builderPresetElements.CHOICE]: choiceCarousalValues,
    [builderPresetElements.IMAGE]: imageValues,
    [builderPresetElements.CAROUSEL]: imageCarousalValues,
    [builderPresetElements.VIDEO]: videoValues,
    [builderPresetElements.FAQ]: faqValues,
    [builderPresetElements.VOICE_MESSAGE]: voiceMessageValues
  };

  const handleChange = (
    elementKey: builderPresetElements,
    keyToUpdate: string,
    updatedValue: any
  ) => {
    const currentPreset = presetMap[elementKey];
    
    const computeValue  = (value: any, availableValues: any) => {
      if (keyToUpdate === 'borderRadius') {
        return availableValues ? getValueByKey(value, availableValues).pixelValue : value;
      } else if (keyToUpdate === 'boldText') {
        return !currentPreset.boldText;
      } else if (keyToUpdate === 'width') {
        return getValueByKey(value, VoiceMessageSizes).value;
      } else if (keyToUpdate === 'italic') {
        return !presetMap[elementKey].italic;
      } else if (keyToUpdate === 'underline') {
        return !presetMap[elementKey].underline;
      } else if (keyToUpdate === 'color') {
        return value;
      }
      return value;
    };

    const borderRadiusMap = {
      [builderPresetElements.BUTTON]: ButtonAvailableBorderRadius,
      [builderPresetElements.IMAGE]: ImageAvailableBorderRadius,
      [builderPresetElements.CAROUSEL]: ImageAvailableBorderRadius,
      [builderPresetElements.CHOICE]: ChoiceAvailableBorderRadius,
      [builderPresetElements.FAQ]: ChoiceAvailableBorderRadius,
      [builderPresetElements.VIDEO]: ImageAvailableBorderRadius
    };

    const value = computeValue(updatedValue, elementKey === builderPresetElements.VOICE_MESSAGE ? null : borderRadiusMap[elementKey]);

    dispatch(
      setElementsPresets({
        elementKey,
        updatedValue: { ...currentPreset, [keyToUpdate]: value }
      })
    );
  };

  return (
    <Col className="builder__settings-sidebar__container h-100">
      <Card className="settings-card h-100" title="Element-Voreinstellungen" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Collapse
            accordion
            style={{ width: '100%' }}
            expandIconPosition="right"
            bordered={false}
            className="presets-collapse"
          >
            <Panel
              header={
                <>
                  <FaHandPointer className="mr-2" />
                  {`   `}Button
                </>
              }
              key={builderPresetElements.BUTTON}
            >
              <SettingsGB
                onChange={(key, val) => {
                  let _key = null;
                  switch (key){
                    case 'BOLD':
                      _key = 'boldText';
                      break;
                    case 'UNDERLINE':
                      _key = 'underline';
                      break;
                    case 'ITALIC':
                      _key = 'italic';
                      break;
                    default:
                      _key = key;
                  }
                  handleChange(builderPresetElements.BUTTON, _key, val)
                }}
                elements={ButtonPresetsSettingsItems}
                values={{
                  ...buttonValues,
                  borderRadius: getKeyByValue(
                    // @ts-ignore
                    buttonValues?.borderRadius,
                    ButtonAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaImage />
                  {`   `}Bild
                </>
              }
              key={builderPresetElements.IMAGE}
            >
              <SettingsGB
                onChange={(key, val) => {
                   handleChange(builderPresetElements.IMAGE, key, val)
                }}
                elements={ImagePresetsSettingsItems}
                values={{
                  ...imageValues,
                  borderRadius: getKeyByValue(
                    imageValues.borderRadius,
                    ImageAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaVideo />
                  {`   `}Video
                </>
              }
              key={builderPresetElements.VIDEO}
            >
              <SettingsGB
                onChange={(key, val) => {
                   handleChange(builderPresetElements.VIDEO, key, val)
                }}
                elements={VideoPResetsSettingsItems}
                values={{
                  ...videoValues,
                  borderRadius: getKeyByValue(
                    videoValues.borderRadius,
                    ImageAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <BiCarousel />
                  {`   `}Bild-Slider
                </>
              }
              key={builderPresetElements.CAROUSEL}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.CAROUSEL, key, val)}
                elements={ImageCarousalPresetsSettingsItems}
                values={{
                  borderRadius: getKeyByValue(
                    imageCarousalValues.borderRadius,
                    ImageAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaCheckSquare />
                  {`   `}Auswahl
                </>
              }
              key={builderPresetElements.CHOICE}
            >
              <SettingsGB
                onChange={(key, val) => {
                  let _key = key;
                  switch (key) {
                    case 'BOLD':
                      _key = 'boldText';
                      break;
                    case 'ITALIC':
                      _key = 'italic';
                      break;
                    case 'UNDERLINE':
                      _key = 'underline';
                      break;
                    default:
                      _key = key;
                      break;
                  }
                  handleChange(builderPresetElements.CHOICE, _key, val)
                }}
                elements={ChoiceComponentPresetsSettingsItems}
                values={{
                  ...choiceCarousalValues,
                  borderRadius: getKeyByValue(
                    choiceCarousalValues.borderRadius,
                    ChoiceAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaQuestionCircle />
                  {`   `}FAQ
                </>
              }
              key={builderPresetElements.FAQ}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.FAQ, key, val)}
                elements={FAQComponentPresetsSettingsItems}
                values={{
                  ...faqValues,
                  borderRadius: getKeyByValue(
                    faqValues.borderRadius,
                    ChoiceAvailableBorderRadius,
                    'pixelValue'
                  ).key
                }}
              />
            </Panel>
            <Panel
              header={
                <>
                  <FaFileAudio />
                  {`   `}Sprachnachricht
                </>
              }
              key={builderPresetElements.VOICE_MESSAGE}
            >
              <SettingsGB
                onChange={(key, val) => handleChange(builderPresetElements.VOICE_MESSAGE, key, val)}
                elements={VoiceMessagePresetsSettingsItems}
                values={{
                  ...voiceMessageValues,
                  width: getKeyByValue(
                    voiceMessageValues.width,
                    VoiceMessageSizes,
                    'value'
                  ).key,
                 color: voiceMessageValues.color
                }}
              />
            </Panel>
          </Collapse>
        </Row>
      </Card>
    </Col>
  );
};

export default ElementsPresets;
