import {
  scrollImage1,
  scrollImage2,
  scrollImage3,
  scrollImage4,
  user1Img,
  user2Img,
  user3Img,
  user4Img,
  trustedLogo1,
  trustedLogo2,
  trustedLogo3,
  trustedLogo4,
  trustedLogo5,
  trustedLogo6
} from './ImageConstants';

export const ExistingUsers = [user3Img, user2Img, user4Img, user1Img];

export const TrustedLogos = [
  trustedLogo1,
  trustedLogo2,
  trustedLogo3,
  trustedLogo4,
  trustedLogo5,
  trustedLogo6
];

export enum AUTH_ROUTES {
  LOGIN = '/login',
  REGISTER = '/registrieren',
  RESET_PASSWORD = '/passwort-vergessen',
}
