import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import checkoutReducer from '../Checkout/redux/checkoutSlice';
import productFruitsReducer from '../ProductFruitsWrapper/redux/productFruitsSlice';
import uiReducer from '../UI/redux/uiSlice';
import userReducer from '../UI/redux/userSlice';
import builderReducer from '../Builder/redux/builderSlice';
import agencyOSBuilderReducer from '../Builder/AgencyOSBuilder/redux/agencyOSBuilderSlice';
import adBuilderReducer from '../Builder/AdBuilder/redux/adBuilderSlice';
import jobBuilderReducer from '../Builder/AdJobBuilder/redux/jobBuilderSlice';
import widgetBuilderReducer from '../Builder/WidgetsBuilder/redux/widgetBuilderSlice';
import librarySlice from '../Library/redux/librarySlice';
import funnelSlice from '../Funnel/redux/funnelSlice';
import marketplaceSlice from '../Marketplace/redux/slice';
import bookingSlice from '../Events/redux/slice';

const composeEnhancers = composeWithDevTools({});

export const rootReducer = combineReducers({
  checkout: checkoutReducer,
  user: userReducer,
  productFruits: productFruitsReducer,
  ui: uiReducer,
  builderUI: builderReducer,
  agencyOSBuilderUI: agencyOSBuilderReducer,
  adBuilderUI: adBuilderReducer,
  jobBuilderUI: jobBuilderReducer,
  widgetBuilderUI: widgetBuilderReducer,
  library: librarySlice,
  funnels: funnelSlice,
  marketplace: marketplaceSlice,
  bookings: bookingSlice
});

export type AppState = ReturnType<typeof rootReducer>;
const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(rootReducer, bindMiddleware([thunk as ThunkMiddleware<AppState, any>]));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
