import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import BuilderSettingsTitle from './BuilderSettingsTitle';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { PossibleFunnelThemeKeys } from '../../interfaces/builderSliceTypes';
import { colorPickerConfig } from '../../config/builderSettingsColor';
// @ts-ignore
import ColorPicker from 'react-best-gradient-color-picker';
import { useBuilderTheme } from '../../hooks/redux/getter/useBuilderTheme';
import { AGENCY_OS_THEME_KEYS } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';

interface ColorPickerProps {
  color: string;
  dataTestId?: string;
  onChange(key: string, colorKey?: string): any;
  showColorTypeBtns?: boolean;
  disabled?: boolean;
}

interface PresetColorsWithTitle {
  title: string;
  hex: string;
  colorKey: PossibleFunnelThemeKeys;
}

const BuilderColorPickerButton = ({
  color,
  onChange,
  dataTestId,
  showColorTypeBtns,
  disabled = false
}: ColorPickerProps) => {
  const [open, setOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorTheme = useBuilderTheme();
  const { height } = useWindowDimensions();

  const [popOverPosition, setPopOverPosition] = useState<undefined | 'top'>();

  useEffect(() => {
    if (open) {
      const { top } = document.getElementById('react-color-picker')?.getBoundingClientRect() || {
        top: 10
      };

      if (top + 350 > height) {
        setPopOverPosition('top');
      }
    }
  }, [height, open]);

  const handleClose = () => {
    setOpen(false);
    setShowColorPicker(false);
  };

  const presetColorsWithTitle: {
    title: string;
    hex: string;
    colorKey: PossibleFunnelThemeKeys;
  }[] = Object.keys(colorTheme).reduce((prev, curr) => {
    if (curr.toLowerCase().includes('color')) {
      return [
        ...prev,
        {
          title:
            colorPickerConfig.find(({ colorKey }) => colorKey === curr)?.label ||
            'Undefinierte Farbe',
          // @ts-ignore
          hex: colorTheme[curr as PossibleFunnelThemeKeys],
          colorKey: curr as PossibleFunnelThemeKeys
        }
      ];
    } else {
      return [...prev];
    }
  }, [] as PresetColorsWithTitle[]);

  const presetColorsOnlyHex = presetColorsWithTitle
    // to prevent confusion we hide all other colors which are equal to accentColor
    .filter(
      ({ colorKey }) =>
        // @ts-ignore
        colorTheme?.[colorKey] !== colorTheme['accentColor'] || colorKey === 'accentColor'
    )
    .map(({ hex }) => hex);

  useEffect(() => {
    if (showColorPicker) {
      const element = document.getElementById('rbgcp-wrapper');

      if (element) {
        const textNodes: Text[] = [];

        const findTextNodes = (node: Node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            const text = node.textContent || '';
            if (text.includes('Solid') || text.includes('Gradient')) {
              textNodes.push(node as Text);
            }
          } else {
            node.childNodes.forEach(findTextNodes);
          }
        };

        findTextNodes(element);

        textNodes.forEach((node: any) => {
          const updatedText = node.textContent
            .replace(/Solid/g, 'Einfach')
            .replace(/Gradient/g, 'Verlauf');
          node.textContent = updatedText;
        });
      }
    }
  }, [showColorPicker]);

  return (
    <div className="color-picker" style={{ position: 'relative' }} data-testid={dataTestId}>
      <Button
        className="color-picker__button mt-1 mb-1"
        onClick={() => setOpen(true)}
        style={{ background: color }}
        disabled={disabled}
      >
        &nbsp;
      </Button>
      {open ? (
        <>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: `
            div[current-route*=builder] .tab-container-style, div[current-route*=builder] .ant-tabs {
              overflow: initial !important;
              overflow-x: initial !important;
            }
        `
            }}
          />
          <div id="react-color-picker">
            <div className="color-picker__overlay">
              <div onClick={handleClose} className="color-picker__cover" />
              <div className="color-picker-popup">
                {!showColorPicker && (
                  <div>
                    <BuilderSettingsTitle title="Design-Farben" />
                    <div className="color-picker__no-background-styles">
                      {[
                        ...presetColorsWithTitle,
                        ...(showColorTypeBtns
                          ? [
                              {
                                title: 'Verlauf',
                                // @ts-ignore
                                hex: colorTheme['defaultGradient'],
                                colorKey: AGENCY_OS_THEME_KEYS.DEFAULT_GRADIENT
                              }
                            ]
                          : [])
                      ].map(({ title, hex, colorKey }, index) => (
                        <div
                          key={index}
                          className="color-picker__preset-color d-flex justify-content-between align-items-center"
                          onClick={() => {
                            onChange(hex, colorKey);
                            handleClose();
                          }}
                        >
                          <label className="color-picker__preset-color__label">{title}</label>
                          <div
                            className="color-picker__preset-color__circle"
                            style={{ background: hex }}
                          ></div>
                        </div>
                      ))}
                    </div>

                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={e => {
                        e.preventDefault();
                        setShowColorPicker(true);
                      }}
                    >
                      Individuell
                    </Button>
                  </div>
                )}
                {showColorPicker && (
                  <div>
                    <p className="color-picker__hint">
                      <InfoCircleOutlined /> Nutze die Design-Einstellungen, diese werden auf den
                      ganzen Funnel angewendet.
                    </p>
                    <div className="color-picker__no-background-styles">
                      <ColorPicker
                        className={''}
                        height={160}
                        width={220}
                        hideGradientStop
                        hideAdvancedSliders
                        hideColorGuide
                        hideOpacity
                        hideInputType
                        hideColorTypeBtns={!showColorTypeBtns}
                        presets={presetColorsOnlyHex}
                        value={color}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BuilderColorPickerButton;
