import React, { useState } from 'react';
import { Nodes, useEditor, useNode } from '@craftjs/core';
import lz from 'lzutf8';
import { getClonedTree } from '../../../../../helper/craftJs';
import { getMediaTemplateById, getNextTemplate } from '../../../../data';
import { CRAFT_ELEMENTS } from '../../../../../config/craftElements';
import SaveWarningModal from '../../common/SaveWarningModal';
import CreateNewTemplate from './CreateNewAdMedia';
import { checkIfMediaIsSame, getCurrentContentState } from '../../../../utils/adBuilderUtils';
import { useUserMediaTemplates } from '../../../../redux/adBuilderSlice';
import mediaSuggestions from '../../../../data/mediaSuggestions';

const SwitchMediaTemplate: React.FC<{
  direction?: 'NEXT' | 'PREV';
  onChange?: Function;
  handleCheckIfChangedRef?: any;
}> = ({ children, direction = 'NEXT', onChange = () => {}, handleCheckIfChangedRef }) => {
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [nameVisible, setNameVisible] = useState(false);
  const { actions, query } = useEditor();
  const userMediaTemp = useUserMediaTemplates();
  const [currentTemplate, setCurrentTemplate] = useState<
    { craftState: string; id: number } | undefined
  >(undefined);
  const { props, currentNode } = useNode(node => {
    return {
      props: node.data.props,
      currentNode: node
    };
  });

  const handleChangeTemplate = ({ craftState, id }: { craftState: string; id: number }) => {
    const serializedNode = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));
    //  Finding the MEDIA_WRAPPER Component to Update the templateId
    const nodes = serializedNode.nodes;
    const key = Object.keys(nodes).find(key => {
      return nodes[key].displayName === CRAFT_ELEMENTS.MEDIA_WRAPPER;
    });
    // @ts-ignore
    serializedNode.nodes[key].props.templateId = id;

    const newNodes: Nodes = {};
    Object.keys(serializedNode.nodes).forEach(key => {
      newNodes[key] = query.parseSerializedNode(serializedNode.nodes[key]).toNode();
    });

    const clonedTree = getClonedTree(
      {
        rootNodeId: serializedNode.id,
        nodes: newNodes
      },
      query
    );
    let actionCreator = actions.history.throttle(500);
    actionCreator.addNodeTree(clonedTree, currentNode.data.parent, 3);
    actions.selectNode(clonedTree.rootNodeId);
    actionCreator.delete(currentNode.id);
  };

  const toggleWarningModal = () => {
    setWarningModalVisible(!setWarningModalVisible);
  };

  const handleMovetoNext = () => {
    if (currentTemplate) {
      handleChangeTemplate({ craftState: currentTemplate.craftState, id: currentTemplate.id });
      setCurrentTemplate(undefined);
      return;
    }
    const nextTemp = getNextTemplate({
      currentId: props.templateId,
      direction,
      // @ts-ignore
      templateData: mediaSuggestions
    });
    // @ts-ignore
    handleChangeTemplate({ craftState: nextTemp.craftState, id: nextTemp.id });
  };

  const handleCheckIfChanged = (template?: { craftState: string; id: number } | undefined) => {
    const currentLoadedTemplate = getMediaTemplateById(props.templateId, userMediaTemp);
    if (!template) {
      const currentTemplate = getCurrentContentState(query, currentNode);
      if (checkIfMediaIsSame(currentTemplate, currentLoadedTemplate.craftState)) {
        const temp = {
          ...mediaSuggestions,
          data: [...mediaSuggestions.data, { content: userMediaTemp, category: 'Customer' }]
        };
        const nextTemp = getNextTemplate({
          currentId: props.templateId,
          direction,
          // @ts-ignore
          templateData: temp
        });
        // @ts-ignore
        handleMovetoNext({ craftState: nextTemp.content, id: nextTemp.id });
      } else {
        setWarningModalVisible(true);
      }
      return;
    } else {
      const currentTemplate = getCurrentContentState(query, currentNode);
      if (checkIfMediaIsSame(currentTemplate, currentLoadedTemplate.craftState)) {
        handleChangeTemplate({ craftState: template.craftState, id: template.id });
      } else {
        setCurrentTemplate({ craftState: template.craftState, id: template.id });
        setWarningModalVisible(true);
      }
    }
  };

  if (handleCheckIfChangedRef) {
    handleCheckIfChangedRef.current = handleCheckIfChanged;
  }

  return (
    <div>
      {children && <div onClick={() => handleCheckIfChanged()}>{children}</div>}
      <SaveWarningModal
        visible={warningModalVisible}
        setVisible={setWarningModalVisible}
        onSave={() => {
          setNameVisible(true);
        }}
        onMoveToNext={() => {
          toggleWarningModal();
          handleMovetoNext();
          if (onChange) {
            onChange();
          }
        }}
      />

      <CreateNewTemplate
        visible={nameVisible}
        toggleVisible={() => {
          setNameVisible(!nameVisible);
        }}
        onSubmit={() => {
          if (currentTemplate) {
            handleChangeTemplate({
              craftState: currentTemplate.craftState,
              id: currentTemplate.id
            });
            setCurrentTemplate(undefined);
            return;
          } else {
            const nextTemp = getNextTemplate({
              currentId: props.templateId,
              direction,
              // @ts-ignore
              templateData: mediaSuggestions
            });
            // @ts-ignore
            handleChangeTemplate({ craftState: nextTemp.craftState, id: nextTemp.id });
          }
          onChange();
        }}
      />
    </div>
  );
};

export default SwitchMediaTemplate;
