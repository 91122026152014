import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Form, Empty, Switch, Space } from 'antd';
import { ConnectSettingsMessages, GeneralMessages } from '../../../config/messages';
import { useMutation } from '@apollo/react-hooks';
import {
  getNewId,
  mutateArrayOfObjectsKeysWithIdSuffix,
  getSubmissionDataFromTempData,
  normalizeKeysWithIdSuffix
} from '../../../Funnel/helper/dynamicFormHelper';
import { Store } from 'antd/lib/form/interface';
import AddSlug from './AddSlug';
import { DomainInterface, DomainStatus } from './Domain';
import Slug, { SlugInterface } from './Slug';
import { GET_ALL_DOMAINS, SET_FUNNEL_DOMAIN } from '../../utils/domainQueries';
import { debounce } from 'debounce';
import Search from '../../../SharedUI/components/Search';
import DomainStatusProgress from './DomainStatusProgress';
import { useAppDispatch } from '../../../redux/hooks';
import { setFunnelDomainInSlice, useAllActiveFunnelsIDs } from '../../../Funnel/redux/funnelSlice';
import SubDomain from './SubDomain';

interface SlugListModal {
  domain: DomainInterface;
  trigger: JSX.Element;
  newlyCreatedDomainId?: number;
  onClose?: Function;
}

const defaultSlug = '/';

const SlugListModal = ({ domain, trigger, newlyCreatedDomainId, onClose }: SlugListModal) => {
  const { name: domainName, slugs, status, cName, id, maximumSlugs } = domain;
  const [form] = Form.useForm();
  const [open, setOpen] = useState(newlyCreatedDomainId == id);
  const [showAllSlugsEnabled, setShowAllSlugsEnabled] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [localSlugs, setLocalSlugs] = useState(slugs.filter(slg => slg.slug !== defaultSlug));
  const [defaultSlugItem, setDefaultSlugItem] = useState<SlugInterface>(slugs.find(slg => slg.slug === defaultSlug) || {
      id: getNewId(slugs),
      slug: defaultSlug,
      funnelId: -1,
      tempId: true
  });
  const [searchedSlugsIds, setSearchedSlugsIds] = useState([] as number[]);
  const activeFunnelIds = useAllActiveFunnelsIDs();
  const [setDomain, { loading }] = useMutation(SET_FUNNEL_DOMAIN, {
    refetchQueries: [{ query: GET_ALL_DOMAINS }]
  });
  const dispatch = useAppDispatch();
  const handleSetFieldsValues = (data: SlugInterface[]) => {
    const newValues = mutateArrayOfObjectsKeysWithIdSuffix({ data: data || [] });
    form.resetFields();
    form.setFieldsValue(newValues);
  };
  const areThereDisabledFunnels = localSlugs.some(
    ({ funnelId }) => !activeFunnelIds.includes(funnelId) && funnelId != -1
  );

  useEffect(() => {
    setOpen(newlyCreatedDomainId == domain?.id);
  }, [newlyCreatedDomainId, id]);

  useEffect(() => {
    if (open) {
      const tempSlugs = slugs;
      const defaultSlugValue = tempSlugs.find(slg => slg.slug === defaultSlug);
      if (!defaultSlugValue) {
        const newDefaultSlug = {
          id: getNewId(slugs),
          slug: defaultSlug,
          funnelId: -1,
          tempId: true
        };
        setDefaultSlugItem(newDefaultSlug);
        tempSlugs.push(newDefaultSlug);
      } else {
        setDefaultSlugItem(defaultSlugValue);
      }

      const nonDefaultSlugs = tempSlugs.filter(slg => slg.slug !== defaultSlug);
      setLocalSlugs(nonDefaultSlugs);
      handleSetFieldsValues(tempSlugs);
      setSearchedSlugsIds(nonDefaultSlugs.map(({ id }) => id));
    }
  }, [domain.slugs, open]);

  const handleSubmit = (values: any) => {
    if (loading || addOpen) return;
    const newSlugs: SlugInterface[] = getSubmissionDataFromTempData(
      normalizeKeysWithIdSuffix(values)
    ).submissionData;
    const { maximumSlugs, status: _status, cName: _cName, ...rest } = domain;
    const newDomain = {
      ...rest,
      slugs: newSlugs
    };

    const updatedDomains: { [key: string]: any } = {};

    domain.slugs.forEach((item: any) => {
      updatedDomains[item.id] = {
        domianStatus: status,
        domain: domainName,
        funnelId: item.funnelId,
        slug: item.slug,
        status: DomainStatus.DELETED
      };
    });
    const includesDuplicates = newSlugs.some(({ id, slug }) =>
      newSlugs.some(innerSlug => innerSlug.id !== id && innerSlug.slug === slug)
    );

    if (includesDuplicates) {
      message.error(ConnectSettingsMessages.duplicateSlugs);
      return;
    }

    setDomain({ variables: { domain: newDomain } })
      .then(({ data }) => {
        form.resetFields();
        setOpen(false);
        onClose && onClose();
        data.setFunnelDomain?.slugs?.forEach((item: SlugInterface) => {
          updatedDomains[item.id] = {
            ...(updatedDomains[item.id] || {}),
            domianStatus: status,
            domain: domainName,
            funnelId: item.funnelId,
            slug: item.slug,
            status: DomainStatus.UPDATED
          };
        });
        dispatch(setFunnelDomainInSlice(Object.values(updatedDomains)));
        message.success(GeneralMessages.success);
      })
      .catch(() => {
        message.error(GeneralMessages.error);
      });
  };

  const handleAddSlug = (values: Store) => {
    if (
      localSlugs.length >= domain.maximumSlugs + 1 &&
      !localStorage.getItem('meetovoAllowUnlimitedSlugs')
    ) {
      message.error(ConnectSettingsMessages.maxSlugCountReached(domain.maximumSlugs));
      return;
    }

    const newSlug = {
      id: getNewId(localSlugs),
      slug: values.slug,
      funnelId: values.funnelId,
      tempId: true
    };
    const slugs = [...localSlugs, newSlug];
    setSearchedSlugsIds(slugs.map(({ id }) => id));
    setLocalSlugs(slugs);
    handleSetFieldsValues([...slugs, defaultSlugItem]);
  };

  const handleDeleteSlug = (id: number) => {
    const newSlugs = localSlugs.filter(slug => slug.id !== id);
    setLocalSlugs(newSlugs);
    handleSetFieldsValues([...newSlugs, defaultSlugItem]);
  };

  const syncStateAndFields = (newValues: any) => {
    const newLocalSlugs: SlugInterface[] = normalizeKeysWithIdSuffix(newValues);
    setLocalSlugs(newLocalSlugs.filter(newSlg => newSlg.id !== defaultSlugItem.id))
    setDefaultSlugItem(newLocalSlugs.find(slg => slg.id === defaultSlugItem.id) as SlugInterface);
  };

  const handleSubmitSlug = () => {
    form.submit();
  };

  const handleValuesChange = debounce((changedValues: Store, allValues: Store) => {
    syncStateAndFields(allValues);
  }, 200);

  return (
    <>
      <Modal
        className="connect-settings-changer"
        width={750}
        closeIcon
        closable
        title={domainName}
        visible={open}
        onCancel={() => {
          onClose && onClose();
          setOpen(false);
        }}
        onOk={handleSubmitSlug}
        footer={
          <Button
            data-testid="domainConfirmSave"
            type="primary"
            disabled={addOpen}
            loading={loading}
            htmlType="submit"
            onClick={handleSubmitSlug}
          >
            Speichern
          </Button>
        }
        destroyOnClose
      >
        <DomainStatusProgress currentStatus={status as string} cname={cName} />
        {areThereDisabledFunnels && (
          <>
            <Space size={8} align="baseline" style={{ display: 'flex', justifyContent: 'end' }}>
              <h4>Deaktiverte anzeigen</h4>
              <Switch
                size="small"
                checked={showAllSlugsEnabled}
                onChange={() => setShowAllSlugsEnabled(!showAllSlugsEnabled)}
              />
            </Space>
          </>
        )}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={handleValuesChange}
        >
          <SubDomain
            domainName={domainName}
            formInstance={form}
            defaultSlugValue={defaultSlugItem}
          />
          <h2 className='mt-4'>
            Slugs ({localSlugs.length}/{maximumSlugs})
          </h2>
          <div className="connect-settings-changer__modal-inner" id="slug-list-modal">
            {localSlugs.length > 5 && (
              <Search
                data={localSlugs}
                searchKey="slug"
                onSearch={sids => setSearchedSlugsIds(sids)}
              />
            )}
            {localSlugs
              .map(({ funnelId, slug, id }) => {
                const isDisabled = ![...activeFunnelIds, -1].includes(funnelId);
                const visible =
                  (!showAllSlugsEnabled && isDisabled) || !searchedSlugsIds.includes(id);
                return (
                  <Slug
                    key={id}
                    visible={visible}
                    domainName={domainName}
                    id={id}
                    funnelId={funnelId}
                    slug={slug}
                    formInstance={form}
                    onDelete={handleDeleteSlug}
                    isDisabled={isDisabled}
                  />
                );
              })}
            {!searchedSlugsIds.length && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Keine Slugs gefunden" />
            )}
          </div>
        </Form>

        <AddSlug
          domainName={domainName}
          onAdd={handleAddSlug}
          open={addOpen}
          setOpen={setAddOpen}
        />
      </Modal>
      <span data-test-id="domainEditButtonForExisting" onClick={() => setOpen(true)}>
        {trigger}
      </span>
    </>
  );
};

export default SlugListModal;
