import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import useAdminUsers from '../graphql/useAdminUsers';

const AdminUserSelector = ({ onChange }: { onChange?: (id: number) => void }) => {
  const { data, loading } = useAdminUsers({ input: { userIds: [] } });
  return (
    <>
      <Select
        placeholder="Select users"
        showSearch
        loading={loading}
        style={{ width: '100%', minWidth: 200 }}
        onChange={onChange && onChange}
        filterOption={(inputValue, option) => !!option?.label?.toString().includes(inputValue)}
        notFoundContent={
          loading ? (
            <div style={{ textAlign: 'center', padding: '10px' }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          ) : null
        }
        options={data?.admin_getUsersV2?.data.map((user: any) => ({
          label: `<${user.firstName} ${user.lastName}> ${user.email} ID: ${user.id}`,
          value: user.id,
          dataTestId: `${user.firstName}_${user.lastName}_${user.email}_ID:_${user.id}`
        }))}
        {...(process.env.NODE_ENV === 'test' && { open: true })}
        data-testid="select-coachId"
      />
    </>
  );
};

export default AdminUserSelector;
