import { CSVUploadMessages } from '../../../config/messages';
import { BookingType, StatusInterface } from '../../container/Events';

export enum CSVMappingFields {
  noValue = 'Nichts ausgewählt',
  firstName = 'Vorname',
  lastName = 'Nachname',
  email = 'E-Mail',
  phone = 'Telefon',
  generalPurpose = 'Zusatzfeld',
  // kept for later
  // upload = 'Upload',
  note = 'Notiz'
}

export const mustRequiredFields = [CSVMappingFields.firstName, CSVMappingFields.email];

export const doNotDuplicateFields = [
  CSVMappingFields.firstName,
  CSVMappingFields.lastName,
  CSVMappingFields.email,
  CSVMappingFields.phone,
  CSVMappingFields.generalPurpose
];

export function getEnumKeyByValue<T extends object>(
  enumObj: T,
  value: T[keyof T]
): keyof T | undefined {
  return (Object.keys(enumObj) as Array<keyof T>).find(key => enumObj[key] === value);
}

export function getEnumValueByKey<T extends object>(
  enumObj: T,
  key: keyof T
): T[keyof T] | undefined {
  return enumObj[key];
}

export const sanitizeEmail = (email: string): { firstEmail: string; remaining: string } => {
  const [firstEmail, ...rest] = email.split(/[,; ]+/);
  return {
    firstEmail: firstEmail.trim(),
    remaining: rest.join(' ').trim()
  };
};

export const sanitizePhoneNumber = (
  phoneNumber: string
): { firstPhoneNumber: string; remaining: string } => {
  const [firstPhoneNumber, ...rest] = phoneNumber.split(/[,;]+/);
  return {
    firstPhoneNumber: firstPhoneNumber.trim(),
    remaining: rest.join(' ').trim()
  };
};

export const handleValueMapping = (
  csvData: any[],
  mappingSchema: Record<string, CSVMappingFields>,
  statusMap: Record<string, any> = {},
  requiredFields: string[] = []
) => {
  if (!csvData || csvData.length < 1) return;

  const newData = csvData.map((row: any) => {
    const newRow: any = {};

    Object.entries(mappingSchema).forEach(([key, val]) => {
      // Skip if the field is not checked (not in requiredFields)
      if (!requiredFields.includes(key)) return;
      
      const value = row[key];
      if (val === CSVMappingFields.noValue) return;

      if (newRow?.generalPurpose && val === CSVMappingFields.generalPurpose) {
        newRow.generalPurpose = `${newRow.generalPurpose || ''}\n${value}`;
        return;
      } else if (val === CSVMappingFields.note) {
        newRow.note = `${newRow?.note || ''}\n${key} : ${value}`;
        return;
      }

      if (val === CSVMappingFields.email) {
        const { firstEmail, remaining } = sanitizeEmail(value);
        newRow.email = firstEmail;
        if (remaining) {
          newRow.note = `${newRow.note || ''}\n${remaining}`;
        }
        return;
      }

      if (val === CSVMappingFields.firstName) {
        const lastNameKey = getEnumKeyByValue(mappingSchema,CSVMappingFields.lastName)
        newRow.name = `${value} ${row[lastNameKey as string] || ''}`;
        return;
      }

      if (val === CSVMappingFields.phone) {
        const { firstPhoneNumber, remaining } = sanitizePhoneNumber(value);
        newRow.phone = firstPhoneNumber;
        if (remaining) {
          newRow.note = `${newRow.note || ''}\n${remaining}`;
        }
        return;
      }

      // @ts-ignore
      newRow[getEnumKeyByValue(CSVMappingFields, val)] = value;
    });
    return newRow;
  });
  return newData;
};

export const validateCSVMappingField = (
  selectedField: string,
  field: CSVMappingFields,
  data: any
) => {
  if (selectedField === CSVMappingFields.noValue) {
    return Promise.resolve();
  }

  const nonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';

  switch (selectedField) {
    case CSVMappingFields.firstName:
    case CSVMappingFields.lastName:
    case CSVMappingFields.generalPurpose:
    case CSVMappingFields.note:
      if (!nonEmptyString(data)) {
        return Promise.reject(`The data for ${field} must be a non-empty string!`);
      }
      break;

    case CSVMappingFields.email:
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emails = (data || '').split(';');
      const isValidEmail = emails.every((email: string) => emailPattern.test(email.trim()));
      if (!isValidEmail) {
        return Promise.reject('The data does not contain valid email(s)!');
      }
      break;

    case CSVMappingFields.phone:
      const phonePattern = /^[+\d]?(?:[\d-.\s()]*)$/;
      const phoneNumbers = data
        .split(';')
        .map((phone: string) => phone.trim().replace(/[()]/g, ''));
        
      for (const phoneNumber of phoneNumbers) {
        const isValidPhone = phonePattern.test(phoneNumber);
        if (!isValidPhone) {
          return Promise.reject('The data does not contain a valid phone number!');
        }
        }
      break;

    default:
      break;
  }

  return Promise.resolve();
};

type CSVMappingResult = { [key: string]: CSVMappingFields };

export const mapCSVHeadersToSchema = (headers: string[]): CSVMappingResult => {
  const mappingResult: CSVMappingResult = {};

  headers.forEach(header => {
    // Skip empty header names or headers that are just whitespace
    if (!header || header.trim() === '') {
      return;
    }

    const lowerHeader = header.toLowerCase();
    if (
      lowerHeader.includes('vorname') ||
      lowerHeader.includes('first name') ||
      lowerHeader.includes('firstname') ||
      lowerHeader.includes('first-name')
    ) {
      mappingResult[header] = CSVMappingFields.firstName;
    } else if (
      lowerHeader.includes('nachname') ||
      lowerHeader.includes('last name') ||
      lowerHeader.includes('lastname') ||
      lowerHeader.includes('last-name')
    ) {
      mappingResult[header] = CSVMappingFields.lastName;
    } else if (
      lowerHeader.includes('mail') ||
      lowerHeader.includes('e-mail') ||
      lowerHeader.includes('email') ||
      lowerHeader.includes('e-mail-adresse') ||
      lowerHeader.includes('e-mail address')
    ) {
      mappingResult[header] = CSVMappingFields.email;
    } else if (
      lowerHeader.includes('nummer') ||
      lowerHeader.includes('handynummer') ||
      lowerHeader.includes('telefon') ||
      lowerHeader.includes('telephone') ||
      lowerHeader.includes('telephonenumber') ||
      lowerHeader.includes('number') ||
      lowerHeader.includes('phone') ||
      lowerHeader.includes('mobile')
    ) {
      mappingResult[header] = CSVMappingFields.phone;
    } else if (
      lowerHeader.includes('dokumente') ||
      lowerHeader.includes('lebenslauf') ||
      lowerHeader.includes('documents') ||
      lowerHeader.includes('curriculum vitae') ||
      lowerHeader.includes('resume') ||
      lowerHeader.includes('cv') ||
      lowerHeader.includes('zusatzfeld')
    ) {
      mappingResult[header] = CSVMappingFields.generalPurpose;
    } else {
      mappingResult[header] = CSVMappingFields.note;
    }
  });

  return mappingResult;
};

export const finalizeDataForCSVSubmission = ({
  dataMapped,
  defaultSelectedStatus,
  selectedFunnelId
}: {
  dataMapped: any;
  selectedFunnelId: number;
  defaultSelectedStatus?: StatusInterface;
}) => {
  const finalData = dataMapped.map((data: any) => {
    const sanitizedEmail = sanitizeEmail(data?.email || '').firstEmail;
    const sanitizedPhoneNumber = data?.phone
      ? sanitizePhoneNumber(data?.phone || '').firstPhoneNumber
      : undefined;

    return {
      ...data,
      email: sanitizedEmail,
      ...(sanitizedPhoneNumber && { phone: sanitizedPhoneNumber }),
      funnelId: selectedFunnelId,
      // Always use the default status since status mapping has been removed
      bookingStatusId: defaultSelectedStatus?.id,
      type: BookingType.CSV
    };
  });
  return finalData;
};

export interface ValidationError {
  row: number;
  field: string;
  value: any;
  message: string;
}

export const validateCSVData = (
  csvData: any[],
  mappingSchema: Record<string, CSVMappingFields>,
  requiredFields: string[] = []
): ValidationError[] => {
  const errors: ValidationError[] = [];
  
  if (!csvData || csvData.length < 1) return errors;

  // Check if required fields are mapped
  const mappedFields = Object.values(mappingSchema);
  const missingRequiredFields = mustRequiredFields.filter(
    requiredField => !mappedFields.includes(requiredField)
  );
  
  if (missingRequiredFields.length > 0) {
    // Add a single error for missing required fields
    errors.push({
      row: 0,
      field: 'missing',
      value: null,
      message: CSVUploadMessages.checkAllFields(missingRequiredFields.join(', '))
    });
  }

  // Skip header row if present (row 0)
  csvData.forEach((row, rowIndex) => {
    
    Object.entries(mappingSchema).forEach(([csvField, mappedField]) => {

      if (mappedField === CSVMappingFields.noValue) return;
      
      // Skip validation for unchecked fields, unless they are must-required fields
      const isMustRequiredField = mustRequiredFields.includes(mappedField);
      const isFieldChecked = requiredFields.includes(csvField);
      
      // Only validate if field is checked or it's a must-required field
      if (!isFieldChecked && !isMustRequiredField) return;
      
      const value = row[csvField];
      const fieldErrors = validateField(mappedField, value);
      
      if (fieldErrors) {
        errors.push({
          row: rowIndex,
          field: csvField,
          value,
          message: fieldErrors
        });
      }
    });
  });
  
  return errors;
};

// Pure validation function that doesn't use promises
export const validateField = (
  field: CSVMappingFields,
  value: string | number | null | undefined
): string | null => {
  if (field === CSVMappingFields.noValue) {
    return null;
  }

  const nonEmptyString = (val: string) => typeof val === 'string' && val.trim() !== '';

  switch (field) {
    case CSVMappingFields.firstName:
      
      if (!nonEmptyString(value as string)) {
        return `Name Zuteilung erforderlich`;
      }
      break;

    case CSVMappingFields.email:
      if (!value) return 'E-Mail-Zuteilung erforderlich';
      
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const email = (value as string).trim();
      const isValidEmail = emailPattern.test(email);
      
      if (!isValidEmail) {
        return `Ungültiges E-Mail-Format: ${email}`;
      }
      break;

    case CSVMappingFields.phone:
      if (!value) return null; // Phone can be optional
      
      const phonePattern = /^[+\d]?(?:[\d-.\s()]*)$/;
      const phoneNumber = (value as string).trim().replace(/[()]/g, '');
      const isValidPhone = phonePattern.test(phoneNumber);
      
      if (!isValidPhone) {
        return `Ungültiges Telefonnummernformat: ${phoneNumber}`;
      }
      break;

    default:
      break;
  }

  return null;
};

// Helper to group validation errors by type
export const groupValidationErrors = (errors: ValidationError[]): Record<string, ValidationError[]> => {
  return errors.reduce((groups, error) => {
    const key = `${error.field}:${error.message}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(error);
    return groups;
  }, {} as Record<string, ValidationError[]>);
};

// Helper to get a summary of validation errors
export const getValidationSummary = (errors: ValidationError[]): string[] => {
  const grouped = groupValidationErrors(errors);
  
  return Object.entries(grouped).map(([key, groupErrors]) => {
    const { field, message } = groupErrors[0];
    const rowCount = groupErrors.length;
    const rowNumbers = groupErrors.map(e => e.row).slice(0, 3).join(', ');
    const hasMore = groupErrors.length > 3;
    
    return `${message} in ${rowCount} Zeile(n) [${rowNumbers}${hasMore ? '...' : ''}]`;
  });
};
