import gql from 'graphql-tag';
import { applicantNotificationTypeEnum } from '../../Builder/components/emailTemplate/utils';

export interface Input {
  bookingIds: number[];
  subject?: string;
  html?: string;
  applicantNotificationType: applicantNotificationTypeEnum;
}

const SEND_APPLICANT_Notification = gql`
  mutation sendApplicantNotification($input: SendApplicantNotificationInput!) {
    sendApplicantNotification(input: $input) {
      status
    }
  }
`;

export default function sendApplicantNotification(input: Input) {
  return window.apolloClient.mutate({
    mutation: SEND_APPLICANT_Notification,
    variables: { input }
  });
}
