import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../sharedUI/ActionsController';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { ImageLoadingSpinner } from '../../../SharedUI/components/ImageLoadingSpinner';
import { videoPlaceholder } from '../../config/builderUi';
import { useDraggingElement } from '../../hooks/useDraggingElement';
import {
  CraftElementBaseProps,
  getElementColor,
  getKeyByValue,
  getValueByKey
} from '../../helper/craftJs';
import SettingsGB from '../Settings/SettingsGB';
import { VideoSettingsItems } from '../Settings/SettingsTemplates';
import {
  ImageAvailableBorderRadius,
  ImageAvailableOverlayTransparency,
} from '../../interfaces/ImageSizeInterface';
import { builderPresetElements, FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import PlayCircle from '../voiceMessage/PlayCircle';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { useElementsPresets } from '../../redux/builderSlice';

interface Props extends CraftElementBaseProps {
  videoSrc?: string;
  src?: string;
  thumbnailEnabled?: boolean;
  borderRadius?: string;
  backgroundColor?: string;
  overlay?: number;
  circleEnabled?: boolean;
  freeSelectionEnabled?: boolean;
  showRatioSelector?: boolean;
  overlayColor?: string;
  isPresetOn?: boolean;
  settings?: {
    customAspectRatio?: { height: number, width: number }
  }
}

function VideoComponent(props: Props): JSX.Element {
  const theme = useCurrentlyEditingBuilderTheme();
  const { videoSrc } = props;
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const draggingElement = useDraggingElement();

  const presets = useElementsPresets(builderPresetElements.VIDEO);
  const { actions } = useEditor();
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  useEffect(() => {
    setLoading(true);
  }, [props.videoSrc]);

  const currentProps = props.isPresetOn ? {...currentNode.data.props, ...presets } : currentNode.data.props;

  return (
    <ActionsController
      className="video-wrapper"
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
      style={{
        borderRadius: currentProps.borderRadius,
      }}
    >
      <div
        className={`video-wrapper__inner w-100 h-100 p-0 ${
          draggingElement ? 'disable-video-player' : ''
        }`}
        style={{
          backgroundImage: `url(${videoPlaceholder})`,
          backgroundSize: `100%`,
          borderRadius: currentProps.borderRadius,
        }}
      >
        {!isPlaying && currentProps.thumbnailEnabled && (
          <div
            className="video-wrapper__overlay"
            style={{
              backgroundImage: `url(${currentProps.src})`,
              borderRadius: currentProps.borderRadius
            }}
          >
            <div
              className="position-absolute w-100 h-100"
              style={{
                backgroundColor: getElementColor(currentProps.overlayColor, theme),
                opacity: currentProps.overlay,
                zIndex: 1,
                borderRadius: currentProps.borderRadius,
              }}
            />
            <PlayCircle
              className="video-wrapper__overlay__play-icon"
              isPlaying={isPlaying}
              style={{
                // @ts-ignore
                '--play-icon-bg': getElementColor(currentProps.backgroundColor, theme),
                background: getElementColor(currentProps.backgroundColor, theme)
              }}
              onClick={() => {
                setIsPlaying(true);
              }}
            />
          </div>
        )}
        <ReactPlayer
          style={{
            borderRadius: currentProps.borderRadius,
            overflow: 'hidden'
          }}
          thumbnail={props.src || ''}
          id="react-player-wrapper"
          url={videoSrc || ''}
          controls
          onReady={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
          }}
          playing={isPlaying}
          width="100%"
          height="100%"
          onPlay={() => {
            setIsPlaying(true);
            actions.selectNode(currentNode.id);
          }}
          onPause={() => {
            setIsPlaying(false);
            actions.selectNode(currentNode.id);
          }}
        />
        {loading && <ImageLoadingSpinner />}
      </div>
    </ActionsController>
  );
}

export const VideoDefaultProps: Props = {
  videoSrc: 'https://vimeo.com/732025226',
  src: process.env.PUBLIC_URL + "/image-placeholder.png",
  thumbnailEnabled: false,
  borderRadius: '0px',
  overlay: .5,
  backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  circleEnabled: false,
  showRatioSelector: false,
  overlayColor: "#000",
  isPresetOn: true,
  settings: {
    customAspectRatio: { height: 9, width: 16 },
  }
};

const VideoSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const presets = useElementsPresets(builderPresetElements.VIDEO);

  const finalProps = props.isPresetOn ? { ...props, ...presets } : props;

  const values = {
    ...finalProps,
    borderRadius: getKeyByValue(props.borderRadius, ImageAvailableBorderRadius, "pixelValue").key,
    overlay: getKeyByValue(finalProps.overlay, ImageAvailableOverlayTransparency, "pixelValue").key
  }

  const handleChange = (key: string, value: any) => {
    setProp((props: any) => {
      switch (key) {
        case 'borderRadius':
          props.borderRadius = getValueByKey(value, ImageAvailableBorderRadius).pixelValue;
          break;
        case 'overlay':
          props.overlay = getValueByKey(value, ImageAvailableOverlayTransparency).pixelValue;
          break;
        default:
          props[key] = value;
      }
    });
  };
  return (
    <SettingsGB
      elements={VideoSettingsItems}
      onChange={handleChange}
      values={values}
      settingTitle="Video"
    />
  );
};

VideoComponent.craft = {
  name: CRAFT_ELEMENTS.VIDEO,
  props: VideoDefaultProps,
  rules: {
    canMoveIn: () => {
      return false;
    }
  },
  related: {
    settings: VideoSettings
  }
};

export default VideoComponent;
