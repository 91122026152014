import { DOMSerializer } from '@tiptap/pm/model';
import { Editor } from '@tiptap/react';

export * from './fontSize';

export const getSelectedHTMLInTiptapEditorState = (editor: Editor): string => {
  if (!editor) return '';

  const { empty, from, to } = editor.state.selection;

  if (empty) {
    return editor.getHTML();
  }

  const fragment = editor.state.doc.slice(from, to).content;

  const serializer = DOMSerializer.fromSchema(editor.schema);
  const div = document.createElement('div');

  fragment.forEach(node => {
    div.appendChild(serializer.serializeNode(node));
  });

  return div.innerHTML;
};

interface TextAttributes {
  fontSize: string;
  color: string;
  textAlign: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strike: boolean;
  textStyle: boolean;
  heading: boolean;
  headingLevel: number | null;
  [key: string]: string | boolean | number | null;  // Index signature
}

export const getTextAttributes = (editor: Editor): TextAttributes => {
  if (!editor) return null as unknown as TextAttributes;

  const isHeading = editor.isActive('heading');
  const paragraphAttrs = isHeading ? editor.getAttributes('heading') : editor.getAttributes('paragraph');

  const headingLevel = isHeading ? editor.getAttributes('heading').level : null;

  const activeMarks = {
    bold: editor.isActive('bold'),
    italic: editor.isActive('italic'),
    underline: editor.isActive('underline'),
    strike: editor.isActive('strike'),
    textStyle: editor.isActive('textStyle'),
    heading: isHeading,
    headingLevel
  };

  const textAlign = paragraphAttrs.textAlign || 'left';
  const { fontSize = "" } = editor.getAttributes('fontSize');
  const { color = '' } = editor.getAttributes('textStyle')

  return {
    ...activeMarks,
    fontSize,
    color,
    textAlign,
  };
};

export function replaceTextInEditorState(editor: Editor, newText: string, isUsingSelectedText: boolean, changeColor: boolean) {
  let chain = editor.chain();
  const attributes = getTextAttributes(editor);

  const applyTextFormatting = (chain: any) => {
    if (attributes.textAlign) {
      chain = chain.setTextAlign(attributes.textAlign);
    }
    if (attributes.italic && !changeColor) {
      chain = chain.toggleItalic();
    }
    if (attributes.underline && !changeColor) {
      chain = chain.toggleUnderline();
    }
    if (attributes.strike && !changeColor) {
      chain = chain.toggleStrike();
    }
    if (attributes.fontSize) {
      chain = chain.setFontSize(attributes.fontSize.trim());
    }
    if (attributes.color && !changeColor) {
      chain = chain.setColor(attributes.color.trim())
    }
    return chain;
  };
  const { from, to } = editor.state.selection;

  if (isUsingSelectedText && from !== to) {
    const fromPos = editor.state.doc.resolve(from);
    const toPos = editor.state.doc.resolve(to);
    
    const fromDepth = fromPos.depth;
    const toDepth = toPos.depth;
    
    let nodeCount = 0;
    editor.state.doc.nodesBetween(from, to, (node) => {
      nodeCount++;
      return true; 
    });
    
    const selectedNode = editor.state.doc.nodeAt(from);
    const nodeSize = selectedNode?.nodeSize || 0;
    const nodeStart = fromPos.start();
    const nodeEnd = nodeStart + nodeSize;

    const isFullBlockSelected = from <= nodeStart && to >= nodeEnd;

    const div = document.createElement("div");
    div.innerHTML = newText;
    const parsedContent = div.textContent as string;

    if (isFullBlockSelected) {
      chain = chain
        .focus()
        .deleteSelection()
        .insertContent(newText, {
          parseOptions: { preserveWhitespace: 'full' },
          updateSelection: true
        })
      chain = applyTextFormatting(chain).run();
      const {to: newTo} = editor.state.selection;
      chain = editor.chain().setTextSelection({ from, to: newTo  });
    } else {
      chain = chain
        .focus()
        .insertContentAt({ from: from, to: to }, parsedContent,
          {
            parseOptions: { preserveWhitespace: 'full' },
            updateSelection: true
          }
        )
        .setTextSelection({ from, to: from + parsedContent.length });
    }
    chain = applyTextFormatting(chain);

  } else {
    chain = chain
      .focus()
      .insertContentAt({ from: 0, to: editor.getText().length + 1 }, newText, {
        parseOptions: {
          preserveWhitespace: "full",
        },
      })
      .setTextSelection({ from: 0, to: newText.length + 1 });

    if (changeColor){
      chain = chain.setColor("#fff")
    }
  }

  chain.run();
  return;
};
