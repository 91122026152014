import React, { useEffect } from 'react';
import { Col, Popover, Row, Select, List } from 'antd';
import BuilderSettingsTitle from '../../components/sharedUI/BuilderSettingsTitle';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { removeBuilderValidationError, useBuilderValidatorErrors, useChannelTemplatesData, useCurrentTemplate } from '../../redux/builderSlice';
import { CHANNEL_TYPE } from '../../interfaces/builderSliceTypes';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ChannelMessages } from '../../components/emailTemplate/utils';
import { selectAddOnPermission } from '../../../Marketplace/redux/slice';
import { ADD_ON_KEY } from '../../../Marketplace/config/types';
import useChannelChange from '../../hooks/useChannelChange';
import WarningModalWithHideForeverCheckbox from '../../components/elements/common/WarningModalWithHideForeverCheckbox';
import { smsAvailiblityZones } from '../../helper/smsAvailabilityZones';
import { BUILDER_VALIDATION_ERRORS } from '../BuilderValidator';

const MessageChannelSelector = () => {
  const currentTemplate = useCurrentTemplate();
  const { templates } = useChannelTemplatesData();
  const getBuilderValidatorErrors = useBuilderValidatorErrors();
  const dispatch = useAppDispatch();
  const hasPermission = useAppSelector(state => selectAddOnPermission(state, ADD_ON_KEY.SMS));
  const isSmsDisabled =
    !hasPermission ||
    currentTemplate.emailTemplate?.type === 'INTERNAL_CONFIRMATION' ||
    !Object.keys(currentTemplate.messagingTemplate).length;

  const {
    showModal,
    setShowModal,
    handleChannelChange,
    handleModalCancel,
    handleOkSubmit
  } = useChannelChange();

  useEffect(() => {
    const hasMessageTemplate=templates.some((template)=>{
      return template.sendingChannel === CHANNEL_TYPE.MESSAGE
     })
  if(currentTemplate?.sendingChannel === CHANNEL_TYPE.EMAIL && getBuilderValidatorErrors.includes(BUILDER_VALIDATION_ERRORS.SMS_FORM) && !hasMessageTemplate){
      dispatch(
        removeBuilderValidationError(
          BUILDER_VALIDATION_ERRORS.SMS_FORM
        ))
    }
  },[templates])

  return (
    <>
      <Row className="builder__settings-sidebar__row px-4">
        <Col span={22}>
          <BuilderSettingsTitle title="Kanal" />
        </Col>
        <Col span={2}>
          {!hasPermission && (
            <Popover
              className="default-popover"
              content={
                <div className="default-popover__content">{ChannelMessages.buySMSAddOn}</div>
              }
            >
              <QuestionCircleOutlined />
            </Popover>
          )}
        </Col>
        <Select
          className="w-100"
          onChange={val => {
            handleChannelChange(val);
          }}
          data-testId="messaging-channel-selector"
          value={currentTemplate?.sendingChannel}
          {...(process.env.NODE_ENV === 'test' && { open: true })}
        >
          <Select.Option value={CHANNEL_TYPE.EMAIL} label="E-Mail">
            <div className="custom-select-label disable-builder">
              <span role="img">
                <img src="/dashboard/funnel-builder/message-channel-icon__email.png" />
              </span>
              E-Mail
            </div>
          </Select.Option>

          <Select.Option value={CHANNEL_TYPE.MESSAGE} label="SMS" disabled={isSmsDisabled}>
            <div className="custom-select-label disable-builder">
              <span role="img">
                <img src="/dashboard/funnel-builder/message-channel-icon__sms.png" />
              </span>
              SMS
            </div>
          </Select.Option>
        </Select>
      </Row>
      <WarningModalWithHideForeverCheckbox
        visible={showModal}
        setVisible={setShowModal}
        onCancel={handleModalCancel}
        onSubmit={handleOkSubmit}
        checkboxVisible={true}
        localStorageKey={'MeetovoSmsHideWarning'}
        iconPath="/dashboard/funnel-builder/message-channel-icon__sms.svg"
        cancelButtonText="Weiterhin E-Mail nutzen"
        submitButtonText="SMS-Service aktivieren"
        title={'Wichtige Informationen zum SMS-Service'}
        content={
          <div className="my-2">
            <p>
              Sobald dein SMS-Kontingent aufgebraucht ist, wird es zum Preis von 0,12€ pro SMS, um
              100 aufgestockt.
            </p>
            <p>
              Bei ungültigen Handynummern oder außerhalb der folgenden Länder werden stattdessen
              automatisch E-Mails versendet.
            </p>
            <div className="my-4">
              <p>
                <b>Verfügbare Länder</b>
              </p>
              <div className="d-flex justify-content-center align-items-center">
                {smsAvailiblityZones.map(({ flagPath, label }, index) => (
                  <div className="d-flex" key={index}>
                    <img className="mx-2" src={flagPath} style={{ width: 20 }} /> {label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default MessageChannelSelector;
