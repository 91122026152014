import React from 'react';
import { Spin, Button, Tooltip, Dropdown, Menu } from 'antd';
import { MoreOutlined, SyncOutlined } from '@ant-design/icons';
import FilterPopover from '../../components/FilterPopover';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import ManualBookingModal from '../EventKanban/ManualBookingModal';
import CSVUploadModal from '../CSVUpload/CSVUploadModal';
import useQueryNavigation from '../../hooks/useQueryNavigation';
import { getAllBookingsThunk } from '../../redux/thunk';
import { useAppDispatch } from '../../../redux/hooks';
import { useBookingState } from '../../redux/slice';
import SelectionPopOver from './SelectionPopover';

function EventFilter({ showFunnelButtonText = false }: { showFunnelButtonText?: boolean }) {
  const {
    handleFunnelSelectionChange,
    handleTagsSelectionChange,
    handleOnSearchQueryChange,
    handleOnTypeChange,
    handleStatusChanged,
    handleDownload,
    downloadLoading
  } = useQueryNavigation();

  const dispatch = useAppDispatch();
  const funnels = useAllFunnels();
  const {
    availableStatus,
    selectedBookings,
    loaders: { bookingLoading, paginatedBookingsLoading }
  } = useBookingState();

  const filterPopoverContent = (
    <FilterPopover
      handleTypeChange={handleOnTypeChange}
      handleFunnelSelectionChange={handleFunnelSelectionChange}
      handleTagsSelectionChange={handleTagsSelectionChange}
      handleOnSearchQueryChange={handleOnSearchQueryChange}
      funnels={funnels}
      availableStatus={availableStatus}
      handleChangeStatus={handleStatusChanged}
      showFunnelButtonText={showFunnelButtonText}
    />
  );

  if (showFunnelButtonText) {
    return <div className={'d-flex justify-content-center'}>{filterPopoverContent}</div>;
  }

  const menu = (
    <Menu className="filter-menu">
      <Menu.Item key="manualBooking">
        <ManualBookingModal availableStatus={availableStatus} />
      </Menu.Item>
      <Menu.Item key="csvUpload">
        <CSVUploadModal />
      </Menu.Item>
      <Menu.Item key="export">
        <Tooltip title="Export">
          <>
            <Button
              className="ant-btn__without-background"
              disabled={downloadLoading}
              loading={downloadLoading}
              onClick={handleDownload}
              type={`ghost`}
              icon={<AiOutlineCloudDownload size={22} className="agency-dashboard-brand-color" />}
            >
              Export{' '}
            </Button>
          </>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex align-items-center">
      {selectedBookings.length > 0 ? (
        <SelectionPopOver selectedBookings={selectedBookings} />
      ) : (
        <>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button
              className={`ant-btn__without-background`}
              style={{ marginRight: '0' }}
              icon={<MoreOutlined style={{ fontSize: 20 }} />}
            />
          </Dropdown>
          <div className="d-flex">
            <Tooltip title="Aktualisieren">
              <Spin
                className="d-flex"
                indicator={
                  <SyncOutlined
                    className="agency-dashboard-brand-color"
                    spin={bookingLoading || paginatedBookingsLoading}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(getAllBookingsThunk());
                    }}
                  />
                }
              />
            </Tooltip>
          </div>
          {filterPopoverContent}
        </>
      )}
    </div>
  );
}

export default EventFilter;
