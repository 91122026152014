import React, { useEffect, useRef, useState } from 'react';
import { Alert, Card, Col, Row, Slider, Tabs } from 'antd';
import { useEditor, useNode } from '@craftjs/core';
import { FaTextHeight } from 'react-icons/fa';

import { ActionsController } from '../sharedUI/ActionsController';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { defaultFontSize, FontAvailableSizes, HeadingLevels } from '../../../interfaces/FontSizeInterface';
import { getKeyByValue, getValueByKey } from '../../../helper/craftJs';
import TextAlignmentButtons from '../../../../SharedUI/components/TextAlignmentButtons';
import FontStyleButtons from '../../../../SharedUI/components/FontStyleButtons';
import BuilderColorPickerButton from '../../../components/sharedUI/BuilderColorPickerButton';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import EmojiPicker from '../../../../GeneralComponents/EmojiPicker';
import { RichTextComponentMessages } from '../../../../config/messages';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { CraftElementBaseProps } from './ImageComponent';
import { elementTypeObj, mediaTypeEnum } from '../../interfaces/TextTypeInterface';
import {
  SETTINGS_VALUE,
  useElementSubSettings
} from '../../../helper/richTextHelpers';
import { useSearchParams } from 'react-router-dom';
import AiTextSettings from '../../../components/elements/RichTextComponent/components/AiTextSettings';
import { isWidgetBuilderPath } from '../../../WidgetsBuilder/helper/helper';
import { useCustomEditor } from '../../../../SharedUI/components/useEditor';
import { Editor as TipTapEditor, EditorContent } from "@tiptap/react";
import { useEditorContext } from '../../../context/EditorContext';
import { SliderValue } from 'antd/lib/slider';
import { FONT_MARK } from '../../../components/elements/RichTextComponent/utils';
import { Level } from "@tiptap/extension-heading"
import { useSetSelectedElementTab } from '../../../reduxCommonMutations/setSelectedElementTab';
import { useDispatch } from 'react-redux';
import { setElementTabSelectMutex } from '../../../redux/builderSlice';
import { htmlToPlainText } from '../../../components/elements/RichTextComponent/helpers/textManipulators';

const { TabPane } = Tabs;

interface Props extends CraftElementBaseProps {
  onlySettingsReq?: boolean;
  textType?: string;
  elementType: string;
  className?: string;
  html?: string;
}
const RichTextComponent = ({
  onlySettingsReq,
  elementType,
  className,
  html,
}: Props) => {
  const setElementsTab = useSetSelectedElementTab();
  const [isEditable, setIsEditable] = useState(true);
  const { registerEditor, unregisterEditor } = useEditorContext();
  const { nodeId, nodeProps } = useNode(node => ({
    dragged: node.events.dragged,
    hasSelectedNode: node.events.selected,
    nodeId: node.id,
    nodeProps: node.data.props
  }));

  const {
    query,
    actions: { setProp, history, selectNode }
  } = useEditor();

  const handleChange = (_editor: TipTapEditor) => {
    setProp(nodeId, (prop: any) => {
      prop.html = _editor.getHTML()
    })
  }

  const editor = useCustomEditor({
    content: html ? html : nodeProps.html,
    onChange: handleChange,
    placeholder: "Gib hier deinen Text ein…",
    history: false,
    disableBullets: true
  })

  useEffect(() => {
    if (editor) {
      registerEditor(nodeId, editor);
    }
    return () => {
      if (editor) {
        unregisterEditor(nodeId);
      }
    }
  }, [editor, nodeId, registerEditor, unregisterEditor]);

  useEffect(() => {
    if (!editor || !nodeProps.html) return;

    const { from, to } = editor.state.selection;
    const stripToComparable = (text: string) => {
      return text
        .toLowerCase()
        .replace(/[\s\x00-\x1F\x7F]/g, '')
        .replace(/[\u2000-\u200F\u2028-\u202F\u205F-\u206F]/g, '')
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9!.,?€$%]/g, '');
    };

    const currentText = stripToComparable(editor.getText());
    const newText = stripToComparable(htmlToPlainText(nodeProps.html));

    editor.commands.setContent(nodeProps.html);

    if (from !== undefined && to !== undefined && currentText === newText) {
      editor.commands.setTextSelection({ from, to });
    }
  }, [nodeProps.html]);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    history.ignore().setProp(nodeId, (props: any) => {
      props.elementType = elementType;
    });
  }, []);

  const editorRef = useRef(null);
  const focus = () => {
    setIsEditable(true);
    setDisableDrag(true);
  };
  const dispatch = useDispatch();
  const [disableDrag, setDisableDrag] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });

  return (
    <ActionsController
      className={`rich-text__wrapper ${
        elementType === elementTypeObj[mediaTypeEnum.HAUPTINHALT] ? 'mx-5' : ''
      }`}
      canDrag={true}
      onlySettingsReq={onlySettingsReq}
      label={elementType}
      textAiReq={true}
    >
      <div
        id={nodeId}
        className={`rich-text__inner preserve-selection-editor ${className || ''}`}
        ref={wrapperRef}
        onClick={focus}
        style={{ height: '100%' }}
        onMouseDown={(e) => {
          setDisableDrag(true);
          dispatch(setElementTabSelectMutex(true));

          const handleGlobalMouseUp = (upEvent: MouseEvent) => {
            const selection = window.getSelection();
            const hasTextSelected = selection && selection.toString().length > 0;
            if (hasTextSelected) {
              selectNode(nodeId);
              setElementsTab({payload: "2"})
              setTimeout(() => {
                dispatch(setElementTabSelectMutex(false));
              }, 0)
            } else {
              dispatch(setElementTabSelectMutex(false))
            }
            document.removeEventListener('mouseup', handleGlobalMouseUp);
          };
          document.addEventListener('mouseup', handleGlobalMouseUp);
        }}
      >
        <EditorContent
          ref={editorRef}
          spellCheck={false}
          editor={editor}
          readOnly={!isEditable}
        />
      </div>
    </ActionsController>
  );
};

const marks = {
  1: 'S',
  2: 'M',
  3: 'L',
  4: 'XL'
};

export function RichTextSettings() {
  const {
    props,
    nodeId,
  } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id
  }));

  const [emojiPickerCollapsed, setEmojiPickerCollapsedState] = useState(true);

  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('elementSubSettings');
  const { goToElementSubSettings } = useElementSubSettings();
  const [selectedElementTab, setSelectedElementTab] = useState(paramValue || SETTINGS_VALUE.GENERAL);
  const { getEditor } = useEditorContext();
  const tiptapEditor = getEditor(nodeId);

  useEffect(() => {
    setSelectedElementTab(
      paramValue === SETTINGS_VALUE.AI ? SETTINGS_VALUE.AI : SETTINGS_VALUE.GENERAL
    );
  }, [paramValue]);

  const [_, setForceUpdate] = useState(0);

  useEffect(() => {
    if (!tiptapEditor) return;

    const handleSelectionUpdate = () => {
      setForceUpdate(prev => prev + 1);
    };

    tiptapEditor.on('selectionUpdate', handleSelectionUpdate);

    tiptapEditor.on('update', handleSelectionUpdate);

    return () => {
      tiptapEditor.off('selectionUpdate', handleSelectionUpdate);
      tiptapEditor.off('update', handleSelectionUpdate);
    };
  }, [tiptapEditor])

  const currentStyle = {
    has: (style: string) => {
      if (!tiptapEditor) return false;

      switch (style) {
        case 'BOLD':
          return tiptapEditor.isActive('bold');
        case 'ITALIC':
          return tiptapEditor.isActive('italic');
        case 'UNDERLINE':
          return tiptapEditor.isActive('underline');
        default:
          return false;
      }
    }
  }

  function toggleBlockType(blockType: string) {
    if (!tiptapEditor) return;

    tiptapEditor
      .chain()
      .setTextAlign(blockType as 'left' | 'center' | 'right')
      .focus()
      .run();
  }

  const getBlockType = () => {
    return tiptapEditor?.isActive({ textAlign: "left" }) ? "left" :
      tiptapEditor?.isActive({ textAlign: "right" }) ? "right" :
        tiptapEditor?.isActive({ textAlign: "center" }) ? "center" :
          "left"
  }

  const handleFontSizeChange = (value: SliderValue) => {
    if (!tiptapEditor) return;

    const fontSize = FontAvailableSizes.find(size => size.key === value)?.value || defaultFontSize.value;
    const headingLevel = (HeadingLevels.find(size => size.key === value)?.value || 3) as Level;
    const isParagraph = fontSize === defaultFontSize.value;
    const alignment = getBlockType();

    const isCurrentlyHeading = tiptapEditor.isActive('heading');

    tiptapEditor.chain()
      .setFontSize(fontSize + "em")
      .run();

    if (isParagraph && isCurrentlyHeading) {
      tiptapEditor.chain()
        .setParagraph()
        .setTextAlign(alignment)
        .run();
    } else {
      tiptapEditor.chain()
        .setHeading({ level: headingLevel })
        .setTextAlign(alignment)
        .run();
    }
  };

  const handleColorChange = (color: string) => {
    if (!tiptapEditor) return;
    tiptapEditor.chain().setColor(color).run();
  };

  const handleEmojiSelect = (emoji: string) => {
    if (!tiptapEditor) return;
    tiptapEditor.chain().focus().insertContent(emoji).run();
  };

  function _toggleInlineStyle(inlineStyle: string) {
    if (!tiptapEditor) return;

    switch (inlineStyle) {
      case 'BOLD':
        tiptapEditor.chain().focus().toggleBold().run();
        break;
      case 'ITALIC':
        tiptapEditor.chain().focus().toggleItalic().run();
        break;
      case 'UNDERLINE':
        tiptapEditor.chain().focus().toggleUnderline().run();
        break;
      default:
        break;
    }
  }

  const blockType = tiptapEditor?.isActive({ textAlign: 'left' }) ? 'left'
    : tiptapEditor?.isActive({ textAlign: 'center' }) ? 'center'
    : tiptapEditor?.isActive({ textAlign: 'right' }) ? 'right'
    : 'left';


  const themeColors = useCurrentlyEditingBuilderTheme();

  const handleChangeTab = (value: string) => {
    setSelectedElementTab(value);
    tiptapEditor?.commands?.focus()
  };

  const isWidgetBuilder = isWidgetBuilderPath();
  const enableAiText = !isWidgetBuilder;

  const handleFocusOnSlider = (event: React.MouseEvent) => {
    const handleTiptapFocus = () => {
      tiptapEditor?.commands?.focus();
      document.removeEventListener('mouseup', handleTiptapFocus);
    }
    document.addEventListener('mouseup', handleTiptapFocus);
  }

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card settings-card__no-padding" title="Text" bordered={false}>
        <Tabs
          className="tab-container-style builder__settings-sidebar__container"
          activeKey={selectedElementTab}
          onChange={handleChangeTab}
        >
          <TabPane tab="Formatierung" className="single-tab-container" key={SETTINGS_VALUE.GENERAL}>
            <Card className="settings-card" bordered={false}>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
                  <div onMouseDown={handleFocusOnSlider}>
                    <Slider
                      className="builder-slider-style"
                      marks={marks}
                      min={1}
                      max={4}
                      step={1}
                      tooltipVisible={false}
                      value={tiptapEditor?.getAttributes(FONT_MARK)?.fontSize ? getKeyByValue(tiptapEditor?.getAttributes(FONT_MARK)?.fontSize.split('em')[0], FontAvailableSizes, "value").key : defaultFontSize.value as SliderValue}
                      onChange={handleFontSizeChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Ausrichtung" />
                  <TextAlignmentButtons align={blockType} onChange={toggleBlockType} />
                </Col>
                <Col span={11} offset={2}>
                  <BuilderSettingsTitle title="Style" />
                  <FontStyleButtons
                    hasStyle={style => currentStyle.has(style)}
                    onChange={_toggleInlineStyle}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Farbe" />
                  <BuilderColorPickerButton
                    color={tiptapEditor?.getAttributes('textStyle')?.color || themeColors.textColor}
                    onChange={handleColorChange}
                  />
                </Col>
                <Col span={11} offset={2}>
                  {/*
            Just temporary removed bevause its not wokring for launch
            <BuilderSettingsTitle title="List" />
            <ListStyleButtons style={blockType} onChange={_toggleBlockType} /> */}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle
                    title="😊 Emoji"
                    onCollapseToggleClick={value => setEmojiPickerCollapsedState(value)}
                    collapsed={emojiPickerCollapsed}
                  />
                  {!emojiPickerCollapsed && (
                    <EmojiPicker
                      height={'500px'}
                      emojiSize={30}
                      onChange={handleEmojiSelect}
                    />
                  )}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <Alert
                    message={RichTextComponentMessages.changeFontFamilyInDesignSettings}
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </Card>
          </TabPane>
          <TabPane tab="Text KI" key={SETTINGS_VALUE.AI}>
            {enableAiText && (
              <AiTextSettings
                tiptapEditorState={tiptapEditor}
                showOptionPicker={false}
                elementType={props.elementType}
                changeColor
                setCenter={false}
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </Col>
  );
}

RichTextComponent.craft = {
  name: CRAFT_ELEMENTS.RICH_TEXT,
  props: {
    html: "<p>This is an awesome ad</p>"
  },
  related: {
    settings: RichTextSettings
  }
};

export default RichTextComponent;
