import React, { useState, useCallback } from 'react';
import { Button, Popover, Input, Typography, Space } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ToolbarItem } from './EditorItem';
import { editorClasses } from './EditorHelpers';
import { EditorToolbarProps } from './EditorTypes';

export function LinkBlock({ editor }: Pick<EditorToolbarProps, 'editor'>) {
  const [url, setUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [invalidDomain, setInvalidDomain] = useState(false);

  const formatLink = (input: string) => {
    input = input.trim();

    const urlRegex = /^(https?:\/\/)/;

    if (urlRegex.test(input)) {
      return input;
    }

    if (input.startsWith('www.')) {
      return `https://${input}`;
    }

    return `https://www.${input}`;
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    const previousUrl = editor?.getAttributes('link')?.href || '';
    setAnchorEl(event.currentTarget);
    setUrl(previousUrl);
    setInvalidDomain(false);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setInvalidDomain(false);
  };

  const handleUpdateUrl = useCallback(() => {
    if (invalidDomain) return;
    handleClosePopover();

    if (!url) {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();
    } else {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: formatLink(url) })
        .run();
    }
  }, [editor, url, invalidDomain]);

  const onChange = (value: string) => {
    const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;

    if (value && !domainRegex.test(value)) {
      setInvalidDomain(true);
    } else {
      setInvalidDomain(false);
    }
    setUrl(value);
  }

  if (!editor) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <ToolbarItem
        aria-label="Link"
        active={editor.isActive('link')}
        className={editorClasses.toolbar.link}
        onClick={handleOpenPopover}
        icon={<LinkOutlined />}
      />
      <ToolbarItem
        aria-label="Unset link"
        disabled={!editor.isActive('link')}
        className={editorClasses.toolbar.unsetlink}
        onClick={() =>
          editor.chain().focus().unsetLink().run()
        }
        icon={<LinkOutlined />}
      />
      <Popover
        id={anchorEl ? 'link-popover' : undefined}
        trigger="click"
        placement="topRight"
        visible={!!anchorEl}
        onVisibleChange={(visible: boolean) => {
          if (!visible) handleClosePopover();
        }}
        content={
          <div style={{ padding: '8px', display: 'flex', flexDirection: 'column' }}>
            <Typography.Text strong>URL&nbsp;</Typography.Text>
            <Space direction="horizontal" size="small">
              <Input
                placeholder="z.B. https://example.com/XXX"
                value={url}
                onChange={(event) => onChange(event.target.value)}
                style={{
                  width: 240,
                  ...(invalidDomain ? { borderColor: 'red', boxShadow: '0 0 0 2px #e8cfcf' } : {}),
                }}
              />
              <Button
                type="primary"
                onClick={handleUpdateUrl}
                disabled={invalidDomain}
              >
                Speichern
              </Button>
            </Space>
          </div>
        }
      />
    </div>
  );
}
