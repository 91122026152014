import gql from 'graphql-tag';

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      id
      category
      categoryId
    }
  }
`;

export function getAllCategoriesApi() {
  return window.apolloClient.query({
    query: GET_ALL_CATEGORIES,
    fetchPolicy: 'no-cache'
  });
}
