import { createAsyncThunk } from "@reduxjs/toolkit"
import { message } from "antd"
import gql from "graphql-tag"
import { GeneralMessages } from "../config/messages"

export const GET_ALL_USER_SETTING_FLAGS = gql`
  query getAllUserSettingFlags {
    getAllUserSettingFlags
}`

export const getAllUserSettingFlags = () => {
  return window.apolloClient.query({
    query: GET_ALL_USER_SETTING_FLAGS,
    fetchPolicy: "no-cache"
  })
}

export const getAllUserSettingsFlagsThunk = createAsyncThunk(
  "get-all-user-setting-flags",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getAllUserSettingFlags()
      return data
    } catch (error) {
      message.error(GeneralMessages.error)
      return rejectWithValue(error)
    }
  }
)
