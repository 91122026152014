import gql from 'graphql-tag';
import { ADD_ON_KEY, AddOnPayload } from '../config/types';

export const SUBSCRIBE_ADD_ON = gql`
  mutation subscribeAddOn($addOnId: Int!, $payload: [AddOnPayload]) {
    subscribeAddOn(addOnId: $addOnId, payload: $payload) {
      valid
      validUntil
    }
  }
`;

export default function subscribeAddOnAPI(addOnId: number, payload: AddOnPayload[]) {
  return window.apolloClient.mutate({
    mutation: SUBSCRIBE_ADD_ON,
    variables: { addOnId, payload }
  });
}
