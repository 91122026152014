import { LocalStorageKeys } from '../Share/constants/localstorageKeys';

interface EnvironmentInterface {
  isDev: boolean;
  isLocal: boolean;
  graphqlEndpoint: string;
  domain: string;
  shouldEnableHotjar: boolean;
  mixpanelProjectToken: string;
}

export const redirectAgencyRootUser = () => {
  const domain = getEnvironment().domain;
  location.replace(domain + '/dashboard/meine-funnels');
};

export const isCustomDomain = () =>
  (!location.host.includes('meetovo.de') &&
    !location.host.includes('localhost') &&
    !location.host.includes('.ngrok.io') &&
    !location.host.includes('.ngrok-free.app') &&
    !location.host.includes('192.168') &&
    !location.href.includes('mtignorecustomdomain') &&
    !location.href.includes('dashboard-1.meetovo-funnel.de') &&
    !location.href.includes('dashboard-2.meetovo-funnel.de') &&
    !location.href.includes('dashboard-3.meetovo-funnel.de')) ||
  location.href.includes('forcecustomdomain');

export const getEnvironment = (): EnvironmentInterface => {
  const isLocal = location.href.includes('localhost');

  if (
    location.href.includes('app-test.meetovo.de') ||
    location.href.includes('localhost') ||
    location.host.includes('.ngrok.io') ||
    location.host.includes('.ngrok-free.app') ||
    location.host.includes('192.168') ||
    location.href.includes('mtignorecustomdomain') ||
    location.href.includes('forcecustomdomain') ||
    localStorage.getItem(LocalStorageKeys.MEETOVO_FORCE_CONNECT_DEV) ||
    location.href.includes('dashboard-1.meetovo-funnel.de') ||
    location.href.includes('dashboard-2.meetovo-funnel.de') ||
    location.href.includes('dashboard-3.meetovo-funnel.de')
  ) {
    return {
      isDev: true,
      isLocal,
      graphqlEndpoint: 'https://app-test.meetovo.de/graphql',
      domain: 'https://app-test.meetovo.de',
      shouldEnableHotjar: false,
      mixpanelProjectToken: '8ceda8c284dc87ddec1314ebab4e1c9c'
    };
  } else {
    return {
      isDev: false,
      isLocal,
      graphqlEndpoint: 'https://app.meetovo.de/graphql',
      domain: 'https://app.meetovo.de',
      shouldEnableHotjar: localStorage.disableHotjar !== 'true',
      mixpanelProjectToken: '8bdbccf277fcb38feb062b7fd5d329c1'
    };
  }
};
