import React, { useEffect, useState } from 'react';
import { Empty, message, Modal, Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ConnectSettingsMessages, GeneralMessages } from '../../../config/messages';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import Domain, { DomainInterface } from './Domain';
import DomainConnectInstructions from './DomainConnectInstructions';
import AddDomain from './AddDomain';
import Search from '../../../SharedUI/components/Search';
import EditAgencyDashboardDomain from './EditAgencyDashboardDomain';
import { EDIT_CONTENT_TYPE, useEditResourceContext } from '../../context/ResourceEditContext';
import { getDomainCount } from '../../utils/domainAndSlugValidation';
import { useAllDomains } from '../../../Builder/hooks/useAllDomains';
import {
  useSubscriptionInfo,
  useSubscriptionInfoError
} from '../../../UI/redux/userSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { getCompleteSubscriptionInfo } from '../../../UI/redux/userThunk';

interface DomainListModalProps {
  trigger: JSX.Element;
}
export interface DomainCountInterface {
  [key: string]: number;
}

const DomainListModal = ({ trigger }: DomainListModalProps) => {
  const {
    data: domainData,
    loading: getAllDomainsLoading,
    error: getAllDomainsError,
    refetch: domainRefetch
  } = useAllDomains();
  const { getAllDomains: domains } = domainData || { getAllDomains: [] as DomainInterface[] };
  const [searchedIds, setSearchedIds] = useState([] as number[]);
  const [open, setOpen] = useState(false);

  const [newlyCreatedDomainId, setNewlyCreatedDomainId] = useState<number>();

  const { toggleResourceUsage, resourceEditState } = useEditResourceContext();

  const dispatch = useAppDispatch();
  const subscriptionInfo = useSubscriptionInfo();
  const getSubscriptionInfoError = useSubscriptionInfoError();
  const refetchSubscriptionInfo = () => dispatch(getCompleteSubscriptionInfo());

  const funnelDomains = domains.filter(({ type }) => type === 'FUNNEL');
  const agencyDashboardDomain =
    domains.find(({ type }) => type === 'AGENCY_DASHBOARD') || ({} as DomainInterface);
  const loading = getAllDomainsLoading;
  const error = getAllDomainsError || getSubscriptionInfoError;

  useEffect(() => {
    setSearchedIds(funnelDomains.map(({ id }) => id));
  }, [domains.length]);

  useEffect(() => {
    if (error) message.error(GeneralMessages.error);
  }, [error]);
  const resourceType = EDIT_CONTENT_TYPE.MY_DOMAINS;

  const handleModalOpen = async () => {
    if (resourceEditState[resourceType]) {
      message.info(ConnectSettingsMessages.duplicateResources);
      return;
    }
    await domainRefetch();
    setOpen(true);
    await toggleResourceUsage(resourceType, true);
  };
  const onCancelModal = async () => {
    setOpen(false);
    await toggleResourceUsage(resourceType, false);
  };

  const domainCountData = getDomainCount(domains);

  return (
    <>
      <Modal
        className="connect-settings-changer"
        width={750}
        closeIcon
        closable
        title="Meine Domains"
        visible={open}
        onCancel={onCancelModal}
        footer={null}
        destroyOnClose
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <DefaultSmallLoader loading />
          </div>
        ) : (
          <>
            <DomainConnectInstructions />
            <Tabs>
              <Tabs.TabPane tab="Funnels" key="funnels-tab">
                <>
                  <div className="connect-settings-changer__modal-inner" id="domain-list-modal">
                    {funnelDomains.length > 4 && (
                      <Search
                        data={funnelDomains}
                        searchKey="name"
                        onSearch={sids => setSearchedIds(sids)}
                      />
                    )}

                    {funnelDomains.map(({ id, name, status, slugs, maximumSlugs, cName }) => {
                      if (searchedIds.includes(id))
                        return (
                          <Domain
                            key={id}
                            id={id}
                            name={name}
                            status={status as string}
                            slugs={slugs}
                            maximumSlugs={maximumSlugs}
                            refetchSubscriptionInfo={refetchSubscriptionInfo}
                            newlyCreatedDomainId={newlyCreatedDomainId}
                            setNewlyCreatedDomainId={setNewlyCreatedDomainId}
                            cName={cName}
                          />
                        );
                    })}

                    {!searchedIds.length && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Keine Domains gefunden"
                      />
                    )}
                  </div>
                  <AddDomain
                    refetchSubscriptionInfo={refetchSubscriptionInfo}
                    addable={
                      subscriptionInfo.remainingDomains > 0 ||
                      subscriptionInfo.remainingSubdomains > 0
                    }
                    domainCount={domainCountData.domainCountData}
                    subdomainCount={domainCountData.subdomainCount}
                    maxDomains={subscriptionInfo.maxDomains}
                    setNewlyCreatedDomainId={setNewlyCreatedDomainId}
                  />
                </>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Agency-Dashboard"
                key="agency-dashboard-tab"
                disabled={!subscriptionInfo.agencyDashboardEnabled && !agencyDashboardDomain.id}
              >
                <EditAgencyDashboardDomain
                  domain={agencyDashboardDomain}
                  domainCount={domainCountData.domainCountData}
                  subdomainCount={domainCountData.subdomainCount}
                  maxDomains={subscriptionInfo.maxDomains}
                  refetchSubscriptionInfo={refetchSubscriptionInfo}
                />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Modal>
      <span data-testid="domainEditButton" className="cursor-pointer" onClick={handleModalOpen}>
        {trigger}
      </span>
    </>
  );
};

export default DomainListModal;
