import React, { useState } from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, Input, Row, Select } from 'antd';
import { ActionsController } from '../../../sharedUI/ActionsController';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { CraftElementBaseProps } from '../../../../helper/craftJs';
import { getElementColor } from '../../../../helper/craftJs';
import { BuilderPageDataType, FUNNEL_THEME_KEYS } from '../../../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { useAllBuilderPages, useBuilderPages, useBuilderSelectedPageData, useBuilderSelectedPageId } from '../../../../redux/builderSlice';
import { thankyouPageType } from '../../../../helper/defaultValues';

interface LoadingComponentDefaultPropsInterface extends CraftElementBaseProps {
  value?: string;
  loadingIconColor?: string;
  checkMarkColor?: string;
  isFirstRender?: boolean;
  isColumnIcon?: boolean;
  className?: string;
  loadingIconRotating?: boolean;
  loadingTime?: number;
  checkVisible?: boolean;
}

const DEFAULT_LOADING_DASH_LENGTH = 504

const LoadingComponent = (props: LoadingComponentDefaultPropsInterface) => {
  const [loadingDashLength, setloadingDashLength] = useState(DEFAULT_LOADING_DASH_LENGTH)
  const [checkMarkDashLength, setcheckMarkDashLength] = useState(123)
  const [isAnimationInitialized, setisAnimationInitialized] = useState(false)
  const { currentNode } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id,
    currentNode: node
  }));

  function handleStartLoading() {
    setisAnimationInitialized(true);
    let currentLoadingDashLength = DEFAULT_LOADING_DASH_LENGTH;
  
    const minTime = 3; 
    const minSteps = 5;
    const maxSteps = 30;
    const maxTime = 30;
  
    const loadingTime = Math.max(minTime, props.loadingTime!);
    const steps = Math.round(minSteps + ((loadingTime - minTime) * (maxSteps - minSteps)) / (maxTime - minTime));
  
    const stepIncrement = (1010 - DEFAULT_LOADING_DASH_LENGTH) / steps;
    const intervalTime = (loadingTime / steps) * 1000;

  
    let stepCount = 0;
  
    setloadingDashLength(currentLoadingDashLength + stepIncrement);
    currentLoadingDashLength += stepIncrement;
    stepCount++;
  
    const loadingInterval = setInterval(() => {
      if (stepCount >= steps - 1) { 
        clearInterval(loadingInterval);
        setloadingDashLength(1010);
  
        setTimeout(() => {
          setloadingDashLength(DEFAULT_LOADING_DASH_LENGTH);
          setcheckMarkDashLength(240);
  
          setTimeout(() => {
            setcheckMarkDashLength(0);
            setisAnimationInitialized(false);
          }, 1000);
        }, 450); 
        return;
      }  
      setloadingDashLength(prev => prev + stepIncrement);
      stepCount++;
    }, intervalTime);
  }
  

  const theme = useCurrentlyEditingBuilderTheme();

  return (
    <ActionsController
      className={`loading__element ${props.className}`}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div className="spinner" onMouseEnter={() => !isAnimationInitialized && handleStartLoading()}>
        <svg width="100%" height="100%" viewBox="0 0 200 200" preserveAspectRatio="xMidYMid meet">
          <path className="light-circle" fill="none" stroke-width="3" stroke-miterlimit="10" d="M100, 100 m0, -80 a80,80 0 0,1 0,160 a80,80 0 0,1 0,-160" />
          <path className="circle" fill="none" stroke-width="8" stroke-miterlimit="12" d="M100, 100 m0, -80 a80,80 0 0,1 0,160 a80,80 0 0,1 0,-160" style={{
            strokeDasharray: `${loadingDashLength !== DEFAULT_LOADING_DASH_LENGTH ? loadingDashLength : 'revert-layer'}`,
            stroke: getElementColor(props.loadingIconColor, theme)
          }} />
          <path className="check" fill="none" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" d="M60,100 92,130 140,66" style={{
            stroke: getElementColor(props.checkMarkColor, theme),
            strokeDasharray: `${checkMarkDashLength == 240 ? checkMarkDashLength : 'revert-layer'}`
          }} />
        </svg>
      </div>
    </ActionsController>
  );
};

const LoadingComponentDefaultProps = {
  value: 'AiOutlineLoading3Quarters',
  loadingIconColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  checkMarkColor: '#0fb90f',
  isCoumnIcon: false,
  isFirstRender: true,
  loadingTime: 3,
};

export const LoadingComponentSettings = () => {

  const {
    actions: { setProp },
    props
  } = useNode(node => {
    return {
      props: node.data.props,
      nodeId: node.id,
    };
  });
  const theme = useCurrentlyEditingBuilderTheme();
  let actionLogicUpdated: string | number = 'next-page';
  const pages = useAllBuilderPages();

  if (typeof props.actionLogic === 'number') {
    actionLogicUpdated = pages.some(page => page.id === props.actionLogic)
      ? props.actionLogic
      : 'next-page';
  } else {
    actionLogicUpdated = props.actionLogic;
  }

  const selectedPageId = useBuilderSelectedPageId();
  const selectedPage = useBuilderSelectedPageData();

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Ladeanimation" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Dauer (Sek.)" />
            <Input
              type="number"
              value={props.loadingTime}
              onChange={(e) => {
                const value = Number(e.target.value);
                setProp((props: any) => {
                  props.loadingTime = value ? value : null;
                });
              }}
              onBlur={()=> {
                if (props.loadingTime < 3) {
                  setProp((props: any) => {
                    props.loadingTime = 3;
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={11}>
            <BuilderSettingsTitle title="Ladekreis" />
            <BuilderColorPickerButton
              color={getElementColor(props.loadingIconColor, theme)}
              onChange={(color, colorKey) => {
                setProp((props: any) => {
                  props.loadingIconColor = colorKey || color;
                });
              }}
            />
          </Col>
          <Col span={11} offset={2}>
            <BuilderSettingsTitle title="Haken" />
            <BuilderColorPickerButton
              color={getElementColor(props.checkMarkColor, theme)}
              onChange={(color, colorKey) => {
                setProp((props: any) => {
                  props.checkMarkColor = colorKey || color;
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Aktion" />
            <Select
              data-testid="craftjs-button-component-action-selector-select"
              {...(process.env.NODE_ENV === 'test' && { open: true })}
              className="mt-2 mb-3 w-100"
              value={actionLogicUpdated || "Nächste Seite"}
              onChange={e => {
                setProp((props: any) => props.isLink = false);
                setProp((state: any) => state.actionLogic = e);
              }}
            >
              {selectedPage?.type !== thankyouPageType && (
                <Select.Option value="next-page">Nächste Seite</Select.Option>
              )}
              {pages
                .filter((page: BuilderPageDataType) => page.id !== selectedPageId)
                .map((page: BuilderPageDataType) => (
                  <Select.Option value={page.id} key={page.id}>
                    {page.name}
                  </Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

LoadingComponent.craft = {
  name: CRAFT_ELEMENTS.LOADING_COMPONENT,
  props: LoadingComponentDefaultProps,
  related: {
    settings: LoadingComponentSettings
  }
};

export default LoadingComponent;

