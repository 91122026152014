import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../redux/hooks';
import { textTypesEnum } from '../interfaces/TextTypeInterface';
import { AdTemplate, MediaSuggestionContent } from '../interfaces/MediaSuggestions';
import {
  createAdMediaTemplateThunk,
  createAdTemplateThunk,
  createAiGeneratedAdThunk,
  deleteAdMediaTemplateThunk,
  deleteAdTemplateThunk,
  deleteAdTextTemplateThunk,
  getCompleteAdBuilder,
  saveAdBuilderStateThunk
} from './thunk';
import { createTextTemplateThunk } from '../../AdJobBuilder/redux/thunk';
import { AiGeneratedAdPrompts } from '../components/sharedUI/AdTemplatePreview';

interface textContentType {
  text: string;
  title: string;
  id: number;
  type: textTypesEnum;
}

export interface Ad {
  id: number;
  craftState: string;
}

export interface AdBuilderStateType {
  ad: Ad;
  isAdSaved: boolean;
  shouldSaveAd: boolean;
  userContentTemplate: {
    textTemplates: textContentType[];
    media: MediaSuggestionContent[];
    adTemplate: AdTemplate[];
    adTemplateTypeData: AiGeneratedAdPrompts[];
  };
  loaders: {
    adBuilderStateFetch: boolean;
    adTemplateFetch: boolean;
    creatingAdMediaTemplate: boolean;
    deletingAdMediaTemplate: boolean;
    creatingTextMediaTemplate: boolean;
    deletingTextMediaTemplate: boolean;
    deletingAdTemplate: boolean;
    createAdTemplateType: boolean;
  };
}

const defaultCraftState =
  'eyJST09UIjp7InR5cGXECHJlc29sdmVkTmFtZSI6Ik1haW5Db250YWluZXIifSwiaXNDYW52YXMiOnRydWUsInByb3BzxDlkYXRhLWN5Ijoicm9vdC1jzDVkaXNwbGF51VQsImN1c3RvbSI6e30sImhpZGRlbiI6ZmFsc2UsIm5vZGVzIjpbIjdmZGcyYzZaTjQiXSwibGlua2VkTsYde319LMwg+gDNQWRk9wDMx2/pAM1pZCI6IjE2NjQxMDY5NzExMDLyAMfNTu4AxnBhcmVudCI65gFe+QDW8wDKfQ==';

const INITIAL_STATE: AdBuilderStateType = {
  ad: {
    id: 0,
    craftState: ''
  },
  isAdSaved: true,
  shouldSaveAd: true,
  userContentTemplate: {
    textTemplates: [],
    media: [],
    adTemplate: [],
    adTemplateTypeData: []
  },
  loaders: {
    adBuilderStateFetch: true,
    adTemplateFetch: false,
    creatingAdMediaTemplate: false,
    deletingAdMediaTemplate: false,
    creatingTextMediaTemplate: false,
    deletingTextMediaTemplate: false,
    deletingAdTemplate: false,
    createAdTemplateType: false
  }
};

export const adBuilderUiSlice = createSlice({
  name: 'adBuilderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialAdBuilderState: state => {
      return INITIAL_STATE;
    },
    setCraftState: (state, { payload }: PayloadAction<{ craftState: string }>) => {
      state.ad.craftState = payload.craftState;
    },
    editIsAdSaved: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdSaved = payload;
    },
    setShouldSaveAd: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldSaveAd = payload;
    }
  },
  extraReducers: {
    // @ts-ignore
    [getCompleteAdBuilder.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<{
        funnelAd: Ad;
        adTemplates: AdTemplate[];
        adMediaTemplates: MediaSuggestionContent[];
        adTextTemplates: {
          id: number;
          title: string;
          text: string;
          type: textTypesEnum;
        }[];
      }>
    ) => {
      state.ad = action.payload.funnelAd;
      state.userContentTemplate.adTemplate = action.payload.adTemplates;
      state.userContentTemplate.media = action.payload.adMediaTemplates;
      state.userContentTemplate.textTemplates = action.payload.adTextTemplates;
      state.loaders.adBuilderStateFetch = false;
    },
    //@ts-ignore
    [getCompleteAdBuilder.pending]: (state: AdBuilderStateType) => {
      state.loaders.adBuilderStateFetch = true;
    },
    // @ts-ignore
    [getCompleteAdBuilder.rejected]: (state: AdBuilderStateType) => {
      state.loaders.adBuilderStateFetch = false;
    },
    // @ts-ignore
    [createAdTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<AdTemplate>
    ) => {
      if (!state.shouldSaveAd) return;

      state.userContentTemplate.adTemplate = [
        ...state.userContentTemplate.adTemplate,
        {
          ...action.payload
        }
      ];
      state.loaders.adTemplateFetch = false;
    },
    //@ts-ignore
    [createAdTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;
      state.loaders.adTemplateFetch = true;
    },
    // @ts-ignore
    [createAdTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.adTemplateFetch = false;
    },
    // @ts-ignore
    [createAiGeneratedAdThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.userContentTemplate.adTemplateTypeData = action.payload?.aiGeneratedAd;
      state.loaders.createAdTemplateType = false;
    },
    //@ts-ignore
    [createAiGeneratedAdThunk.pending]: (state: AdBuilderStateType) => {
      state.loaders.createAdTemplateType = true;
    },
    // @ts-ignore
    [createAiGeneratedAdThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.createAdTemplateType = false;
    },
    // @ts-ignore
    [createAdMediaTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<MediaSuggestionContent>
    ) => {
      if (!state.shouldSaveAd) return;

      state.userContentTemplate.media = [
        ...state.userContentTemplate.media,
        {
          ...action.payload
        }
      ];
      state.loaders.creatingAdMediaTemplate = false;
    },
    //@ts-ignore
    [createAdMediaTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.creatingAdMediaTemplate = true;
    },
    // @ts-ignore
    [createAdMediaTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.creatingAdMediaTemplate = false;
    },
    // @ts-ignore
    [createTextTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<{
        id: number;
        title: string;
        text: string;
        type: textTypesEnum;
      }>
    ) => {
      if (!state.shouldSaveAd) return;
      state.userContentTemplate.textTemplates = [
        ...state.userContentTemplate.textTemplates,
        action.payload
      ];
      state.loaders.creatingTextMediaTemplate = false;
    },
    //@ts-ignore
    [createTextTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.creatingTextMediaTemplate = true;
    },
    // @ts-ignore
    [createTextTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.creatingTextMediaTemplate = false;
    },
    // @ts-ignore
    [deleteAdMediaTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<number>
    ) => {
      if (!state.shouldSaveAd) return;

      state.userContentTemplate.media = state.userContentTemplate.media.filter(single => {
        return single.id != action.payload;
      });
      state.loaders.deletingAdMediaTemplate = false;
    },
    //@ts-ignore
    [deleteAdMediaTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.deletingAdMediaTemplate = true;
    },
    // @ts-ignore
    [deleteAdMediaTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.deletingAdMediaTemplate = false;
    },
    // @ts-ignore
    [deleteAdTextTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<{
        id: number;
        type: string;
      }>
    ) => {
      if (!state.shouldSaveAd) return;
      state.userContentTemplate.textTemplates = state.userContentTemplate.textTemplates.filter(
        single => {
          return single.id != action.payload.id;
        }
      );
      state.loaders.deletingTextMediaTemplate = false;
    },
    //@ts-ignore
    [deleteAdTextTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.deletingTextMediaTemplate = true;
    },
    // @ts-ignore
    [deleteAdTextTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.deletingTextMediaTemplate = false;
    },
    // @ts-ignore
    [deleteAdTemplateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<number>
    ) => {
      if (!state.shouldSaveAd) return;

      state.userContentTemplate.adTemplate = state.userContentTemplate.adTemplate.filter(
        template => template.id !== action.payload
      );
      state.loaders.deletingAdTemplate = false;
    },
    //@ts-ignore
    [deleteAdTemplateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.deletingAdTemplate = true;
    },
    // @ts-ignore
    [deleteAdTemplateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.deletingAdTemplate = false;
    },
    // @ts-ignore
    [saveAdBuilderStateThunk.fulfilled]: (
      state: AdBuilderStateType,
      action: PayloadAction<{ craftState: string }>
    ) => {
      if (!state.shouldSaveAd) return;

      state.ad.craftState = action.payload.craftState;
      state.loaders.adBuilderStateFetch = false;
      state.isAdSaved = true;
    },
    //@ts-ignore
    [saveAdBuilderStateThunk.pending]: (state: AdBuilderStateType) => {
      if (!state.shouldSaveAd) return;

      state.loaders.adBuilderStateFetch = true;
    },
    // @ts-ignore
    [saveAdBuilderStateThunk.rejected]: (state: AdBuilderStateType) => {
      state.loaders.adBuilderStateFetch = false;
    }
  }
});
export const {
  setCraftState,
  editIsAdSaved,
  setShouldSaveAd,
  resetToInitialAdBuilderState
} = adBuilderUiSlice.actions;

export const useUserTextTemplates = (key: textTypesEnum) => {
  return useAppSelector(state =>
    state.adBuilderUI.userContentTemplate.textTemplates.filter(({ type }) => type === key)
  );
};

export const useUserMediaTemplates = () => {
  return useAppSelector(state => state.adBuilderUI.userContentTemplate.media);
};
export const useUserAdTemplates = () => {
  return useAppSelector(state => state.adBuilderUI.userContentTemplate.adTemplate);
};

export const useAd = (): Ad => {
  return useAppSelector(state => state.adBuilderUI.ad);
};

export const useUserContentTemplate = (contentType: textTypesEnum) => {
  return useAppSelector(state => {
    return (
      state.adBuilderUI?.userContentTemplate?.textTemplates?.filter(
        ({ type }) => type === contentType
      ) || []
    );
  });
};

export const useAdBuilderLoading = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.loaders.adBuilderStateFetch);
};

export const useAdTemplateTypeData = (): AiGeneratedAdPrompts[] => {
  return useAppSelector(state => {
    return state.adBuilderUI.userContentTemplate.adTemplateTypeData;
  });
};

export const useCreateAdTemplateTypeLoading = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.loaders.createAdTemplateType);
};

export const useDeleteAdTemplateLoading = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.loaders.deletingAdTemplate);
};

export const useDeleteAdMedialTemplateLoading = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.loaders.deletingAdMediaTemplate);
};

export const useDeleteAdTextTemplateLoading = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.loaders.deletingTextMediaTemplate);
};

export const useIsAdSaved = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.isAdSaved);
};

export const useShouldSaveAd = (): boolean => {
  return useAppSelector(state => state.adBuilderUI.shouldSaveAd);
};

export default adBuilderUiSlice.reducer;
