import React from 'react';
import { BuilderBase } from '../../config/builderUi';
import { getCurrentRoute } from '../../helper/sharedFunctions';
import { builderInvalidationClass } from '../../config/builderUi';
import { Menu, Tooltip } from 'antd';

interface IconSidebarBasePropsTypes {
  item: BuilderBase;
  index: number;
  isDisabled: boolean;
  handleNavigate: (item: BuilderBase) => void;
}

export const IconSidebarBase = ({
  item,
  index,
  isDisabled,
  handleNavigate
}: IconSidebarBasePropsTypes) => {
  const currentRoute = getCurrentRoute();

  const handleClick = (item: any) => {
    if (isDisabled) return;
    const isUserAllowed = item.isUserAllowedCheck ? item.isUserAllowedCheck() : true;
    isUserAllowed ? handleNavigate(item) : alert(item.userIsNotAllowedMessage);
  };
  const isActiveIcon =
    location.pathname.includes(item.onClickRoute) ||
    item?.otherRoutes?.some((path: string) => location.pathname.includes(path));

  return (
    <React.Fragment key={index}>
      {item?.item?.submenu ? (
        <>
          <Menu style={{ padding: '0px', display: 'flex', margin: '0px' }} mode="vertical">
            <Menu.SubMenu
              className={`sidebar-icons__submenu ${builderInvalidationClass} ${
                isDisabled ? 'disbabled' : ''
              }`}
              icon={
                <i
                  key={index}
                  className={`sidebar-icons__icon cursor-pointer ${
                    isActiveIcon ? 'sidebar-icons__icon-active' : ''
                  } ${builderInvalidationClass} ${isDisabled ? 'disbabled' : ''}`}
                >
                  {item.icon &&
                    React.cloneElement(item.icon, {
                      key: index,
                      onClick: () => (item.item.route === 'ad-builder' ? null : handleClick(item))
                    })}
                </i>
              }
            >
              {item?.item?.submenu.map((submenuItem: any, submenuIndex: any) => {
                return (
                  <Menu.Item
                    icon={<span className="submenue-icon">{submenuItem.icon}</span>}
                    key={submenuIndex}
                    className={`sidebar-icons__submenu-item ${
                      location.pathname.includes(item.item.submenu![submenuIndex].onClickRoute)
                        ? 'ant-menu-item-selected'
                        : ''
                    } `}
                    onClick={() => handleClick(submenuItem)}
                  >
                    {submenuItem.title}
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          </Menu>
        </>
      ) : (
        <Tooltip placement="right" title={item.description}>
          <i
            key={index}
            className={`sidebar-icons__icon cursor-pointer ${
              isActiveIcon ? 'sidebar-icons__icon-active' : ''
            } ${builderInvalidationClass} ${isDisabled ? 'disbabled' : ''}`}
          >
            {item.icon &&
              React.cloneElement(item.icon, {
                key: index,
                onClick: () => handleClick(item)
              })}
          </i>
        </Tooltip>
      )}
    </React.Fragment>
  );
};
