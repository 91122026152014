import React, { useEffect } from 'react';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { Element, useEditor, useNode } from '@craftjs/core';
import {
  CraftElementBaseProps,
  getUniqueId,
  parentSelectionOnChildClick
} from '../../../../helper/craftJs';
import RichTextComponent from '../../RichTextComponent/container/RichTextComponent';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { useElementsPresets } from '../../../../redux/builderSlice';
import { builderPresetElements } from '../../../../interfaces/builderSliceTypes';
import { useSetSelectedElementTab } from '../../../../reduxCommonMutations/setSelectedElementTab';
import { ImageAvailableBorderRadius } from '../../../../interfaces/ImageSizeInterface';

interface Props extends CraftElementBaseProps {
  image: string;
  label: string;
  editorState?: any;
  opacity?: number;
  isPresetOn?: boolean;
  children?: any;
  borderRadius?: string;
}

const state =
  '<p style="text-align: center"><span class="text-style" style="color: white">🚀 Consectetur adipiscing elit.</span></p><h1 style="text-align: center"><span style="font-size: 2.5em"><span class="text-style" style="color: white">Lorem ipsum dolor sit amet, consectetur adipiscing</span></span></h1><p style="text-align: center"><span class="text-style" style="color: white">Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed magna dictum porta.</span></p>';

export const defaultCarouselSlideImage =
  'https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/bilder/coach-image-cid-1-1687352510049-e0rlig.jpeg';

const CarouselSlide = ({ image, opacity, editorState, children, label }: Props) => {
  const { nodes, id, props, currentNode } = useNode(node => ({
    nodes: node.data.nodes,
    props: node.data.props,
    currentNode: node
  }));

  const { actions, query } = useEditor();

  useEffect(() => {
    if (!children && nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getUniqueId()}
            is={RichTextComponent}
            onlySettingsReq={true}
            html={state}
            canDrag={true}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, id);
    }
  }, []);

  const presets = useElementsPresets(builderPresetElements.CAROUSEL);

  const finalProps = props?.isPresetOn ? { ...props, ...presets } : props;

  const { borderRadius } = finalProps;
  const setSelectedElementTab = useSetSelectedElementTab();

  const handelOpenSettings = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedElementTab(
      {
        payload: '2'
      },
      {
        navigationReq: true
      }
    );
    parentSelectionOnChildClick(id, query, actions.selectNode);
  };

  return (
    <ActionsController
      className={`carousel-item `}
      ParentTag="div"
      id={`slide-${id}`}
      style={{ borderRadius }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div
        className="carousel-item-overlay"
        style={{ opacity: (opacity || 5) / 10, borderRadius }}
        onClick={handelOpenSettings}
      ></div>
      <img src={image} style={{ borderRadius }} className="d-block w-100" alt="..." />
      <div
        style={{ borderRadius }}
        className="carousel-caption"
        onClick={() => {
          parentSelectionOnChildClick(id, query, actions.selectNode);
        }}
      >
        {children}
      </div>
    </ActionsController>
  );
};

export default CarouselSlide;

CarouselSlide.craft = {
  name: CRAFT_ELEMENTS.CAROUSEL_SLIDE,
  props: {
    image: defaultCarouselSlideImage,
    canDrag: false,
    activeActionControllerClassNotRequired: true,
    isPresetOn: true,
    borderRadius: ImageAvailableBorderRadius[1].pixelValue
  },
  rules: {
    canMoveIn: () => {
      return false;
    },
    canMoveOut: () => {
      return false;
    }
  }
};
