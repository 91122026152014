import { sanitizerConfig } from '../../Funnel/utils/sanitizerConfig';
import { EmailTemplateType } from '../interfaces/builderSliceTypes';
import sanitizeHtml from 'sanitize-html';

export const getEmailTemplateSanitizedHtml = ({
  tabContent
}: {
  tabContent: EmailTemplateType;
}) => {
  const sanitizedHtml = sanitizeHtml(tabContent.html, sanitizerConfig);
  return sanitizedHtml;
};
