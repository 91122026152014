import { useEffect, useState } from 'react';
import { useAllSubCategories, useJobInformation } from '../redux/builderSlice';
import { Category } from '../interfaces/builderSliceTypes';

export const useFilteredSubCategories = () => {
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const availableSubCategories = useAllSubCategories();
  const { jobCategory } = useJobInformation();

  useEffect(() => {
    if (jobCategory?.id) {
      setSubCategories(
        availableSubCategories.filter(subCategory => subCategory.categoryId === jobCategory.id)
      );
    }
  }, [jobCategory, availableSubCategories]);

  return subCategories;
};
