import React, { useState, useEffect } from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, message, Row, Slider } from 'antd';
import { BsGearFill } from 'react-icons/bs';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderSettingsTitleWithSwitch from '../sharedUI/BuilderSettingsTitleWithSwitch';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import VoiceRecorder from '../../../VoiceRecorder/container/VoiceRecorder';
import VoiceMessagePreview from '../voiceMessage/VoiceMessagePreview';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import {
  CraftElementBaseProps,
  getElementColor,
  getKeyByValue,
  getValueByKey
} from '../../helper/craftJs';
import { defaultVoiceMessageSize, VoiceMessageSizes } from '../../interfaces/VoiceMessageSizes';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_VOICE_MESSAGE } from '../../graphql/uploadVoiceMessage';
import * as Sentry from '@sentry/browser';
import { VoiceMessageComponentMessages } from '../../../config/messages';
import ImageUploadV3, { CropperShape } from '../../../GeneralComponents/ImageUploadV3';
import { enhanceStockImage } from '../../helper/images';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import { useElementsPresets } from '../../redux/builderSlice';
import { builderPresetElements } from '../../interfaces/builderSliceTypes';

interface Props extends CraftElementBaseProps {
  imageURL?: string;
  align?: string;
  value?: any;
  width?: string;
  color?: FUNNEL_THEME_KEYS;
  useDefaultSettings?: boolean;
}

interface VoiceMessagePreviewProps {
  color: string;
  width: string;
  imageUrl?: string;
  voiceMessageSignedUrl?: string;
}

const voiceMessagePresetsInitialValues = {
  width: defaultVoiceMessageSize.value,
  color: FUNNEL_THEME_KEYS.ACCENT_COLOR
};

const VoiceMessageComponent = (props: Props) => {
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const theme = useCurrentlyEditingBuilderTheme();
  const presets = useElementsPresets(builderPresetElements.VOICE_MESSAGE) || voiceMessagePresetsInitialValues;

  // Use preset values if useDefaultSettings is true
  const width = props.useDefaultSettings ? presets.width : props.width;
  const color = props.useDefaultSettings ? presets.color : props.color;

  return (
    <ActionsController
      className="voice-message-preview"
      style={{ display: 'flex', justifyContent: props.align }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <VoiceMessagePreview
        color={getElementColor(color || FUNNEL_THEME_KEYS.ACCENT_COLOR, theme)}
        width={(width || defaultVoiceMessageSize.value).toString()}
        imageUrl={enhanceStockImage(props.imageURL as string, '200')}
        voiceMessageSignedUrl={props.value}
      />
    </ActionsController>
  );
};

const VoiceMessageComponentDefaultProps = {
  width: defaultVoiceMessageSize.value,
  form: 'square',
  align: 'left',
  value: '',
  color: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  useDefaultSettings: true,
  imageURL: `${process.env.PUBLIC_URL}/profile-image-placeholder.png`
};

const marks = {
  1: 'S',
  2: 'M',
  3: 'L'
};

const defaultVoiceMessageStateValue = {
  url: '',
  blob: new Blob()
};

export const VoiceMessageComponentSettings = () => {
  const [uploadVoiceMessage, { loading }] = useMutation(UPLOAD_VOICE_MESSAGE);
  const [voiceMessageBlob, setVoiceMessageBlob] = useState(defaultVoiceMessageStateValue);
  const theme = useCurrentlyEditingBuilderTheme();
  const presets = useElementsPresets(builderPresetElements.VOICE_MESSAGE) || voiceMessagePresetsInitialValues;

  // Store custom settings with proper typing
  const [customSettings, setCustomSettings] = useState<{
    width: string;
    color: FUNNEL_THEME_KEYS;
  }>({
    width: String(defaultVoiceMessageSize.value),
    color: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  });

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  // Update component when presets change and useDefaultSettings is true
  useEffect(() => {
    if (props.useDefaultSettings) {
      setProp((props: any) => {
        props.useDefaultSettings = true;
        props.width = presets.width;
        props.color = presets.color;
      });
    }
  }, [presets, setProp]);

  const handleUploadVoiceMessage = () => {
    uploadVoiceMessage({
      variables: { input: { voiceMessageFile: voiceMessageBlob.blob, isPublic: true } }
    })
      .then(res => {
        const { voiceMessageSignedUrl } = res.data.uploadVoiceMessage;
        setProp((props: any) => {
          props.value = voiceMessageSignedUrl;
        });
        setVoiceMessageBlob(defaultVoiceMessageStateValue);
      })
      .catch(e => {
        Sentry.captureException(e);
        message.error(VoiceMessageComponentMessages.uploadFailed);
      });
  };

  const handleChangeVoiceMessage = (blob: Blob) => {
    var blobUrl = URL.createObjectURL(blob);
    setVoiceMessageBlob({
      url: blobUrl,
      blob: blob
    });
  };

  const src = enhanceStockImage(props.imageURL);

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Sprachnachricht" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <VoiceRecorder
              uploadLoading={loading}
              onClickUpload={handleUploadVoiceMessage}
              voiceMessageSignedUrl={voiceMessageBlob.url || props.value}
              onChange={handleChangeVoiceMessage}
              onDelete={(key: any) => {
                setVoiceMessageBlob(defaultVoiceMessageStateValue);
                setProp((props: any) => {
                  props.value = '';
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row voice-img__wrapper">
          <Col span={24}>
            <BuilderSettingsTitle title="Bild" />
            <ImageUploadV3
              previewImage={enhanceStockImage(src, '200')}
              onChange={(url: string) => setProp((prop: any) => (prop.imageURL = url))}
              maxHeight={300}
              minWidth={100}
              minHeight={100}
              minZoom={1}
              showRatioSelector={false}
              uploadedImage
              grid
              shape={CropperShape.ROUND}
              restrictPosition={true}
            />
          </Col>
        </Row>

        <Row className='builder__settings-sidebar__row'>
          <Col span={12}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.align}
              onChange={align => {
                setProp((props: any) => {
                  props.align = align;
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitleWithSwitch
              title="Voreinstellungen übernehmen"
              checked={props.useDefaultSettings}
              onChange={(checked) => {
                if (checked) {
                  setCustomSettings({
                    width: props.width,
                    color: props.color as FUNNEL_THEME_KEYS,
                  });
                  setProp((props: any) => {
                    props.useDefaultSettings = checked;
                    props.width = presets.width;
                    props.color = presets.color;
                  });
                } else {
                  setProp((props: any) => {
                    props.useDefaultSettings = checked;
                    props.width = customSettings.width;
                    props.color = customSettings.color;
                  });
                }
              }}
              description={'Voreinstellungen, die du unter “Design” festgelegt hast'}
              icon={<BsGearFill />}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Größe" disabled={props.useDefaultSettings} />
          </Col>
          <Col span={24}>
            <div className={`${props.useDefaultSettings && 'disable-layer__disabled'}`}>
              <Slider
                className="builder-slider-style"
                marks={marks}
                min={1}
                max={3}
                tooltipVisible={false}
                defaultValue={defaultVoiceMessageSize.key}
                value={getKeyByValue(props.width || defaultVoiceMessageSize.value, VoiceMessageSizes).key}
                onChange={(value: number | [number, number]) => {
                  const numericValue = Array.isArray(value) ? value[0] : value;
                  const newWidth = getValueByKey(numericValue, VoiceMessageSizes).value;
                  setProp((props: any) => {
                    props.width = String(newWidth);
                  });
                  setCustomSettings(prev => ({ ...prev, width: String(newWidth) }));
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={12}>
            <BuilderSettingsTitle title="Farbe" disabled={props.useDefaultSettings} />
            <div className={`${props.useDefaultSettings && 'disable-layer__disabled'}`}>
              <BuilderColorPickerButton
                color={getElementColor(props.color, theme)}
                onChange={(color, colorKey) => {
                  const newColor = (colorKey || FUNNEL_THEME_KEYS.ACCENT_COLOR) as FUNNEL_THEME_KEYS;
                  setProp((props: any) => {
                    props.color = newColor;
                  });
                  setCustomSettings(prev => ({ ...prev, color: newColor }));
                }}
                showColorTypeBtns={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

VoiceMessageComponent.craft = {
  name: CRAFT_ELEMENTS.VOICE_MESSAGE,
  props: VoiceMessageComponentDefaultProps,
  related: {
    settings: VoiceMessageComponentSettings
  }
};

export default VoiceMessageComponent;
