import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { AgencyDashboardResponse } from '../AgencyDashboard/utils/types';
import { isCustomDomain } from '../helper/environment';
import { getUniqueIdentifier } from '../CustomerLogin/helper/getUniqieIdentifier';
import { useAppSelector } from '../redux/hooks';
import { selectUserData } from '../UI/redux/userSlice';
import { isTokenValid } from '../Login/container/AuthRoutesHOC';

export const GET_AGENCY_DASHBOARD = gql`
  query {
    getAgencyDashboard {
      id
      primaryColor
      title
      logoURL
      secondaryColor
      faviconURL
      uniqueIdentifier
    }
  }
`;

export const GET_AGENCY_DASHBOARD_BY_DOMAIN = gql`
  query($uniqueIdentifier: String, $customDomain: String) {
    getAgencyDashboard(uniqueIdentifier: $uniqueIdentifier, customDomain: $customDomain) {
      id
      primaryColor
      title
      logoURL
      secondaryColor
      faviconURL
      uniqueIdentifier
    }
  }
`;

const useAgencyDashboard = () => {
  const userData = useAppSelector(selectUserData);
  const tokenValid = isTokenValid();
  const customDomain = isCustomDomain() ? location.host : '';
  const uniqueIdentifier = getUniqueIdentifier();

  return useQuery<AgencyDashboardResponse>(
    userData && tokenValid ? GET_AGENCY_DASHBOARD : GET_AGENCY_DASHBOARD_BY_DOMAIN,
    !userData || !tokenValid
      ? {
          variables: { uniqueIdentifier: !customDomain ? uniqueIdentifier : '', customDomain }
        }
      : {}
  );
};

export default useAgencyDashboard;
