// @flow
import { DraggableId, DraggableLocation } from 'react-beautiful-dnd';
import { BookingInterface, StatusInterface, BookingTag } from '../../container/Events';

export type Id = string;

export type AuthorColors = {
  soft: string;
  hard: string;
};

export type Author = {
  id: Id;
  name: string;
  avatarUrl: string;
  url: string;
  colors: AuthorColors;
};

export type Event = {
  id: Id;
  content: string;
  author: Author;
};

export type Dragging = {
  id: DraggableId;
  location: DraggableLocation;
};

export type EventMap = {
  [key: string]: BookingInterface[];
};

export type Task = {
  id: Id;
  content: string;
};

export enum BookingType {
  NORMAL = 'NORMAL',
  MANUAL = 'MANUAL',
  CSV = 'CSV'
}

export interface EventState {
  bookings: BookingInterface[];
  leadQualifier: any;
  availableStatus: StatusInterface[];
  allBookingTags: BookingTag[];
  allBookingTagsHashMap: {};
  loading: boolean;
  selectedEvent: SelectedEvent | undefined;
  eventCache: { [key: number]: BookingInterface };
  loaders: {
    selectedEventLoading: boolean;
    deleteEventLoading: boolean;
    bookingLoading: boolean;
    eventsLoading: boolean;
    downloadLoading: boolean;
    loadingDeleteBookingStatus: boolean;
    loadingUpdateBookingStatus: boolean;
    loadingCreateBookingStatus: boolean;
    manualBookingLoading: boolean;
    statusChangeLoading: boolean;
    deleteRatingLoading: boolean;
    createOrUpdateRatingLoading: boolean;
    leadQualifierLoading: boolean;
    updateBookingTagLoading: boolean;
    updateLeadQualifierLoading: boolean;
    paginatedBookingsLoading: boolean;
  };
  eventDrawerTabView: EventDrawerTabView;
  isBookingTagsChanged: boolean;
  updateStatusPayload: any;
  error: string | null;
  selectedBookings: BookingInterface[];
  eventsMoved: {
    phase: string;
    count: number;
  };
  eventsMovedToDecline: number[];
}

export enum EventDrawerTabView {
  CONTACT = 'CONTACT',
  INPUTS = 'INPUTS',
  NOTES = 'NOTES',
  UPLOAD = 'UPLOAD',
  COMMUNICATION = 'COMMUNICATION'
}

export interface SelectedEvent {
  event: any;
  index: number | undefined;
}
