import React, { useState } from 'react';
import { Col, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Nodes, useEditor } from '@craftjs/core';
import lz from 'lzutf8';
import TemplatePreview from './TemplatePreview';
import { useDefaultAdTemplate } from '../../data';
import { getClonedTree } from '../../../helper/craftJs';
import { useUserAdTemplates } from '../../redux/adBuilderSlice';
import mixpanel from 'mixpanel-browser';
import { getFunnelIdFromToken, mixpanelEvents } from '../../../../UI/utils/mixpanel';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { useBuilderPages, useFunnelTheme, useJobInformation } from '../../../redux/builderSlice';
import { FUNNEL_THEME_KEYS } from '../../../interfaces/builderSliceTypes';
import { elementTypeObj, mediaTypeEnum } from '../../interfaces/TextTypeInterface';
import { adTemplates } from '../../data/adTemplates';

interface Props {
  span?: number;
  toggleVisible?: (e: boolean) => void;
}

const RenderAdTemplates = ({ span, toggleVisible }: Props) => {
  const userAdTemplates = useUserAdTemplates();
  const { actions, query } = useEditor();
  const [value, setValue] = useState();
  const builderPages = useBuilderPages();
  const funnelTheme = useFunnelTheme();
  const jobInfo = useJobInformation();

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleTemplateSelection = (craftState: string, defaultTemplate: boolean) => {
    const serializedNode = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));
    const newNodes: Nodes = {};

    Object.keys(serializedNode.nodes).forEach(key => {
      newNodes[key] = query.parseSerializedNode(serializedNode.nodes[key]).toNode();

      if (newNodes[key].data.displayName === CRAFT_ELEMENTS.RICH_TEXT) {
        let elementType = '';
        const isMediaText =
          newNodes[key].data.props.elementType === elementTypeObj[mediaTypeEnum.MEDIA_TEXT];
        const isBannerText =
          newNodes[key].data.props.elementType === elementTypeObj[mediaTypeEnum.BANNER_TEXT];

        if (isMediaText) {
          elementType = elementTypeObj[mediaTypeEnum.HAUPTINHALT];
        } else if (isBannerText) {
          elementType = elementTypeObj[mediaTypeEnum.CALL_TO_ACTION];
        }

        let html = newNodes[key].data.props.html || '';
        if (jobInfo && defaultTemplate) {
          html = html.replace(/\[\[LOCATION\]\]/g, jobInfo.address?.place || 'Stadt');
          html = html.replace(/\[\[POSITION\]\]/g, jobInfo.title || 'Position');
          html = html.replace(
            /\[\[SALARY\]\]/g,
            jobInfo.salary?.length ? `${jobInfo.salary[0]}-${jobInfo.salary[1]}` : 'Gehalt'
          );
          html = html.replace(
            /\[\[EMPLOYEMENT_TYPE\]\]/g,
            jobInfo.employmentType?.length
              ? jobInfo.employmentType.join(', ')
              : 'Arbeitsplatzmodell'
          );
        }

        newNodes[key].data.props = {
          ...newNodes[key].data.props,
          elementType,
          html
        };
      }

      if (newNodes[key].data.displayName === CRAFT_ELEMENTS.IMAGE_COLORIZED_BANNER) {
        newNodes[key].data.props = {
          ...newNodes[key].data.props,
          backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR
        };
      }
      if (newNodes[key].data.displayName === CRAFT_ELEMENTS.IMAGE) {
        let json = lz.decompress(lz.decodeBase64(builderPages[0]?.craftState));
        if (json) {
          for (const itemKey in JSON.parse(json)) {
            const item = JSON.parse(json)[itemKey];
            if (item.displayName === CRAFT_ELEMENTS.LOGO) {
              newNodes[key].data.props = {
                ...newNodes[key].data.props,
                logoUrl: item.props.url
                  ? item.props.url
                  : '/dashboard/funnel-builder/default-logo.png',
                backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR
              };
            }
          }
        }
      }
    });

    const clonedTree = getClonedTree(
      {
        rootNodeId: serializedNode.id,
        nodes: newNodes
      },
      query
    );

    actions.addNodeTree(clonedTree, 'ROOT');
    mixpanel.track(mixpanelEvents.CREATE_AD, {
      funnelId: getFunnelIdFromToken()
    });
    toggleVisible?.(false);

    setTimeout(() => {
      const element = document.getElementById(clonedTree.rootNodeId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 1000);
  };

  return (
    <Radio.Group style={{ width: '100%' }} onChange={onChange} value={value}>
      <Row gutter={48}>
        {[adTemplates[0], ...userAdTemplates]?.map((template, index) => (
          <Col span={span || 12} key={index}>
            <TemplatePreview
              {...template}
              handleTemplateSelection={() =>
                handleTemplateSelection(
                  template.craftState,
                  // @ts-ignore
                  'default' in template ? (template.default as boolean) : false
                )
              }
            />
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
};
export default RenderAdTemplates;
