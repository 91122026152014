export const adTypesData = [
  {
    type: 'Statement',
    name: 'Statement',
    description:
      'Tritt mit einer klaren Botschaft nach außen, um gezielt diejenigen abzuholen, die sich davon angesprochen fühlen.',
    imgUrl: `${process.env.PUBLIC_URL}/ad-builder/statement.png`,
    button: 'Auswählen'
  },
  {
    type: 'Vorteile',
    name: 'Vorteile',
    description:
      'Hebe alle wichtigen Vorteile hervor, die dein Unternehmen einem neuem Mitarbeiter bieten kann.',
    imgUrl: `${process.env.PUBLIC_URL}/ad-builder/benefits.png`,
    button: 'Auswählen'
  },
  {
    type: 'Mitarbeiter',
    name: 'Mitarbeiterstimme',
    description:
      'Stelle einen Mitarbeiter vor, um diejenigen anzusprechen, die sich eine ähnliche Kariere wünschen.',
    imgUrl: `${process.env.PUBLIC_URL}/ad-builder/Employee.png`,
    button: 'Auswählen'
  },
  {
    type: 'KeineVorlage',
    name: 'Keine Vorlage',
    description:
      'Sei kreativ und entwickle einen Anzeigen-Typ ganz nach deinen Vorstellungen, ohne Vordefinierung.',
    imgUrl: `${process.env.PUBLIC_URL}/ad-builder/free.png`,
    button: 'Auswählen'
  }
];

export const stepData = [
  {
    step: 'Typ auswählen',
    description: 'Darstellungsweise deiner Anzeige'
  },
  {
    step: 'Inhalte',
    description: 'Bestimme die Inhalte und Ausrichtung'
  },
  {
    step: 'Erstellung',
    description: 'Deine Anzeige wird automatisch generiert'
  }
];

export const statementTypePlaceHolders: { [key: string]: string } = {
  Karriereentwicklung:
    'z.B. Bieten interne Schulungen, Mentorship-Programme und klare Aufstiegsmöglichkeiten in allen Abteilungen.',
  'Work-Life-Balance':
    'z.B. Flexible Arbeitszeiten, Homeoffice-Optionen und zusätzliche Urlaubstage für eine bessere Work-Life-Balance.',
  Unternehmenskultur:
    'z.B. Offene Kommunikation, flache Hierarchien, und regelmäßige Teamevents zur Förderung der Zusammenarbeit.',
  'Innovative Projekte':
    'z.B. Entwicklung von KI-Lösungen für die Gesundheitsbranche, mit direkter Einflussnahme auf Produktentscheidungen.',
  'Stabilität & Sicherheit':
    'z.B. Langfristige Verträge, stetiges Unternehmenswachstum und klare Zukunftsperspektiven für alle Mitarbeiter.',
  'Vision & Sinnhaftigkeit':
    'z.B. Unsere Projekte verbessern den Zugang zu sauberem Wasser in Entwicklungsländern.',
  'Exklusive Weiterbildung':
    'z.B. Zugang zu Online-Kursen, Konferenzen und individuellen Fortbildungsbudgets für persönliche Entwicklung.',
  Teamgeist:
    'z.B. Starkes Teamgefühl durch gemeinsame Projekte, regelmäßige Feedbackrunden und offenes Miteinander.',
  'Vielfalt und Inklusion':
    'z.B. Wir schätzen Diversität: Ein inklusives Arbeitsumfeld mit Mitarbeitern aus über 15 Nationen.'
};
