import React from 'react';
import { Switch, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import {
  BellFilled,
  CheckOutlined,
  ClockCircleFilled,
  CloseOutlined,
  ExclamationOutlined
} from '@ant-design/icons';
import EmailTemplateForm from './EmailTemplateForm';
import {
  switchEmailTemplateTab,
  toggleChannelEnable,
  updateEmailTemplate,
  updateMessageTemplates,
  useChannelTemplatesData
} from '../../redux/builderSlice';
import {
  CHANNEL_TYPE,
  EmailType,
  MessagingTemplateTabType
} from '../../interfaces/builderSliceTypes';
import { isCalendarExistInFunnel } from '../../helper/craftJs';
import { getEmailTemplateTabName } from '../../helper/sharedFunctions';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';
import MessagingTemplateBlock from '../../container/messageSender/MessagingTemplateBlock';

const defaultEnabledEmailTemplates = [
  EmailType.CONFIRMATION,
  EmailType.INTERNAL_CONFIRMATION,
  EmailType.CANCELATION,
  EmailType.SCHEDULED,
  EmailType.NOT_REACHED
];

const alwaysEnabledEmailTemplates = [EmailType.CANCELATION, EmailType.NOT_REACHED];

const { TabPane } = Tabs;

function CreateTemplateTabs(): JSX.Element {
  const { templates, activeTab, brandingEnabled } = useChannelTemplatesData();
  const { calendarEnabled } = isCalendarExistInFunnel();
  const dispatch = useDispatch();

  const handleUpdateEmailTemplate = (data: any): void => {
    dispatch(updateEmailTemplate(data));
  };

  const handleUpdateMessageTemplate = (data: MessagingTemplateTabType): void => {
    dispatch(updateMessageTemplates(data));
  };

  const handleTabChange = (): void => {
    dispatch(saveCompleteBuilderDataThunk({ shouldOnlySaveEmailTemplates: true }));
  };

  const getTabIcon = (type: EmailType): JSX.Element => {
    switch (type) {
      case EmailType.INTERNAL_CONFIRMATION:
        return (
          <span className="template-tab-icon">
            <BellFilled />
          </span>
        );
      case EmailType.CONFIRMATION:
        return (
          <span className="template-tab-icon">
            <CheckOutlined />
          </span>
        );
      case EmailType.SCHEDULED:
        return (
          <span className="template-tab-icon">
            <ClockCircleFilled />
          </span>
        );
      case EmailType.CANCELATION:
        return (
          <span className="template-tab-icon">
            <CloseOutlined />
          </span>
        );
      case EmailType.NOT_REACHED:
        return (
          <span className="template-tab-icon">
            <ExclamationOutlined />
          </span>
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  return (
    <Tabs
      defaultActiveKey={activeTab}
      type="card"
      size="large"
      onChange={async (e) : Promise<void> => {
        await handleTabChange();
        dispatch(switchEmailTemplateTab(e));
      }}
      className={`px-5 ${!brandingEnabled && 'create-email-template__wrapper__disabled'}`}
    >
      {templates?.map((tabContent, i): JSX.Element | undefined => {
        const {emailTemplate} = tabContent;
        const tabDisabled =
          !calendarEnabled && !defaultEnabledEmailTemplates.includes(emailTemplate.type);

        const isReminderTemplate = emailTemplate.type === EmailType.REMINDER;

        if (isReminderTemplate && !calendarEnabled) {
          return undefined;
        }

        return (
          <TabPane
            tab={
              <>
                {getTabIcon(emailTemplate.type)}
                {getEmailTemplateTabName(emailTemplate.type)}
                {!alwaysEnabledEmailTemplates.includes(emailTemplate.type) && !isReminderTemplate && (
                  <Switch
                    disabled={!(+activeTab === i)}
                    className="ml-1"
                    size="small"
                    defaultChecked
                    checked={tabContent.channelEnabled && !tabDisabled}
                    onChange={() => {
                      dispatch(toggleChannelEnable());
                    }}
                  />
                )}
              </>
            }
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}`}
            disabled={tabDisabled}
          >
            {tabContent.sendingChannel === CHANNEL_TYPE.EMAIL ? (
              <EmailTemplateForm
                onChange={handleUpdateEmailTemplate}
                emailTemplate={tabContent.emailTemplate}
                emailTemplateEnabled={tabContent.channelEnabled}
              />
            ) : (
              <MessagingTemplateBlock
                template={tabContent.messagingTemplate}
                enabled={tabContent.channelEnabled}
                onChange={handleUpdateMessageTemplate}
              />
            )}
          </TabPane>
        );
      })}
    </Tabs>
  );
}

export default CreateTemplateTabs;
