import React, { CSSProperties } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';

interface PlayCircleInterface {
  className: string;
  onClick(): any;
  isPlaying: boolean;
  style?: CSSProperties;
}

export default function PlayCircle({ className, onClick, isPlaying, style }: PlayCircleInterface) {
  return (
    <div
      className={`${className} play-circle ${isPlaying ? 'is-playing' : ''}`}
      onClick={() => onClick && onClick()}
      style={style}
    >
      {!isPlaying ? <FaPlay /> : <FaPause /> }
    </div>
  );
}
