import { Button, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiUpload } from 'react-icons/bi';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CSVSteps from './CSVSteps';
import UploadFile from '../Event/UploadFile';
import { acceptedFormatsForCSVBulkApplicants } from '../../helper/uploadFile';
import { parse } from 'papaparse';
import MapFieldsView from './MapFieldsView';
import setManualBookings from '../../graphql/setManualBookings';
import {
  CSVMappingFields,
  finalizeDataForCSVSubmission,
  handleValueMapping,
  mustRequiredFields,
  validateCSVData
} from './helpersCSV';
import { CSVUploadMessages } from '../../../config/messages';
import CSVSubmitTab from './CSVSubmitTab';
import { StatusInterface } from '../../container/Events';
import { handleAddCSVBookingEvent, useBookingState } from '../../redux/slice';
import { useAppDispatch } from '../../../redux/hooks';
import { getAllBookingsThunk } from '../../redux/thunk';

export enum CSVUploadStepsEnum {
  UPLOAD = 1,
  MAP = 2,
  FUNNEL_SELECTION = 0
}

type CSVUploadModalProps = {
  trigger?: React.ReactNode;
};

const CSVUploadModal = ({ trigger }: CSVUploadModalProps) => {
  const dispatch = useAppDispatch();
  const { availableStatus } = useBookingState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<CSVUploadStepsEnum>(
    CSVUploadStepsEnum.FUNNEL_SELECTION
  );
  const [csvData, setCsvData] = useState<any[]>();
  const [csvFields, setCsvFields] = useState<string[]>();
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(true);
  const [dataMapped, setDataMapped] = useState<any[]>([]);
  const [selectedFunnelId, setSelectedFunnelId] = useState(0);
  const [mappingSchema, setMappingSchema] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [defaultSelectedStatus, setDefaultSelectedStatus] = useState<StatusInterface | undefined>(
    availableStatus.find((e: StatusInterface) => e.value === 'NEW')
  );

  const isFirstStep = currentStep === CSVUploadStepsEnum.FUNNEL_SELECTION;
  const isLastStep = currentStep === CSVUploadStepsEnum.MAP;
  const handleModalOpen = (): void => {
    setIsModalOpen(!isModalOpen);
  };
  const onCancelModal = (): void => {
    if (isFirstStep) {
      handleResetState();
      setIsModalOpen(false);
    } else {
      handlePrev();
    }
  };

  const handleUploadSubmit = (files: any) => {
    parse(files?.[0], {
      download: true,
      header: true,

      complete: response => {
        const { data, meta } = response;
        let { fields } = meta;

        // Filter out empty header fields
        fields = fields.filter((field: string) => field && field.trim() !== '');

        // Filter out empty rows where all fields are empty
        const filteredData = data.filter((row: any) => {
          // Check if any field in the row has a non-empty value
          return Object.values(row).some(
            (value: any) => value !== null && value !== undefined && value.toString().trim() !== ''
          );
        });

        setCsvData(filteredData);
        setCsvFields(fields);
      },
      error: () => {
        message.error(CSVUploadMessages.uploadFailed);
      }
    });
  };

  const handleNext = (): void => {
    const nextStep = currentStep + 1;

    switch (currentStep) {
      case CSVUploadStepsEnum.FUNNEL_SELECTION:
        break;
      case CSVUploadStepsEnum.UPLOAD:
        break;
      case CSVUploadStepsEnum.MAP:
        // Check if all required fields are mapped
        const mappedFields = Object.values(mappingSchema);

        // Find the CSV fields that map to required fields
        const requiredFieldKeys = Object.entries(mappingSchema)
          .filter(([_, value]) => mustRequiredFields.includes(value as CSVMappingFields))
          .map(([key]) => key);

        // Check if any of these required field keys are unchecked
        const uncheckedRequiredFields = requiredFieldKeys.filter(
          key => !requiredFields.includes(key)
        );

        if (uncheckedRequiredFields.length > 0) {
          // Get the display names of the unchecked required fields
          const uncheckedFieldNames = uncheckedRequiredFields.map(key => {
            const mappedField = mappingSchema[key] as CSVMappingFields;
            const fieldName =
              csvFields?.findIndex(field => field === key) !== -1 ? key : mappedField;
            return fieldName;
          });

          message.error(CSVUploadMessages.checkAllFields(uncheckedFieldNames.join(', ')));
          return;
        }

        // Check if all required fields are mapped in the schema
        const missingRequiredFields = mustRequiredFields.filter(
          requiredField => !mappedFields.includes(requiredField)
        );

        if (missingRequiredFields.length > 0) {
          message.error(CSVUploadMessages.checkAllFields(missingRequiredFields.join(', ')));
          return;
        }

        // Validate the data before proceeding
        const validationErrors = validateCSVData(csvData as any[], mappingSchema, requiredFields);
        // Filter out the missing required fields error since we already checked that
        const dataErrors = validationErrors.filter(error => error.field !== 'missing');

        if (dataErrors.length > 0) {
          message.error(CSVUploadMessages.validationFailed);
          return;
        }

        const newDataMapped = handleValueMapping(
          csvData as any[],
          mappingSchema,
          [],
          requiredFields
        );
        if (!newDataMapped) {
          message.error(CSVUploadMessages.mapFailed);
          return;
        }
        setDataMapped(newDataMapped);
        handleSubmit(newDataMapped);
        break;
    }

    setCurrentStep(nextStep);
  };
  const handlePrev = (): void => {
    const nextStep = currentStep - 1;

    if (nextStep === -1) {
      onCancelModal();
      return;
    }

    setCurrentStep(nextStep);
  };

  const handleResetState = () => {
    setCurrentStep(CSVUploadStepsEnum.FUNNEL_SELECTION);
    setCsvData([]);
    setCsvFields([]);
    setDataMapped([]);
    setMappingSchema({});
    setSelectedFunnelId(0);
  };

  const handleSubmit = async (mappedData: any[]) => {
    setSubmitLoading(true);

    const finalData = finalizeDataForCSVSubmission({
      dataMapped: mappedData,
      defaultSelectedStatus,
      selectedFunnelId
    });

    try {
      const response = await setManualBookings(finalData);
      await dispatch(handleAddCSVBookingEvent(response.data.createBulkBookings));
      dispatch(getAllBookingsThunk());
    } catch (error) {
      message.error(CSVUploadMessages.submitFailed);
    } finally {
      handleResetState();
    }
    setSubmitLoading(false);
    setIsModalOpen(false);
  };

  const defaultTrigger = (
    <>
      <Button
        className={`ant-btn__without-background`}
        type={`ghost`}
        icon={<BiUpload className={'agency-dashboard-brand-color'} size={22} />}
      >
        CSV hochladen
      </Button>
    </>
  );

  useEffect(() => {
    switch (currentStep) {
      case CSVUploadStepsEnum.FUNNEL_SELECTION:
        const shouldAllowSubmit = selectedFunnelId && defaultSelectedStatus;
        if (shouldAllowSubmit) {
          setIsOkButtonDisabled(false);
        } else {
          setIsOkButtonDisabled(true);
        }
        break;
      case CSVUploadStepsEnum.UPLOAD:
        if (
          !csvData ||
          !csvFields ||
          !((csvData as unknown) as any[])?.length ||
          !((csvFields as unknown) as any[])?.length
        )
          setIsOkButtonDisabled(true);
        else setIsOkButtonDisabled(false);

        break;
      case CSVUploadStepsEnum.MAP:
        setIsOkButtonDisabled(false);
        break;

      default:
        setIsOkButtonDisabled(true);
    }
  }, [currentStep, selectedFunnelId, defaultSelectedStatus, csvData, csvFields]);

  return (
    <>
      <Modal
        className="csv-upload-modal"
        width={1164}
        closeIcon={<CloseOutlined onClick={() => setIsModalOpen(false)} />}
        title={
          <div className="modal-title">
            <div>
              <h3 className="d-inline-block mb-0 me-2">CSV Datei hochladen</h3>
            </div>
          </div>
        }
        visible={isModalOpen}
        footer={
          <>
            <Button onClick={onCancelModal}>{isFirstStep ? 'Abbrechen' : 'Zurück'}</Button>
            <Button
              loading={submitLoading}
              onClick={handleNext}
              type="primary"
              disabled={isOkButtonDisabled}
            >
              {isLastStep ? 'Importieren' : 'Weiter'}
            </Button>
          </>
        }
        destroyOnClose
      >
        <CSVSteps currentStep={currentStep} />
        {currentStep === CSVUploadStepsEnum.FUNNEL_SELECTION && (
          <CSVSubmitTab
            selectedFunnelId={selectedFunnelId}
            setSelectedFunnelId={setSelectedFunnelId}
            defaultSelectedStatus={defaultSelectedStatus}
            setDefaultSelectedStatus={setDefaultSelectedStatus}
            showStatusSelector={true}
          />
        )}
        {currentStep === CSVUploadStepsEnum.UPLOAD && (
          <UploadFile
            multiple={false}
            accept={acceptedFormatsForCSVBulkApplicants}
            handleSubmit={handleUploadSubmit}
            fileInputChangeEvent={files => {
              if (!files) {
                setCsvData(undefined);
                setCsvFields(undefined);
                setIsOkButtonDisabled(true);
              }
            }}
            shouldDefaultSubmit={true}
            title="CSV-Datei Hochladen"
            innerText="Die Datei kann max. 5MB haben und auch nur CSV kann hier hochgeladen werden."
          />
        )}
        {currentStep === CSVUploadStepsEnum.MAP && (
          <MapFieldsView
            csvData={csvData as any[]}
            csvFields={csvFields}
            mappingSchema={mappingSchema}
            setMappingSchema={setMappingSchema}
            setRequiredFields={setRequiredFields}
          />
        )}
      </Modal>
      <span className="cursor-pointer" onClick={handleModalOpen}>
        {trigger || defaultTrigger}
      </span>
    </>
  );
};

export default CSVUploadModal;
