import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import { FaArrowsAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getTextFromHtml } from "../../../Builder/helper/sharedFunctions";
import { StatusInterface } from "../../container/Events";
import { useBookingState } from "../../redux/slice";
import { changeBulkBookingStatusThunk } from "../../redux/thunk";


const MovePopover = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { availableStatus } = useBookingState();
  const statusNew = availableStatus.find(status => status.value === 'NEW') as StatusInterface;
  const [selectedValue, setSelectedValue] = useState({
    value: statusNew?.value,
    props: { data: {...statusNew } },
    color: statusNew?.color,
    status: statusNew?.status
  });
  const toggleVisibility = () => setVisible(!visible)

  const handleChangeStatus = (value: string, props: any) => {
    setSelectedValue({value, props, color: props.data.color, status: props.data.status})
  }

  const onOK = () => {
    dispatch(changeBulkBookingStatusThunk(selectedValue))
    toggleVisibility()
  }

  return (
    <>
      <Button className="selection-button" onClick={toggleVisibility}>
        <FaArrowsAlt />
        <span>Verschieben</span>
      </Button>
      <Modal
        visible={visible}
        title="Phase ändern"
        okText="Bestatigen"
        onCancel={toggleVisibility}
        onOk={() => onOK()}
      >
        <label>Phase ändern in</label>
        <Select
          style={{ width: '100%' }}
          onChange={handleChangeStatus}
          {...(process.env.NODE_ENV === 'test' && { open: true })}
          data-testid="booking-status-selection"
          // @ts-ignore
          defaultValue={
            <div className="d-flex align-items-center" style={{ columnGap: 8 }}>
              <div
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: '50%',
                  backgroundColor: selectedValue.color
                }}
              ></div>
              <span data-testid="current-booking-status">{getTextFromHtml(selectedValue.status)}</span>
            </div>
          }
        >
          {availableStatus &&
            availableStatus.map(single => {
              const colorIndex = availableStatus
                .map((val: StatusInterface) => val.status)
                .indexOf(single.status);
              return (
                <Select.Option key={single.id} value={single.value} data={single}>
                  <div
                    className="d-flex align-items-center"
                    style={{ columnGap: 8 }}
                    data-testid={single.status}
                  >
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: '50%',
                        backgroundColor: availableStatus[colorIndex]?.color
                      }}
                    ></div>
                    <span>{getTextFromHtml(single.status)}</span>
                  </div>
                </Select.Option>
              );
            })}
        </Select>
      </Modal>
    </>
  )
}

export default MovePopover;
