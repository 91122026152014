import React from "react";
import { BookingInterface } from "../../container/Events";
import DeletePopover from "./DeletePopover";
import MovePopover from "./MovePopover";

interface Props {
  selectedBookings: BookingInterface[]
}

const SelectionPopOver = ({
  selectedBookings
}: Props) => {

  return (
    <div className="d-flex align-items-center">
      <h3 className="mb-0 heading-margin">{selectedBookings.length} Bewerber ausgewählt</h3>
      <MovePopover />
      <DeletePopover />
    </div>
  )
}

export default SelectionPopOver;


