export interface SettingsElement {
  component: SettingsComponents;
  classes?: string;
  title?: string;
  key: string | string[];
  block: boolean;
  disabled: boolean;
  icon?: JSX.Element;
  customComponent?: any;
  shouldNotDisableWhenPresetIsOn?: boolean;
  enabilityKey?: string;
  otherProps?: {
    marks?: any;
    'data-testid'?: string;
    [key: string]: any;
  };
}

export interface SettingsElementsWithValues extends SettingsElement {
  value: { [key: string]: any };
}

export enum SettingsComponents {
  SLIDER = 'SLIDER',
  ALIGNMENT_OPTIONS = 'ALIGNMENT_OPTIONS',
  COLOR_PICKER = 'COLOR_PICKER',
  SWITCH = 'SWITCH',
  FONT_STYLE_BUTTONS = 'FONT_STYLE_BUTTONS',
  CUSTOM_COMPONENT = 'CUSTOM_COMPONENT'
}
