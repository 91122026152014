import { FontAvailableSizes } from '../../../../interfaces/FontSizeInterface';

export function replaceHtmlTextColor(html: string, color: string, setCenter: boolean): string {
  if (!html) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const validTags = ['h1', 'h2', 'h3', 'h4', 'p', 'div'];
  const validInLineTags = ['strong', 'i', 'b', 'em', 'br', 'u'];
  const fontSizes = FontAvailableSizes.reduce((acc, size) => {
    if (size.finalHtmlElement) {
      acc[size.finalHtmlElement] = `${size.value}em`;
    }
    return acc;
  }, {} as Record<string, string>);

  const wrapWithColorSpan = (text: string) => {
    const span = doc.createElement('span');
    span.style.color = color;
    span.textContent = text;
    return span;
  };

  const processNode = (node: Node): Node => {
    if (node.nodeType === Node.TEXT_NODE) {
      const parentElementName = node.parentElement?.tagName?.toLowerCase();
      if (parentElementName === 'body') return document.createTextNode('');
      if (!node.textContent?.trim()) return node;
      return wrapWithColorSpan(node.textContent);
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      const tagName = element.tagName.toLowerCase();

      if (tagName === 'span') {
        if (element.style.fontSize) {
          const sizeSpan = doc.createElement('span');
          sizeSpan.style.fontSize = element.style.fontSize;

          Array.from(element.childNodes)
            .map(child => processNode(child))
            .forEach(child => sizeSpan.appendChild(child));

          return sizeSpan;
        }

        // Create a new span to preserve nested elements
        const newSpan = doc.createElement('span');
        newSpan.style.color = color;

        Array.from(element.childNodes)
          .map(child => processNode(child))
          .forEach(child => newSpan.appendChild(child));

        return newSpan;
      }

      if (validInLineTags.includes(tagName)) {
        if (tagName === 'br') {
          return doc.createElement('br');
        }
        const inlineElement = doc.createElement(tagName);
        Array.from(element.childNodes)
          .map(child => processNode(child))
          .forEach(child => inlineElement.appendChild(child));
        return inlineElement;
      }

      const newElement = doc.createElement(validTags.includes(tagName) ? tagName : 'p');

      if (element.style.fontSize) {
        newElement.style.fontSize = element.style.fontSize;
      }
      if (element.style.fontWeight) {
        newElement.style.fontWeight = element.style.fontWeight;
      }
      if (element.style.textAlign)
        newElement.style.textAlign = element.style.textAlign || 'center';
      if (setCenter) {
        const closestDivAlign = element.closest('div')?.style.textAlign;
        newElement.style.textAlign = closestDivAlign || 'center';
      }

      const hasFontSize = element.style.fontSize || fontSizes[tagName as keyof typeof fontSizes];

      if (hasFontSize) {
        const sizeSpan = doc.createElement('span');
        sizeSpan.style.fontSize = element.style.fontSize || fontSizes[tagName as keyof typeof fontSizes];

        Array.from(element.childNodes)
          .map(child => processNode(child))
          .forEach(child => sizeSpan.appendChild(child));

        newElement.appendChild(sizeSpan);
      } else {
        Array.from(element.childNodes)
          .map(child => processNode(child))
          .forEach(child => newElement.appendChild(child));
      }

      return newElement;
    }

    return node;
  };

  const fragment = doc.createDocumentFragment();
  Array.from(doc.body.childNodes)
    .map(node => processNode(node))
    .forEach(node => fragment.appendChild(node));

  doc.body.innerHTML = '';
  doc.body.appendChild(fragment);

  return doc.body.innerHTML;
}

export function htmlToPlainText(html: string): string {
  if (!html) return '';

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const blockElements = ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li', 'tr'];
  blockElements.forEach(tag => {
    const elements = tempDiv.getElementsByTagName(tag);
    for (let i = 0; i < elements.length; i++) {
      if (i > 0 && elements[i].textContent) {
        elements[i].textContent = '\n' + elements[i].textContent;
      }
    }
  });

  const html2 = tempDiv.innerHTML.replace(/<br\s*[\/]?>/gi, '\n');
  tempDiv.innerHTML = html2;

  let text = tempDiv.textContent || '';
  text = text.replace(/\n{3,}/g, '\n\n');
  return text.trim();
}

export function cleanHtml(inputHtml: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputHtml, 'text/html');

  function cleanNode(node: Node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;

      element.removeAttribute('style');
      element.removeAttribute('align');
      element.removeAttribute('class');

      Array.from(element.childNodes).forEach(cleanNode);

      if (!element.innerHTML.trim()) {
        element.remove();
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      const textNode = node as Text;

      const trimmedText = textNode.nodeValue?.trim();

      if (trimmedText) {
        if (!textNode.parentElement || textNode.parentElement === doc.body) {
          const p = doc.createElement('p');
          p.textContent = trimmedText;
          textNode.replaceWith(p);
        }
      } else {
        textNode.remove();
      }
    }
  }

  Array.from(doc.body.childNodes).forEach(cleanNode);

  const cleanedHtml = Array.from(doc.body.childNodes)
    .map(node => (node as HTMLElement).outerHTML)
    .join('');

  return cleanedHtml;
}
