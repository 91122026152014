import React, { useState } from 'react';
import { Tooltip, Modal } from 'antd';
import { tooltipText } from '../../helper/tooltipText';
import { isBuilderInvalid } from '../../container/BuilderValidator';
import { useEditor, useNode } from '@craftjs/core';
import { handleSelectCraftElement } from '../../helper/craftJs';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';
import useGeneralSettingsDataSave from '../../hooks/useGeneralSettingsDataSave';
import { SETTINGS_VALUE, useElementSubSettings } from '../../helper/richTextHelpers';
import { isAdBuilderPath } from '../../AdBuilder/utils/adBuilderUtils';

const TextAiAction = () => {
  const { goToElementSubSettings } = useElementSubSettings();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const imgUrl = `${process.env.PUBLIC_URL}/funnel-builder/text-ai-solution.jpg`;
  const { query, actions } = useEditor();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();
  const isAdBuilder = isAdBuilderPath();

  const {
    openChildSettingsOnParentSettings,
    openParentSettingsOnChildSettings,
    name,
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id,
      name: node.data.custom.displayName || node.data.displayName,
      openChildSettingsOnParentSettings: node.data.props.openChildSettingsOnParentSettings,
      openParentSettingsOnChildSettings: node.data.props.openParentSettingsOnChildSettings
    };
  });

  // quick fix as this modal is only tempory
  const handleSwitchSettings = async (e: any) => {
    if (isBuilderInvalid()) return;
    goToElementSubSettings(SETTINGS_VALUE.AI);
    handleSelectCraftElement({
      actions,
      nodeId,
      query,
      openChildSettingsOnParentSettings,
      openParentSettingsOnChildSettings
    });
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_SWITCH_SETTINGS_IN_RENDER_NODE,
      additionalData: { name, nodeId }
    });
    e.stopPropagation();
    if (!isAdBuilder) {
      await handleGeneralSettingsDataSave('2');
    }
  };

  const showModal = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title={tooltipText.textAi}>
        <li className="mr-2 cursor-pointer action-icon text-ai" onClick={handleSwitchSettings}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1.2em"
            width="1.2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m11 4-.5-1-.5 1-1 .125.834.708L9.5 6l1-.666 1 .666-.334-1.167.834-.708zm8.334 10.666L18.5 13l-.834 1.666-1.666.209 1.389 1.181L16.834 18l1.666-1.111L20.166 18l-.555-1.944L21 14.875zM6.667 6.333 6 5l-.667 1.333L4 6.5l1.111.944L4.667 9 6 8.111 7.333 9l-.444-1.556L8 6.5zM3.414 17c0 .534.208 1.036.586 1.414L5.586 20c.378.378.88.586 1.414.586s1.036-.208 1.414-.586L20 8.414c.378-.378.586-.88.586-1.414S20.378 5.964 20 5.586L18.414 4c-.756-.756-2.072-.756-2.828 0L4 15.586c-.378.378-.586.88-.586 1.414zM17 5.414 18.586 7 15 10.586 13.414 9 17 5.414z"></path>
          </svg>
        </li>
      </Tooltip>
      {/* 
        This modal is not being used anymore
      */}
      <Modal
        className="text-ai-modal-header text-center"
        title={
          <div>
            <img src={imgUrl} alt="ChatGPT" />
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <h2>Text KI - in Kürze verfügbar</h2>
        <div>
          <p>
            <strong>Was macht diese Funktion?</strong>
          </p>
          <p>
            Mit dieser Funktion kannst du Texte generieren lassen, die genau auf deine Zielgruppe
            zugeschnitten sind. Dadurch kannst du wirkungsvollere Texte verfassen, die deine Funnels
            noch erfolgreicher machen
          </p>
          <p>
            Wir wollen sichergehen, dass diese Funktion dir einen großen Nutzen bring. Daher haben
            wir uns entschieden, sie etwas verzögert zu veröffentlichen.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default TextAiAction;
