import React, { CSSProperties, MouseEventHandler } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import { Button } from 'antd';

const defaultIconProps = {
  component: FaTimes,
  className: 'mb-3',
  style: { color: '#e0e0e0', fontSize: 60 }
};
const DefaultEmptyViewForSettingsPanel = ({
  style,
  title,
  description,
  iconProps = defaultIconProps,
  onButtonClick,
  buttonLabel,
  secondButtonIcon,
  secondOnButtonCLick,
  secondButtonLabel
}: {
  title?: string;
  description?: string;
  style?: CSSProperties;
  iconProps?: any;
  secondButtonIcon?: any;
  onButtonClick?(): any;
  secondOnButtonCLick?: MouseEventHandler<HTMLElement>;
  buttonLabel?: string;
  secondButtonLabel?: string;
}) => {
  return (
    <div className="empty-page-list--container" style={style || {}}>
      <Icon {...{ ...defaultIconProps, ...iconProps }} />
      <Typography.Title level={4}>{title || 'Kein Element aktiv'}</Typography.Title>
      <p>
        {description ||
          'Sobald du ein Element anklickst, werden hier die entsprechenden Einstellungen angezeigt.'}
      </p>
      {buttonLabel && (
        <Button type="primary" className="mt-1" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
      {secondButtonLabel && (
        <Button
          type="link"
          icon={secondButtonIcon ? secondButtonIcon : null}
          className="mt-1 color-grey"
          onClick={secondOnButtonCLick}
        >
          {secondButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default DefaultEmptyViewForSettingsPanel;
