import React, { useEffect } from 'react';
import { Frame, useEditor } from '@craftjs/core';
import lz from 'lzutf8';
import { JSONtoCraft, getRandomId } from '../../helper/craftJs';
import { Viewport } from '../../Viewport';
import { AgencyOSBuilderPageDataType } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';

import { Ad } from '../../AdBuilder/redux/adBuilderSlice';
import {
  Job,
  useSaveJobBuilderLoading,
  useShouldReplaceJobPlaceHolders
} from '../../AdJobBuilder/redux/jobBuilderSlice';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import { BuilderPageDataType } from '../../interfaces/builderSliceTypes';
import { useIsAgencyOSBuilderPath } from '../../hooks/useIsAgencyOSBuilderPath';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { useLocation, useNavigate } from 'react-router';
import { jobTemplate } from '../../AdJobBuilder/components/sharedUI/JobPreview';
import { widgetPreview } from '../../WidgetsBuilder/components/elements/widgetPreview';
import { replaceJobPlaceHoldersWithValues } from '../../AdJobBuilder/helper/AdJobHelper';
import { useJobInformation, useTargetAudience } from '../../redux/builderSlice';

interface BuilderPreviewBasePropsTypes {
  children?: React.ReactNode;
  selectedPage?: AgencyOSBuilderPageDataType | BuilderPageDataType | Ad | Job;
  pagesExisting: boolean;
  emptyPageComponent?: any;
  className?: string;
  shouldReplacePlaceHolders?: boolean;
  styles?: { [key: string]: any };
}

const previewTemplate = null;

export const BuilderPreviewBase = ({
  children,
  selectedPage,
  emptyPageComponent = <></>,
  pagesExisting,
  className = '',
  styles,
  shouldReplacePlaceHolders
}: BuilderPreviewBasePropsTypes) => {
  const loading = useSaveJobBuilderLoading();
  const { actions, store } = useEditor();
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  const theme = useCurrentlyEditingBuilderTheme();
  const { pathname } = useLocation();
  const jobData = useJobInformation();
  const targetGroupData = useTargetAudience();
  const jobRelatedData = { ...jobData, ...targetGroupData };
  const shouldReplaceJobPlaceHolders = useShouldReplaceJobPlaceHolders();

  useEffect(() => {
    store.history.clear();
  }, [selectedPage?.id, pathname]);

  useEffect(() => {
    if (!selectedPage?.craftState) return;
    window.__CRAFT_STATE__ = selectedPage.craftState;
    if (!previewTemplate) {
      let json = lz.decompress(lz.decodeBase64(selectedPage.craftState));
      // ! #############
      // ! Comment the below condition in case you want to get the  __CRAFT_STATE__
      // ! #############
      if (shouldReplacePlaceHolders) {
        const { text } = replaceJobPlaceHoldersWithValues(
          {
            text: `${JSON.stringify(json)}`,
            id: 2
          },
          jobRelatedData
        );
        json = JSON.parse(text);
      }
      actions.history.ignore().deserialize(json);
    }
    window.convertCraftStateToJson = state => {
      return JSON.parse(lz.decompress(lz.decodeBase64(state)));
    };
  }, [selectedPage?.id, shouldReplaceJobPlaceHolders]);

  return (
    <>
      <div
        id="builder-preview"
        className={className}
        data-mutation-observer-id={getRandomId()}
        style={{ ...styles, ...(!pagesExisting ? { height: '100%' } : {}) }}
      >
        {children}
        <DefaultLoader loading={loading} />
        {previewTemplate ? (
          <Viewport>
            <Frame>{previewTemplate}</Frame>
          </Viewport>
        ) : pagesExisting ? (
          <Viewport>
            <Frame></Frame>
          </Viewport>
        ) : (
          <div className="h-100">{emptyPageComponent}</div>
        )}
      </div>
    </>
  );
};
