import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const BannerTop = ({
  text,
  subText,
  imageUrl,
  imageUrlMobile
}: {
  text: string | React.ReactElement;
  subText: string;
  imageUrl: string;
  imageUrlMobile?: string;
}) => {
  return (
    <Row className="banner-top-container">
      <Col
        span={14}
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 14 }}
        className="text-side flex-vertical-center-column"
      >
        <div className="text-area">
          <Title className="text-heading" level={1}>
            {text}
          </Title>
          <Paragraph className="banner-text-paragraph">{subText}</Paragraph>
        </div>
      </Col>
      <Col span={10} xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} className={'image-side'}>
        <img src={imageUrl} className={imageUrlMobile ? 'show-on-min-md' : ''} />
        {imageUrlMobile && <img src={imageUrlMobile} className="show-on-max-md" />}
      </Col>
    </Row>
  );
};

export default BannerTop;
