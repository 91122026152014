import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  Tag,
  message,
  Tooltip,
  Typography,
  Space,
  Button,
  Dropdown,
  Menu,
  Checkbox
} from 'antd';
import { StarFilled } from '@ant-design/icons';
import moment from 'moment';
import { BookingInterface, BookingTag } from '../container/Events';
import ActionModal from './Event/ActionModal';
import { AgencyDashboarMessages } from '../../config/messages';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import DefaultEmpty from '../../SharedUI/components/DefaultEmpty';
import dateFormats from '../../config/dateFormats';
import ActionEventIcon from './Event/ActionEventIcon';
import { getColorForRating } from '../helper/bookingRating';
import { Store } from 'antd/lib/form/interface';
import { getTextFromHtml } from '../../Builder/helper/sharedFunctions';
import RenderBookingTags from './EventKanban/RenderBookingTags';
import { getTagsFromHashMap } from '../helper/bookingTags';
import {
  MoreOutlined,
  CloseOutlined,
  ContactsOutlined,
  DeleteOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  changeBookingStatusLocally,
  setAllIdsSelection,
  setSelectedIds,
  useBookingState
} from '../redux/slice';
import {
  updateBookingStatusAndAddNote,
  deleteEventThunk,
  handleSetSelectedEvent
} from '../redux/thunk';
import { selectUserData } from '../../UI/redux/userSlice';
import { handleLocalDelete } from '../redux/slice';
import { calculateAverageRating } from '../helper/bookingRating';
import { useFilteredBookings } from '../hooks/useFilteredBookings';
import BookingItemRating from './EventKanban/BookingItemRating';

export interface ActionInterface {
  event: BookingInterface;
  action: 'APPROVED' | 'DECLINE';
  value: any;
  color?: string;
}

export const EventTable = ({ loading, funnels }: { loading: boolean; funnels: any[] }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUserData);
  const [toBeDeleted, setToBeDeleted] = useState<number | undefined>();

  const {
    allBookingTagsHashMap,
    bookings,
    selectedBookings,
    loaders: { deleteEventLoading }
  } = useBookingState();

  const filteredBookings = useFilteredBookings();
  const [action, setAction] = useState<ActionInterface | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const paginatedData = useMemo(() => {
    dispatch(setAllIdsSelection({ records: [], check: true }));
    return (filteredBookings || bookings).slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [filteredBookings, bookings, currentPage, pageSize]);

  useEffect(() => {
    if (paginatedData.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [paginatedData, currentPage]);

  const handleDeleteClick = (e: any, event: BookingInterface) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      deleteEventThunk({
        id: event?.id,
        version: event.version,
        callBack: handleLocalDelete,
        userData
      })
    );
    setToBeDeleted(undefined);
  };

  const funnelTitleById = funnels.reduce((acc, { title, id }) => {
    acc[id] = title;
    return acc;
  }, {});

  const calculateAverage = (rating: any) => {
    if (!rating) return 0;
    return parseFloat(
      ((rating.competancy + rating.communicationSkills + rating.teamCompatibility) / 3).toFixed(1)
    );
  };

  const handleCheck = (record: BookingInterface, checked: boolean) => {
    dispatch(
      setSelectedIds({
        record,
        check: checked
      })
    );
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (text: any, record: any) => {
        return (
          <div className="d-flex">
            <div className="table-column-icon">
              {record.bookingActionEvent && <ActionEventIcon record={record?.bookingActionEvent} />}
            </div>
            <div>
              <strong>{record?.name || 'Unvollständig'}</strong>
              <div>{record?.email || 'Der Funnel wurde verlassen'}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'FUNNEL',
      dataIndex: 'funnelId',
      key: 'funnelId',
      width: '300px',
      render: (funnelId: string, record: any) => {
        const maxLength = 50;
        const title = funnelTitleById[funnelId];

        if (!title) return '';

        if (title.length > maxLength) return title.substring(0, maxLength) + '...';

        return title;
      }
    },
    {
      title: 'DATUM',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      render: (text: any, record: any) => moment(text).format(dateFormats.dateReadableMonth)
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      width: '200px',
      render: (tags: BookingTag[]) => {
        const bookingTags = getTagsFromHashMap(tags, allBookingTagsHashMap);
        return <div>{tags && <RenderBookingTags tags={bookingTags} />}</div>;
      }
    },
    {
      title: 'BEWERTUNG',
      dataIndex: 'bookingRatings',
      key: 'bookingRatings',
      width: '150px',
      render: (text: any, record: any) => {
        return (
          <div className="d-flex ">
            <BookingItemRating event={record} />
          </div>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'createdAt',
      key: 'createdAt',
      maxWidth: '200px',
      render: (text: any, event: any) => {
        return (
          event?.bookingStatus?.status && (
            <div className="table-column-status">
              <Tag color={event?.bookingStatus?.color || 'default'}>
                {getTextFromHtml(event?.bookingStatus?.status)}
              </Tag>
              <div className="table-column-status__date">
                Geändert am {moment(text).format(dateFormats.dateReadableMonth)}
              </div>
            </div>
          )
        );
      }
    },
    {
      title: '',
      dataIndex: 'accept-reject',
      key: 'accept-reject',
      width: '50px',
      // align: 'right',
      render: (text: any, event: any, idx: number) => {
        return toBeDeleted === idx ? (
          deleteEventLoading ? (
            <LoadingOutlined />
          ) : (
            <Space size="small">
              <Button
                danger
                type="link"
                onClick={e => {
                  handleDeleteClick(e, event);
                }}
              >
                <DeleteOutlined />
                Löschen
              </Button>
              <Button type="link">
                <CloseOutlined
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setToBeDeleted(undefined);
                  }}
                />
              </Button>
            </Space>
          )
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="no-select"
                  key="4"
                  style={{ color: 'red' }}
                  onClick={e => {
                    e.domEvent.stopPropagation();
                    e.domEvent.preventDefault();
                    setToBeDeleted(idx);
                  }}
                >
                  <DeleteOutlined /> Löschen
                </Menu.Item>
              </Menu>
            }
          >
            <div className="funnel-overview__card__action no-select">
              <MoreOutlined
                style={{ fontSize: 20 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          </Dropdown>
        );
      }
    }
  ];

  const handleSubmit = (e: Store) => {
    if (action?.event.id)
      dispatch(updateBookingStatusAndAddNote({ e, action })).then(() => {
        // @ts-ignore
        changeBookingStatusLocally([{ id: action.event.id, index: action }]);
        setAction(undefined);
        message.success(AgencyDashboarMessages.changeStatusSuccess);
      });
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: filteredBookings.length || bookings.length,
    hideOnSinglePage: true
  };

  const handleBulkSelection = () => {
    const currentState = selectedBookings.length === paginatedData.length;
    dispatch(setAllIdsSelection({ records: paginatedData, check: currentState }));
  };

  const rowSelection = {
    selectedRowKeys: selectedBookings.map(i => i.id),
    type: 'checkbox' as const,
    fixed: true,
    onSelect: (record: any, value: boolean) => {
      handleCheck(record, value);
    },
    columnTitle: (
      <Checkbox
        checked={!!selectedBookings.length}
        indeterminate={
          selectedBookings.length > 0 && selectedBookings.length < paginatedData.length
        }
        onChange={() => handleBulkSelection()}
      />
    )
  };

  return (
    <>
      <Table
        rowKey={record => record.id}
        rowSelection={rowSelection}
        locale={{
          emptyText: (
            <DefaultEmpty
              icon={<ContactsOutlined />}
              title="Keine Bewerbungen vorhanden"
              description="Hier werden deine Bewerbungen aufgelistet, sobald sich jemand in deinen Funnel eingetragen hat."
            />
          )
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              dispatch(handleSetSelectedEvent({ event: record, index: rowIndex }));
            }
          };
        }}
        columns={columns}
        dataSource={paginatedData}
        loading={
          loading ? { indicator: <DefaultSmallLoader loading={true} fontSize={30} /> } : false
        }
        pagination={paginationConfig}
        scroll={{ scrollToFirstRowOnChange: true }}
        onChange={handleTableChange}
      />
      <ActionModal
        isOpen={!!action}
        handleClose={() => {
          setAction(undefined);
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
