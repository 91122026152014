import React, { useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  message,
  Typography,
  Row,
  Col
} from 'antd';
import PhoneInput from '../../../Builder/components/elements/ContactForm/components/PhoneInput';
import LoginValidationRules from '../../../Login/utils/LoginValidationRules';
import {
  defaultDateValidation,
  defaultSelectFieldValidation,
  defaultStringFieldValidation
} from '../../../UI/utils/formValidation';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { BookingStatus } from '../../container/Events';
import moment from 'moment';
import { ManualBookingMessages } from '../../../config/messages';
import { Store } from 'antd/lib/form/interface';
import FunnelSelectList from '../../../UI/components/FunnelSelectList';
import { useDispatch } from 'react-redux';
import { manualBooking } from '../../redux/thunk';
import { BookingType } from './types';
import { useBookingState } from '../../redux/slice';

interface ManualBookingModalProps {
  availableStatus: BookingStatus[];
}

export default function ManualBookingModal({ availableStatus }: ManualBookingModalProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const {
    loaders: { manualBookingLoading }
  } = useBookingState();

  const handleAddManualBookingEvent = async (values: Store) => {
    const { funnel, status, phoneNumber, applicationDate, ...rest } = values;
    const name = rest.lastName ? `${rest.firstName} ${rest.lastName}` : rest.firstName;

    await dispatch(
      manualBooking({
        input: {
          funnelId: funnel,
          bookingStatusId: status,
          createdAt: applicationDate,
          name,
          type: BookingType.MANUAL,
          ...rest
        },
        shouldCreate: true
      })
    );
  };

  const handleSubmit = async (values: Store) => {
    try {
      await handleAddManualBookingEvent(values);
    } catch (error) {
      message.error(ManualBookingMessages.error);
    } finally {
      form.resetFields();
      setShowModal(false);
    }
  };

  return (
    <div className="d-flex">
      <Button
        className={`ant-btn__without-background`}
        type={`ghost`}
        onClick={() => setShowModal(true)}
        icon={<PlusOutlined className={'agency-dashboard-brand-color'} size={22} />}
      >
        Manuell anlegen
      </Button>

      <Modal
        title={
          <div className="d-flex align-items-center">
            <UserAddOutlined style={{ marginRight: '5px' }} />
            <h3>Manuell anlegen</h3>
          </div>
        }
        width={950}
        visible={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button type="default" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={manualBookingLoading}
            disabled={manualBookingLoading}
            onClick={() => form.submit()}
          >
            Erstellen
          </Button>
        ]}
        className="manual-booking-modal"
      >
        <div>
          <Typography.Text>
            Füge einen Bewerber manuell hinzu, indem du folgende Informationen ausfüllst.
          </Typography.Text>
        </div>
        <Form
          name="account_update_form"
          form={form}
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleSubmit}
          initialValues={{
            applicationDate: moment()
          }}
          className="manual-booking-form"
        >
          <div style={{ padding: 20 }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <h3>Bewerber Informationen</h3>
              </Col>
              <Col span={12}>
                <h3>Zuordnung</h3>
              </Col>

              <Col span={12}>
                <Form.Item label="Vorname" name="firstName" rules={defaultStringFieldValidation}>
                  <Input placeholder="Max" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Funnel" name="funnel" rules={defaultSelectFieldValidation}>
                  <FunnelSelectList />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Nachname" name="lastName">
                  <Input placeholder="Mustermann" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Status" name="status" rules={defaultSelectFieldValidation}>
                  <Select
                    placeholder={'Status auswählen'}
                    options={availableStatus?.map(status => ({
                      label: status.status,
                      value: status.id
                    }))}
                    defaultValue={availableStatus?.[0]?.id}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="E-mail" name="email" rules={LoginValidationRules.email}>
                  <Input placeholder="max@mustermann.de" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Bewerbungsdatum"
                  name="applicationDate"
                  rules={defaultDateValidation}
                >
                  <DatePicker
                    className="w-100"
                    showTime={{ use12Hours: false }}
                    disabledDate={current => current && current > moment().endOf('day')}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Telefonnummer"
                  name="phone"
                  className="mb-0 message-contact-number"
                >
                  <PhoneInput inputClass="w-100" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
