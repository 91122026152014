import { formatNumber } from '../Builder/AdJobBuilder/helper/validateFunnelJobPresets';
import { additionalTokens } from '../constants';

export const getCurrentChatAiUsageProgress = (
  currentChatAiTokens: number,
  maxChatAiTokens: number
): string => formatNumber(currentChatAiTokens) + '/' + formatNumber(maxChatAiTokens);

export const getChatAiUsage = (subscriptionInfo: any) => {
  const {
    remainingChatAiTokens,
    remainingAdditionalChatAiTokens,
    maxChatAiTokens
  } = subscriptionInfo;

  const maxTokens =
    remainingAdditionalChatAiTokens > 0 ? maxChatAiTokens + additionalTokens : maxChatAiTokens;
  const currentChatAiTokens = Math.max(
    maxTokens - (remainingChatAiTokens + remainingAdditionalChatAiTokens),
    0
  );

  const currentChatAiUsageProgress = getCurrentChatAiUsageProgress(currentChatAiTokens, maxTokens);
  const currentChatAiUsagePercentage = (currentChatAiTokens * 100) / maxTokens;

  return {
    currentChatAiUsageProgress,
    currentChatAiUsagePercentage
  };
};
