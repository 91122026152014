import { useEffect } from 'react';
import {
  getAndValidateParams,
  getSessionStorageData,
  setSessionStorageData
} from '../Login/helper/login';
import { SessionStorageKeys } from '../Share/constants/sessionStorageKeys';

export const useStoredQueryParams = () => {
  useEffect(() => {
    const utmParams = getAndValidateParams();

    if (
      Object.keys(utmParams).length > 0 &&
      !getSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS)
    ) {
      setSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS, utmParams);
    }
  }, []);
};
