import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';
import createAdMediaTemplate, {
  Input as CreateAdMediaTemplateInput
} from '../../graphql/createAdMediaTemplate';
import createAdTemplate, { Input as CreateAdTemplateInput } from '../../graphql/createAdTemplate';
import deleteAdMediaTemplate from '../../graphql/deleteAdMediaTemplate';
import deleteAdTemplate from '../../graphql/deleteAdTemplate';
import deleteAdTextTemplate from '../../graphql/deleteAdTextTemplate';
import { shouldSaveOrShowMessage } from '../../helper/sharedFunctions';
import { fetchCompleteAdBuilder } from '../../hooks/useGetCompleteAdBuilder';
import { AdBuilderStateType } from './adBuilderSlice';
import { useCraftSerializedState } from '../../helper/craftJs';
import lz from 'lzutf8';
import { Delete } from 'craftjs-utils-meetovo';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import updateAd from '../../graphql/updateAd';
import { getAiGeneratedAd } from '../../graphql/getAiGeneratedAd';
import { saveCompanyBenefits, saveJobTitle } from '../../redux/thunk';
import { updateUserSubscriptionInfo, UserState } from '../../../UI/redux/userSlice';
import { AdGeneration, setAdGenerationInput } from '../../graphql/setAdGenerationInput';
import { BuilderState } from '../../interfaces/builderSliceTypes';
import { defaultTokenDeductionPerImageGeneration } from '../../../constants';
import { AD_TYPES } from '../Templates/container/TemplateTypesView';

export const getCompleteAdBuilder = createAsyncThunk(
  'get-complete-ad-builder',
  async (data, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      const { data } = await fetchCompleteAdBuilder();
      return data;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createAdTemplateThunk = createAsyncThunk(
  'create-ad-template',
  async (data: CreateAdTemplateInput, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      const res = await createAdTemplate(data);
      return res.data.createAdTemplate;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createAiGeneratedAdThunk = createAsyncThunk(
  'create-ai-generated-ad',
  async (
    {
      formData,
      adGenerationData,
      shouldGenerateAd
    }: {
      formData: any;
      adGenerationData: AdGeneration;
      shouldGenerateAd: Boolean;
    },
    thunkAPI
  ) => {
    const { adBuilderUI, user, builderUI } = thunkAPI.getState() as {
      adBuilderUI: AdBuilderStateType;
      user: UserState;
      builderUI: BuilderState;
    };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);
    if (!shouldSave) return;
    try {
      const {
        benefits,
        title,
        [`promptMessage${formData.type}`]: promptMessage,
        ...rest
      } = formData;

      if (benefits) {
        thunkAPI.dispatch(saveCompanyBenefits({ benefits }));
      }
      if (title) {
        thunkAPI.dispatch(saveJobTitle({ title }));
      }

      const adGenerationInputResponse = await setAdGenerationInput({
        promptMessage,
        ...rest,
        id: adGenerationData?.id,
        nodeId: adGenerationData?.nodeId
      });

      const { id, nodeId } = adGenerationInputResponse?.data?.setAdGenerationInput;

      const createAiGeneratedAdInput = {
        adInputId: id,
        type: formData.type,
        ...(adGenerationData?.regenerateAd ? { regenerateAd: adGenerationData.regenerateAd } : {})
      };

      if (shouldGenerateAd) {
        const {
          remainingChatAiTokens,
          remainingAdditionalChatAiTokens
        } = user?.userSubscriptionInfo;
        const response = await getAiGeneratedAd(createAiGeneratedAdInput);
        const aiGeneratedAd = response.data.getAiGeneratedAd.response;
        const adImage = response.data.getAiGeneratedAd.imgUrl;
        let usedTokens = aiGeneratedAd.reduce(
          (totalTokens: number, item: { [key: string]: any }) => totalTokens + item.totalTokens,
          0
        );
        if (formData.type !== AD_TYPES.EMPLOYEE_TESTIMONIAL && adImage) {
          usedTokens += defaultTokenDeductionPerImageGeneration;
        }

        const remainingTokens =
          remainingChatAiTokens >= usedTokens
            ? Math.max(remainingAdditionalChatAiTokens - usedTokens, 0)
            : Math.max(remainingChatAiTokens - usedTokens, 0);

        const variables: any =
          remainingChatAiTokens >= usedTokens
            ? { remainingAdditionalChatAiTokens: remainingTokens }
            : { remainingChatAiTokens: remainingTokens };

        thunkAPI.dispatch(updateUserSubscriptionInfo(variables));
        return { aiGeneratedAd, nodeId, adImage };
      } else {
        return {
          nodeId
        };
      }
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createAdMediaTemplateThunk = createAsyncThunk(
  'create-ad-media-template',
  async (data: CreateAdMediaTemplateInput, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      const res = await createAdMediaTemplate(data);
      return res.data.createAdMediaTemplate;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteAdMediaTemplateThunk = createAsyncThunk(
  'delete-ad-media-template',
  async (id: number, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      await deleteAdMediaTemplate({ id });
      return id;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteAdTextTemplateThunk = createAsyncThunk(
  'delete-ad-text-template',
  async (data: { id: number; type: string }, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      await deleteAdTextTemplate({ id: data.id });
      return data;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteAdTemplateThunk = createAsyncThunk(
  'delete-ad-template',
  async (id: number, thunkAPI) => {
    const { adBuilderUI } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(adBuilderUI.shouldSaveAd);

    if (!shouldSave) return;

    try {
      await deleteAdTemplate({ id });
      return id;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const saveAdBuilderStateThunk = createAsyncThunk(
  'save-ad-builder-state',
  async (
    args:
      | undefined
      | {
          query?: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
          callbackOnSucces?(): void;
        },
    thunkAPI
  ) => {
    let { query, callbackOnSucces } = args || {};
    const { adBuilderUI: state } = thunkAPI.getState() as { adBuilderUI: AdBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(state.shouldSaveAd);

    if (!shouldSave) return;

    try {
      query =
        query ||
        (window.craftJsAdBuilderQuery as Delete<
          useInternalEditorReturnType<any>['query'],
          'deserialize'
        >);

      const json = useCraftSerializedState(query);
      const encodedJson = lz.encodeBase64(lz.compress(JSON.stringify(json)));
      const shouldSaveAdBuilder = !!(state.ad.craftState !== encodedJson || !encodedJson);
      if (shouldSaveAdBuilder) {
        const {
          data: {
            updateAd: { craftState }
          }
        } = await updateAd({ ...state.ad, craftState: encodedJson });

        if (callbackOnSucces) callbackOnSucces();

        return {
          craftState
        };
      }
      if (callbackOnSucces) callbackOnSucces();
      return {
        craftState: state.ad.craftState
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
