import React, { useEffect, useState } from 'react';
import { Steps, Button } from 'antd';
import TemplateTypeFields from './TemplateTypeFields';
import { adTypesData, stepData } from '../../data/adTypesData';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import BlockSettingsOverlay from '../../../components/sharedUI/BlockSettingsOverlay';
import { messages } from '../../../components/elements/RichTextComponent/helpers/aiTextSettingHelpers';
import DefaultLoader from '../../../../SharedUI/components/DefaultLoader';
import { useCreateAdTemplateTypeLoading } from '../../redux/adBuilderSlice';
import TokenRebuyModal from '../../../components/sharedUI/TokenRebuyModal';
import { isUserOutOfAiTokens } from '../../../helper/aiTokensHelper';
import { useSubscriptionInfo } from '../../../../UI/redux/userSlice';
import { headings } from '../../../components/elements/RichTextComponent/helpers/aiTextSettingHelpers';
import { aiTokenRebuyCheck } from '../../../../Login/utils/ImageConstants';
import { SETTINGS_OVERLAY_MESSAGE_KEY } from '../../../components/sharedUI/BlockSettingsOverlay';
const { Step } = Steps;

export enum AD_TYPES {
  STATEMENT = 'Statement',
  ADVANTAGES = 'Advantages',
  EMPLOYEE_TESTIMONIAL = 'EmployeeTestimonial',
  FREE = 'Free'
}

export const adTypeObject: { [key: string]: string } = {
  Statement: AD_TYPES.STATEMENT,
  Vorteile: AD_TYPES.ADVANTAGES,
  Mitarbeiter: AD_TYPES.EMPLOYEE_TESTIMONIAL,
  KeineVorlage: AD_TYPES.FREE
};

export interface TemplateTypesViewProps {
  toggleVisible: (e: boolean) => void;
  adGenerationData: AdGeneration;
  shouldGenerateAd?: Boolean;
}

const TemplateTypesView = ({
  toggleVisible,
  adGenerationData,
  shouldGenerateAd = true
}: TemplateTypesViewProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const loading = useCreateAdTemplateTypeLoading();
  const subscriptionInfo = useSubscriptionInfo();
  const [selectedType, setSelectedType] = useState(adGenerationData?.type as AD_TYPES);
  const { isOutOfAiTokens } = isUserOutOfAiTokens('ad', subscriptionInfo);
  const [isRebuyModalOpened, setIsRebuyModalOpened] = useState(false);

  const conditions: any = [
    {
      condition: isOutOfAiTokens,
      messageKey: 'isOutOfAiTokens',
      heading: headings[SETTINGS_OVERLAY_MESSAGE_KEY.IS_OUT_OF_AI_TOKENS],
      icon: aiTokenRebuyCheck
    }
  ];

  useEffect(() => {
    if (adGenerationData?.type) {
      setCurrentStep(0);
      setSelectedType(adGenerationData?.type);
    }
  }, [adGenerationData?.type]);

  const nextStep = (type?: string) => {
    setCurrentStep((prevStep: number) => Math.min(prevStep + 1, stepData.length - 1));
    if (type) {
      setSelectedType(adTypeObject[type] as AD_TYPES);
    }
  };

  const backStep = (goToStep?: number) => {
    if ((goToStep as number) >= 0) {
      setCurrentStep(goToStep as number);
    } else {
      setCurrentStep((prevStep: number) => Math.min(prevStep - 1));
    }
    setSelectedType(' ' as AD_TYPES);
  };

  return (
    <div className="ai-generated-ad">
      <TokenRebuyModal visible={isRebuyModalOpened} onClose={() => setIsRebuyModalOpened(false)} />
      <DefaultLoader loading={!shouldGenerateAd && loading} />
      <BlockSettingsOverlay conditions={conditions} />
      <Steps style={{ marginBottom: '10px' }} current={currentStep}>
        {stepData.map((item, index) => {
          return !shouldGenerateAd && item.step === 'Erstellung' ? null : (
            <Step key={index} title={item.step} description={item.description} />
          );
        })}
      </Steps>
      <div className="pt-4">
        {currentStep === 1 ? (
          <TemplateTypeFields
            selectedType={selectedType}
            toggleVisible={toggleVisible}
            backStep={backStep}
            nextStep={nextStep}
            adGenerationData={adGenerationData}
            shouldGenerateAd={shouldGenerateAd}
          />
        ) : currentStep === 2 && shouldGenerateAd ? (
          <div className="loader-container">
            <DefaultSmallLoader makeItCenter={true} fontSize={24} loading={true} />
            <h5>Anzeige erstellen ...</h5>
          </div>
        ) : (
          <div className="ad-type-cards-container">
            {adTypesData.map((item, index) => (
              <div
                key={index}
                className={`ad-type-card ${
                  adGenerationData?.type === adTypeObject[item.type] ? 'selected' : ''
                }`}
              >
                <img className="card-img" alt="example" src={item.imgUrl} />
                <div className="ad-type-card-content">
                  <h2>{item.name}</h2>
                  <p>{item.description}</p>
                  <Button
                    className="ad-type-card-button"
                    type="default"
                    onClick={() => nextStep(item.type)}
                  >
                    {item.button}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateTypesView;
