import { Form, Input } from 'antd';
import React from 'react';
import LoginValidationRules from '../../utils/LoginValidationRules';

interface EmailSendPasswordResetFormProps {
}

const EmailSendPasswordResetForm: React.FC<EmailSendPasswordResetFormProps> = ({
}) => {
  return (
    <div className='styled-form-group'>
      <Form.Item
        label="E-Mail"
        name="email"
        className="form-input"
        rules={LoginValidationRules.email}
      >
        <Input autoCapitalize="none" />
      </Form.Item>
    </div>
  );
};

export default EmailSendPasswordResetForm;
