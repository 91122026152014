import { Button, Card, Form, Input } from 'antd';
import React, { useState } from 'react';
import FunnelSelector from '../../../SharedUI/components/FunnelSelector';
import { FunnelSelectionValidator } from '../../utils/domainAndSlugValidation';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { SlugInterface } from './Slug';

export default function SubDomain({
  domainName,
  formInstance,
  defaultSlugValue
}: {
  domainName: string;
  formInstance: any;
  defaultSlugValue: SlugInterface;
}) {
  const funnels = useAllFunnels();
  const extra =
    'Um zusätzlich eine Hauptdomain zu verbinden, verknüpfe hier die Subdomain und richte beim Domainanbieter eine Weiterleitung von der Hauptdomain auf die Subdomain ein.';

  const fieldName = `funnelId_${defaultSlugValue.id}`;
  return (
    <div>
      <h2>Subdomain</h2>
      <Card className="ui__white-round-wrapper" style={{ marginBottom: '1em' }}>
        <Form.Item extra={extra}>
          <Input disabled data-testid="editSubDomainInput" value={domainName} />
        </Form.Item>
        <Form.Item required rules={FunnelSelectionValidator} name={fieldName} label={'Funnel'}>
          <FunnelSelector
            placeholder="Wähle einen Funnel"
            defaultValue={[defaultSlugValue.funnelId]}
            onChange={selectedFunnelId => {
              formInstance.setFieldsValue({
                [`funnelId_${defaultSlugValue.id}`]: selectedFunnelId
              });
            }}
            customFunnels={[
              {
                title: 'Ohne Funnel',
                id: -1
              }
            ]}
            initialData={funnels}
            allSelectdable={false}
          />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name={`tempId_${defaultSlugValue.id}`}>
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name={`slug_${defaultSlugValue.id}`}>
          <Input />
        </Form.Item>
      </Card>
    </div>
  );
}
