import gql from 'graphql-tag';

export const GET_BOOKING_INFO = gql`
  query getBookingInfo($filter: BookingFilterV2!) {
    getBookings(input: $filter) {
      id
      name
      email
      phone
      eventStart
      eventEnd
      createdAt
      funnelId
      utmParameter
      generalPurpose
      version
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      bookingStatus {
        status
        value
        id
      }
      file {
        fileLink
      }
    }
    getLeadQualifier(input: { showAll: true }) {
      id
      question
      choices {
        id
        title
        unit
      }
    }
  }
`;

export default function getDownloadFunnelData(variables: any) {
  return window.apolloClient.query({
    query: GET_BOOKING_INFO,
    variables: {
      ...variables,
      filter: {
        includeChoices: true,
        ...variables.filter
      }
    },
    fetchPolicy: 'no-cache'
  });
}
