import { Form, Input } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import React from 'react';
import LoginValidationRules from '../../utils/LoginValidationRules';

interface RegisterFromProps {
}

const RegisterForm: React.FC<RegisterFromProps> = ({
}) => {
  return (
    <>
        <div className="form-group-inline styled-form-group">
          <Form.Item
            hasFeedback
            className="form-input"
            label="Vorname"
            name="firstName"
            rules={LoginValidationRules.name}
          >
            <Input placeholder="Maxime" />
          </Form.Item>

          <Form.Item
            hasFeedback
            className="form-input"
            label="Nachname"
            name="lastName"
            rules={LoginValidationRules.name}
          >
            <Input placeholder="Muster" />
          </Form.Item>
        </div>

        <div className="form-group-inline styled-form-group">
          <Form.Item
            className="email-input form-input"
            label="E-Mail"
            name="email"
            hasFeedback
            rules={LoginValidationRules.email}
          >
            <Input autoCapitalize="none" placeholder="dein.name@dein-unternehmen.de" />
          </Form.Item>

          <Form.Item
            className="form-input"
            label="Passwort"
            name="password"
            rules={LoginValidationRules.password}
          >
            <PasswordInput inputProps={{ placeholder: '⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺' }} />
          </Form.Item>
        </div>
    </>
  );
};

export default RegisterForm;
