import { FormInstance } from 'antd/lib/form';
import React, { ReactElement } from 'react';
import {
  builderInvalidationClass,
  otherClassesRelatedToFunnelInvalidationClass
} from '../config/builderUi';
import { useAppDispatch } from '../../redux/hooks';
import { addBuilderValidationError, removeBuilderValidationError } from '../redux/builderSlice';

interface Style {
  pointerEvents: string;
}

export const isBuilderInvalid = () => {
  const elements = Array.from(
    document.getElementsByClassName(`${builderInvalidationClass} blocked`)
  ) as HTMLElement[];
  return elements.length > 0;
};

export enum BUILDER_VALIDATION_ERRORS {
  FORM_VALIDATION_ERROR = 'FORM_VALIDATION_ERROR',
  TITLE = 'TITLE',
  WIDGET_TITLE = 'WIDGET_TITLE',
  EMAIL_SUBJECT = 'EMAIL_SUBJECT',
  EMAIL_EDITOR = 'EMAIL_EDITOR',
  RICH_TEXT = 'RICH_TEXT',
  SMS_FORM = 'SMS_FORM',
  UNIQUE_IDENTIFIER = 'UNIQUE_IDENTIFIER',
  JOB_TITLE = 'JOB_TITLE',
}

export const BuilderValidationErrorMessages = {
  FORM_VALIDATION_ERROR: 'Bitte fülle alle erforderlichen Felder aus',
  TITLE: 'Bitte fülle das Titelfeld aus',
  WIDGET_TITLE: 'Bitte fülle das Feld „Widget-Titel“ aus',
  EMAIL_SUBJECT: 'Bitte fülle das E-Mail-Betreffeld aus',
  EMAIL_EDITOR: 'Bitte fülle das E-Mail-Textfeld aus',
  RICH_TEXT: 'Bitte fülle das Textfeld aus',
  SMS_FORM: 'Bitte fülle alle erforderlichen SMS-Felder aus',
  UNIQUE_IDENTIFIER: 'Bitte fülle das Feld Unique Identifier aus',
  JOB_TITLE: 'Bitte fülle das Feld Jobtitel aus'
};

export const disabledBuilderSaveOnInvalidation = (isInValid: boolean) => {
  const style: Style = {
    pointerEvents: 'auto'
  };
  if (isInValid) {
    style.pointerEvents = 'none';
  } else {
    style.pointerEvents = 'auto';
  }
  const elements = Array.from(
    document.querySelectorAll(
      `.${builderInvalidationClass}${otherClassesRelatedToFunnelInvalidationClass.reduce(
        (total, currentValue) => `${total},.${currentValue}`,
        ''
      )}`
    )
  ) as HTMLElement[];
  elements.forEach((element: HTMLElement) => {
    Object.keys(style).forEach((key: any) => {
      element.style[key] = style[key as keyof Style];
      if (isInValid) {
        if (element.classList.contains(builderInvalidationClass)) {
          element.classList.add('blocked');
        } else {
          element.classList.add(builderInvalidationClass);
          element.classList.add('blocked');
        }
      } else element.classList.remove('blocked');
    });
  });
};

const BuilderValidator = ({
  children,
  form,
  formType
}: {
  children: ReactElement;
  form: FormInstance;
  formType?: BUILDER_VALIDATION_ERRORS;
}) => {
  const dispatch = useAppDispatch();
  return (
    <>
      {React.cloneElement(children, {
        onFieldsChange: () => {
          form
            .getFieldsError()
            .map(field => field.errors)
            .flat().length > 0
            ? dispatch(
                addBuilderValidationError(
                  formType || BUILDER_VALIDATION_ERRORS.FORM_VALIDATION_ERROR
                )
              )
            : dispatch(
                removeBuilderValidationError(
                  formType || BUILDER_VALIDATION_ERRORS.FORM_VALIDATION_ERROR
                )
              );
        }
      })}
    </>
  );
};

export default BuilderValidator;
