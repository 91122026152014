import React from 'react';
import { Popover, Typography } from 'antd';
import { QuestionCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';

interface Props {
  title: string;
  suffix?: string;
  icon?: any;
  classNames?: string;
  infoPopoverText?: string | React.ReactElement;
  infoPopoverIcon?: React.ReactElement;
  rightAlignedElement?: React.ReactElement | null;
  onCollapseToggleClick?(value: boolean): void;
  collapsed?: boolean;
  disabled?: boolean;
}

const { Title } = Typography;

const BuilderSettingsTitle = ({
  classNames,
  icon,
  disabled,
  title,
  suffix,
  rightAlignedElement,
  infoPopoverText,
  infoPopoverIcon,
  onCollapseToggleClick,
  collapsed
}: Props) => {
  return (
    <div onClick={() => onCollapseToggleClick && onCollapseToggleClick(!collapsed)}>
      <Title
        className={`settings-title ${classNames} d-flex align-items-center ${
          onCollapseToggleClick ? ' default-opacity-hover' : ''
        }`}
        level={4}
        disabled={disabled}
      >
        {icon && React.cloneElement(icon, { className: 'settings-title__icon' })}
        {title}
        {suffix && <Typography className="ps-1 text-light">{suffix}</Typography>}
        {infoPopoverText && (
          <Popover
            className="default-popover"
            content={<div className="default-popover__content">{infoPopoverText}</div>}
          >
            {infoPopoverIcon || <QuestionCircleOutlined />}
          </Popover>
        )}
        {rightAlignedElement && (
          <div className="settings-title__right px-1 default-opacity-hover">
            {rightAlignedElement}
          </div>
        )}
        {onCollapseToggleClick && (
          <div className="settings-title__right px-1">
            {collapsed ? (
              <DownOutlined onClick={() => onCollapseToggleClick(!collapsed)} />
            ) : (
              <UpOutlined onClick={() => onCollapseToggleClick(!collapsed)} />
            )}
          </div>
        )}
      </Title>
    </div>
  );
};

export default BuilderSettingsTitle;
