import React, { useEffect } from 'react';
import { useEditor, WithoutPrivateActions } from '@craftjs/core';
import {
  resetSelectedBlockNodeIds,
  useBuilderPages,
  useBuilderQuestionPages,
  useBuilderSelectedPageData,
  useConnectedFonts,
  useIsMobileView
} from '../redux/builderSlice';
import { useAppDispatch } from '../../redux/hooks';
import { BuilderPreviewBase } from '../components/ui/BuilderPreviewBase';
import { useCurrentlyEditingBuilderTheme } from '../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { appendStylesAccordingToColorTheme } from '../helper/craftJs';
import EmptyPageList from '../components/sharedUI/EmptyPageList';

declare global {
  interface Window {
    craftJsActions?: WithoutPrivateActions<null>;
  }
}

export const BuilderPreview = () => {
  const data = useBuilderSelectedPageData();
  const builderPages = useBuilderPages();
  const builderQuestinPages = useBuilderQuestionPages()
  const dispatch = useAppDispatch();
  const theme = useCurrentlyEditingBuilderTheme();
  const isMobileView = useIsMobileView();
  const connectedFonts = useConnectedFonts()

  const pagesExisting = data && (builderPages.length || builderQuestinPages.length);

  const { actions, query } = useEditor();

  window.craftJsActions = actions;
  window.craftJsPageBuilderQuery = query;

  useEffect(() => {
    appendStylesAccordingToColorTheme(theme, isMobileView , connectedFonts);
  }, [theme,isMobileView,connectedFonts]);

  useEffect(() => {
    dispatch(resetSelectedBlockNodeIds());
  }, [data?.id]);

  return (
    <BuilderPreviewBase
      selectedPage={data}
      pagesExisting={Boolean(pagesExisting)}
      emptyPageComponent={<EmptyPageList />}
      className={'preview-section__inner'}
    />
  );
};
