import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import AdminUserSelector from '../../../CustomerManagement/components/AdminUserSelector';
import { getAuthData } from '../../container/AuthRoutesHOC';

function LoginSelector({ setAuthDataByAdmin }: { setAuthDataByAdmin: Function }) {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      onFinish={({ userId }) => {
        setAuthDataByAdmin(userId);
      }}
      initialValues={{ userId: undefined }}
    >
      <Row className="d-block w-100">
        <Col>
          <Form.Item
            className="mb-3"
            name={'userId'}
            rules={[{ required: true, message: 'Please Select User to login!' }]}
          >
            <AdminUserSelector />
          </Form.Item>
        </Col>
        <Col className="d-flex justify-content-between  login-as-admin">
          <Button htmlType="submit" type="primary">
            Auswählen
          </Button>
          <Button
            onClick={() => {
              const token = getAuthData('token');
              const payload = JSON.parse(atob(token.split('.')[1]));
              setAuthDataByAdmin(payload.coachId);
            }}
            type="link"
            style={{ color: 'white' }}
            className="mt-0 mb-4"
          >
            Proceed to Admin Account
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default LoginSelector;
