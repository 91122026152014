import { useNavigate } from 'react-router';

export enum BuilderIdentifier {
  WIDGET_BUILDER = 'widgetBuilder',
  JOB_BUILDER = 'jobBuilder'
}

export enum SETTINGS_VALUE {
  AI = 'ai',
  GENERAL = 'general'
}

export const useElementSubSettings = () => {
  const navigate = useNavigate();
  const goToElementSubSettings = (settings: SETTINGS_VALUE) => {
    const params = new URLSearchParams({
      elementSubSettings: settings
    });
    navigate(`?${params.toString()}`);
  };
  return { goToElementSubSettings };
};

export const capitalizeFirstLetter = (val: string) => {
  if (!val) return '';

  return (
    String(val)
      .charAt(0)
      .toUpperCase() + String(val).slice(1)
  );
};
