import React from 'react';
import { Form, Input } from 'antd';
import {
  AiFieldsRules,
  COMMUNICATION_ELEMENTS,
  COMPANY_INFORMATION_ELEMENTS,
  getCommunicationDataHelper
} from '../../../components/generalSettings/AiDataTab/components/AiDataHelper';
import CategoryPicker from '../../../components/sharedUI/CategoryPicker';
import {
  predefinedBenefitOptions,
  predefinedGoalOptions,
  predefinedTaskOptions,
  predefinedUspOptions
} from '../../../components/generalSettings/utils/predefined-options';
import {
  defaultStringFieldValidation,
  jobTitleValidation
} from '../../../../UI/utils/formValidation';
import TextArea from 'antd/lib/input/TextArea';
import {
  getOptionsValuesInListAi,
  getTargetGroupOptions,
  TARGET_GROUP_ELEMENTS
} from '../../../components/generalSettings/TargetGroup/components/TargetGroupHelper';
import OptionsPicker from '../../../components/sharedUI/OptionsPicker';
import RangeSlider from '../../../components/sharedUI/RangeSlider';
import ChoicePicker from '../../../components/sharedUI/ChoicePicker';
import {
  ExperienceDuration,
  getJobOptions,
  JOB_ATTRIBUTES_IDENTIFIER
} from '../../helper/AdJobHelper';
import AddressPicker from '../../../components/sharedUI/AddressPicker';
import { formatCurrency } from '../../helper/validateFunnelJobPresets';
import MinMaxNumberInput from '../../../../SharedUI/components/MinMaxNumberInput';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import useGetAllFunnelsSkills from '../../../../hooks/useAllFunnelSkills';
import { formRules } from '../../../DebugTracking/utils/formValidationRules';

// Form Items HashMaps
export const hashFormItemsCompany = {
  [COMPANY_INFORMATION_ELEMENTS.NAME]: (
    value: any,
    handleChange: (value: any, key: any) => void
  ) => (
    <Form.Item name={COMPANY_INFORMATION_ELEMENTS.NAME} rules={[defaultStringFieldValidation[0]]}>
      <Input
        placeholder="z.B. Muster GmbH"
        maxLength={60}
        onChange={e => {
          handleChange(e.target.value, COMPANY_INFORMATION_ELEMENTS.NAME);
        }}
      />
    </Form.Item>
  ),

  [COMPANY_INFORMATION_ELEMENTS.USP]: (
    value: any,
    handleChange: (value: any, key: any) => void
  ) => (
    <Form.Item
      validateTrigger={['onBlur', 'onFocus', 'onInput']}
      name={COMPANY_INFORMATION_ELEMENTS.USP}
      rules={AiFieldsRules.generalTags}
      getValueFromEvent={value => {
        handleChange(value, COMPANY_INFORMATION_ELEMENTS.USP);
        return value;
      }}
    >
      <CategoryPicker
        placeholder="z.B. herausragender Kundensupport, skalierbare Lösungen"
        mode="tags"
        value={value}
        options={predefinedUspOptions}
      />
    </Form.Item>
  ),

  [COMPANY_INFORMATION_ELEMENTS.BENEFITS]: (
    value: any,
    handleChange: (value: any, key: any) => void
  ) => (
    <Form.Item
      validateTrigger="onBlur"
      name={COMPANY_INFORMATION_ELEMENTS.BENEFITS}
      rules={AiFieldsRules.generalTags}
      getValueFromEvent={value => {
        handleChange(value, COMPANY_INFORMATION_ELEMENTS.BENEFITS);
        return value;
      }}
    >
      <CategoryPicker
        placeholder="z.B. JobRad, Remote-Anteil, Weihnachtsgeld"
        mode="tags"
        value={value}
        options={predefinedBenefitOptions}
      />
    </Form.Item>
  ),

  [COMPANY_INFORMATION_ELEMENTS.PRODUCT]: (
    value: any,
    handleChange: (value: any, key: any) => void
  ) => (
    <Form.Item
      validateTrigger="onBlur"
      name={COMPANY_INFORMATION_ELEMENTS.PRODUCT}
      rules={AiFieldsRules.textFields}
    >
      <TextArea
        className="mt-2"
        rows={4}
        value={value}
        onChange={e => {
          handleChange(e.target.value, COMPANY_INFORMATION_ELEMENTS.PRODUCT);
        }}
        placeholder="z.B. Herstellung und Installation von XYZ."
      />
    </Form.Item>
  )
};

export const hashFormItemsTargetGroup = {
  [TARGET_GROUP_ELEMENTS.AGE]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => {
    return (
      <Form.Item
        name={TARGET_GROUP_ELEMENTS.AGE}
        rules={formRules.required}
        getValueFromEvent={value => {
          handleChange(value, TARGET_GROUP_ELEMENTS.GOALS);
          return value;
        }}
      >
        <OptionsPicker
          options={getTargetGroupOptions(TARGET_GROUP_ELEMENTS.AGE).options}
          handleChange={(value, identifier) => {
            handleChange(value, identifier);
          }}
          identifier={TARGET_GROUP_ELEMENTS.AGE}
          value={value}
        />
      </Form.Item>
    );
  },

  [TARGET_GROUP_ELEMENTS.GOALS]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item
      name="goals"
      rules={AiFieldsRules.generalTags}
      getValueFromEvent={value => {
        handleChange(value, TARGET_GROUP_ELEMENTS.GOALS);
        return value;
      }}
    >
      <CategoryPicker
        placeholder="z.B. Karriereentwicklung, bessere Vergütung"
        mode="tags"
        value={value || []}
        options={predefinedGoalOptions}
      />
    </Form.Item>
  ),

  [TARGET_GROUP_ELEMENTS.SKILLS]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => {
    const { data: skillsData = {}, loading: skillsLoading } = useGetAllFunnelsSkills();

    return (
      <Form.Item
        name="skills"
        getValueFromEvent={value => {
          handleChange(value, TARGET_GROUP_ELEMENTS.SKILLS);
          return value;
        }}
        rules={AiFieldsRules.generalTags}
      >
        <CategoryPicker
          placeholder="z.B. Technische Fähigkeiten, Projektmanagement"
          loading={skillsLoading}
          mode="tags"
          value={value || []}
          options={[
            ...(skillsData?.getAllFunnelsSkills || [])?.map((value: string) => ({
              label: value,
              value
            })),
            ...predefinedTaskOptions
          ]}
        />
      </Form.Item>
    );
  },

  [TARGET_GROUP_ELEMENTS.EXPERIENCE]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item name={TARGET_GROUP_ELEMENTS.EXPERIENCE}>
      <RangeSlider
        title="Erfahrung"
        identifier={TARGET_GROUP_ELEMENTS.EXPERIENCE}
        handleChange={(value, identifier) => {}}
        onChange={value => {
          handleChange(value, TARGET_GROUP_ELEMENTS.EXPERIENCE);
        }}
        value={value}
        max={30}
      />
    </Form.Item>
  ),

  [TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item name={TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION}>
      <ChoicePicker
        title="Einheit"
        handleChange={(value, identifier) => {
          handleChange(value, identifier);
        }}
        value={value}
        options={getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION).options}
        identifier={TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION}
      />
    </Form.Item>
  ),

  [TARGET_GROUP_ELEMENTS.EDUCATION]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item name={TARGET_GROUP_ELEMENTS.EDUCATION} rules={formRules.atLeastOneRequired}>
      <OptionsPicker
        options={getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EDUCATION).options}
        handleChange={(value, identifier) => {
          handleChange(value, identifier);
        }}
        identifier={TARGET_GROUP_ELEMENTS.EDUCATION}
        value={value}
      />
    </Form.Item>
  )
};

export const hashFormItemsJobAttributes = {
  [JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => {
    return (
      <>
        <BuilderSettingsTitle classNames="settings-title__required" title="Formaler Titel" />
        <Form.Item name="title" rules={jobTitleValidation}>
          <Input
            placeholder="z.B. Software-Entwickler (m/w/d) für Schnittstellen und Datenbank"
            maxLength={60}
            value={value}
            onChange={e => {
              handleChange(e.target.value, JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE);
            }}
          />
        </Form.Item>
      </>
    );
  },
  [JOB_ATTRIBUTES_IDENTIFIER.JOB_CATEGORY]: (
    value: any,
    handleChange: (value: any, identifier: any) => void,
    options: any,
    resetSubCategory: () => void
  ) => (
    <Form.Item
      name="jobCategory"
      rules={[{ required: true, message: 'Kategorie ist erforderlich.' }]}
      getValueFromEvent={value => {
        const finalValue = options.find((op: any) => op.id === value);
        handleChange(finalValue, JOB_ATTRIBUTES_IDENTIFIER.JOB_CATEGORY);
        resetSubCategory();
        return finalValue.id;
      }}
    >
      <CategoryPicker
        placeholder="z.B. IT, Telekommunikation"
        value={value.id}
        overriddenValue={value.id}
        options={[...options]
          .sort((a: any, b: any) => {
            if (a.category === 'Sonstiges') return 1;
            if (b.category === 'Sonstiges') return -1;
            return a.category.localeCompare(b.category);
          })
          .map((op: any) => ({
            label: op.category,
            value: op.id
          }))}
        mode={undefined}
        prioritizeValue
        showSearch
      />
    </Form.Item>
  ),
  [JOB_ATTRIBUTES_IDENTIFIER.SUB_CATEGORY]: (
    value: any,
    handleChange: (value: any, identifier: any) => void,
    options?: any,
    disabled: boolean = false
  ) => (
    <Form.Item
      name={JOB_ATTRIBUTES_IDENTIFIER.SUB_CATEGORY}
      getValueFromEvent={value => {
        const finalValue = options.find((op: any) => op.id === value);
        handleChange(finalValue, JOB_ATTRIBUTES_IDENTIFIER.SUB_CATEGORY);
        return finalValue.id;
      }}
    >
      <CategoryPicker
        placeholder="z.B. Projektmanagement, Holzhandwerk"
        value={value.id}
        overriddenValue={value.id}
        options={[...options]
          .sort((a: any, b: any) => a.category.localeCompare(b.category))
          .map((op: any) => ({
            label: op.category,
            value: op.id
          }))}
        mode={undefined}
        prioritizeValue
        disabled={disabled}
        showSearch
      />
    </Form.Item>
  ),

  [JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item
      name={JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE}
      rules={formRules.atLeastOneRequired}
    >
      <OptionsPicker
        options={getJobOptions(JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE).options}
        handleChange={(value, identifier) => {
          handleChange(value, identifier as JOB_ATTRIBUTES_IDENTIFIER);
        }}
        identifier={JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE}
        value={value}
      />
    </Form.Item>
  ),

  [JOB_ATTRIBUTES_IDENTIFIER.SALARY]: (
    value: any,
    handleChange: (value: any, identifier: any) => void,
    salaryPlan: any,
    form: any
  ) => (
    <>
      <Form.Item name={JOB_ATTRIBUTES_IDENTIFIER.SALARY}>
        <MinMaxNumberInput
          title={'Gehalt'}
          minLimit={value?.[0]}
          maxLimit={value?.[1]}
          setMinLimit={(minLimit: number): void => {
            handleChange([minLimit, value?.[1]], JOB_ATTRIBUTES_IDENTIFIER.SALARY);
          }}
          setMaxLimit={(setMaxLimit: number): void => {
            handleChange([value?.[0], setMaxLimit], JOB_ATTRIBUTES_IDENTIFIER.SALARY);
          }}
          span={24}
          maximum={1000000}
          form={form}
          formatter={value => formatCurrency(value)}
        />
      </Form.Item>
      <Form.Item name={JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN}>
        <ChoicePicker
          title="Einheit"
          handleChange={(value, identifier) => {
            handleChange(value, identifier as JOB_ATTRIBUTES_IDENTIFIER);
          }}
          value={salaryPlan}
          options={getJobOptions(JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN).options}
          identifier={JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN}
        />
      </Form.Item>
    </>
  ),

  [JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item rules={formRules.required} name={JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL}>
      <OptionsPicker
        options={getJobOptions(JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL).options}
        handleChange={(value, identifier) => {
          handleChange(value, identifier as JOB_ATTRIBUTES_IDENTIFIER);
        }}
        identifier={JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL}
        value={value}
      />
    </Form.Item>
  ),

  [JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item name={JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS}>
      <AddressPicker
        handleChange={(value, identifier) => {
          handleChange(value, identifier);
        }}
        identifier={JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS}
        value={value}
      />
    </Form.Item>
  )
};

export const hashFormItemsCommunication = {
  [COMMUNICATION_ELEMENTS.SPEECH]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item
      name={COMMUNICATION_ELEMENTS.SPEECH}
      rules={[{ required: true, message: 'Ansprache ist erforderlich.' }]}
      getValueFromEvent={value => {
        handleChange(value, COMMUNICATION_ELEMENTS.SPEECH);
        return value;
      }}
    >
      <OptionsPicker
        options={getCommunicationDataHelper(COMMUNICATION_ELEMENTS.SPEECH).options}
        handleChange={(value, identifier) => {
          handleChange(value, identifier as COMMUNICATION_ELEMENTS);
        }}
        identifier={COMMUNICATION_ELEMENTS.SPEECH}
        value={value}
      />
    </Form.Item>
  ),

  [COMMUNICATION_ELEMENTS.WRITING_STYLE]: (
    value: any,
    handleChange: (value: any, identifier: any) => void
  ) => (
    <Form.Item
      name={COMMUNICATION_ELEMENTS.WRITING_STYLE}
      rules={[{ required: true, message: 'Schreibstil ist erforderlich.' }]}
      getValueFromEvent={value => {
        handleChange(value, COMMUNICATION_ELEMENTS.WRITING_STYLE);
        return value;
      }}
    >
      <OptionsPicker
        options={getCommunicationDataHelper(COMMUNICATION_ELEMENTS.WRITING_STYLE).options}
        handleChange={(value, identifier) => {
          handleChange(value, identifier as COMMUNICATION_ELEMENTS);
        }}
        identifier={COMMUNICATION_ELEMENTS.WRITING_STYLE}
        value={value}
      />
    </Form.Item>
  )
};

// Item Titles HashMaps
export const hashTitlesCompanyInformation = {
  [COMPANY_INFORMATION_ELEMENTS.NAME]: () => (
    <BuilderSettingsTitle title="Firmenname" classNames="settings-title__required" />
  ),

  [COMPANY_INFORMATION_ELEMENTS.USP]: (value: boolean | any) => (
    <div>
      <h6 className="settings-title__required">USP</h6>
      {typeof value === 'boolean' && value ? (
        <></>
      ) : (
        <p className="m-0 settings-subtitle">
          {(value?.length && value?.join(', ')) ||
            `Was unterscheidet euch von anderen Unternehmen in eurer Branche? Schreibe in Stichworten.`}
        </p>
      )}
    </div>
  ),

  [COMPANY_INFORMATION_ELEMENTS.BENEFITS]: (value: boolean | any) => (
    <div>
      <h6 className="settings-title__required">Vorteile</h6>
      <p className="m-0 settings-subtitle">
        {typeof value === 'boolean' && value ? (
          <></>
        ) : Array.isArray(value) ? (
          value.join(', ')
        ) : (
          `Welche top 5 Vorteile kann dein Unternehmen Mitarbeitern bieten?`
        )}
      </p>
    </div>
  ),

  [COMPANY_INFORMATION_ELEMENTS.PRODUCT]: (value: any) => (
    <div>
      <h6 className="settings-title__required">Unternehmensbeschreibung</h6>

      {typeof value === 'boolean' && value ? (
        <></>
      ) : (
        <p className="m-0 settings-subtitle">
          {value || `Was produziert oder bietet das Unternehmen konkret an?`}
        </p>
      )}
    </div>
  )
};

export const hashTitlesTargetGroup = {
  [TARGET_GROUP_ELEMENTS.AGE]: (age: string | boolean) => (
    <div>
      <h6 className="settings-title__required">Alter</h6>
      {typeof age !== 'boolean' && (
        <p className="m-0 settings-subtitle">
          {getOptionsValuesInListAi(
            TARGET_GROUP_ELEMENTS.AGE,
            [age as string],
            getTargetGroupOptions(TARGET_GROUP_ELEMENTS.AGE).options
          ) || `Wie alt sollte dein Wunschmitarbeiter sein?`}
        </p>
      )}
    </div>
  ),
  [TARGET_GROUP_ELEMENTS.GOALS]: (goals: string[] | boolean) => (
    <div>
      <h6 className="settings-title__required">Ziele & Wünsche</h6>
      {typeof goals !== 'boolean' && (
        <p className="m-0 settings-subtitle">
          {goals?.join(', ') || `Was möchte dein Wunschmitarbeiter erreichen?`}
        </p>
      )}
    </div>
  ),
  [TARGET_GROUP_ELEMENTS.SKILLS]: (skills: string[] | boolean) => (
    <div>
      <h6 className="settings-title__required">Fähigkeiten</h6>
      {typeof skills !== 'boolean' && (
        <p className="m-0 settings-subtitle">
          {skills?.join(', ') ||
            `Welche Fähigkeiten oder Kenntnisse sollte dein Mitarbeiter beherrschen?`}
        </p>
      )}
    </div>
  ),
  [TARGET_GROUP_ELEMENTS.EXPERIENCE]: (experience: string, experienceDuration: any) => (
    <div>
      <h6 className="settings-title__required">Erfahrung</h6>
      <p className="m-0">
        {`Erfahrung ${experience} ${getOptionsValuesInListAi(
          TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION,
          [experienceDuration || ExperienceDuration.YEARS],
          getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION).options
        )}`}
      </p>
    </div>
  ),
  [TARGET_GROUP_ELEMENTS.EDUCATION]: (education: string[] | boolean) => (
    <div>
      <h6 className="settings-title__required">Bildungsgrad</h6>
      {typeof education !== 'boolean' && (
        <p className="m-0">
          {getOptionsValuesInListAi(
            TARGET_GROUP_ELEMENTS.EDUCATION,
            education || [],
            getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EDUCATION).options
          ) || 'Wähle die Ausbildung der Zielgruppe aus.'}
        </p>
      )}
    </div>
  )
};

export const hashTitlesCommunication = {
  [COMMUNICATION_ELEMENTS.SPEECH]: (speech: string | boolean) => (
    <div>
      <h6 className="settings-title__required">Ansprache</h6>
      {typeof speech !== 'boolean' && (
        <p className="m-0 settings-subtitle">
          {getOptionsValuesInListAi(
            COMMUNICATION_ELEMENTS.SPEECH,
            [speech as string],
            getCommunicationDataHelper(COMMUNICATION_ELEMENTS.SPEECH).options
          ) || `Möchtest du duzen oder siezen?`}
        </p>
      )}
    </div>
  ),

  [COMMUNICATION_ELEMENTS.WRITING_STYLE]: (writingStyle: string | boolean) => (
    <div>
      <h6 className="settings-title__required">Schreibstil</h6>
      {typeof writingStyle !== 'boolean' && (
        <p className="m-0 settings-subtitle">
          {getOptionsValuesInListAi(
            COMMUNICATION_ELEMENTS.WRITING_STYLE,
            [writingStyle as string],
            getCommunicationDataHelper(COMMUNICATION_ELEMENTS.WRITING_STYLE).options
          ) || `Wie sollen die Texte grundsätzlich wirken?`}
        </p>
      )}
    </div>
  )
};
