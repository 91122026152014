import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../redux/hooks';
import {
  getAllFunnelFoldersThunk,
  getAllFunnelsThunk,
  getAllWidgetsThunk,
  getFunnelsBookingCountThunk
} from '../../Funnel/redux/thunk';
import {
  useAllFunnels,
  useAllFunnelsLoading,
  useFunnelsError
} from '../../Funnel/redux/funnelSlice';
import { Alert } from 'antd';
import { useLocation } from 'react-router';

type Props = {
  children: ReactNode;
};

export const FUNNEL_REFRESH_INTERVAL_MINUTES = 10;
export const fetchFunnelTimeInLocalStorageKey = 'meetovo-fetch-funnel-time';

export const FetchFunnelsBeforeComponentLoadHOC = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const funnels = useAllFunnels();
  const funnelsError = useFunnelsError();
  const getAllFunnelsLoading = useAllFunnelsLoading();
  const location = useLocation();
  const isTrashPage = location.pathname.includes('papierkorb');
  const trashPageRef = useRef<boolean>(isTrashPage);

  useEffect(() => {
    const lastTimeFunnelsFetched = localStorage.getItem(fetchFunnelTimeInLocalStorageKey);
    const minutesSinceLastFetch = lastTimeFunnelsFetched
      ? (new Date().getTime() - parseInt(lastTimeFunnelsFetched)) / (1000 * 60)
      : Infinity;
    const shouldRefreshFunnels =
      !funnels?.length ||
      minutesSinceLastFetch > FUNNEL_REFRESH_INTERVAL_MINUTES ||
      isTrashPage !== trashPageRef.current;

    const isBuilderPage = location.pathname.includes('/builder');

    if ((isBuilderPage && !funnels?.length) || (!isBuilderPage && shouldRefreshFunnels)) {
      const fetchData = async (): Promise<void> => {
        await Promise.all([
          dispatch(getAllFunnelsThunk(isTrashPage)),
          dispatch(getAllWidgetsThunk(isTrashPage)),
          dispatch(getAllFunnelFoldersThunk())
        ]);
        localStorage.setItem(fetchFunnelTimeInLocalStorageKey, new Date().getTime().toString());
        trashPageRef.current = isTrashPage;
      };
      fetchData();
    }
  }, [location.pathname, isTrashPage]);

  useEffect(() => {
    if (funnels.length) {
      dispatch(getFunnelsBookingCountThunk(funnels.map(({ id }) => id)));
    }
  }, [funnels.length]);

  if (funnelsError) return <Alert type="error" message={funnelsError} banner />;
  return (
    <>
      {getAllFunnelsLoading && (
        <div className="loading-overlay">
          <LoadingOutlined className="centered" spin />
        </div>
      )}
      {[children]}
    </>
  );
};
