import { LoginMode } from '../container/AuthRoutesHOC';

const COMMON_TEXTS = {
  accountQuestion: {
    hasAccount: 'Hast du bereits einen Account?',
    noAccount: 'Hast du noch keinen Account?'
  },
  actions: {
    login: 'Einloggen',
    register: 'Jetzt 14 Tage kostenlos testen'
  }
};

export const loginHeadline: { [key in LoginMode]: string } = {
  login: 'Willkommen zurück!',
  register: 'Starte jetzt – Gewinne die besten Talente in nur wenigen Klicks!',
  'reset-password': 'Passwort zurücksetzen',
  'google-login': 'Google Login'
};

export const loginParagraph: { [key in LoginMode]: string } = {
  login: 'Log dich ein und mach da weiter, wo du aufgehört hast',
  register:
    'Starte ohne Risiko: Job-Kampagne bauen, Bewerbungen erhalten, einstellen – ohne Bezahldaten. Jetzt 14 Tage unverbindlich testen.',
  'reset-password':
    'Nachdem du deine E-Mail eingeben hast, erhältst du eine E-Mail mit einem Link, um dein Passwort zurückzusetzen.',
  'google-login': 'Google Login'
};

export const loginSwitchQuestion: { [key in LoginMode]: string } = {
  login: COMMON_TEXTS.accountQuestion.noAccount,
  register: COMMON_TEXTS.accountQuestion.hasAccount,
  'reset-password': 'Dein Passwort ist dir wieder eingefallen?',
  'google-login': COMMON_TEXTS.accountQuestion.hasAccount
};

export const loginSwitchLink: { [key in LoginMode]: string } = {
  login: COMMON_TEXTS.actions.register,
  register: COMMON_TEXTS.actions.login,
  'reset-password': COMMON_TEXTS.actions.login,
  'google-login': COMMON_TEXTS.actions.login
};
