import React from 'react';
import { MessagingTemplateTabType, MessagingType } from '../../interfaces/builderSliceTypes';
import { Alert, Form, message } from 'antd';
import { applicantNotificationTypeEnum, explanations } from '../../components/emailTemplate/utils';
import sendApplicantNotification from '../../../Events/graphql/sendApplicantNotification';
import { SmsSendingStatusMessages } from '../../../config/messages';
import SMSPreview from '../../components/ui/SMSPreview';
import ScheduledNotificationTemplateSelectList from '../../components/emailTemplate/ScheduledNotificationTemplateSelectList';

const MessagingTemplateBlock = ({
  template,
  showHelperMessage = true,
  eventId,
  applicantNotificationType,
  setIsSending,
  setVisibility,
  form,
  enabled,
  onChange,
  senderName
}: {
  template: MessagingTemplateTabType;
  showHelperMessage?: boolean;
  eventId?: number;
  setIsSending?: (newState: boolean) => void;
  setVisibility?: (newState: boolean) => void;
  form?: any;
  applicantNotificationType?: applicantNotificationTypeEnum;
  enabled?: boolean;
  onChange?: any;
  senderName?: string;
}) => {
  const handleSubmit: (values: any) => void = values => {
    if (setIsSending && setVisibility && eventId && applicantNotificationType) {
      const emailData = {
        bookingIds: [eventId],
        subject: 'message Template',
        html: template.content.toString(),
        applicantNotificationType
      };

      setIsSending(true);
      sendApplicantNotification(emailData)
        .then((res: any) => {
          if (res.data?.sendApplicantNotification?.status) {
            message.success(SmsSendingStatusMessages.success);
            setVisibility(false);
          }
        })
        .catch((error: any) => {
          message.error(SmsSendingStatusMessages.error);
        })
        .finally(() => setIsSending(false));
    }
  };
  return (
    <Form form={form} onFinish={handleSubmit}>
      <div
        className={`message-wrapper template-form disable-layer ${!enabled &&
          'disable-layer__disabled'}`}
      >
        {showHelperMessage && (
          <Alert message={explanations[template.type]} className="mt-0 mb-4" type="info" showIcon />
        )}
        {template.type === MessagingType.SCHEDULED && (
          <div className="w-100 mx-2">
            <ScheduledNotificationTemplateSelectList
              scheduledTime={template?.scheduledTime}
              timeInMinutesTillEvent={template?.timeInMinutesTillEvent}
              templateType={template.type}
              onChange={onChange}
              data={template}
            />
          </div>
        )}
        <SMSPreview className="mt-5" template={template.content.toString()} name={senderName} />
      </div>
    </Form>
  );
};

export default MessagingTemplateBlock;
