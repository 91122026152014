import React, { useEffect, useState } from 'react';
import { Table, Checkbox, Select, Form, Alert, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CSVMappingFields,
  ValidationError,
  doNotDuplicateFields,
  getValidationSummary,
  mapCSVHeadersToSchema,
  mustRequiredFields,
  validateCSVData,
  validateField
} from './helpersCSV';
import { CSVUploadMessages } from '../../../config/messages';

const { Option } = Select;

type MapFieldsViewProps = {
  csvData: any[];
  csvFields?: string[];
  setMappingSchema: (value: any) => void;
  mappingSchema: any;
  setRequiredFields?: (fields: string[]) => void;
};

const MapFieldsView = ({
  csvData,
  csvFields,
  mappingSchema,
  setMappingSchema,
  setRequiredFields: setParentRequiredFields
}: MapFieldsViewProps) => {
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [validationSummary, setValidationSummary] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState(false);
  const [requiredFields, setRequiredFields] = useState(csvFields);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const onFieldChange = (changedValues: any) => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }

    // Handle checkbox changes
    const checkboxChanges = Object.keys(changedValues).filter(key => key.startsWith('check-'));
    if (checkboxChanges.length > 0) {
      const newRequiredFields = [...(requiredFields || [])];
      const newMappingSchema = { ...mappingSchema };

      checkboxChanges.forEach(checkKey => {
        const fieldName = checkKey.replace('check-', '');
        const isChecked = changedValues[checkKey];

        if (isChecked && !newRequiredFields.includes(fieldName)) {
          newRequiredFields.push(fieldName);
        } else if (!isChecked && newRequiredFields.includes(fieldName)) {
          const index = newRequiredFields.indexOf(fieldName);
          if (index !== -1) {
            newRequiredFields.splice(index, 1);
          }
        
          newMappingSchema[fieldName] = CSVMappingFields.noValue;
          
          form.setFieldsValue({
            [fieldName]: CSVMappingFields.noValue
          });
        }
      });

      setRequiredFields(newRequiredFields);
      if (setParentRequiredFields) {
        setParentRequiredFields(newRequiredFields);
      }
      
      setMappingSchema(newMappingSchema);
      validateMappedData(newMappingSchema);
    }

    const fieldMappingChanges = Object.keys(changedValues).filter(
      key => !key.startsWith('check-')
    );
    if (fieldMappingChanges.length > 0) {
      const newMappingSchema = { ...mappingSchema };
      fieldMappingChanges.forEach(key => {
        newMappingSchema[key] = changedValues[key];
      });
      setMappingSchema(newMappingSchema);
      validateMappedData(newMappingSchema);
    } else {
      validateMappedData(mappingSchema);
    }
  };

  // Validate the entire dataset with current mapping schema
  const validateMappedData = (schema: any) => {
    setIsValidating(true);
    
    // Debounce validation to avoid excessive re-renders
    setTimeout(() => {
      const errors = validateCSVData(csvData || [], schema, requiredFields);
      
      const filteredErrors = errors.filter(error => {
        // Always include missing required fields error
        if (error.field === 'missing') return true;
        
        
        const mappedField = schema[error.field];
        const isChecked = requiredFields?.includes(error.field) || false;
        const isMustRequiredField = mustRequiredFields.includes(mappedField);
        
        return isChecked || isMustRequiredField;
      });
      
      setValidationErrors(filteredErrors);

      const summary = getValidationSummary(filteredErrors);
      setValidationSummary(summary);

      setIsValidating(false);
    }, 0);
  };

  // Run validation on component mount and when csvData changes
  useEffect(() => {
    if (csvData && csvData.length > 0 && mappingSchema) {
      validateMappedData(mappingSchema);
      // Set hasUserInteracted to true to show validation errors immediately
      setHasUserInteracted(true);
    }
  }, [csvData]);

  // Set up initial mapping schema
  useEffect(() => {
    const defaultMapped = mapCSVHeadersToSchema(csvFields || []);
    form.setFieldsValue(defaultMapped);
    setMappingSchema(defaultMapped);

    if (csvFields && csvFields.length > 0) {
      setRequiredFields(csvFields);
      
      if (setParentRequiredFields) {
        setParentRequiredFields(csvFields);
      }
    }

    // Validate the initial mapping schema
    if (csvData && csvData.length > 0) {
      validateMappedData(defaultMapped);
      // Show validation errors immediately when tab loads
      setHasUserInteracted(true);
    }
  }, []);

  const isDuplicationAllowed = (field: any) => {
    return doNotDuplicateFields.includes(field) && Object.values(mappingSchema)?.includes(field);
  };

  // Transform data for the table
  const tableData = csvFields?.map((field, index) => {
    const data = csvData?.[index]?.[field];
    const isChecked = requiredFields?.includes(field);
    const currentSelectedField = mappingSchema[field];
    
    const isMustRequiredField = mustRequiredFields.includes(mappingSchema[field]);
    
    const fieldErrors = validationErrors.filter(
      error => error.field === field
    );
    
    // Get validation status for this field - only if it's checked or must-required
    const validationStatus = (isChecked || isMustRequiredField) && fieldErrors.length > 0 ? 'error' : undefined;
    
    // Get validation message for this field - only if it's checked or must-required
    const validationMessage = (isChecked || isMustRequiredField) && fieldErrors.length > 0
      ? `${fieldErrors.length} validation errors`
      : undefined;

    return {
      key: index,
      field,
      data,
      isChecked,
      validationStatus,
      validationMessage,
      fieldErrors: (isChecked || isMustRequiredField) ? fieldErrors : []
    };
  });

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'field',
      width: 30,
      render: (field: string) => (
        <Form.Item valuePropName="checked" initialValue={true} name={`check-${field}`}>
          <Checkbox key={field}></Checkbox>
        </Form.Item>
      )
    },
    {
      title: 'Aktuelle Zuteilung',
      dataIndex: 'field',
      key: 'csvField',
      render: (field, record) => {
        // Show validation error indicator if there are errors and user has interacted
        const hasErrors = hasUserInteracted && record.fieldErrors && record.fieldErrors.length > 0;
        return (

          <div>
            {field}
            {hasErrors && (
              <div style={{ color: 'red', fontSize: '12px' }}>
                {record.fieldErrors[0].message}
                {record.fieldErrors.length > 1 && ` (+${record.fieldErrors.length - 1} more)`}
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Neue Zuteilung',
      dataIndex: 'field',
      width: 250,
      key: 'newField',
      render: (field, record) => (
        <Form.Item
          name={field}
          validateTrigger={'onChange'}
          initialValue={CSVMappingFields.noValue}
        >
          <Select
            placeholder="Nichts ausgewählt"
            disabled={!record.isChecked}
            style={{ width: '100%' }}
            value={mappingSchema[field] || CSVMappingFields.noValue}
          >
            {Object.values(CSVMappingFields).map((val, idx) => (
              <Option disabled={isDuplicationAllowed(val)} value={val} key={idx}>
                {val}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )
    }
  ];

  return (
    <div className="map-fields">
      {validationSummary.length > 0 && hasUserInteracted && (
        <Alert
          message={`"Neue Zuteilung" fehlt. Bitte weise alle Felder zu`}
          description={
            <ul>
              {validationSummary.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          }
          type="warning"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      )}

      <Form form={form} onValuesChange={onFieldChange}>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={isValidating}
          size="small"
        />
      </Form>
      <Alert
        style={{ marginBottom: '0px !important' }}
        message={CSVUploadMessages.checkAllFields(mustRequiredFields.join(', ') || '')}
        type="info"
        showIcon
      />
    </div>
  );
};

export default MapFieldsView;
