export const waitForElement = (
  elementSelector: any,
  action: 'removed' | 'added' | undefined = 'added'
) =>
  new Promise<HTMLElement>((resolve, reject) => {
    const timeout = 10000;
    const interval = 100;
    let iterations = 0;

    const thisInterval = setInterval(() => {
      const element: HTMLElement = document.querySelector(elementSelector);

      if (action === 'added' && element) {
        resolve(element);
        clearInterval(thisInterval)
      }

      if (action === 'removed' && !element) {
        resolve(element);
        clearInterval(thisInterval)
      }

      if (iterations * interval >= timeout) reject('Timeout element not found');

      iterations++;
    }, interval);
  });

export const injectScripts = (
  scriptData: { src: string; appendTo: 'head' | 'body' }[]
): Element[] => {
  const scripts: Element[] = [];

  scriptData.forEach(({ src, appendTo }) => {
    const existingScript = document.querySelector('[src="' + src + '"]');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = src;
      document[appendTo].appendChild(script);
      scripts.push(script);
    } else scripts.push(existingScript);
  });

  return scripts;
};

export const removeArrayOfElenents = (elements: Element[]) => {
  elements.forEach(el => el && el.remove());
};
