import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../routes';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/browser';
import { useAppDispatch } from '../../redux/hooks';
import { setUserData, setUserIsInitializing, useSubscriptionInfo, useSubscriptionInfoLoading } from '../redux/userSlice';
import { setProductFruitsProps } from '../../ProductFruitsWrapper/redux/productFruitsSlice';
import { getAllRoutes } from '../utils/routeUtils';
import SuspenseLoadingWrapper from '../../Share/container/SuspenseLoadingWrapper';
import { getAllAddOnsThunk } from '../../Marketplace/redux/thunk';
import { isTokenValid } from '../../Login/container/AuthRoutesHOC';

export const GET_EMPTY_UI_INFO = gql`
  query {
    getUser {
      id
      firstName
      lastName
      email
      imageURL
      role
      employeeGmails
      allowedTemplates
      provider
      createdAt
      trialAchievements {
        productFruitId
        hoursAdded
      }
      questionsAnswered {
        question
        answer
      }
      createdAt
    }
  }
`;

function EmptyUI() {
  const dispatch = useAppDispatch();
  const { data, loading: emptyUiInfoLoading, error } = useQuery(GET_EMPTY_UI_INFO);
  const { getUser: userData } = isTokenValid() && data ? data : { getUser: {} };

  const subscriptionInfo = useSubscriptionInfo();
  const subscriptionInfoLoading = useSubscriptionInfoLoading()

  const loading = emptyUiInfoLoading || subscriptionInfoLoading;

  if (error) {
    dispatch(setUserIsInitializing(false));
  }

  useEffect(() => {
    if (userData?.id) {
      Sentry.setUser({
        id: userData?.id
      });
      Sentry.setTag('uniqueIdentifier', String(new Date().getTime()));
      dispatch(setUserData(userData));
      dispatch(getAllAddOnsThunk());
    }
  }, [userData]);

  useEffect(() => {
    if (subscriptionInfo?.status)
      dispatch(setProductFruitsProps({ subscription_status: subscriptionInfo.status }));
  }, [subscriptionInfo]);

  if (loading) return <LoadingOutlined className="centered" spin />;

  const allRoutes = getAllRoutes(routes);
  return (
    <SuspenseLoadingWrapper>
      <Routes>
        {allRoutes.map(({ path, component }, i) => (
          <Route key={i} path={path} element={component} />
        ))}
      </Routes>
    </SuspenseLoadingWrapper>
  );
}

export default EmptyUI;
