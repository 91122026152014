import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { ApolloProvider, useMutation } from '@apollo/react-hooks';
import { MockedProvider } from '@apollo/react-testing';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';
import gql from 'graphql-tag';

import './App.scss';
import 'antd/dist/antd.css';
import 'moment/locale/de';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import deDe from 'antd/es/locale/de_DE';
import routes, { defaultRoute } from './UI/routes';
import mockWrapper from './mockWrapper';
import { Role } from './UI/utils/roleTypes';
import { AppMessages } from './config/messages';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { selectUserData, setUserIsInitializing, useUserIsInitializing } from './UI/redux/userSlice';
import {
  isTokenValid,
  closeSession,
  getMeetovoAuthData,
  populateLocalStorageWithAuth
} from './Login/container/AuthRoutesHOC';
import { getAppoloClient } from './Login/helper/login';
import { getEnvironment, isCustomDomain, redirectAgencyRootUser } from './helper/environment';
import { getUrlParam } from './helper/url';
import { navigateAndPreventSameRoute } from './helper/navigation';
import { getFunnelIdFromToken, mixpanelEvents } from './UI/utils/mixpanel';
import ErrorBoundary from './GeneralComponents/ErrorBoundary';
import { handleEmptyFunnelRedux } from './Funnel/redux/funnelSlice';
import { clearReduxStore } from './helper/clearReduxStore';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { selectedFunnelViewSelection } from './UI/redux/uiSlice';
import { useSelector } from 'react-redux';
import { getCompleteSubscriptionInfo } from './UI/redux/userThunk';
import SuspenseLoadingWrapper from './Share/container/SuspenseLoadingWrapper';
import { getAllUserSettingsFlagsThunk } from './hooks/getAllUserSettingFlags';
import AppHOC from './hoc/AppHOC';

const CustomerLogin = lazy(() => import('./CustomerLogin/container/CustomerLogin'));
const AutoRegistration = lazy(() => import('./Login/container/AutoRegistration'));
const UIController = lazy(() => import('./UI/container/UIController'));
const HelpButton = lazy(() => import('./HelpButton/container/HelpButton'));
const AgencyDashboardBrandedMetaData = lazy(() =>
  import('./AgencyDashboard/components/AgencyDashboardBrandedMetaData')
);

moment.locale('de');

declare global {
  interface Window {
    handleLogout(): any;
    productFruits?: any;
    apolloClient?: any;
    richTextExtensionErrorIsShowingUp?: boolean;
    richTextMissmatchErrorIsShowingUp?: boolean;
    faitracker: any;
  }
}

const RESET_TRIAL = gql`
  mutation resetTrial($trialResetToken: String!) {
    resetTrial(trialResetToken: $trialResetToken)
  }
`;

const AppActionsInApolloContext: React.FC = () => {
  const [resetTrial] = useMutation(RESET_TRIAL);
  const trialResetToken = getUrlParam('trial_reset_token');

  useEffect(() => {
    if (trialResetToken) handleResetTrial();
  }, [trialResetToken]);

  const handleResetTrial = () => {
    resetTrial({ variables: { trialResetToken } })
      .then(() => {
        if (isTokenValid()) window.handleLogout();
        message.success(AppMessages.resetTrial.successLoggedout, 8);
      })
      .catch(e => {
        const errorMessage = e.message.replace('GraphQL error: ', '');

        //@ts-ignore
        const thisError = AppMessages.resetTrial.apiErrors[errorMessage];
        if (thisError) message.error(thisError, 8);
        else message.error(AppMessages.resetTrial.error, 8);
      });
  };

  return null;
};

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loggedIn, setLoggedIn] = useState(isTokenValid() && !getUrlParam('trial_reset_token'));
  const [client, setClient] = useState<any>();

  const userData = useAppSelector(selectUserData);
  const isUserInitializing = useUserIsInitializing();

  const isDev = getEnvironment().isDev;
  const useMock = !!localStorage.useMock;
  const isRegister = location.pathname.includes('/registrieren');
  const isCheckout = location.pathname.includes('/checkout');
  const isCustomerLogin = location.pathname.includes('kunden-login/') || !!isCustomDomain();
  const isAgencyCustomer = userData?.role === Role.AGENCY_CUSTOMER;
  const isAdmin = userData?.role === Role.ADMIN;
  const isCoach = userData?.role === Role.COACH;
  const isDefaultDomainCustomerLogin = location.pathname.includes('kunden-login/');

  const data = getMeetovoAuthData();

  if (isDefaultDomainCustomerLogin && (isCoach || isAdmin))
    message.warning(AppMessages.useAgencyDashboardInkognito, 6);

  if (loggedIn && isCustomDomain() && !isUserInitializing && isCoach) {
    redirectAgencyRootUser();
  }

  if (loggedIn && isCustomerLogin && !isUserInitializing && !isCheckout) {
    navigateAndPreventSameRoute(navigate, '/bewerbungen', { replace: true });
  }

  if (location.pathname.endsWith('/')) {
    if (location.pathname === '/') {
      navigateAndPreventSameRoute(navigate, isAgencyCustomer ? '/bewerbungen' : '/meine-funnels', {
        replace: true
      });
    } else {
      navigateAndPreventSameRoute(
        navigate,
        location.pathname.substring(0, location.pathname.length - 1) + location.search,
        {
          replace: true
        }
      );
    }
  }
  const startTimeRef = useRef<Date>();

  useEffect(() => {
    handleSetAppolloClient();
  }, [loggedIn, data]);

  useEffect(() => {
    handleBuilderTimeTracking();
  }, [location.pathname]);

  useEffect(() => {
    if (userData) {
      handleLogin();
    }
  }, [userData]);

  useEffect(() => {
    initializePixelAndErrorHandling();
    loadAuthData();
  }, [data]);

  useEffect(() => {
    handleRouteRedirects();
  }, [loggedIn, location.pathname, isUserInitializing, userData?.role]);

  const handleSetAppolloClient = () => {
    if (!useMock) {
      const apolloClient = getAppoloClient();
      window.apolloClient = apolloClient;
      setClient(apolloClient);
    }
  };

  const initializePixelAndErrorHandling = () => {
    if (!isDev) {
      ReactPixel.init('717262752181116', undefined, {
        autoConfig: true,
        debug: process.env.NODE_ENV !== 'production'
      });
    }

    const handleOnError = (event: ErrorEvent) => {
      const { message: errorMessage } = event;
      if (
        (errorMessage === 'Uncaught Error: Got unexpected null or undefined' ||
          errorMessage === 'Uncaught Invariant Violation: Invalid DraftEditorContents node.') &&
        !window.richTextExtensionErrorIsShowingUp
      ) {
        window.richTextExtensionErrorIsShowingUp = true;
        message.error(
          'Eine Browser-Erweiterung unterbricht die Verbindung zum Textfeld. Kontaktiere den Support für die mögliche Unterstützung der Erweiterung.',
          10,
          () => {
            window.richTextExtensionErrorIsShowingUp = false;
          }
        );
      }
    };

    window.addEventListener('error', handleOnError);
    return () => window.removeEventListener('error', handleOnError);
  };

  const loadAuthData = () => {
    const data = getMeetovoAuthData();
    if (data) {
      populateLocalStorageWithAuth(JSON.parse(data));
      dispatch(getCompleteSubscriptionInfo());
      dispatch(getAllUserSettingsFlagsThunk());
    }
  };

  const handleBuilderTimeTracking = () => {
    if (location.pathname.includes('builder')) {
      startTimeRef.current = new Date();
    } else if (startTimeRef.current) {
      mixpanel.track(mixpanelEvents.TIME_SPEND_INSIDE_BUILDER, {
        funnelId: getFunnelIdFromToken(),
        minutes: moment.duration(moment().diff(startTimeRef.current)).asMinutes()
      });
      startTimeRef.current = undefined;
    }
  };

  const handleRouteRedirects = () => {
    if (isDefaultDomainCustomerLogin && (isCoach || isAdmin)) {
      message.warning(AppMessages.useAgencyDashboardInkognito, 6);
    }

    if (loggedIn && isCustomerLogin && !isUserInitializing) {
      navigateAndPreventSameRoute(navigate, '/kontakte', { replace: true });
    }

    if (location.pathname === '/') {
      navigateAndPreventSameRoute(navigate, isAgencyCustomer ? '/kontakte' : '/meine-funnels', {
        replace: true
      });
    } else if (location.pathname.endsWith('/')) {
      navigateAndPreventSameRoute(navigate, location.pathname.slice(0, -1) + location.search, {
        replace: true
      });
    }
  };

  const handleLogin = async () => {
    // await dispatch(getCompleteSubscriptionInfo());
    setLoggedIn(true);
    if (isCustomerLogin) {
      localStorage.setItem(
        'meetovoAgencyDashboardPathname',
        location.pathname.replace('/dashboard', '')
      );
      navigateAndPreventSameRoute(navigate, '/bewerbungen');
    }
  };

  window.handleLogout = () => {
    if (userData?.role === Role.AGENCY_CUSTOMER) {
      const meetovoAgencyDashboardPathname = localStorage.getItem('meetovoAgencyDashboardPathname');
      if (meetovoAgencyDashboardPathname) {
        navigateAndPreventSameRoute(navigate, meetovoAgencyDashboardPathname);
      }
    }

    if (getMeetovoAuthData()) {
      window.$crisp?.push(['do', 'session:reset']);
      window.$crisp?.push(['do', 'chat:hide']);
      window.CRISP_TOKEN_ID = '';
    }

    closeSession();
    clearReduxStore(dispatch);
    setLoggedIn(false);
  };

  const isPublicRoute = () => {
    const route = routes.find(({ path }) => location.pathname.includes(path));
    return route?.isPublic || false;
  };

  if (!client) return <LoadingOutlined className="centered" spin />;

  if (location.pathname.includes('/auto-registration') && !loggedIn) {
    return (
      <SuspenseLoadingWrapper>
        <ApolloProvider client={client}>
          <AutoRegistration
            onFinish={(success: boolean) => {
              if (success) handleLogin();
              else {
                navigateAndPreventSameRoute(navigate, '/registrieren', { replace: true });
                window.location.reload();
              }
            }}
          />
        </ApolloProvider>
      </SuspenseLoadingWrapper>
    );
  }

  if (
    !loggedIn &&
    !isRegister &&
    !location.pathname.includes('login') &&
    !isCheckout &&
    !isPublicRoute()
  ) {
    navigateAndPreventSameRoute(navigate, `login${location.search}`, { replace: true });
    dispatch(setUserIsInitializing(false));
  }

  if (
    loggedIn &&
    !isCustomerLogin &&
    (location.pathname.includes('/login') || location.pathname.includes('/registrieren'))
  ) {
    navigateAndPreventSameRoute(navigate, defaultRoute, { replace: true });
  }

  const renderContent = () => {
    if (!loggedIn && !isPublicRoute() && !isCheckout) {
      return isCustomerLogin ? <CustomerLogin onLogin={handleLogin} /> : undefined;
    }

    return (
      <AppHOC>
        <UIController />
        <HelpButton anonymAllowed={isCheckout && !loggedIn} />
      </AppHOC>
    );
  };

  const appContent = (
    <SuspenseLoadingWrapper>
      <ConfigProvider locale={deDe}>
        <>
          <AppActionsInApolloContext />
          {(isAgencyCustomer || isCustomerLogin) && <AgencyDashboardBrandedMetaData />}
          {renderContent()}
        </>
      </ConfigProvider>
    </SuspenseLoadingWrapper>
  );

  return (
    <ErrorBoundary>
      <div className="app">
        {useMock ? (
          <MockedProvider mocks={mockWrapper} addTypename={false}>
            {appContent}
          </MockedProvider>
        ) : (
          <ApolloProvider client={client}>{appContent}</ApolloProvider>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default App;
