import React, { useState } from 'react';
import { TbBrandMeta } from 'react-icons/tb';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';
import { useAppDispatch } from '../../../../redux/hooks';
import { useNavigate } from 'react-router';
import { getCurrentRoute } from '../../../helper/sharedFunctions';
import { saveAdBuilderStateThunk } from '../../../AdBuilder/redux/thunk';
import { FaGoogle } from 'react-icons/fa';
import { saveJobBuilderStateThunk } from '../../../AdJobBuilder/redux/thunk';

interface BuilderPreviewBasePropsTypes {
  className: string;
}

const GoogleJobAndAdBuilderHeader = ({ className }: BuilderPreviewBasePropsTypes) => {
  const [jobBuilderLoading, setJobBuilderLoading] = useState(false);
  const [adBuilderLoading, setAdBuilderLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentRoute = getCurrentRoute();

  const handleNavigate = async (item: string) => {
    if (currentRoute.includes('/ad-builder')) {
      setJobBuilderLoading(true);
      await dispatch(saveAdBuilderStateThunk());
      setJobBuilderLoading(false);
      navigate(`${item}`);
    }
    if (currentRoute.includes('/google-job-builder')) {
      setAdBuilderLoading(true);
      await dispatch(saveJobBuilderStateThunk());
      setAdBuilderLoading(false);
      navigate(`${item}`);
    }
  };
  return (
    <div className={className}>
      <div className="header-view-changer">
        <div
          className={`preview-size-icon 
                ${currentRoute.includes('/google-job-builder') ? 'active' : ''}`}
        >
          {jobBuilderLoading ? (
            <DefaultSmallLoader loading={jobBuilderLoading} />
          ) : (
            <FaGoogle
              onClick={() => {
                handleNavigate('/google-job-builder/job');
              }}
            />
          )}
        </div>
        <div
          className={`preview-size-icon ${currentRoute.includes('/ad-builder') ? 'active' : ''}`}
        >
          {adBuilderLoading ? (
            <DefaultSmallLoader loading={adBuilderLoading} />
          ) : (
            <TbBrandMeta
              size={'1.25em'}
              strokeWidth={3}
              onClick={() => {
                handleNavigate('/ad-builder/bearbeiten');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleJobAndAdBuilderHeader;
