import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { ROOT_NODE } from 'craftjs-utils-meetovo';
import ReactDOM from 'react-dom';
import { useAppSelector } from '../../redux/hooks';
import { elementsNotReqActionIcons, CRAFT_ELEMENTS } from '../config/craftElements';
import {
  addDefaultPropsInElement,
  addDefaultPropsInElementOnParentBase,
  checkIfUserIntractingComponentExisitInTree
} from '../helper/craftJs';
import { useIsMobileView } from '../hooks/useIsMobileView';
import { POSITION_OFFSET, getActionControllerPositionOffset } from '../helper/renderNode';
import CopyAction from './components/CopyAction';
import DeleteAction from './components/DeleteAction';
import SettingAction from './components/SettingAction';
import DragAction from './components/DragAction';
import SaveAction from './components/SaveAction';
import SaveBlockCheckboxAction from './components/SaveBlockCheckboxAction';
import ArrowAction from './components/ArrowAction';
import HideAction from './components/HideAction';
import TextAiAction from './components/TextAiAction';
import { isGoogleAdBuilderPath, isWidgetBuilderPath } from '../WidgetsBuilder/helper/helper';
import { useScrollPosition } from '../../helper/useScrollPosition';
import { useBuilderSelectedPageData } from '../redux/builderSlice';
import { contactPageType } from '../helper/defaultValues';

export const RenderNode = ({ render }: any) => {
  const [portalDimensions, setPortalDimensions] = useState({ width: 0, height: 0 });
  const isMobileView = useIsMobileView();
  const { actions, query } = useEditor();
  const currentPageData = useBuilderSelectedPageData()
  const role = useAppSelector(state => state.user?.data?.role);
  const canUserSaveMultipleBlocks =
  role === 'ADMIN' ||
  window.location.host.includes('localhost') ||
  !!localStorage.getItem('meetovoAllowUserToSaveMultipleBlocks');

  const scrollPosition = useScrollPosition('builder-preview');
  const {
    isHover,
    isActive,
    dom,
    moveable,
    deletable,
    copyable,
    haveSettings,
    saveable,
    name,
    nodeId,
    currentNode,
    props,
    hideable,
    saveableElementsProp
  } = useNode(node => {
    const childIsBeingHovered =
      node.data.props.showParentSettingsOnChildHover &&
      !!node.data.nodes.find(curr => query.getEvent('hovered').contains(curr));

      return {
        nodeId: node.id,
        isActive: query.getEvent('selected').contains(node.id),
        isHover: node.events.hovered || childIsBeingHovered,
        dom: node.dom,
        name: node.data.custom.displayName || node.data.displayName,
        moveable: query.node(node.id).isDraggable() && !node.data.props.onlySettingsReq,
        deletable:
        query.node(node.id).isDeletable() &&
        !node.data.props.onlySettingsReq &&
        !node.data.props.deleteNotReq,
        copyable: !node.data.props.copyNotReq && !node.data.props.onlySettingsReq,
        haveSettings: !node.data.props.customRules?.noSettings,
        openChildSettingsOnParentSettings: node.data.props.openChildSettingsOnParentSettings,
        openParentSettingsOnChildSettings: node.data.props.openParentSettingsOnChildSettings,
        saveable: node.data.props.customRules?.saveable,
        parent: node.data.parent,
        props: node.data.props,
        hideable: node.data.props.canHide,
        currentNode: node,
        saveableElementsProp: node.data.props?.saveableElements
      };
    });


    const isWidgetBuilder = isWidgetBuilderPath();
    const isGoogleAdBuilder = isGoogleAdBuilderPath();

    const enableAiText = !(isWidgetBuilder || isGoogleAdBuilder);

    const hasUserInteractingComponent = checkIfUserIntractingComponentExisitInTree(
      query.getNodes(),
      nodeId
    );

    function isContactFormNode(query: any, nodeId: string, name: string): boolean {
      const allNodes = query.getNodes();

      // If it's already a contact form by name, return true
      if (name === CRAFT_ELEMENTS.CONTACT_FORM && currentPageData?.type == contactPageType) return true;

      // Helper function to check all nodes at a level
      const checkDisplayNameInNodes = (nodes: number[] | undefined): boolean => {
        if (!nodes) return false;
        let isContactFormElement =  nodes.some((node) => allNodes[node]?.data?.displayName === CRAFT_ELEMENTS.CONTACT_FORM);
        return isContactFormElement && currentPageData?.type == contactPageType
      };

      // Start checking levels
      let currentNodes = allNodes[nodeId]?.data?.nodes;

      for (let level = 1; level <= 3; level++) {
        if (checkDisplayNameInNodes(currentNodes)) {
          return true;
        }

        // Collect all nodes at the next level
        currentNodes = currentNodes?.flatMap((node:any) => allNodes[node]?.data?.nodes || []);
      }

      return false; // Not found after checking all levels
    }

    const isContactForm = isContactFormNode(query, nodeId, name);

  const parentNode = query.getState()?.nodes[currentNode?.data?.parent]?.data;
  const currentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    directManipulationInPos();
  }, [isMobileView]);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;
    addDefaultPropsInElement(currentNode.data.name, actions.history.ignore().setProp, nodeId);
    addDefaultPropsInElementOnParentBase(
      currentNode.data.name,
      parentNode?.displayName,
      actions.history.ignore().setProp,
      parentNode,
      nodeId
    );
  }, []);

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback(
    (dom: HTMLElement | null) => {
      const { top, left, bottom, right } = dom
        ? dom.getBoundingClientRect()
        : { top: 0, left: 0, bottom: 0, right: 0 };
      return {
        top: `${top - portalDimensions.height}px`,
        left: `${right - portalDimensions.width}px`
      };
    },
    [portalDimensions]
  );

  const getElementDimensions = useCallback((dom: HTMLElement | null) => {
    const { width, height } = dom ? dom.getBoundingClientRect() : { width: 0, height: 0 };
    return { width, height };
  }, []);

  const directManipulationInPos = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos , scrollPosition ]);

  useEffect(() => {
    document
      .querySelector('.builder__content, .job-builder-container')
      ?.addEventListener('scroll', directManipulationInPos);

    return () => {
      document
        .querySelector('.builder__content, .job-builder-container')
        ?.removeEventListener('scroll', directManipulationInPos);
    };
  }, [directManipulationInPos]);

  useEffect(() => {
    setPortalDimensions(getElementDimensions(currentRef.current));
  }, [isHover, isActive, dom, deletable]);

  return (
    <>
      {(isHover || isActive) && !elementsNotReqActionIcons.includes(currentNode.data.name)
        ? document.querySelector('.page-container') &&
          ReactDOM.createPortal(
            <div
              ref={currentRef}
              className="text-white bg-primary position-fixed flex items-center indicator-container"
              style={{
                left: getActionControllerPositionOffset(
                  getPos(dom).left,
                  name,
                  POSITION_OFFSET.LEFT
                ),
                top: getActionControllerPositionOffset(getPos(dom).top, name, POSITION_OFFSET.TOP),
                zIndex: 1
              }}
            >
              <ul>
                {saveable && canUserSaveMultipleBlocks && <SaveBlockCheckboxAction />}
                {(saveable || saveableElementsProp) && <SaveAction />}
                {moveable && <DragAction />}
                {name === CRAFT_ELEMENTS.RICH_TEXT && props.showAiTextSettings && enableAiText && <TextAiAction />}
                {props.showArrow && <ArrowAction elementType={props.elementType} />}
                {hideable && <HideAction />}
                {nodeId !== ROOT_NODE && haveSettings && <SettingAction />}
                {nodeId !== ROOT_NODE && !hasUserInteractingComponent && copyable && <CopyAction />}
                {deletable && <DeleteAction disabled={isContactForm}/>}
              </ul>
            </div>,
            document.querySelector('.page-container') as Element
          )
        : null}
      {render}
    </>
  );
};
