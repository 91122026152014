import { Form, Input, Button } from 'antd';
import React from 'react';
import LoginValidationRules from '../../utils/LoginValidationRules';
import { navigateAndPreventSameRoute } from '../../../helper/navigation';
import { useNavigate } from 'react-router';
import { AUTH_ROUTES } from '../../utils/LoginContent';

interface LoginFormProps {}

const LoginForm: React.FC<LoginFormProps> = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="styled-form-group">
      <Form.Item
        className="email-input form-input "
        label="E-Mail"
        name="email"
        hasFeedback
        rules={LoginValidationRules.email}
      >
        <Input autoCapitalize="none" placeholder="dein.name@dein-unternehmen.de" />
      </Form.Item>

      <Form.Item
        className="form-input"
        label="Passwort"
        name="password"
        rules={LoginValidationRules.password}
      >
        <Input.Password placeholder="⏺ ⏺ ⏺ ⏺ ⏺ ⏺ ⏺" />
      </Form.Item>
      <Button
        className="forgot-pswd-btn"
        type="link"
        onClick={() => navigateAndPreventSameRoute(navigate, AUTH_ROUTES.RESET_PASSWORD)}
      >
        Passwort vergessen?
      </Button>
    </div>
  );
};

export default LoginForm;
