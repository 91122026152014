import { useNavigate, useParams } from 'react-router';
import { useAppDispatch } from '../../redux/hooks';
import { setSelectedElementTab, useElementTabSelectMutex } from '../redux/builderSlice';
import { setAgencyOSBuilderSelectedElementTab } from '../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { useIsAgencyOSBuilderPath } from '../hooks/useIsAgencyOSBuilderPath';
import { AGENCY_OS_BUILDER_PATH } from '../../UI/paths';
import { useIsBuilderElementPath } from '../hooks/useIsBuilderElementPath';
import { useIsJobBuilderPath } from '../hooks/useIsJobBuilderPath';
import { useIsWidgetsBuilderPath } from '../hooks/useIsWidgetsBuilderPath';

interface PayloadType {
  payload: string;
}

interface Options {
  navigationReq?: boolean;
}

export const useSetSelectedElementTab = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isWidgetsBuilderPath = useIsWidgetsBuilderPath();
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  const isJobBuilderPath = useIsJobBuilderPath();
  const isBuilderElementPath = useIsBuilderElementPath();
  const elementTabSelectMutex = useElementTabSelectMutex();

  const navigateToHubSettingsIfNeeded = (options?: Options) => {
    if (options?.navigationReq && !location.pathname.includes(AGENCY_OS_BUILDER_PATH.hubSettings)) {
      navigate(AGENCY_OS_BUILDER_PATH.hubSettings);
    }
  };

  const navigateToBuilderElementIfNeeded = (options?: Options) => {
    if (options?.navigationReq && !isBuilderElementPath) {
      navigate('/builder/bearbeiten/elemente');
    }
  };

  const navigateToeWidgetBuilderIfNeeded = () => {
    if (!location.pathname.includes('/bearbeiten')) {
      navigate(`bearbeiten`);
    }
  };

  const setElementTab = (payload: PayloadType, options?: Options) => {
    if (isAgencyOSBuilderPath) {
      navigateToHubSettingsIfNeeded(options);
      dispatch(setAgencyOSBuilderSelectedElementTab(payload));
    } else if (isWidgetsBuilderPath) {
      navigateToeWidgetBuilderIfNeeded();
    } else if (isJobBuilderPath) {
      return;
    } else {
      navigateToBuilderElementIfNeeded(options);
      if (!elementTabSelectMutex)
        dispatch(setSelectedElementTab(payload));
    }
  };

  return setElementTab;
};
