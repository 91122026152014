import { Col, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import { Element, useEditor, useNode } from '@craftjs/core';
import { CraftElementBaseProps, getElementColor, getRandomId } from '../../helper/craftJs';
import RichTextComponent from './RichTextComponent/container/RichTextComponent';
import IconComponent, { IconType } from './IconComponent';
import { Card } from 'react-bootstrap';
import { Form, Card as SettingsCard } from 'antd';
import { urlAndPhoneFormValidator } from '../../../helper/url';
import { linkBoxDefaultText } from '../../config/linkBoxComponentDefaultValues';
import { useAgencyOSCurrentlyEditingTheme } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { AGENCY_OS_THEME_KEYS } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';

interface LinkBoxComponentProps extends CraftElementBaseProps {
  background?: string;
  children?: React.ReactElement;
}

const LinkBoxComponent = ({ background, children }: LinkBoxComponentProps) => {
  const { query, actions } = useEditor();
  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const theme = useAgencyOSCurrentlyEditingTheme();
  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const nodeTreeIconComponent = query
        .parseReactElement(
          <Element
            fontSize={3}
            type={IconType.icon}
            value="IoIosList"
            color={AGENCY_OS_THEME_KEYS.ACCENT_COLOR}
            align="center"
            isOutline
            onlySettingsReq
            id={getRandomId(10)}
            is={IconComponent}
            showPlusIcon={false}
          ></Element>
        )
        .toNodeTree(node => {
          node.id = getRandomId(10);
        });
      actions.history.ignore().addNodeTree(nodeTreeIconComponent, nodeId);
      const nodeTreeTextComponent = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={RichTextComponent}
            html={linkBoxDefaultText}
            onlySettingsReq
            showPlusIcon={false}
          ></Element>
        )
        .toNodeTree(node => {
          node.id = getRandomId(10);
        });
      actions.history.ignore().addNodeTree(nodeTreeTextComponent, nodeId);
    }
  }, []);

  const nestedChildren = children?.props?.children;

  return (
    <ActionsController className="link-box-action-controller">
      <Card
        data-testid="link-box-component-testing"
        style={{ background: getElementColor(background, theme), minHeight: '200px' }}
      >
        <div data-testid="link-box-component-main-wrapper" className="link-box-main-wrapper">
          {nestedChildren?.[0]}
          <div className="text-component-wrapper">{nestedChildren?.[1]}</div>
        </div>
      </Card>
    </ActionsController>
  );
};

const LinkBoxComponentSettings = () => {
  const [form] = Form.useForm();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));

  const theme = useAgencyOSCurrentlyEditingTheme();
  return (
    <SettingsCard className="settings-card" title="Link" bordered={false}>
      <BuilderSettingsTitle title="URL" />
      <Form
        form={form}
        initialValues={{ url: props.url || '' }}
        onFinish={values => {
          setProp((props: any) => (props.url = values.url));
        }}
        autoComplete="off"
      >
        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: 'URL oder Telefon ist erforderlich.'
            },
            urlAndPhoneFormValidator
          ]}
        >
          <Input placeholder="https://www" onChange={form.submit} />
        </Form.Item>
      </Form>
      <BuilderSettingsTitle title="Farbe" />
      <Row>
        <Col span={12} data-testid="link-box-component-background">
          <BuilderColorPickerButton
            dataTestId="craftjs-link-box-component-button-color-picker"
            color={getElementColor(props.background, theme)}
            onChange={(background, colorKey) => {
              setProp((props: any) => {
                props.background = colorKey || background;
              });
            }}
          />
        </Col>
      </Row>
    </SettingsCard>
  );
};

const LinkBoxComponentDefaultProps = {
  url: '',
  background: AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR
};

LinkBoxComponent.craft = {
  name: CRAFT_ELEMENTS.LINK_BOX,
  props: LinkBoxComponentDefaultProps,
  related: {
    settings: LinkBoxComponentSettings
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false
  }
};

export default LinkBoxComponent;
