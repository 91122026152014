import React from 'react';
import AuthRoutesHOC from '../../container/AuthRoutesHOC';
import EmailSendPasswordResetForm from './EmailSendPasswordResetForm';

const ResetPasswordPage = () => {
  return (
    <AuthRoutesHOC loginMode="reset-password" onLogin={() => {}}>
      <EmailSendPasswordResetForm />
    </AuthRoutesHOC>
  );
};

export default ResetPasswordPage;
