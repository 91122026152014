import React, { useRef } from 'react';
import { AD_TYPES } from './TemplateTypesView';
import FreeTemplate from './FreeTemplate';
import EmployeeTestimonialTemplate from './EmployeeTestimonialTemplate';
import AdvantagesTemplate from './AdvantagesTemplate';
import StatementTemplate from './StatementTemplate';
import { Button } from 'antd';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import { useEditor } from '@craftjs/core';
import { adTemplatePreview } from '../../components/sharedUI/AdTemplatePreview';
import { useDispatch } from 'react-redux';
import { createAiGeneratedAdThunk } from '../../redux/thunk';
import { useCreateAdTemplateTypeLoading } from '../../redux/adBuilderSlice';
import { useBuilderPages, useFunnelTheme, useJobInformation } from '../../../redux/builderSlice';
import { CRAFT_ELEMENTS } from '../../config/craftElements';

interface TemplateTypeFieldsProps {
  selectedType: AD_TYPES;
  backStep: (goToStep?: number) => void;
  nextStep: (type?: string) => void;
  toggleVisible: (e: boolean) => void;
  adGenerationData: AdGeneration;
  shouldGenerateAd: Boolean;
}

const TemplateTypeFields = ({
  selectedType,
  backStep,
  nextStep,
  toggleVisible,
  adGenerationData,
  shouldGenerateAd
}: TemplateTypeFieldsProps) => {
  const { actions, query } = useEditor();
  const builderPages = useBuilderPages();
  const theme = useFunnelTheme();
  const loading = useCreateAdTemplateTypeLoading();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const getElementAppenderData = (nodeId: string) => {
    const elements = query.getSerializedNodes();
    let elementAppenderData = { key: '', index: 0 };

    Object.entries(elements).find(([key, value]) => {
      if (value.displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER) {
        elementAppenderData.index = elementAppenderData.index + 1;
      }
      if (
        value.displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER &&
        (value.props?.id === nodeId || key === adGenerationData?.nodeId)
      ) {
        elementAppenderData = {
          key,
          index: elementAppenderData.index
        };
        return true;
      }
      return false;
    });
    return elementAppenderData;
  };

  const handleFormFinish = async (values: any) => {
    nextStep();
    const formData = { ...values, type: selectedType };
    const aiGeneratedAdResponse: { [key: string]: any } = await dispatch(
      createAiGeneratedAdThunk({
        formData,
        adGenerationData,
        shouldGenerateAd
      })
    );

    if (!loading) {
      backStep(0);
      toggleVisible(false);
    }
    const { aiGeneratedAd, adImage, nodeId } = aiGeneratedAdResponse?.payload;

    if (!shouldGenerateAd && nodeId) {
      actions.setProp(nodeId, (props: any) => (props.hasAdGenerationInput = true));
    }

    const elementAppenderData = getElementAppenderData(nodeId);

    if (!loading && aiGeneratedAd) {
      actions.delete(elementAppenderData?.key);
      const nodeTree = query
        .parseReactElement(
          adTemplatePreview(builderPages, theme, nodeId, aiGeneratedAd, adImage, formData?.type)
        )
        .toNodeTree();

      actions.history.ignore().setProp('ROOT', state => {
        state.childNodes = [];
      });
      actions.addNodeTree(
        nodeTree,
        `ROOT`,
        elementAppenderData?.key ? elementAppenderData?.index : elementAppenderData?.index + 1
      );
      
      setTimeout(() => {
        const element = document.getElementById(nodeId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 1000);
    }
  };

  const renderComponent = () => {
    switch (selectedType) {
      case AD_TYPES.STATEMENT:
        return (
          <StatementTemplate
            initialData={adGenerationData}
            formInstance={formRef}
            onSubmit={handleFormFinish}
          />
        );
      case AD_TYPES.ADVANTAGES:
        return (
          <AdvantagesTemplate
            initialData={adGenerationData}
            formInstance={formRef}
            onSubmit={handleFormFinish}
          />
        );
      case AD_TYPES.EMPLOYEE_TESTIMONIAL:
        return (
          <EmployeeTestimonialTemplate
            initialData={adGenerationData}
            formInstance={formRef}
            onSubmit={handleFormFinish}
          />
        );
      case AD_TYPES.FREE:
        return (
          <FreeTemplate
            initialData={adGenerationData}
            formInstance={formRef}
            onSubmit={handleFormFinish}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {renderComponent()}
      <div className="d-flex justify-content-between">
        <Button type="link" onClick={() => backStep()}>
          Zurück
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Erstellen
        </Button>
      </div>
    </div>
  );
};

export default TemplateTypeFields;
