import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const DELETE_BOOKING = gql`
  mutation deleteBooking($bookingId: Int!) {
    deleteBooking(id: $bookingId)
  }
`;

export default () => useMutation(DELETE_BOOKING);

export function deleteBookingApi(id: number) {
  return window.apolloClient.mutate({
    mutation: DELETE_BOOKING,
    variables: { bookingId: id }
  });
}
