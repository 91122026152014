import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useBookingState } from "../../redux/slice";
import { deleteBulkEventThunk } from "../../redux/thunk";
import { selectUserData } from "../../../UI/redux/userSlice";
import { useAppSelector } from "../../../redux/hooks";

const DeletePopover = () => {
  const [visible, setVisible] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const { selectedBookings } = useBookingState();
  const dispatch = useDispatch();
  const userData = useAppSelector(selectUserData);

  const toggleVisibility = () => {
    setVisible(!visible);
    if (!visible) {
      setConfirmation('');
    }
  };

  const handleCallback = () => {
    toggleVisibility()
  }

  const handleDelete = () => {
    if (confirmation === 'LÖSCHEN') {
      dispatch(deleteBulkEventThunk({ selectedBookings, userData, callBack: handleCallback }))
      setConfirmation('');
    }
  };

  return (
    <>
      <Button className="selection-button" onClick={toggleVisibility}>
        <BiTrash color="red" />
        <span>Löschen</span>
      </Button>
      <Modal
        title="Bewerbungen löschen"
        visible={visible}
        onCancel={toggleVisibility}
        footer={[
          <Button key="cancel" onClick={toggleVisibility}>
            Abbrechen
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={handleDelete}
            disabled={confirmation !== 'LÖSCHEN'}
          >
            Unwiderruflich löschen
          </Button>
        ]}
      >
        <p>
          Du hast {selectedBookings.length} Bewerbungen ausgewählt, die du unwiderruflich und ohne
          Rückstände aus dem System löschen möchtest. Um dies zu tun, bestätige dies, indem
          du 'LÖSCHEN' eingibst.
        </p>
        <Input
          placeholder="LÖSCHEN"
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
          style={{ marginTop: 16 }}
        />
      </Modal>
    </>
  );
}

export default DeletePopover;
