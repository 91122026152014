import React, { createContext, useContext, useCallback } from 'react';
import { Editor } from '@tiptap/react';

interface EditorContextType {
  editors: { [key: string]: Editor };
  registerEditor: (id: string, editor: Editor) => void;
  unregisterEditor: (id: string) => void;
  getEditor: (id: string) => Editor | undefined;
}

const EditorContext = createContext<EditorContextType>({
  editors: {},
  registerEditor: () => {},
  unregisterEditor: () => {},
  getEditor: () => undefined,
});

export const useEditorContext = () => useContext(EditorContext);

export const EditorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [editors, setEditors] = React.useState<{ [key: string]: Editor }>({});

  const registerEditor = useCallback((id: string, editor: Editor) => {
    setEditors(prev => ({
      ...prev,
      [id]: editor,
    }));
  }, []);

  const unregisterEditor = useCallback((id: string) => {
    setEditors(prev => {
      const newEditors = { ...prev };
      delete newEditors[id];
      return newEditors;
    });
  }, []);

  const getEditor = useCallback((id: string) => editors[id], [editors]);

  return (
    <EditorContext.Provider
      value={{
        editors,
        registerEditor,
        unregisterEditor,
        getEditor,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
