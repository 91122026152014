import { getEnvironment } from './environment';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

export const configureSentryLogs = () => {
  const { isDev } = getEnvironment();

  const originalConsoleLog = console.log;
  const originalConsoleError = console.error;
  const originalConsoleWarn = console.warn;

  if (!isDev) {
    console.log = (...args) => {
      Sentry.captureMessage(`Console Log: ${args.join(' ')}`, Severity.Info);
      originalConsoleLog.apply(console, args);
    };

    console.error = (...args) => {
      Sentry.captureMessage(`Console Error: ${args.join(' ')}`, Severity.Error);
      originalConsoleError.apply(console, args);
    };

    console.warn = (...args) => {
      Sentry.captureMessage(`Console Warn: ${args.join(' ')}`, Severity.Warning);
      originalConsoleWarn.apply(console, args);
    };
  }
};
