import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { CRAFT_ELEMENTS } from '../../../config/craftElements';
import { ActionsController } from '../../sharedUI/ActionsController';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../sharedUI/BuilderColorPickerButton';
import { Element, useEditor, useNode } from '@craftjs/core';
import { CraftElementBaseProps, getElementColor, getRandomId } from '../../../helper/craftJs';
import RichTextComponent from '../RichTextComponent/container/RichTextComponent';
import { Card } from 'react-bootstrap';
import { Card as SettingsCard } from 'antd';
import { defaultParagraph, defaultTitle } from '../../../config/supportCardDefaultValues';
import { ContactComponent } from './ContactComponent';
import { AGENCY_OS_THEME_KEYS } from '../../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { useAgencyOSCurrentlyEditingTheme } from '../../../AgencyOSBuilder/redux/agencyOSBuilderSlice';

interface LinkBoxComponentProps extends CraftElementBaseProps {
  background?: string;
  children?: React.ReactElement;
}

export default function SupportCardComponent({ background, children }: LinkBoxComponentProps) {
  const { query, actions } = useEditor();
  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const theme = useAgencyOSCurrentlyEditingTheme();
  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const nodeTreeTitleComponent = query
        .parseReactElement(
          <Element
            //@ts-ignore
            fontSize={3}
            align="left"
            id={getRandomId(10)}
            is={RichTextComponent}
            html={defaultTitle}
            onlySettingsReq
            showPlusIcon={false}
            required
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTreeTitleComponent, nodeId);
      const nodeTreeContactComponent = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={ContactComponent}
            contentAlign="left"
            onlySettingsReq
            showPlusIcon={false}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTreeContactComponent, nodeId);
      const nodeTreeTextComponent = query
        .parseReactElement(
          <Element
            id={getRandomId(10)}
            is={RichTextComponent}
            showPlusIcon={false}
            html={defaultParagraph}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTreeTextComponent, nodeId);
    }
  }, []);

  const nestedChildren = children?.props?.children;
  return (
    <ActionsController className="support-card-component">
      <Card
        style={{
          background: getElementColor(background, theme),
          padding: '1.5rem'
        }}
      >
        <div className="link-box-main-wrapper">
          {nestedChildren?.[0]}
          {nestedChildren?.[1]}
          <div style={{ maxHeight: 300, overflow: 'auto' }} className="text-component-wrapper">
            {nestedChildren?.[2]}
          </div>
        </div>
      </Card>
    </ActionsController>
  );
}

const SupportCardSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const theme = useAgencyOSCurrentlyEditingTheme();

  return (
    <SettingsCard className="settings-card" title="Contact Card" bordered={false}>
      <BuilderSettingsTitle title="Farbe" />
      <Row>
        <Col span={12}>
          <BuilderColorPickerButton
            color={getElementColor(props.background, theme)}
            onChange={(backgroundColor, colorKey) => {
              setProp((props: any) => {
                props.background = colorKey || backgroundColor;
              });
            }}
          />
        </Col>
      </Row>
    </SettingsCard>
  );
};

const LinkBoxComponentDefaultProps = {
  background: AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR
};

SupportCardComponent.craft = {
  name: CRAFT_ELEMENTS.SUPPORT_CARD,
  props: LinkBoxComponentDefaultProps,
  related: {
    settings: SupportCardSettings
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false
  }
};
