import gql from 'graphql-tag';

export const SET_COMMUNICATION_MUTATION = gql`
  mutation SetCommunication($input: SetCommunicationInput!) {
    setCommunication(input: $input) {
      id
      speech
      writingStyle
    }
  }
`;

export const SET_COMPANY_DETAILS_MUTATION = gql`
  mutation SetCompanyDetail($input: SetCompanyDetailInput!) {
    setCompanyDetail(input: $input) {
      id
      name
      description
      benefits
      uniqueSellingPoint
    }
  }
`;
export const SET_JOB_INFORMATION_MUTATION = gql`
  mutation SetJobInformation($input: SetJobInformationInput!) {
    setJobInformation(input: $input) {
      id
      title
      category
      employmentType
      salary
      salaryPlan
      workplaceModel
      address
      jobCategory {
        id
        category
        categoryId
      }
      subCategory {
        id
        category
        categoryId
      }
    }
  }
`;

export const SET_TARGET_AUDIENCE_MUTATION = gql`
  mutation SetTargetAudience($input: SetTargetAudienceInput!) {
    setTargetAudience(input: $input) {
      id
      age
      goals
      experience
      experienceDuration
      education
      skills {
        skill
      }
    }
  }
`;

export const COMPANY_JOB_TARGET_AUDIENCE_COMMUNICATION = gql`
  mutation SetCompanyJobTargetAudience(
    $company: SetCompanyDetailInput!
    $job: SetJobInformationInput!
    $targetAudience: SetTargetAudienceInput!
    $communication: SetCommunicationInput!
  ) {
    setCompanyDetail(input: $company) {
      id
      name
      description
      benefits
      uniqueSellingPoint
    }
    setJobInformation(input: $job) {
      id
      title
      category
      employmentType
      salary
      salaryPlan
      workplaceModel
      address
      jobCategory {
        id
        category
        categoryId
      }
      subCategory {
        id
        category
        categoryId
      }
    }
    setTargetAudience(input: $targetAudience) {
      id
      age
      goals
      experience
      experienceDuration
      education
      skills {
        skill
      }
    }
    setCommunication(input: $communication) {
      id
      speech
      writingStyle
    }
  }
`;

export function setCompanyJobTargetAudienceMutation(
  company: any,
  job: any,
  targetAudience: any,
  communication: any
) {
  return window.apolloClient.mutate({
    mutation: COMPANY_JOB_TARGET_AUDIENCE_COMMUNICATION,
    variables: { company, job, targetAudience, communication }
  });
}

export function setCommunicationMutation(input: any) {
  return window.apolloClient.mutate({
    mutation: SET_COMMUNICATION_MUTATION,
    variables: { input }
  });
}

export function setCompanyDetailMutation(input: any) {
  return window.apolloClient.mutate({
    mutation: SET_COMPANY_DETAILS_MUTATION,
    variables: { input }
  });
}

export function setJobInformationMutation(input: any) {
  return window.apolloClient.mutate({
    mutation: SET_JOB_INFORMATION_MUTATION,
    variables: { input }
  });
}

export function setTargetAudienceMutation(input: any) {
  return window.apolloClient.mutate({
    mutation: SET_TARGET_AUDIENCE_MUTATION,
    variables: { input }
  });
}
