import React, { useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Button } from 'react-bootstrap';
import { SliderValue } from 'antd/lib/slider';
import ContentEditable from 'react-contenteditable';
import { ActionsController } from '../../sharedUI/ActionsController';
import { useBuilderSelectedPageData, useElementsPresets } from '../../../redux/builderSlice';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../config/craftElements';
import { FUNNEL_THEME_KEYS, builderPresetElements } from '../../../interfaces/builderSliceTypes';
import {
  CraftElementBaseProps,
  getElementColor,
  getKeyByValue,
  getValueByKey
} from '../../../helper/craftJs';
import {
  ButtonAvailableBorderRadius,
  defaultButtonRadius
} from '../../../interfaces/ButtonSizeInterface';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { thankyouPageType } from '../../../helper/defaultValues';
import SettingsGB from '../../Settings/SettingsGB';
import { ButtonSettingsItems } from '../../Settings/SettingsTemplates';

interface Props extends CraftElementBaseProps {
  backgroundColor?: string;
  color?: string;
  boldText?: any;
  href?: any;
  isLink?: any;
  size?: any;
  fontSize?: string;
  buttonAlign?: any;
  title?: string;
  isFirstRender?: boolean;
  actionLogic?: string | number;
  customClasses?: string;
  onlySettingsReq?: boolean;
  borderRadius?: any;
  settings?: {
    shouldShowActionSelector: boolean;
  };
  isPresetOn?: boolean;
  isDefaultGradient?: boolean;
}

const buttonSize = (value: SliderValue) => {
  if (value == 0) {
    return {
      fontSize: '0.9em'
    };
  } else if (value == 50) {
    return {
      fontSize: '1em'
    };
  } else {
    return {
      fontSize: '1.15em'
    };
  }
};

export const ButtonComponent = ({
  href,
  title,
  isLink,
  isFirstRender,
  customClasses,
  isPresetOn,
  ...props
}: Props) => {
  const selectedPage = useBuilderSelectedPageData();
  const theme = useCurrentlyEditingBuilderTheme();
  const { selected, nodeId, node } = useNode(state => ({
    nodeId: state.id,
    selected: state.events.selected,
    dragged: state.events.dragged,
    node: state
  }));

  const { query, enabled, actions } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selectedNode: any;

    if (currentNodeId) {
      selectedNode = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }
    return {
      enabled: state.options.enabled,
      selectedNode
    };
  });

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    if (isFirstRender) {
      selectedPage?.type === thankyouPageType &&
        actions.history.ignore().setProp(nodeId, (state: any) => {
          state.actionLogic = '';
        });
    }
  }, []);

  const [editable, setEditable] = useState(false);

  const presets = useElementsPresets(builderPresetElements.BUTTON);
  const currentStyles = isPresetOn ? { ...props, ...presets } : props;
  const { backgroundColor, italic, underline, color, boldText, size, buttonAlign, borderRadius } = currentStyles;

  const styles = buttonSize(size);

  return (
    <ActionsController
      className={`button-component__wrapper ${customClasses}`}
      style={{ textAlign: buttonAlign }}
      label={CRAFT_ELEMENTS_LABEL[node.data.displayName]}
    >
      <Button
        className="builder-element button-component"
        data-testid="craftjs-button-component"
        onClick={() => selected && setEditable(true)}
        {...(isLink && !enabled && { href })}
        style={{
          background:
            isPresetOn && currentStyles?.isDefaultGradient
              ? getElementColor('defaultGradient', theme)
              : getElementColor(backgroundColor, theme),
          fontWeight: boldText ? `bold` : `normal`,
          fontSize: `${styles.fontSize}`,
          borderRadius,
          fontStyle: italic ? "italic" : "",
          textDecoration: underline ? "underline" : "none",
        }}
      >
        <ContentEditable
          //@ts-ignore
          html={title}
          disabled={enabled ? !editable : true}
          placeholder="Dein Text..."
          onChange={e => {
            actions.history
              .throttle()
              .setProp(
                nodeId,
                (props: any) => (props.title = e.target.value.replace(/<\/?[^>]+(>|$)/g, ''))
              );
          }}
          tagName="span"
          style={{
            fontSize: `${styles.fontSize}`,
            borderRadius: borderRadius,
            textAlign: 'center',
            color: getElementColor(color, theme),
            fontWeight: boldText ? `bold` : `normal`,
            fontStyle: italic ? 'italic' : '',
            textDecoration: underline ? 'underline' : ''
          }}
        />
      </Button>
    </ActionsController>
  );
};

export const ButtonComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  color: FUNNEL_THEME_KEYS.ACCENT_COLOR_CONTRAST,
  boldText: false,
  underline: false,
  italic: false,
  href: '',
  size: 50,
  buttonAlign: 'center',
  title: 'Ich will mehr erfahren',
  isFirstRender: true,
  actionLogic: 'next-page',
  customClasses: '',
  borderRadius: defaultButtonRadius.pixelValue,
  isPresetOn: true,
  settings: {
    shouldShowActionSelector: true
  }
};

const ButtonSettingsComponent = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const presets = useElementsPresets(builderPresetElements.BUTTON);

  const handleChange = (key: string, value: any) => {
    setProp((props: any) => {
      switch (key) {
        case 'borderRadius':
          props.borderRadius = getValueByKey(value, ButtonAvailableBorderRadius).pixelValue;
          break;
        case 'BOLD':
          props.boldText = !props.boldText;
          break;
        case 'ITALIC':
          props.italic = !props.italic;
          break;
        case 'UNDERLINE':
          props.underline = !props.underline;
          break;
        default:
          props[key] = value;
      }
    });
  };

  const currentStyles = props.isPresetOn ? { ...props, ...presets } : props;

  const values = {
    ...currentStyles,
    borderRadius: getKeyByValue(
      currentStyles.borderRadius,
      ButtonAvailableBorderRadius,
      'pixelValue'
    ).key
  };

  return (
    <SettingsGB
      settingTitle="Button"
      onChange={handleChange}
      elements={ButtonSettingsItems}
      values={values}
    />
  );
};

ButtonComponent.craft = {
  name: CRAFT_ELEMENTS.BUTTON,
  props: ButtonComponentDefaultProps,
  related: {
    settings: ButtonSettingsComponent
  }
};
