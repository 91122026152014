export const maxRichTextLength = 10000;
export const inActiveTimeLimit = process.env.NODE_ENV === 'test' ? 100 : 300000;
export const pixelImageQuality = 60;
export const maxTaglength = 30;
export const freezePlanId = '784109';
export const pixelImagePerPage = 24;
export const additionalTokens = 100000;
export const aiTokenUsage = {
  text: 5,
  ad: 6750,
  image: 5000
};
export const defaultTokenDeductionPerImageGeneration = 100;
