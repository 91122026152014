import gql from 'graphql-tag';
import { AD_TYPES } from '../AdBuilder/Templates/container/TemplateTypesView';

export interface AiGeneratedAdInput {
  adInputId: number;
  type: AD_TYPES;
}

export const GET_AI_GENERATED_AD = gql`
  query GetAiGeneratedAd($input: AiGeneratedAdInput!) {
    getAiGeneratedAd(input: $input) {
      response {
        conversationId
        message
        totalTokens
        type
      }
      imgUrl
    }
  }
`;

export function getAiGeneratedAd(input: AiGeneratedAdInput) {
  return window.apolloClient.query({
    query: GET_AI_GENERATED_AD,
    variables: {
      input
    },
    fetchPolicy: 'no-cache'
  });
}
