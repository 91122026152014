import { useNode } from '@craftjs/core';
import React, { useState } from 'react';
import { TemplateContent, TemplateType, getTextTemplateById } from '../../../../AdBuilder/data';
import SelectTemplateCard from './SelectTemplateCard';
import SwitchTemplate from './SwitchTemplate';
import { useJobInformation, useTargetAudience } from '../../../../redux/builderSlice';
import { replaceJobPlaceHoldersWithValues } from '../../../../AdJobBuilder/helper/AdJobHelper';

export interface TextSuggestionTypes {
  userTemplate: TemplateContent[];
  content: TemplateType;
  handleDelete?: any;
}

const TextSuggestionSidebar = ({ userTemplate, content, handleDelete }: TextSuggestionTypes) => {
  const jobData = useJobInformation();
  const targetGroupData = useTargetAudience();
  const jobRelatedData = { ...jobData, ...targetGroupData };
  const {
    actions: { setProp },
    props,
    displayName,
  } = useNode(node => ({
    props: node.data.props,
    displayName: node.data.displayName,
  }));
  const { templateId } = props;
  const [makeVisible, setMakeVisible] = useState(false);
  const [template, setTemplate] = useState<any>();
  const handleSelectTemplate = (temp: { id: number; text: string; name?: string }) => {
    let template;

    template = userTemplate.find(_ => _.id === props.templateId);
    if (!template) {
      template = getTextTemplateById(props.templateId, content);
    }
    const replacedPlaceHoldersText = replaceJobPlaceHoldersWithValues(temp, jobRelatedData);
    if (template && props.isEdited) {
      setMakeVisible(true);
      setTemplate(replacedPlaceHoldersText);
    } else {
      setProp((props: any) => {
        props.html = `<p>${replacedPlaceHoldersText.text.split(/\r?\n/)
          .filter((text: string) => text.trim())
          .map((text: string, i: number, arr: string[]) =>(!arr[i+1] ? text : `${text}</br>`))
          .join('')}</p>`;
        props.templateId = temp.id;
      })
    }
  };

  type ContentItem = {
    id: number;
    text: string;
    title?: string;
  };

  const contentTemplates = content.data.flatMap((category) =>
    category.content.map((item: ContentItem) => ({
      id: item.id,
      text: item.text,
      title: item.title || item.text.substring(0, 20)
    }))
  );

  const allTemplates = [...userTemplate, ...contentTemplates];

  return (
    <div className="ad-suggestion__container builder__settings-sidebar__container">
      <SwitchTemplate
        template={template}
        direction="NEXT"
        makeModalOpen={makeVisible}
        userTemplate={userTemplate}
        content={content}
        onChange={() => {
          setMakeVisible(false);
        }}
      />
      <div className="builder__settings-sidebar__row">
        {allTemplates.map((template, i) => {
          return (
            <SelectTemplateCard
              key={template.id}
              handelSelect={() => handleSelectTemplate(template)}
              template={template}
              currentTemplateId={templateId}
              deleteLoading={false}
              handleDelete={() => handleDelete(template.id)}
            />
          );
        })}
        {userTemplate.length === 0 && (
          <p className="p-3">
            Es sind keine eigenen Templates vorhanden. Klicke auf die Diskette über einem Text um
            ihn zu Speichern.
          </p>
        )}
      </div>
    </div>
  );
};

export default TextSuggestionSidebar;
