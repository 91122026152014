import React from 'react';
import AuthRoutesHOC from '../../container/AuthRoutesHOC';
import RegisterForm from './RegisterForm';
import { Form } from 'antd';
import { useStoredQueryParams } from '../../../hooks/useStoredQueryParams';

const RegisterPage = () => {
  const [form] = Form.useForm();
  useStoredQueryParams();

  return (
    <AuthRoutesHOC form={form} loginMode="register" onLogin={() => {}}>
      <RegisterForm />
    </AuthRoutesHOC>
  );
};

export default RegisterPage;
