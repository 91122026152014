import React, { useEffect, useRef, useState } from 'react';
import { Layout, Tag } from 'antd';
import { MobileOutlined, DesktopOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setMobileView,
  useCompleteBuilderState,
  useBuilderGeneralSettings,
  useBuilderSelectedPageData,
  editFunnelSavedIndicator,
  useShouldSaveFunnel,
  useSelectedBlockNodeIds,
  resetSelectedBlockNodeIds,
  useMainFunnelLoader,
  resetToInitialBuilderState,
  editGeneralSettings,
  addBuilderValidationError,
  removeBuilderValidationError,
  useFunnel
} from '../redux/builderSlice';
import { useNavigate, useLocation } from 'react-router';
import {
  builderConfig,
  checkIfBlank,
  builderInvalidationClass,
  isCurrentlyOnEmailTemplateRoute
} from '../config/builderUi';
import Logo from '../../UI/components/Logo';
import { Editor, useEditor } from '@craftjs/core';
import { BuilderPreview } from './BuilderPreview';
import { EditorProvider } from '../context/EditorContext';
// Craftjs user component
import DividerComponent from '../components/elements/DividerComponent/container/DividerComponent';
import { ButtonComponent } from '../components/elements/BuilderButtonComponent/ButtonComponent';

import FooterComponent from '../components/elements/FooterComponent';
import LogoComponent from '../components/elements/LogoComponent';
import RichTextComponent from '../components/elements/RichTextComponent/container/RichTextComponent';
import ImageComponent from '../components/elements/ImageComponent';
import IconComponent from '../components/elements/IconComponent';
import ContainerComponent from '../components/elements/Container/container/ContainerComponent';
import VideoComponent from '../components/elements/VideoComponent';
import MainContainer from '../components/elements/MainContainer';
import BodyComponent from '../components/elements/BodyComponent';
import VoiceMessageComponent from '../components/elements/VoiceMessageComponent';
import CalenderComponent from '../components/elements/Calendar/CalenderComponent';
import ColumnGrid from '../components/elements/grid/ColumnGrid';
import Column from '../components/elements/grid/Column';
import { AddContainerComponent } from '../components/elements/AddContainerComponent';
import ContactForm from '../components/elements/ContactForm/container/ContactForm';
import ElementAppender from '../components/elements/common/ElementAppender/container/ElementAppender';
import Choice from '../components/elements/SingleChoiceComponent/components/Choice';
import SingleChoice from '../components/elements/SingleChoiceComponent/container/SingleChoiceComponent';
import MultipleChoice from '../components/elements/MultipleChoiceComponent/container/MultipleChoiceComponent';
import LongAnswerComponent from '../components/elements/LongAnswerComponent';
import RangeSliderComponent from '../components/elements/RangeSliderComponent';
import DefaultLoader from '../../SharedUI/components/DefaultLoader';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { useCraftStringifiedState } from '../helper/craftJs';
import SaveIndicator from '../components/ui/SaveIndicator';
import { getBuilderConfigForRoute } from '../helper/sharedFunctions';
import MenuComponentContainer from '../layout/MenuComponentContainer';
import IconSidebar from '../layout/IconSidebar';
import PublishModal from '../components/ui/PublishModal';
import { debounce } from 'debounce';
import { Delete } from 'craftjs-utils-meetovo';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import { RenderNode } from '../renderNode/RenderNode';
import ContentEditable from 'react-contenteditable';
import ContainerComponentHOC from '../components/elements/common/ContainerHOC';
import UndoRedoButtons from '../components/elements/UndoRedoButtons';
import UploadFileComponent from '../components/elements/UploadFileComponent';
import HTMLWidget from '../components/elements/Widgets/HTMLWidget';
import BuilderCarousel from '../components/elements/Carousel/Container/BuilderCarousel';
import CarouselContainer from '../components/elements/Carousel/Container/CarouselContainer';
import CarouselSlide from '../components/elements/Carousel/Container/CarouselSlide';
import { AiFillSave } from 'react-icons/ai';
import CreateMultiBlockTemplate from '../components/TemplatesPanel/CreateMultiBlockTemplate';
import DebugTracking from '../DebugTracking/container/DebugTracker';
import SaveBuilderStateOnRouteChange from '../components/sharedUI/SaveBuilderStateOnRouteChange';
import { saveCompleteBuilderDataThunk } from '../redux/thunk';
import store from '../../redux/store';
import { resetToInitialAdBuilderState } from '../AdBuilder/redux/adBuilderSlice';
import { BuilderPreviewContainerBase } from '../components/ui/BuilderPreviewContainerBase';
import { useIsMobileView } from '../hooks/useIsMobileView';
import useFunnelPublishUrl from '../hooks/useFunnelPublishUrl';
import { returnUrlToMyFunnels } from '../../Funnel/redux/funnelSlice';
import DontUseBrowserButtonsWarning from '../components/ui/DontUseBrowserButtonsWarning';
import createScreenshotOfFirstFunnelPageAPI from '../graphql/createScreenshotOfFirstFunnelPage';
import { BUILDER_VALIDATION_ERRORS } from './BuilderValidator';
import { fetchFunnelTimeInLocalStorageKey } from '../../SharedUI/components/FetchFunnelsBeforeComponentLoadHOC';
import CraftStateRenderer from '../components/elements/CraftStateRenderer';
import LoadingComponent from '../components/elements/SingleChoiceComponent/LoadingComponent/LoadingComponent';
import FAQTile from '../components/elements/FrequentlyAskedQuestionsComponent/components/FAQTile';
import FAQContainerHOC from '../components/elements/FrequentlyAskedQuestionsComponent/container/FAQContainerHOC';
import MultiReactElementAppender from '../components/elements/MultiReactElementAppender';

export const CraftJsUserComponents = {
  [CRAFT_ELEMENTS.BODY]: BodyComponent,
  [CRAFT_ELEMENTS.BUTTON]: ButtonComponent,
  [CRAFT_ELEMENTS.IMAGE]: ImageComponent,
  [CRAFT_ELEMENTS.CONTAINER]: ContainerComponent,
  [CRAFT_ELEMENTS.ICON]: IconComponent,
  [CRAFT_ELEMENTS.MAIN_CONTAINER]: MainContainer,
  [CRAFT_ELEMENTS.COLUMN_GRID]: ColumnGrid,
  [CRAFT_ELEMENTS.COLUMN]: Column,
  [CRAFT_ELEMENTS.VIDEO]: VideoComponent,
  [CRAFT_ELEMENTS.RICH_TEXT]: RichTextComponent,
  [CRAFT_ELEMENTS.ADD_CONTAINER]: AddContainerComponent,
  [CRAFT_ELEMENTS.DIVIDER]: DividerComponent,
  [CRAFT_ELEMENTS.LOGO]: LogoComponent,
  [CRAFT_ELEMENTS.FOOTER]: FooterComponent,
  [CRAFT_ELEMENTS.CONTACT_FORM]: ContactForm,
  [CRAFT_ELEMENTS.ELEMENT_APPENDER]: ElementAppender,
  [CRAFT_ELEMENTS.CHOICE]: Choice,
  [CRAFT_ELEMENTS.SINGLE_CHOICE]: SingleChoice,
  [CRAFT_ELEMENTS.MULTIPLE_CHOICE]: MultipleChoice,
  [CRAFT_ELEMENTS.VOICE_MESSAGE]: VoiceMessageComponent,
  [CRAFT_ELEMENTS.LONG_ANSWER]: LongAnswerComponent,
  [CRAFT_ELEMENTS.RANGE_SLIDER]: RangeSliderComponent,
  [CRAFT_ELEMENTS.CALENDAR]: CalenderComponent,
  [CRAFT_ELEMENTS.CONTAINER_COMPONENT_HOC]: ContainerComponentHOC,
  [CRAFT_ELEMENTS.UPLOAD_FILE]: UploadFileComponent,
  [CRAFT_ELEMENTS.HTML_WIDGET]: HTMLWidget,
  [CRAFT_ELEMENTS.CAROUSEL_CONTAINER]: CarouselContainer,
  [CRAFT_ELEMENTS.CAROUSEL]: BuilderCarousel,
  [CRAFT_ELEMENTS.CAROUSEL_SLIDE]: CarouselSlide,
  [CRAFT_ELEMENTS.CRAFT_STATE_RENDERER]: CraftStateRenderer,
  [CRAFT_ELEMENTS.LOADING_COMPONENT]: LoadingComponent,
  [CRAFT_ELEMENTS.FAQ]: MultiReactElementAppender,
  [CRAFT_ELEMENTS.FAQ_TILE]: FAQTile,
  [CRAFT_ELEMENTS.FAQContainerHOC]: FAQContainerHOC,
  [CRAFT_ELEMENTS.MULTI_REACT_ELEMENT_APPENDER]: MultiReactElementAppender
};

const { Header, Sider, Content } = Layout;

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const builderSelectedPageData = useBuilderSelectedPageData();
  const loading = useMainFunnelLoader();
  const blank = checkIfBlank(builderConfig, location.pathname);
  const info = getBuilderConfigForRoute();

  useEffect(() => {
    if (location.pathname === '/builder') {
      navigate(`/builder/${info.item.route}`);
    }
  }, []);

  const handleOnNodesChange = debounce(
    (query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>) => {
      const { builderUI } = store.getState();
      const craftState = useCraftStringifiedState(query);
      window.__CRAFT_STATE__ = craftState;
      window.craftJsPageBuilderQuery = query;

      if (builderUI.loaders.mainFunnelLoader || !builderUI.lastSaveSuccessful) return;

      if (JSON.stringify(builderSelectedPageData?.craftState) !== JSON.stringify(craftState))
        dispatch(editFunnelSavedIndicator(false));
      else dispatch(editFunnelSavedIndicator(true));
    },
    1500
  );

  return (
    <EditorProvider>
        <DefaultLoader loading={loading} />
        <DontUseBrowserButtonsWarning />
        <Layout className="meetovo-funnel-builder-layout">
          <Editor
            enabled={localStorage.getItem('disabledCraftEditor') !== 'true'}
            resolver={CraftJsUserComponents}
            onNodesChange={handleOnNodesChange}
            onRender={RenderNode}
            indicator={{
              success: 'rgb(98, 196, 98)',
              error: 'transparent',
              transition: "transparent"
            }}
          >
            <SaveBuilderStateOnRouteChange />
            <>
              <DebugTracking />
              <Header className="builder__header">
                <HeaderContent />
              </Header>
              <Sider theme="light" className="builder__icon-sidebar">
                <IconSidebar />
              </Sider>
              {builderSelectedPageData && (
                <>
                  {!blank && (
                    // This class has been used in useEffect of Settings Panel. Avoid updating it.
                    <Sider theme="light" className={`builder__settings-sidebar`}>
                      <MenuComponentContainer builderConfig={builderConfig} />
                    </Sider>
                  )}
                  {
                    // This class has been used in useEffect of BuilderPreviewContainer. Avoid updating it.
                  }
                  {!blank ? (
                    <Content className="builder__content">
                      <BuilderPreviewContainer />
                    </Content>
                  ) : (
                    blank.component
                  )}
                </>
                )}
            </>
          </Editor>
        </Layout>
    </EditorProvider>
  );
};

const HeaderContent = () => {
  const isMobileView = useIsMobileView();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const generalSettings = useBuilderGeneralSettings();
  const selectedBlockNodeIds = useSelectedBlockNodeIds();
  const [openCreateMultiBlockTemplate, setOpenCreateMultiBlockTemplate] = useState(false);
  const shouldSaveFunnel = useShouldSaveFunnel();
  const returnUrlToMyFunnelsLink = returnUrlToMyFunnels();
  const funnelPublishUrl = useFunnelPublishUrl();

  const handleSaveBuilder = (doNotNavigate?: boolean) => {
    localStorage.removeItem(fetchFunnelTimeInLocalStorageKey);

    if (!shouldSaveFunnel) {
      if (doNotNavigate) {
        return;
      }
      navigate(returnUrlToMyFunnelsLink);
      dispatch(resetToInitialBuilderState());
      dispatch(resetToInitialAdBuilderState());
      return;
    }

    createScreenshotOfFirstFunnelPageAPI();

    return dispatch(
      saveCompleteBuilderDataThunk({
        ...(location.pathname.includes('/builder/emails') && {
          shouldOnlySaveEmailTemplates: true
        }),
        callbackOnSucces: () => {
          if (doNotNavigate) {
            return;
          }
          navigate(returnUrlToMyFunnelsLink);
          dispatch(resetToInitialBuilderState());
          dispatch(resetToInitialAdBuilderState());
        }
      })
    );
  };

  const toggleVisibilityOfCreateMultiBlockTemplate = () => {
    setOpenCreateMultiBlockTemplate(!openCreateMultiBlockTemplate);
  };

  const handleOpen = async () => handleSaveBuilder(true);

  // ContentEditable with State doesn't work onBlur
  // issue : https://github.com/lovasoa/react-contenteditable/issues/161
  const ref = useRef(generalSettings.title);

  const saveTitle = () => {
    if (ref.current && (ref.current as string)?.length > 0) {
      dispatch(saveCompleteBuilderDataThunk());
    }
  };

  const handleTitleChange = (e: any) => {
    let title = e.target.value;
    title = title.replace(/<[^>]*>/g, '');
    title = title.replace(/(\s*&nbsp;\s*)+/g, ' ');
    title.trim()
      ? dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.TITLE))
      : dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.TITLE));
    dispatch(
      editGeneralSettings({
        title
      })
    );
    ref.current = title;
  };

  const { selectedNode } = useEditor((state, query) => {
    const selectedNodeId = state.events.selected.values().next().value;
    const selectedNode = selectedNodeId && query.node(selectedNodeId).get();
    return {
      selectedNode
    };
  });

  const handleScrollIntoView = () => {
    selectedNode?.dom?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="builder__header__logo-wrapper">
        <span
          className={`builder__header__logo-wrapper__logo ${builderInvalidationClass}`}
          onClick={() => handleSaveBuilder()}
        >
          <Logo iconOnly redirectionNeeded={false} />
        </span>
        <div
          className={`builder__header__logo-wrapper__container ${
            generalSettings.title ? '' : 'title-invalid'
          }`}
        >
          <ContentEditable
            html={`${generalSettings.title}`}
            onBlur={saveTitle}
            onChange={handleTitleChange}
            onKeyDown={handleKeyDown}
            spellCheck={false}
            className={`funnel__title disable-builder`}
            tagName="p"
          />
          <div className="edit-icon">
            <EditOutlined />
          </div>
          {!generalSettings.title && (
            <span className={`funnel__title__msg`}>Gib deinem Funnel einen Namen.</span>
          )}
        </div>
        <div>
          <SaveIndicator />
        </div>
      </div>

      {!isCurrentlyOnEmailTemplateRoute() && (
        <div className="view-changer">
          <DesktopOutlined
            onClick={() => {
              dispatch(
                setMobileView({
                  payload: false
                })
              );
              handleScrollIntoView();
            }}
            className={`preview-size-icon ${!isMobileView ? 'active' : ''}`}
          />
          <div style={{ width: '20px' }}></div>
          <MobileOutlined
            onClick={() => {
              dispatch(
                setMobileView({
                  payload: true
                })
              );
              handleScrollIntoView();
            }}
            className={`preview-size-icon ${isMobileView ? 'active' : ''}`}
          />
        </div>
      )}
      <div className="d-flex align-items-center me-2">
        {!!selectedBlockNodeIds.length && (
          <div>
            <Tag
              className="d-flex align-items-center me-2 py-2"
              closable
              onClose={() => {
                dispatch(resetSelectedBlockNodeIds());
              }}
            >
              <AiFillSave
                className="cursor-pointer me-2"
                size={20}
                onClick={toggleVisibilityOfCreateMultiBlockTemplate}
              />
            </Tag>
          </div>
        )}
        <UndoRedoButtons />
        <div className={`builder__header__right-wrapper ${builderInvalidationClass}`}>
          <PublishModal shouldSaveOnClick handleOpen={handleOpen} url={funnelPublishUrl} />
          <CloseOutlined onClick={() => handleSaveBuilder()} />
        </div>
        <CreateMultiBlockTemplate
          visible={openCreateMultiBlockTemplate}
          toggleVisible={toggleVisibilityOfCreateMultiBlockTemplate}
        />
      </div>
    </>
  );
};

const BuilderPreviewContainer = () => {
  const builderState = useCompleteBuilderState();
  const { pathname } = useLocation();
  const scrollToTop = () => {
    if (pathname.includes('emails'))
      document.getElementsByClassName('builder__content')?.[0]?.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
    return () => scrollToTop();
  }, [pathname]);

  return (
    <BuilderPreviewContainerBase builderState={builderState}>
      <BuilderPreview />
    </BuilderPreviewContainerBase>
  );
};
