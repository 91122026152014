import React from 'react';
import { Modal, Card, Button, Typography, Progress, Row, Col, List, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { aiTokenRebuyCheck, aiTokenRebuyUnCheck } from '../../../Login/utils/ImageConstants';
import { useMutation } from '@apollo/react-hooks';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';
import gql from 'graphql-tag';
import { GeneralMessages } from '../../../config/messages';
import { useAppDispatch } from '../../../redux/hooks';
import { updateUserSubscriptionInfo } from '../../../UI/redux/userSlice';
import { additionalTokens } from '../../../constants';
import { getTokenInformation } from '../../helper/aiTokensHelper';

const { Title, Text, Paragraph } = Typography;

const rebuyTokens = gql`
  mutation RebuyAiTokens {
    rebuyAiTokens
  }
`;

interface TokenModalProps {
  visible: boolean;
  onClose: () => void;
  allowClose?: boolean;
}

interface TokenCardProps {
  title: string;
  description: string;
  price: string;
  priceText: string;
  points: string[];
  tokenInformation: any;
  image: string;
  buttonText: string;
  onClick: () => void;
  isPrimary?: boolean;
  loading?: boolean;
}

const TokenCard: React.FC<TokenCardProps> = ({
  title,
  description,
  price,
  priceText,
  points,
  tokenInformation,
  image,
  buttonText,
  onClick,
  isPrimary = false,
  loading = false
}) => (
  <Col span={12}>
    <Card
      className={isPrimary ? 'purchase-card' : ''}
      extra={<img src={image} alt={title} width={50} />}
    >
      <Title level={4}>{title}</Title>

      <div className="mb-4">
        <Text className="mt-2" style={{ color: 'black' }} strong>
          {price}
        </Text>
        <Text strong>{priceText}</Text>
        <Paragraph className="mt-2">{description}</Paragraph>
      </div>

      <List
        dataSource={points}
        bordered={false}
        renderItem={item => (
          <List.Item>
            {isPrimary ? (
              <CheckOutlined style={{ color: 'green', marginRight: 8 }} />
            ) : (
              <CloseOutlined style={{ color: 'red', marginRight: 8 }} />
            )}
            {item}
          </List.Item>
        )}
      />

      <div className="mt-4">
        <Text strong style={{ color: 'black' }}>
          Kontingent
        </Text>
        <Progress
          percent={tokenInformation.progress}
          status={isPrimary ? 'active' : 'exception'}
          strokeColor={isPrimary ? '#50D4D4' : '#E82D56'}
        />
        <Text>
          {tokenInformation.currentTokens} / {tokenInformation.maxTokens}
        </Text>
      </div>

      <div>
        <Button
          type={isPrimary ? 'primary' : 'default'}
          className="mt-4"
          style={{ width: '100%' }}
          onClick={onClick}
          loading={loading}
        >
          {buttonText}
        </Button>
      </div>
    </Card>
  </Col>
);

const TokenRebuyModal: React.FC<TokenModalProps> = ({ visible, onClose, allowClose = true }) => {
  const dispatch = useAppDispatch();
  const subscriptionInfo = useSubscriptionInfo();
  const [rebuyAiTokens, { loading: rebuyLoading }] = useMutation(rebuyTokens);

  const handleRebuyTokens = async () => {
    try {
      const response = await rebuyAiTokens();
      if (response.data.rebuyAiTokens) {
        dispatch(
          updateUserSubscriptionInfo({
            remainingAdditionalChatAiTokens:
              subscriptionInfo.remainingAdditionalChatAiTokens + additionalTokens
          })
        );
        onClose();
      } else {
        message.error(GeneralMessages.error);
      }
    } catch (err) {
      message.error(GeneralMessages.error);
    }
  };

  const cards = [
    {
      title: 'Ohne KI Kontingent',
      price: '',
      priceText: 'Standard-Kontingent monatlich erneuert',
      description: 'Ohne Nachbuchung stehen dir folgende Funktionen nicht zur Verfügung:',
      points: [
        'Automatisch Bilder generieren',
        'Texte und Fragen generieren',
        'Vorhandene Texte nachbessern',
        'Zielgruppengerechte Inhalte produzieren',
        'Effiziente und zeitsparende Umsetzung'
      ],
      tokenInformation: getTokenInformation(0, subscriptionInfo),
      image: aiTokenRebuyUnCheck,
      buttonText: 'Ohne KI nutzen',
      onClick: onClose,
      isPrimary: false
    },
    {
      title: 'KI-Kontingent',
      price: '12€',
      priceText: ' – 1 Mio. KI-Tokens',
      description: 'Mit einer Nachbuchung erhellt du Zugriff auf folgende Funktionen:',
      points: [
        'Zielgruppengerechte Bilder',
        'Blitzschnell erstellte Texte',
        'Optimierung und Verfeinerung deiner Texte',
        'Inhalte exakt auf deine Bedürfnisse angepasst',
        'Zeitersparnis durch intelligente Automatisierung'
      ],
      tokenInformation: getTokenInformation(additionalTokens, subscriptionInfo),
      image: aiTokenRebuyCheck,
      buttonText: 'KI-Tokens nachladen',
      onClick: handleRebuyTokens,
      isPrimary: true,
      loading: rebuyLoading
    }
  ];

  return (
    <Modal
      visible={visible}
      onCancel={allowClose ? onClose : undefined}
      footer={null}
      centered
      width={800}
      closable
      className="ai-token-rebuy-modal"
    >
      <div className="pb-3">
        <div className="modal-title">
          <Title level={4}>Deine KI-Tokens sind aufgebraucht</Title>
          <Text type="secondary">
            Mit 5 Tokens kann ein Wort generiert werden, mit 5.000 Tokens ein Bild. Buche jetzt
            einmalig nach, um weiteres Kontingent freizuschalten. Nicht genutztes Kontingent
            verfällt nicht.
          </Text>
        </div>

        <Row gutter={16} className="mt-6">
          {cards.map((card, index) => (
            <TokenCard key={index} {...card} />
          ))}
        </Row>
      </div>
    </Modal>
  );
};

export default TokenRebuyModal;
