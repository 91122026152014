import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BookingFilterInterface } from '../container/Events';
import { message } from 'antd';
import { EventsMessages } from '../../config/messages';
import { getApplicationFiltersFromQueryParams } from '../helper/getApplicationFiltersFromQueryParams';
import getDownloadFunnelData from '../helper/getDownloadFunnelData';
import { csvMaker } from '../helper/csvMaker';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';
import download from 'downloadjs';
import moment from 'moment';

const useQueryNavigation = () => {
  const navigate = useNavigate();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const bookingFilters: BookingFilterInterface = getApplicationFiltersFromQueryParams();
  const funnels = useAllFunnels();

  const filter = {
    start: bookingFilters.start,
    end: bookingFilters.end,
    funnelIds: bookingFilters?.funnelIds,
    type: bookingFilters.type
  };

  const { type: dummyType, ...filterForTracking } = filter;

  const variables = {
    filter1: {
      ...filter,
      searchQuery: bookingFilters.searchQuery,
      statusId: bookingFilters.statusId,
      tagIds: bookingFilters?.tagIds,
      includeChoices: true
    },
    filter2: {
      ...filterForTracking,
      minProgressCollectorCount: 3,
      funnelIds: [0]
    },
    filter3: {
      showAll: !bookingFilters?.funnelIds?.length,
      funnelIds: bookingFilters.funnelIds
    }
  };

  const handleDownload = async () => {
    if (!bookingFilters.funnelIds?.length)
      return message.info(EventsMessages.pleaseChooseAFunnelBeforeExport);

    setDownloadLoading(true);
    const { data } = await getDownloadFunnelData({
      filter: variables.filter1
    });
    setDownloadLoading(false);
    const { getBookings, getLeadQualifier } = data;
    if (getBookings.length === 0) return message.info(EventsMessages.noDataToExport);

    const csvData = csvMaker({
      bookings: getBookings,
      leadQualifiers: getLeadQualifier,
      funnels
    });

    const file = new Blob([csvData], { type: 'text/csv' });

    download(file, `Export_${moment().format('DD.MM.YYYY_HH.mm')}.csv`);
  };

  const updateUrlQueryParams = (params: { [key: string]: string | number | null }) => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);

    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value !== null && value !== undefined && value !== '') {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    return searchParams.toString();
  };

  const navigateWithQueryParams = (params: string) => {
    navigate(`?${params}`);
  };

  const handleFunnelSelectionChange = (value: number[]) => {
    const funnelIdsParam = updateUrlQueryParams({ funnelIds: value.join(',') });
    navigateWithQueryParams(funnelIdsParam);
  };

  const handleTagsSelectionChange = (value: number[]) => {
    const tagIdsParam = updateUrlQueryParams({ tagIds: value.join(',') });
    navigateWithQueryParams(tagIdsParam);
  };

  const handleOnSearchQueryChange = (value: string) => {
    const searchQueryParam = updateUrlQueryParams({ searchQuery: value });
    navigateWithQueryParams(searchQueryParam);
  };

  const handleOnDateRangeChange = (dates: any) => {
    const updatedQueryString = updateUrlQueryParams({
      start: dates[0].toISOString(),
      end: dates[1].toISOString()
    });
    navigateWithQueryParams(updatedQueryString);
  };

  const handleOnTypeChange = (value: string) => {
    const typeParams = updateUrlQueryParams({ type: value });
    navigateWithQueryParams(typeParams);
  };

  const handleStatusChanged = (value: number[]) => {
    const statusParams = updateUrlQueryParams({ statusId: value.join(',') });
    navigateWithQueryParams(statusParams);
  };

  return {
    handleFunnelSelectionChange,
    handleTagsSelectionChange,
    handleOnSearchQueryChange,
    handleOnDateRangeChange,
    handleOnTypeChange,
    handleStatusChanged,
    bookingFilters,
    funnels,
    handleDownload,
    downloadLoading,
    variables
  };
};

export default useQueryNavigation;
