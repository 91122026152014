import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CREATE_BOOKING_ACTION_EVENTS = gql`
  mutation CreateBookingActionEvent($input: CreateBookingActionEventInput!) {
    createBookingActionEvent(input: $input) {
      id
      eventTime
      actionEventType
    }
  }
`;

export const GET_BOOKINGS_ACTIONS_EVENTS = gql`
  query getBookingActionEvents($bookingId: Int!) {
    getBookingActionEvents(bookingId: $bookingId) {
      id
      eventTime
      actionEventType
    }
  }
`;

export const CHANGE_BULK_BOOKING_STATUS = gql`
  mutation($input: ChangeBulkBookingStatus!) {
    changeBulkBookingStatus(input: $input)
  }
`;

export const CHANGE_BOOKING_STATUS_AND_ORDER = gql`
  mutation($status: ChangeBookingStatus!, $newSortOrder: [ChangeBookingOrder!]!) {
    changeBookingOrder(input: $newSortOrder)

    changeBookingStatus(input: $status) {
      name
      bookingStatus {
        status
        id
      }
    }
  }
`;
export const CREATE_BOOKING_STATUS = gql`
  mutation createBookingStatus($input: CreateBookingStatusInput!) {
    createBookingStatus(input: $input) {
      id
      status
      value
      color
      sortOrder
      updatedAt
    }
  }
`;

export const UPDATE_BOOKING_STATUS_ORDER = gql`
  mutation updateBookingStatusOrder($input: [UpdateBookingSortOrderInput!]!) {
    updateBookingStatusOrder(input: $input)
  }
`;

export const UPDATE_BOOKING_STATUS = gql`
  mutation updateBookingStatus($input: UpdateBookingStatusInput) {
    updateBookingStatus(input: $input) {
      id
      status
      value
      color
      sortOrder
      updatedAt
    }
  }
`;

export const DELETE_BOOKING_STATUS = gql`
  mutation removeBookingStatus($input: Int!) {
    removeBookingStatus(input: $input)
  }
`;

export const GET_BOOKING_INFO = gql`
  query getBookingInfo(
    $filter1: BookingFilterV2!
    $filter2: TrackingFilter!
    $filter3: LeadQualifierFilter!
  ) {
    getBookings(input: $filter1) {
      id
      name
      email
      createdAt
      funnelId
      sortOrder
      type
      version
      bookingStatus {
        id
        color
        status
        value
      }
      tags {
        id
        name
        color
        coachId
        agencyCoachId
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      bookingRatings {
        id
        coachId
        competancy
        communicationSkills
        teamCompatibility
        partialUser {
          firstName
          lastName
          imageURL
        }
      }
    }

    getTrackings(filter: $filter2) {
      id
      completed
      createdAt
      source
      totalDurationInSeconds
      funnelId
      progressCollectors {
        key
        firstActivity
        leadQualifierId
        durationInSeconds
        choices {
          choiceId
          stringValue
          numberValue
        }
      }
    }

    getLeadQualifier(input: $filter3) {
      id
      question
      type
      craftJsNodeId
      choices {
        id
        title
        unit
        craftJsNodeId
      }
    }

    getAllBookingStatus {
      id
      status
      value
      color
      sortOrder
    }

    getAllBookingTags {
      color
      id
      name
      coachId
      agencyCoachId
    }
  }
`;

export const GET_ALL_BOOKING_TAGS = gql`
  query GetAllBookingTags {
    getAllBookingTags {
      color
      id
      name
      coachId
      agencyCoachId
    }
  }
`;

export const UPDATE_BOOKINGS_TAGS = gql`
  mutation UpdateBookingTags($input: UpdateBookingTagsInput) {
    updateBookingTags(input: $input) {
      color
      id
      name
    }
  }
`;

export const DELETE_BOOKING_TAGS = gql`
  mutation DeleteBookingTags($id: Int!) {
    deleteBookingTags(id: $id)
  }
`;

export const CREATE_BOOKING_TAGS = gql`
  mutation($input: CreateBookingTagsInput!) {
    createBookingTags(input: $input) {
      color
      id
      name
      coachId
      agencyCoachId
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($bookingId: Int!) {
    deleteBooking(id: $bookingId)
  }
`;

export const CREATE_OR_UPDATE_BOOKING_RATING = gql`
  mutation createOrUpdateBookingRating($input: CreateBookingRatingInput!) {
    createOrUpdateBookingRating(input: $input) {
      id
      coachId
      competancy
      communicationSkills
      teamCompatibility
      partialUser {
        firstName
        lastName
        imageURL
      }
    }
  }
`;

export const DELETE_BOOKING_RATING = gql`
  mutation removeBookingRating($id: Int!) {
    removeBookingRating(id: $id)
  }
`;

export const CHANGE_BOOKING_STATUS = gql`
  mutation($input: ChangeBookingStatus!) {
    changeBookingStatus(input: $input) {
      name
      bookingStatus {
        status
        id
      }
    }
  }
`;

export const UPDATE_BOOKING_STATUS_AND_ADD_NOTE = gql`
  mutation($status: ChangeBookingStatus!, $note: BookingNoteData!) {
    changeBookingStatus(input: $status) {
      name
      bookingStatus {
        status
        id
      }
    }

    createBookingNote(input: $note) {
      id
      coachId
      note
      createdAt
    }
  }
`;

export const UPDATE_APPLICANT_DATA = gql`
  mutation($input: UpdateApplicantData!) {
    updateApplicantData(input: $input) {
      name
      email
      phone
    }
  }
`;

export const GET_LEAD_QUALIFIER = gql`
  query GetLeadQualifier($input: LeadQualifierFilter) {
    getLeadQualifier(input: $input) {
      id
      funnelId
      question
      messageTitle
      messageText
      messageSkippable
      messageCtaLink
      messageCtaTitle
      pixelName
      gTag
      weight
      type
      voiceMessageAWSKey
      voiceMessageSignedUrl
      craftJsNodeId
      pageId
      choices {
        id
        funnelId
        title
        weight
        icon
        imageURL
        placeholder
        length
        min
        max
        unit
        initialStringValue
        initialNumberValue
        craftJsNodeId
        pageId
      }
      logic {
        id
        fallback
        leftSideValue
        operator
        rightSideValue
        thenLeadQualifierId
        thenStepKey
      }
    }
  }
`;

export const GET_BOOKINGS_PAGINATED = gql`
  query getBookingsPaginated($filter1: BookingFilterV2!) {
    getBookings(input: $filter1) {
      id
      name
      email
      createdAt
      funnelId
      sortOrder
      type
      version
      bookingStatus {
        id
        color
        status
        value
      }
      tags {
        id
        name
        color
        coachId
        agencyCoachId
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      bookingRatings {
        id
        coachId
        competancy
        communicationSkills
        teamCompatibility
        partialUser {
          firstName
          lastName
          imageURL
        }
      }
    }
  }
`;

export function setCreateBookingActionEvents() {
  return useMutation(CREATE_BOOKING_ACTION_EVENTS);
}

export function useChangeBookingStatusAndOrder() {
  return useMutation(CHANGE_BOOKING_STATUS_AND_ORDER);
}

export function useUpdateBookingStatusAndAddNote() {
  return useMutation(UPDATE_BOOKING_STATUS_AND_ADD_NOTE);
}

export function useCreateBookingStatus() {
  return useMutation(CREATE_BOOKING_STATUS);
}

export function useUpdateBookingStatusOrder() {
  return useMutation(UPDATE_BOOKING_STATUS_ORDER);
}

export function useUpdateBookingStatus() {
  return useMutation(UPDATE_BOOKING_STATUS);
}

export function useDeleteBookingStatus() {
  return useMutation(DELETE_BOOKING_STATUS);
}

export function useGetBookingInfo(options?: any) {
  return useQuery(GET_BOOKING_INFO, options);
}

export function useDeleteBookingTag() {
  return useMutation(DELETE_BOOKING_TAGS);
}

export function useUpdateBookingTags() {
  return useMutation(UPDATE_BOOKINGS_TAGS);
}

export function useCreateBookingTags() {
  return useMutation(CREATE_BOOKING_TAGS);
}

export function useGetAllBookingTags() {
  return useQuery(GET_ALL_BOOKING_TAGS);
}
