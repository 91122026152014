import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import OptionsPicker from '../../../components/sharedUI/OptionsPicker';
import { FaGraduationCap } from 'react-icons/fa';
import { FaBalanceScale } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaLightbulb } from 'react-icons/fa';
import { FaShieldAlt } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaUniversalAccess } from 'react-icons/fa';
import { statementTypePlaceHolders } from '../../data/adTypesData';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import { AD_TYPES } from './TemplateTypesView';
import { customStringFieldValidation } from '../../../../UI/utils/formValidation';

const statementTypeOptions: any[] = [
  {
    label: 'Karriereentwicklung',
    value: 'Karriereentwicklung',
    icon: <FaGraduationCap />,
    tooltip:
      'Hebe hervor, wie dein Unternehmen Möglichkeiten zur beruflichen Weiterentwicklung bietet, um ambitionierte Talente anzuziehen.'
  },
  {
    label: 'Work-Life-Balance',
    value: 'Work-Life-Balance',
    icon: <FaBalanceScale />,
    tooltip:
      'Betone die Flexibilität und die Ausgewogenheit zwischen Arbeit und Privatleben, die du potenziellen Mitarbeitern bieten kannst.'
  },
  {
    label: 'Unternehmenskultur',
    value: 'Unternehmenskultur',
    icon: <FaHandshake />,
    tooltip:
      'Beschreibe die Werte und das Arbeitsumfeld deines Unternehmens, um Kandidaten anzuziehen, die sich mit deiner Kultur identifizieren.'
  },
  {
    label: 'Innovative Projekte',
    value: 'Innovative Projekte',
    icon: <FaLightbulb />,
    tooltip:
      'Stelle die spannenden und zukunftsweisenden Projekte vor, an denen neue Mitarbeiter arbeiten würden.'
  },
  {
    label: 'Stabilität & Sicherheit',
    value: 'Stabilität & Sicherheit',
    icon: <FaShieldAlt />,
    tooltip:
      'Betone die Arbeitsplatzsicherheit und die Stabilität, die dein Unternehmen bietet, um sicherheitsbewusste Kandidaten anzusprechen.'
  },
  {
    label: 'Vision & Sinnhaftigkeit',
    value: 'Vision & Sinnhaftigkeit',
    icon: <FaGlobe />,
    tooltip:
      'Hebe hervor, wie die Arbeit in deinem Unternehmen einen positiven Einfluss auf die Gesellschaft oder Umwelt hat.'
  },
  {
    label: 'Exklusive Weiterbildung',
    value: 'Exklusive Weiterbildung',
    icon: <FaChalkboardTeacher />,
    tooltip:
      'Betone die exklusiven Schulungs- und Weiterbildungsangebote, die du für die berufliche Entwicklung deiner Mitarbeiter bereitstellst.'
  },
  {
    label: 'Teamgeist',
    value: 'Teamgeist',
    icon: <FaUsers />,
    tooltip:
      'Beschreibe den starken Zusammenhalt und die kollegiale Atmosphäre in deinem Team, um Kandidaten anzulocken, die Wert auf Zusammenarbeit legen.'
  },
  {
    label: 'Vielfalt und Inklusion',
    value: 'Vielfalt und Inklusion',
    icon: <FaUniversalAccess />,
    tooltip:
      'Betone das Engagement deines Unternehmens für Diversität und Inklusion, um eine breite und vielfältige Bewerberbasis anzusprechen.'
  }
];

export interface AiGeneratedTypeProps {
  formInstance: any;
  onSubmit: (e: any) => void;
  initialData: AdGeneration;
}

const StatementTemplate = ({ formInstance, onSubmit, initialData }: AiGeneratedTypeProps) => {
  const [value, setValue] = useState(initialData?.alignment);
  const [form] = Form.useForm();

  const initial = {
    ...initialData
  };

  useEffect(() => {
    if (formInstance) {
      formInstance.current = form;
    }
  }, [formInstance, form]);

  const handleChange = (value: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, alignment: value });
    setValue(value);
  };

  return (
    <Form
      name="advantages_form"
      layout="vertical"
      form={form}
      initialValues={{ ...initial }}
      style={{ margin: '0 auto' }}
      onFinish={onSubmit}
    >
      <Form.Item label="Worüber soll das Statement handeln?" name={'alignment'}>
        <OptionsPicker
          options={statementTypeOptions}
          className="statement-options"
          colSpan={3}
          handleChange={value => {
            handleChange(value);
          }}
          value={value}
        />
      </Form.Item>

      <Form.Item
        label="Anweisung (ca. 1-3 Sätze)"
        name={`promptMessage${AD_TYPES.STATEMENT}`}
        rules={customStringFieldValidation(10, 500)}
      >
        <Input.TextArea
          placeholder={value && statementTypePlaceHolders[value]}
          disabled={!value}
          autoSize={{ minRows: 7, maxRows: 12 }}
        />
      </Form.Item>
    </Form>
  );
};

export default StatementTemplate;
