import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactEasyCrop from 'react-easy-crop';
import { Input, Modal, message, Button, Empty } from 'antd';
import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import getCroppedImg, {
  resizeImage,
  blobToBase64,
  isValidImage,
  compressBlobImage
} from './helper/cropImage';
import { GeneralComponentsMessages } from '../config/messages';
import { DeleteOutlined } from '@ant-design/icons';
import { UltimateImagePickerMessages } from '../config/messages';
import { useMutation } from '@apollo/react-hooks';
import { deepCopy } from '../helper/array';
import { detectSafari, detectFirefox, detectMacintosh } from '../VoiceRecorder/helper/deviceHelper';
import { UPLOAD_IMAGE } from '../Funnel/helper/uploadImages';

export interface ImageUploadValueInterface {
  id: string;
  src: string;
  height: number;
  width: number;
  minWidth?: number;
  hint?: string;
  notRequired?: boolean;
}

interface ImageUploadInterface {
  onSubmit: Function;
  onImageDelete?: Function;
  initialValue?: ImageUploadValueInterface[] | [];
  value?: ImageUploadValueInterface[] | [];
  withoutModal?: boolean;
  bigPreview?: boolean;
  cropShape?: 'rect' | 'round';
  trigger?: JSX.Element;
  modalTitle?: string;
  headline?: string;
  saveImageAndReturnUrl?: boolean;
  zoomEnabled?: boolean;
  uploadQuality?: number;
}

let saveImage: any = '';

declare global {
  interface Window {
    showCroppedImage(): any;
  }
}

export const onSelectFile = (imageBlob: any, callback: Function, minSize?: any) => {
  if (!minSize) minSize = { width: 850, height: 450 };

  if (imageBlob) {
    const reader = new FileReader();
    reader.onload = function(e: any) {
      var img = new Image() as any;
      img.src = e.target.result;

      img.onload = function() {
        const w = this.width;
        const h = this.height;

        if (w < minSize.width || h < minSize.height) {
          message.error(`Das Bild muss mindestens ${minSize.width}x${minSize.height} groß sein.`);
          callback();
        } else {
          callback(reader.result);
        }
      };
    };
    reader.readAsDataURL(imageBlob);
  }
};

function ImageUpload({
  onSubmit,
  onImageDelete,
  initialValue = [],
  value,
  withoutModal,
  bigPreview,
  cropShape = 'rect',
  trigger,
  modalTitle,
  headline,
  saveImageAndReturnUrl,
  zoomEnabled,
  uploadQuality
}: ImageUploadInterface) {
  initialValue =
    Array.isArray(initialValue) && initialValue.some(iv => !!iv.id) ? initialValue : [];

  let [currentImageId, setCurrentImageId] = useState(initialValue[0]?.id);

  const [currentImages, setCurrentImages] = useState<ImageUploadValueInterface[]>(initialValue);
  const currentImage = currentImages.find(ci => ci.id === currentImageId) || {
    id: '',
    src: '',
    width: 0,
    height: 0
  };

  const [upImg, setUpImg] = useState<any>();
  const [zoom, setZoom] = useState<number>(1);
  const [cropperVisible, setCropperVisibility] = useState(false);
  const [popupVisible, setPopupVisibility] = useState(false);
  const [loading, setLoader] = useState(false);
  const [crop, setCrop] = useState<any>({ width: currentImage?.width, y: 0, x: 0, aspect: 16 / 9 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);

  const areAllImagesUploaded = () =>
    !currentImages.some(({ src, notRequired }) => !src && !notRequired);

  const handleSetCurrentImage = (image?: any) => {
    if (currentImage) {
      const newCurrentImages = deepCopy(currentImages);
      const currentImageIndex = currentImages.findIndex(ci => ci.id === currentImageId);
      currentImages[currentImageIndex].src = image;
      newCurrentImages[currentImageIndex].src = image;
      setCurrentImages(newCurrentImages);
    }
  };

  useEffect(() => {
    saveImage = '';
    if (value) setCurrentImages(value);
  }, [value]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    setLoader(true);

    if (!croppedAreaPixels && !upImg && areAllImagesUploaded()) {
      setLoader(false);
      onSubmit(currentImages);
      setPopupVisibility(false);
      return true;
    }

    if (!croppedAreaPixels && !upImg) {
      message.error(UltimateImagePickerMessages.noImageCropped);
      setLoader(false);
      return false;
    }

    try {
      const { croppedImage, customSize } = (await getCroppedImg(upImg, croppedAreaPixels)) || {};
      const resizedImageBlob = (!customSize
        ? await resizeImage(
            croppedImage,
            currentImage?.width,
            currentImage?.height
          )
        : croppedImage) as Blob;

      const compressedImage = await compressBlobImage({
        blob: resizedImageBlob,
        quality: uploadQuality
      });
      const base64Image = await blobToBase64(compressedImage);
      handleSetCurrentImage(base64Image);
      saveImage = base64Image;

      if (saveImageAndReturnUrl) {
        const uploadRes = await uploadImage({ variables: { input: [{ imageURL: base64Image }] } });
        const imageLink = uploadRes.data.uploadImages[0].imageLink;
        handleSetCurrentImage(imageLink);

        if (areAllImagesUploaded()) onSubmit(currentImages);
      } else {
        if (areAllImagesUploaded()) onSubmit(currentImages);
      }
    } catch (e) {
      message.error(GeneralComponentsMessages.noBlobSupport);
    }

    const _areAllImagesUploaded = areAllImagesUploaded();

    setCropperVisibility(false);
    if (_areAllImagesUploaded) setPopupVisibility(false);
    setUpImg('');
    setLoader(false);

    return _areAllImagesUploaded;
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if ((detectSafari() || detectFirefox()) && detectMacintosh()) {
      message.error(
        'Bitte nutze Google Chrome. Das Hochladen von Bildern wird unter ' +
          (detectSafari() ? 'Safari' : 'Firefox') +
          ' nicht unterstützt.',
        8
      );
      return;
    }

    if (e.target.files && e.target.files.length > 0) {
      const imageBlob = e.target.files[0];

      if (isValidImage(imageBlob.type)) {
        onSelectFile(
          imageBlob,
          (result: string) => {
            if (result) {
              setUpImg(result);
              setCropperVisibility(true);
            }
          },
          { width: currentImage?.minWidth || currentImage?.width, height: currentImage?.height }
        );
      }
    }
  };

  const prepareImageUpload = (id: string) => {
    setCurrentImageId(id);
  };

  const handleDeleteImage = (id: string) => {
    saveImage = '';

    const imageToDeleteIndex = currentImages.findIndex(ci => ci.id === id);
    if (imageToDeleteIndex > -1) {
      const newCurrentImages = deepCopy(currentImages);
      newCurrentImages[imageToDeleteIndex].src = '';
      setCurrentImages(newCurrentImages);
      onImageDelete && onImageDelete();
    }
  };

  window.showCroppedImage = showCroppedImage;

  const maxModalWidth = 800;
  let modalWidth = window.outerWidth - window.outerWidth * 0.3;
  modalWidth = modalWidth > maxModalWidth ? maxModalWidth : modalWidth;
  const minOneImageChosen = currentImages.some(ci => ci.src);

  const inner = (
    <>
      {!cropperVisible &&
        currentImages.map(({ id, src, hint }) => {
          if (!src) return null;
          if (!src.includes('//') && !src.includes('base64')) handleDeleteImage(id);

          return (
            <div className="image-upload__preview" key={id}>
              {hint && <h3>{hint}</h3>}
              <img src={src} />
              <Button
                onClick={() => handleDeleteImage(id)}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
              />
              {bigPreview && <label onClick={() => handleDeleteImage(id)}>Bild ersetzen</label>}
            </div>
          );
        })}

      <div className="image-upload__input-wrapper">
        {bigPreview && !cropperVisible && !minOneImageChosen && (
          <Empty image={<CloudUploadOutlined />} description={null}>
            <React.Fragment>
              <h2>{headline || 'Eigenes Foto hochladen'}</h2>
              <p>Die hochgeladenen Bilder müssen den Maßen unter den Upload-Feldern entsprechen.</p>
            </React.Fragment>
          </Empty>
        )}
        {!cropperVisible &&
          currentImages.map(({ hint, width, height, id }) => {
            const thisImage = currentImages.find(cs => cs.id === id);

            if (thisImage?.src) return null;

            return (
              <div className="image-upload__input" key={id}>
                <Input
                  onFocus={() => prepareImageUpload(id)}
                  id="image-upload-input"
                  type="file"
                  accept="image/*"
                  onChange={e => handleImageSelect(e, id)}
                  prefix={<CloudUploadOutlined />}
                />
                <label htmlFor="image-upload-input">Datei auswählen</label>
                <p>
                  {hint ? hint + ':' : ''} {width}px x {height}px
                </p>
              </div>
            );
          })}
      </div>

      {cropperVisible && currentImage && (
        <React.Fragment>
          <div style={{ height: 400, position: 'relative' }}>
            <ReactEasyCrop
              image={upImg}
              crop={crop}
              zoom={zoom}
              minZoom={0.25}
              maxZoom={2}
              zoomSpeed={0.1}
              restrictPosition={!zoomEnabled}
              cropShape={cropShape}
              onZoomChange={zoom => {
                zoomEnabled ? setZoom(zoom) : null;
              }}
              initialCroppedAreaPixels={{
                height: currentImage.height,
                width: currentImage.width,
                y: 0,
                x: 0
              }}
              aspect={currentImage.width / currentImage.height}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
            />
          </div>

          <div className="image-upload__cancel-cropping">
            <Button
              onClick={() => setCropperVisibility(false)}
              type="primary"
              shape="circle"
              icon={<CloseOutlined />}
              danger
            />
            <label>Abbrechen</label>
          </div>
        </React.Fragment>
      )}
    </>
  );

  return (
    <div className={'image-upload' + (bigPreview ? ' big-preview' : '')}>
      {withoutModal ? (
        inner
      ) : (
        <Modal
          width={modalWidth}
          className="image-upload__modal"
          title={modalTitle || 'Bild hochladen'}
          visible={popupVisible}
          onCancel={() => setPopupVisibility(false)}
          footer={[
            <Button key="submit" loading={loading} type="primary" onClick={showCroppedImage}>
              Bestätigen
            </Button>
          ]}
        >
          {inner}
        </Modal>
      )}

      {trigger && (
        <div onClick={() => setPopupVisibility(true)} className="image-upload__trigger">
          {trigger}
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
