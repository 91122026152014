import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

interface FAQItem {
  title: string;
  content: string;
}

interface FAQProps {
  data: FAQItem[];
}

const FAQAccordion: React.FC<FAQProps> = ({ data }) => {
  return (
    <div className="w-100">
      <div className='text-center mb-4'>
        <h2 className="title-text">
          Häufig gestellte Fragen
        </h2>
        <p>Bei weiteren Fragen melde dich gerne über den Support-Chat unten rechts.</p>
      </div>
      <Collapse className="collapse-container">
        {data.map((item, index) => (
          <Panel header={item.title} key={index.toString()} className="collapse-container__header">
            <p className="collapse-container__content">{item.content}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQAccordion;
