import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Tabs, Slider, Alert } from 'antd';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { FaTextHeight } from 'react-icons/fa';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import FontStyleButtons from '../../../SharedUI/components/FontStyleButtons';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { FontAvailableSizes, defaultFontSize, HeadingLevels } from '../../interfaces/FontSizeInterface';
import { useNode } from '@craftjs/core';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { RichTextComponentMessages } from '../../../config/messages';
import { useSearchParams } from 'react-router-dom';
import { SETTINGS_VALUE } from '../../helper/richTextHelpers';
import { FAQ } from '../../AdBuilder/interfaces/TextTypeInterface';
import EmojiPicker from '../../../GeneralComponents/EmojiPicker';
import { SliderValue } from 'antd/lib/slider';
import { FONT_MARK } from './RichTextComponent/utils';
import AiTextSettings from './RichTextComponent/components/AiTextSettings';
import { isWidgetBuilderPath } from '../../WidgetsBuilder/helper/helper';
import { getKeyByValue } from '../../helper/craftJs';
import { useEditorContext } from '../../context/EditorContext';
import { Level } from "@tiptap/extension-heading"

const { TabPane } = Tabs;

const marks = {
  1: 'S',
  2: 'M',
  3: 'L',
  4: 'XL'
};

function RichTextSetting() {
  const {
    nodeId,
    props
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { getEditor } = useEditorContext();
  const tiptapEditor = getEditor(nodeId);

  const themeColors = useCurrentlyEditingBuilderTheme();
  const [emojiPickerCollapsed, setEmojiPickerCollapsedState] = useState(true);
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('elementSubSettings');
  const [selectedElementTab, setSelectedElementTab] = useState(
    paramValue || SETTINGS_VALUE.AI
  );

  const { elementType } = props;

  useEffect(() => {
    setSelectedElementTab(
      paramValue === SETTINGS_VALUE.AI
        ? SETTINGS_VALUE.AI
        : SETTINGS_VALUE.GENERAL
    );
  }, [paramValue]);

  const isFAQElement = Object.values(FAQ).some(type => type === elementType);

  const getBlockType = () => tiptapEditor?.isActive({ textAlign: 'left' }) ? 'left'
    : tiptapEditor?.isActive({ textAlign: 'center' }) ? 'center'
    : tiptapEditor?.isActive({ textAlign: 'right' }) ? 'right'
    : 'left';

  const handleFontSizeChange = (value: SliderValue) => {
    if (!tiptapEditor) return;

    const fontSize = FontAvailableSizes.find(size => size.key === value)?.value || defaultFontSize.value;
    const headingLevel = (HeadingLevels.find(size => size.key === value)?.value || 3) as Level;
    const isParagraph = fontSize === defaultFontSize.value;
    const alignment = getBlockType();

    const isCurrentlyHeading = tiptapEditor.isActive('heading');

    tiptapEditor.chain()
      .setFontSize(fontSize + "em")
      .run();

    if (isParagraph && isCurrentlyHeading) {
      tiptapEditor.chain()
        .setParagraph()
        .setTextAlign(alignment)
        .run();
    } else {
      tiptapEditor.chain()
        .setHeading({ level: headingLevel })
        .setTextAlign(alignment)
        .run();
    }
  };

  const handleColorChange = (color: string) => {
    if (!tiptapEditor) return;
    tiptapEditor.chain().setColor(color).run();
  };

  const handleEmojiSelect = (emoji: string) => {
    if (!tiptapEditor) return;
    tiptapEditor.chain().focus().insertContent(emoji).run();
  };

  const handleChangeTab = (value: string) => {
    setSelectedElementTab(value);
    tiptapEditor?.commands?.focus();
  };

  function _toggleInlineStyle(inlineStyle: string) {
    if (!tiptapEditor) return;

    switch (inlineStyle) {
      case 'BOLD':
        tiptapEditor.chain().focus().toggleBold().run();
        break;
      case 'ITALIC':
        tiptapEditor.chain().focus().toggleItalic().run();
        break;
      case 'UNDERLINE':
        tiptapEditor.chain().focus().toggleUnderline().run();
        break;
      default:
        break;
    }
  }

  function _toggleBlockType(blockType: string) {
    if (!tiptapEditor) return;

    tiptapEditor
      .chain()
      .setTextAlign(blockType as 'left' | 'center' | 'right')
      .focus()
      .run();
  }

  const currentStyle = {
    has: (style: string) => {
      if (!tiptapEditor) return false;

      switch (style) {
        case 'BOLD':
          return tiptapEditor.isActive('bold');
        case 'ITALIC':
          return tiptapEditor.isActive('italic');
        case 'UNDERLINE':
          return tiptapEditor.isActive('underline');
        default:
          return false;
      }
    }
  }

  const blockType = getBlockType();
  const isWidgetBuilder = isWidgetBuilderPath();
  const enableAiText = !isWidgetBuilder && !isFAQElement;

  const getColor = () => {
    if (tiptapEditor?.getAttributes('textStyle')?.color) {
      return tiptapEditor?.getAttributes('textStyle').color;
    }
    else if (isFAQElement) {
      return themeColors.cardTextColor
    }
    else if (tiptapEditor?.isActive('heading')){
      return themeColors.headlineColor;
    } else {
      return themeColors.textColor;
    }
  }

  const handleFocusOnSlider = (event: React.MouseEvent) => {
    const handleTiptapFocus = () => {
      tiptapEditor?.commands?.focus();
      document.removeEventListener('mouseup', handleTiptapFocus);
    }

    document.addEventListener('mouseup', handleTiptapFocus);
  }

  return (
    <Card className='settings-card settings-card__no-padding' title='Text' bordered={false}>
      <Tabs
        activeKey={selectedElementTab}
        onChange={handleChangeTab}
        className="tab-container-style builder__settings-sidebar__container"
      >
        <TabPane tab="Formatierung" key={SETTINGS_VALUE.GENERAL}>
          <Card className="settings-card settings-card__no-padding" bordered={false}>
            <Col span={24} className="builder__settings-sidebar__container my-3 px-4">
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
                  <div
                    onMouseDown={handleFocusOnSlider}
                  >
                  <Slider
                    className="builder-slider-style"
                    marks={marks}
                    min={1}
                    max={4}
                    step={1}
                    tooltipVisible={false}
                    disabled={isFAQElement}
                    // @ts-ignore
                    value={tiptapEditor?.getAttributes(FONT_MARK)?.fontSize ? getKeyByValue(tiptapEditor?.getAttributes(FONT_MARK)?.fontSize.split('em')[0], FontAvailableSizes, "value").key : defaultFontSize.value}
                    onChange={handleFontSizeChange}
                  />
                  </div>
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Ausrichtung" />
                  <TextAlignmentButtons
                    align={blockType}
                    disabled={props.alignmentDisabled}
                    onChange={_toggleBlockType}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <BuilderSettingsTitle title="Style" />
                  <FontStyleButtons
                    hasStyle={style => currentStyle.has(style)}
                    onChange={_toggleInlineStyle}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Farbe" />
                  <BuilderColorPickerButton
                    color={getColor()}
                    onChange={handleColorChange}
                  />
                </Col>
                <Col span={11} offset={2}></Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle
                    title="😊 Emoji"
                    onCollapseToggleClick={value => setEmojiPickerCollapsedState(value)}
                    collapsed={emojiPickerCollapsed}
                  />
                  {!emojiPickerCollapsed && (
                    <EmojiPicker
                      height={'500px'}
                      emojiSize={30}
                      onChange={handleEmojiSelect}
                    />
                  )}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <Alert
                    message={RichTextComponentMessages.changeFontFamilyInDesignSettings}
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          </Card>
        </TabPane>
        {enableAiText && (
          <TabPane tab="Text KI" key={SETTINGS_VALUE.AI}>
            <AiTextSettings
              tiptapEditorState={tiptapEditor}
              elementType={elementType}
              setCenter={false}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
}

export default RichTextSetting;
