import React from 'react';
import { Button, Space } from 'antd';
import { editorClasses } from './EditorHelpers';
import { LinkBlock } from './LinkBlock';
import { EditorToolbarProps } from './EditorTypes';
import { ToolbarItem } from './EditorItem';
import { HeadingBlock } from './HeadingBlock';
import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  FontColorsOutlined
} from '@ant-design/icons';
import { BiUnderline } from 'react-icons/bi';
import ColorPicker from '../../../Funnel/components/subcomponents/ColorPicker';
import EmojiPickerModal from '../../../Builder/components/elements/RichTextComponent/components/EmojiPickerModal';

export function Toolbar({
  editor,
  fullItem,
  showHeadingBlock = true,
  showColorPicker = true,
  showStrikeThrough = true,
  classes = "",
  showEmojiPicker = false,
  showPointsMaker = false,
}: EditorToolbarProps) {
  if (!editor) {
    return null;
  }

  const currentTextColor = editor.getAttributes('textStyle').color;

  const handleEmojiChange = (e: any) => {
    editor
      .chain()
      .focus()
      .insertContent(e)
      .run();
  }

  return (
    <Space className={`${editorClasses.toolbar.root} ${classes}`} direction="horizontal" size="small">
    {showHeadingBlock &&
      <HeadingBlock editor={editor} />
    }
      <Space size="small">
        <ToolbarItem
          aria-label="Bold"
          active={editor.isActive('bold')}
          className={editorClasses.toolbar.bold}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setColor(currentTextColor)
              .toggleBold()
              .run()
          }
          icon={<BoldOutlined />}
        />
        {showStrikeThrough &&
          <ToolbarItem
            aria-label="Strike"
            active={editor.isActive('strike')}
            className={editorClasses.toolbar.strike}
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleStrike()
                .run()
            }
            icon={<StrikethroughOutlined />}
          />
        }
        <ToolbarItem
          aria-label="Italic"
          active={editor.isActive('italic')}
          className={editorClasses.toolbar.italic}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          icon={<ItalicOutlined />}
        />
        <ToolbarItem
          aria-label="Underline"
          active={editor.isActive('underline')}
          className={editorClasses.toolbar.strike}
          onClick={() =>
            editor.extensionManager.editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          icon={<BiUnderline />}
        />
        {showColorPicker &&
          <ColorPicker
            color={currentTextColor}
            onChange={color => {
              editor
                .chain()
                .focus()
                .setColor(color)
                .run();
            }}
            className="notes__color-picker"
            palletteClassName="notes__color-picker"
          >
            <ToolbarItem
              aria-label="Color"
              styles={{ color: currentTextColor }}
              icon={<FontColorsOutlined />}
            />
          </ColorPicker>
        }
      </Space>
      {showPointsMaker &&
        <Space size="small">
          <ToolbarItem
            aria-label="Bullet list"
            active={editor.isActive('bulletList')}
            className={editorClasses.toolbar.bulletList}
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .setColor(currentTextColor)
                .run()
            }
            icon={<UnorderedListOutlined />}
          />
          <ToolbarItem
            aria-label="Ordered list"
            active={editor.isActive('orderedList')}
            className={editorClasses.toolbar.orderedList}
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .setColor(currentTextColor)
                .run()
            }
            icon={<OrderedListOutlined />}
          />
        </Space>
      }
      <LinkBlock editor={editor} />
      {showEmojiPicker &&
        <EmojiPickerModal onChange={handleEmojiChange}>
          <Button className="toolbar-item-button rounded-0 px-2">😉</Button>
        </EmojiPickerModal>
      }
    </Space>
  );
}
