import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, message, Tooltip, Tabs } from 'antd';
import { useEditor, useNode } from '@craftjs/core';
import { EditorContent, Editor as TiptapEditor } from '@tiptap/react';
import { useCustomEditor } from '../../../../../../SharedUI/components/useEditor';
import { ActionsController } from '../../../sharedUI/ActionsController';
import useOutsideAlerter from '../../../../../hooks/useOutsideAlerter';
import EmojiPickerModal from '../../../../../components/elements/RichTextComponent/components/EmojiPickerModal';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import { textTypesEnum } from '../../../../interfaces/TextTypeInterface';
import { getTextTemplateByText, useTextTemplateData } from '../../../../data';
import { useUserContentTemplate } from '../../../../redux/adBuilderSlice';
import { tooltipText } from '../../../../../helper/tooltipText';
import AiTextSettings from '../../../../../components/elements/RichTextComponent/components/AiTextSettings';
import { useEditorContext } from '../../../../../context/EditorContext';
import { SETTINGS_VALUE, useElementSubSettings } from '../../../../../helper/richTextHelpers';

const { TabPane } = Tabs;
export interface PlainTextComponentPropTypes {
  html?: string;
  onlySettingsReq?: boolean;
  elementType: textTypesEnum;
  isEdited: boolean;
  templateId: number;
}

const PlainTextComponent = ({
  templateId,
  html,
  onlySettingsReq,
  isEdited = false,
  elementType,
}: PlainTextComponentPropTypes) => {
  const [isEditable, setIsEditable] = useState(true);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const userTemplate = useUserContentTemplate(elementType);
  const content = useTextTemplateData(elementType);
  const { registerEditor, unregisterEditor } = useEditorContext();
  const {
    query,
    actions: { history }
  } = useEditor();

  const {
    actions: { setProp },
    nodeId,
    nodeProps
  } = useNode(node => ({
    dragged: node.events.dragged,
    nodeId: node.id,
    nodeProps: node.data.props
  }));

  const handleOnChange = (_editor: TiptapEditor) => {
    history.throttle().setProp(nodeId, (prop: any) => {
      if(prop.rawState){
        delete prop.rawState;
      }
      prop.html = _editor.getHTML()
    })
  }

  const tiptapEditor = useCustomEditor({
    content: html || nodeProps.html || nodeProps.plainText || '',
    editable: isEditable,
    onChange: handleOnChange,
    disableBullets: true,
    onCreate({ editor }) {
      const content = editor.options.content as string;
      if (typeof content === 'string' && content.trim() && !content.startsWith("<p")) {
        const paragraphs = content
          .split(/\r?\n/)
          .filter(text => text.trim())
          .map(text => ({
            type: 'paragraph',
            content: [{ type: 'text', text: text.trim() }]
          }));
        if (paragraphs.length > 0) {
          editor.commands.setContent({
            type: 'doc',
            content: paragraphs
          });
        }
        if (nodeProps.plainText) {
          history.ignore().setProp(nodeId, (prop: any) => {
            prop.html = editor.getHTML()
            delete prop.plainText
          });
        }
      }
    },
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'plain-text-editor',
        style: 'white-space: pre-wrap; word-break: break-word !important;',
      },
    },
    history: false
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [disableDrag, setDisableDrag] = useState(false);

  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });

  useEffect(() => {
    if (tiptapEditor){
      registerEditor(nodeId, tiptapEditor);
      const templateData = {
        ...content,
        data: [...content.data, { content: userTemplate, category: 'Customer' }]
      };
      const template = getTextTemplateByText(html as string, templateData);
      if (template) {
        history.ignore().setProp(nodeId, (props: any) => (props.templateId = template.id));
      }
    }
    return () => {
      if(tiptapEditor)
        unregisterEditor(nodeId);
    }
  }, [nodeId, tiptapEditor]);

  useEffect(() => {
    if (tiptapEditor && nodeProps.html && nodeProps.html !== tiptapEditor.getHTML()){
      const newContent = nodeProps.html.startsWith("<p") ?
        nodeProps.html :
        nodeProps.html.split(/\r?\n/).filter((text: string) => text.trim()).map((text: string) => ({type: "paragraph", content: [{type: 'text', text: text.trim() }]}));
      const content = typeof newContent === "string" ?
        newContent :
        { type: "doc", content: newContent }

      tiptapEditor.commands.setContent(content)
    }
  }, [nodeProps.html])

  const handleEmojiInsert = (emoji: string) => {
    tiptapEditor?.commands.insertContent(emoji)
  };

  return (
    <ActionsController
      className="rich-text__wrapper"
      canDrag={!disableDrag}
      onlySettingsReq={onlySettingsReq}
      label={content?.title}
      textAiReq={true}
      actionIcon={
        <Tooltip title={tooltipText.emoji}>
          <li>
            <EmojiPickerModal onChange={handleEmojiInsert}>
              😉
            </EmojiPickerModal>
          </li>
        </Tooltip>
      }
    >
      <EditorContent className='plain-text' editor={tiptapEditor} spellCheck={false}  />
    </ActionsController>
  );
};

export const PlainTextSettings = () => {
  const {
    props,
    nodeId
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));

  const { getEditor } = useEditorContext();

  const editorInstance = getEditor(nodeId)

  const [selectedElementTab, setSelectedElementTab] = useState(SETTINGS_VALUE.AI as string);
  const { goToElementSubSettings } = useElementSubSettings();

  const handleChangeTab = (value: string) => {
    goToElementSubSettings(value as SETTINGS_VALUE);
    setSelectedElementTab(value);
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card settings-card__no-padding" title="Art" bordered={false}>
        <Tabs
          className="tab-container-style builder__settings-sidebar__container"
          activeKey={selectedElementTab}
          onChange={handleChangeTab}
          tabBarStyle={{ margin: 0 }}
        >
          <TabPane
            tab="Text KI"
            className="single-tab-container m-0"
            key={SETTINGS_VALUE.AI}
          >
            <AiTextSettings
              tiptapEditorState={editorInstance}
              showOptionPicker={false}
              elementType={props.elementType}
              shouldReplaceTextColor={false}
              processAsPlainText
              setCenter={false}
            />
          </TabPane>
        </Tabs>
      </Card>
    </Col>
  );
};

PlainTextComponent.craft = {
  name: CRAFT_ELEMENTS.PLAIN_TEXT,
  props: {
    html: "",
    isEdited: false,
  },
  related: {
    settings: PlainTextSettings
  },
  rules: {
    canMoveIn: (selectedNode: any) => false,
    canMoveOut: () => false
  }
};

export default PlainTextComponent;
