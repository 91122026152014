import { Mark, mergeAttributes } from '@tiptap/core';

export const FONT_MARK = "fontSize"

export interface FontSizeOptions {
  types: string[]
  defaultSize?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [FONT_MARK]: {
      /**
       * Set the font size
       */
      setFontSize: (fontSize: string) => ReturnType
    }
  }
}

export const FontSize = Mark.create<FontSizeOptions>({
  name: FONT_MARK,

  priority: 1000, // High priority to ensure it overrides other marks

  addOptions() {
    return {
      types: ['textStyle'],
      defaultSize: '1em'
    }
  },

  extending: 'textStyle',

  addAttributes() {
    return {
      [FONT_MARK]: {
        default: null,
        parseHTML: element => {
          const fontSize = element.style.fontSize;
          return fontSize?.trim() || null;
        },
        renderHTML: attributes => {
          if (!attributes[FONT_MARK] || attributes[FONT_MARK] === this.options.defaultSize) {
            return {
              style: `font-size: ${this.options.defaultSize}`
            };
          }

          return {
            style: `font-size: ${attributes[FONT_MARK]}`
          };
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element: HTMLElement) => {
          return element.style.fontSize ? {} : false;
        }
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setFontSize: fontSize => ({ commands }) => {
        return commands.setMark(this.name, { [FONT_MARK]: fontSize })
      }
    }
  }
});

export interface FontSizeAttribute {
  [FONT_MARK]: string | null
}
