import { FunnelInterface } from '../components/GeneralSettingsBuilder';
import { setLocalStorage, getLocalStorage } from '../../helper/objectAccess';
import { TrustBadgeInterface } from '../components/SocialProofSettingsBuilder';
import { message } from 'antd';
import { FunnelMessages } from '../../config/messages';
import { ThemeStylerValuesInterface } from '../components/subcomponents/utils/themeStylerConfig';
import { getMeetovoAuthData, populateLocalStorageWithAuth } from '../../Login/container/AuthRoutesHOC';

interface FunnelGeneralInfosInterface {
  trustBadges?: TrustBadgeInterface | [];
  linkTree?: {
        title: string;
        url: string;
      }[] | [];
  brandColors?: {
        primaryBrandColor: string;
        secondaryBrandColor: string;
      } | {};
  basicInfo?: {
        builderVersion: string;
        title: string;
        typ: string;
        uniqueIdentifier: string;
        imageURL: string;
        isFirstFunnelCreation?: boolean;
        templateId: number;
        domain?: {
          name: string;
          status: string;
              }
    } | any,
  themeStyles?: ThemeStylerValuesInterface | {}
}

export const getLocalCurrentFunnelGeneralInfo = (): FunnelGeneralInfosInterface => {
  const localGeneralInfo = getLocalStorage('meetovoFunnelGeneralInfo');

  if (!Object.keys(localGeneralInfo).length)
    return {
      trustBadges: [],
      linkTree: [],
      brandColors: {},
      basicInfo: {},
      themeStyles: {},
    }

  return localGeneralInfo;
};

export const getCurrentTemplateId = () => {
  const { basicInfo } = getLocalCurrentFunnelGeneralInfo();
  const { templateId } = basicInfo;
  return templateId
}

export const setLocalCurrentFunnelGeneralInfo = (generalInfo: FunnelGeneralInfosInterface) => {
  const localGeneralInfo = getLocalStorage('meetovoFunnelGeneralInfo');
  setLocalStorage('meetovoFunnelGeneralInfo', { ...localGeneralInfo, ...generalInfo });
};

export const setNewAuthDataToLocalStorage = ({
  expirationDate,
  token
}: {
  expirationDate: string;
  token: string;
}) => {
  const currentAuthData = JSON.parse(getMeetovoAuthData() || "{}");
  populateLocalStorageWithAuth({ ...(currentAuthData || {}), expirationDate, token });
}

export const isFunnelBuilderEnabledAndMessage = () => {
  const funnelBuilderEnabled = window.innerWidth > 1230;

  if(funnelBuilderEnabled)
      return true

  message.info(FunnelMessages.funnelBuilderOnlyOnDesktop, 8);
  return false
}