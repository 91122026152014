export const logoWhiteURL = process.env.PUBLIC_URL + '/icons/meetovo-logo-white.png';
export const scrollImage1 = process.env.PUBLIC_URL + '/login/scroll-images/scroll-image1.svg';
export const scrollImage2 = process.env.PUBLIC_URL + '/login/scroll-images/scroll-image2.svg';
export const scrollImage3 = process.env.PUBLIC_URL + '/login/scroll-images/scroll-image3.svg';
export const scrollImage4 = process.env.PUBLIC_URL + '/login/scroll-images/scroll-image4.svg';
export const dotsGrid = process.env.PUBLIC_URL + '/login/dots-grid.png';
export const blueCircles = process.env.PUBLIC_URL + '/login/blue-circles.png';

export const user1Img = process.env.PUBLIC_URL + '/login/avatars/user1.png';
export const user2Img = process.env.PUBLIC_URL + '/login/avatars/user2.png';
export const user3Img = process.env.PUBLIC_URL + '/login/avatars/user3.png';
export const user4Img = process.env.PUBLIC_URL + '/login/avatars/user4.png';

export const trustedLogo1 = process.env.PUBLIC_URL + '/login/linde-logo-white.png';
export const trustedLogo2 = process.env.PUBLIC_URL + '/login/edeka-logo-white.png';
export const trustedLogo3 = process.env.PUBLIC_URL + '/login/o2-logo-white.png';
export const trustedLogo4 = process.env.PUBLIC_URL + '/login/hansmekur-logo-white.png';
export const trustedLogo5 = process.env.PUBLIC_URL + '/login/wmf-logo-white.png';
export const trustedLogo6 = process.env.PUBLIC_URL + '/login/spaksse-logo-white.png';

export const loginFacebookIcon = process.env.PUBLIC_URL + '/login/Facebook.svg';
export const loginTikTokIcon = process.env.PUBLIC_URL + '/login/tik-tok.svg';
export const loginInstagramIcon = process.env.PUBLIC_URL + '/login/instagram.svg';
export const loginLinkedinIcon = process.env.PUBLIC_URL + '/login/linkedin.svg';

export const aiTokenRebuyCheck = process.env.PUBLIC_URL + '/icons/ai-token-check.svg';
export const aiTokenRebuyUnCheck = process.env.PUBLIC_URL + '/icons/ai-token-uncheck.svg';

export const loginSocialIcons = [
  loginFacebookIcon,
  loginTikTokIcon,
  loginInstagramIcon,
  loginLinkedinIcon
];

// export const user5Img = process.env.PUBLIC_URL + '/login/avatars/user5.jpeg';
