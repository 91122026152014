import React, { Ref } from 'react';
import { Editor as TiptapEditor, EditorContent } from '@tiptap/react';
import { Toolbar } from './helpers/EditorToolbar';
import { editorClasses } from './helpers/EditorHelpers';
import { Space } from 'antd';

interface Props {
  editor: TiptapEditor;
  fullItem: boolean;
  ref?: Ref<HTMLDivElement>;
  showHeadingBlock?: boolean;
  showColorPicker?: boolean;
  showPointsMaker?: boolean;
  showStrikeThrough?: boolean;
  showEmojiPicker?: boolean;
  toolbarClasses?: string;
  editorRootClassName?: string;
  editable?: boolean;
  helperText?: React.ReactNode;
  error?: boolean;
  editorClass?: string;
}

const TextEditor = ({
  editor,
  fullItem,
  ref,
  showHeadingBlock = true,
  showColorPicker = true,
  showPointsMaker = true,
  showStrikeThrough = true,
  showEmojiPicker = false,
  toolbarClasses = "",
  editorRootClassName = "",
  editable = true,
  helperText,
  error = false,
  editorClass = "",
}: Props) => {
  return (
    <Space style={{ ...(!editable && { cursor: 'not-allowed' }) }} className="editor_base">
      <div className={`${editorClasses.root} ${editorRootClassName || ''}`}>
        <Toolbar
          editor={editor}
          fullItem={fullItem}
          showHeadingBlock={showHeadingBlock}
          showColorPicker={showColorPicker}
          showPointsMaker={showPointsMaker}
          showStrikeThrough={showStrikeThrough}
          showEmojiPicker={showEmojiPicker}
          classes={toolbarClasses}
        />
        <EditorContent
          ref={ref}
          spellCheck="false"
          autoComplete="off"
          autoCapitalize="off"
          editor={editor}
          className={`${editorClasses.content.root} ${editorClass}`}
        />
      </div>
      {helperText && (
      <p style={{ paddingBlock: 2, color: !!error ? 'red' : 'default' }}>{helperText}</p>
      )}
    </Space>
  )
}

export default TextEditor;
