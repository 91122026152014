import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Card, Col, Row, Slider, Tabs } from 'antd';
import {
  getElementColor,
  getKeyByValue,
  getParentNodes,
  getUniqueId,
  getValueByKey
} from '../../../helper/craftJs';
import { defaultImageOpacity, ImageAvailableOpacity } from '../../interfaces/ImageSizeInterface';
import { defaultImage } from '../../../config/builderUi';
import { ImageLoadingSpinner } from '../../../../SharedUI/components/ImageLoadingSpinner';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import RichTextComponent from './RichTextComponent';
import { BasicElementWrapper } from '../sharedUI/BasicElementWrapper';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import ImageUploadV3 from '../../../../GeneralComponents/ImageUploadV3';
import { enhanceStockImage } from '../../../helper/images';
import ColorPicker from '../../../../Funnel/components/subcomponents/ColorPicker';
import ImageColorizedBanner from './ImageColorizedBanner';
import LogoComponent from './LogoComponent';
import { elementTypeObj, mediaTypeEnum } from '../../interfaces/TextTypeInterface';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useFunnelTheme } from '../../../redux/builderSlice';
import { FUNNEL_THEME_KEYS } from '../../../interfaces/builderSliceTypes';
import MediaSuggestionsSideBar from './MediaContentWrapper/components/MediaSuggestionsSideBar';

export interface CraftElementBaseProps {
  canHide?: {
    hideSwich: boolean;
  };
}

interface Props extends CraftElementBaseProps {
  src?: string;
  opacity?: number;
  children?: any;
  isLoading?: boolean;
  backgroundColor?: string;
  logoUrl?: string;
}

const { TabPane } = Tabs;

const ImageComponent = (props: Props) => {
  const { children } = props;
  const [img, setImg] = useState<undefined | string>();
  const { currentNode, id } = useNode(node => ({
    currentNode: node
  }));
  const { actions, query } = useEditor();
  const theme = useFunnelTheme();
  const parentNodes = getParentNodes(id, query);
  const colorizedBannerId = parentNodes.find(
    (nodeId: any) =>
      query.getSerializedNodes()[nodeId].displayName === CRAFT_ELEMENTS.COLORIZED_BANNER
  );

  const richTextProps = query.getSerializedNodes()[
    query.getSerializedNodes()[colorizedBannerId]?.nodes[0]
  ]?.props;

  useEffect(() => {
    if (currentNode.data.nodes.length === 1) {
      currentNode.data.nodes.map((node: any) => {
        const displayName = query.getSerializedNodes()[node].displayName;
        if (displayName === CRAFT_ELEMENTS.RICH_TEXT) {
          actions.setProp(node, (state: any) => (state.canHide = { hideSwich: false }));
          actions.setProp(
            node,
            (state: any) => (state.elementType = elementTypeObj[mediaTypeEnum.HAUPTINHALT])
          );
        }

        if (displayName !== CRAFT_ELEMENTS.IMAGE_COLORIZED_BANNER) {
          const bannerNodeTree = query
            .parseReactElement(
              <Element
                id={getUniqueId()}
                is={ImageColorizedBanner}
                backgroundColor={props.backgroundColor}
                onlySettingsReq={true}
                canHide={{ hideSwich: false }}
              >
                <Element
                  id={getUniqueId()}
                  is={RichTextComponent}
                  textType={mediaTypeEnum.BANNER_TEXT}
                  onlySettingsReq={true}
                  elementType={elementTypeObj[mediaTypeEnum.CALL_TO_ACTION]}
                  canHide={{ hideSwich: false }}
                  html={richTextProps.html}
                ></Element>
              </Element>
            )
            .toNodeTree();
          actions.addNodeTree(bannerNodeTree, currentNode.id, 2);
        }

        if (displayName !== CRAFT_ELEMENTS.LOGO) {
          const logoNodeTree = query
            .parseReactElement(
              <Element
                id={getUniqueId()}
                is={LogoComponent}
                canHide={{ hideSwich: false }}
                url={props?.logoUrl || '/dashboard/funnel-builder/default-logo.png'}
                elementType={elementTypeObj[mediaTypeEnum.LOGO]}
              ></Element>
            )
            .toNodeTree();
          actions.addNodeTree(logoNodeTree, currentNode.id, 0);
        }
      });
    }
  }, []);

  const src = enhanceStockImage(props.src as string);

  if (src !== img) {
    if (query.node(id).get()) {
      actions.history.ignore().setProp(id, state => (state.isLoading = true));
      setImg(src);
    }
  }

  const toggleLoading = () => {
    if (!query.node(id).get()) return;

    actions.history.ignore().setProp(id, state => (state.isLoading = false));
  };

  const loading = props.isLoading;

  return (
    <BasicElementWrapper>
      <div className={'img-element__wrapper'}>
        <div
          className={!loading ? 'img-element__overlay' : ''}
          style={{
            opacity: props.opacity,
            background: getElementColor(props.backgroundColor, theme)
          }}
        />
        <img
          width={'100%'}
          src={src || defaultImage}
          onLoadCapture={toggleLoading}
          onError={toggleLoading}
          onLoad={toggleLoading}
        />
        {loading && <ImageLoadingSpinner />}
        {!loading && (
          <div className="content-wrapper">
            <div className="content-wrapper__inner">{children}</div>
          </div>
        )}
      </div>
    </BasicElementWrapper>
  );
};

export const ImageComponentDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  src:
    'https://images.unsplash.com/photo-1519904981063-b0cf448d479e?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwxNDMyODR8MHwxfHNlYXJjaHwyMnx8bW91bnRhaW58ZW58MHx8fHwxNjYzNzQzMTA5&ixlib=rb-1.2.1&q=60&mtusphotoid=hvPB-UCAmmU&w=0.25&h=0.25&rect=608,0,9728,7296&fit=fill&fill=solid&fill-color=transparent&width=1000',
  opacity: 0.2,
  logoUrl: ''
};

const marks = {
  1: '10%',
  2: '20%',
  3: '30%',
  4: '40%',
  5: '50%',
  6: '60%',
  7: '70%',
  8: '80%',
  9: '90%',
  10: '100%'
};

export const ImageComponentSettings = () => {
  const [selectedElementTab, setSelectedElementTab] = useState('0');
  const theme = useFunnelTheme();
  const { actions, query } = useEditor();
  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id,
      props: node.data.props
    };
  });
  const parentNodeId = query.getSerializedNodes()[nodeId].parent;
  const parentNodeProps = query.getSerializedNodes()[parentNodeId].props;

  const handleChangeTab = (value: string) => {
    setSelectedElementTab(value);
  };

  const setImageSrc = (src: string, shape: string) => {
    setProp((props: any) => {
      props.src = src;
      props.form = shape;
    });
  };
  const [pexelsImage, setPexelsImage] = useState();

  const src = enhanceStockImage(props.src);

  return !parentNodeProps?.canHide?.hideSwich ? (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card settings-card__no-padding" title="Bild" bordered={false}>
        <Tabs
          className="tab-container-style builder__settings-sidebar__container"
          activeKey={selectedElementTab}
          onChange={handleChangeTab}
        >
          <TabPane tab="Text KI" className="single-tab-container mt-4 mx-1" key={'0'}>
            <Col span={24} className="mt-1 mb-3 px-4">
              <Row className="builder__settings-sidebar__row ">
                <Col span={24}>
                  <BuilderSettingsTitle title="Overlay-Transparenz" />
                </Col>
                <Col span={24}>
                  <Slider
                    className="builder-slider-style"
                    marks={marks}
                    min={1}
                    max={10}
                    tooltipVisible={false}
                    defaultValue={defaultImageOpacity.key}
                    value={getKeyByValue(props.opacity, ImageAvailableOpacity).key}
                    onChange={value => {
                      actions.setProp(
                        nodeId,
                        (props: any) =>
                          // @ts-ignore
                          (props.opacity = getValueByKey(value, ImageAvailableOpacity).value)
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Overlay-Farbe" />
                </Col>
                <Col span={24}>
                  <ColorPicker
                    className="ad-builder-color-picker"
                    color={getElementColor(props.backgroundColor, theme)}
                    onChange={color => {
                      actions.setProp(nodeId, (props: any) => (props.backgroundColor = color));
                    }}
                    showColorTypeBtns={true}
                  >
                    <>
                      <div
                        className={'color-circle__circle'}
                        style={{ background: getElementColor(props.backgroundColor, theme) }}
                      ></div>
                      <span>
                        <b>{'Farbe'}</b>
                      </span>
                    </>
                  </ColorPicker>
                </Col>
              </Row>

              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Eigenes Bild" />
                  <ImageUploadV3
                    showRatioSelector={false}
                    previewImage={src}
                    onChange={(url: string, shape: any) => {
                      setImageSrc(url, shape);
                      setPexelsImage(undefined);
                    }}
                    minWidth={800}
                    minHeight={700}
                    uploadedImage
                    grid
                    restrictPosition={true}
                    existingImageURL={pexelsImage}
                  />
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tab="Eigene Vorlagen" key={'1'}>
            <MediaSuggestionsSideBar />
          </TabPane>
        </Tabs>
      </Card>
    </Col>
  ) : (
    <div className="empty-page-list--container">
      <EyeInvisibleOutlined
        className="mb-3"
        style={{ color: '#e0e0e0', fontSize: 60 }}
        onClick={() => {
          actions.setProp(
            parentNodeId,
            (props: any) => (props.canHide.hideSwich = !parentNodeProps?.canHide?.hideSwich)
          );
        }}
      />
      <h4>Element verborgen</h4>
      <p>Aktivere es über das Augesymbol, um es wieder anzuzeigen.</p>
    </div>
  );
};

ImageComponent.craft = {
  name: CRAFT_ELEMENTS.IMAGE,
  props: ImageComponentDefaultProps,
  related: {
    settings: ImageComponentSettings
  }
};

export default ImageComponent;
