import { Select } from 'antd';
import React from 'react';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';

interface Props {
  value?: number;
  onChange?: (id: number) => void;
  showActiveFunelsOnly?:boolean
}

const FunnelSelectList = ({ value, onChange = () => {} ,showActiveFunelsOnly}: Props) => {
  const funnels = useAllFunnels();
  let data = funnels
  if(showActiveFunelsOnly){
     data = funnels?.filter(e=>e.enabled || e.id==value);
  }

  return (
    <Select
    {...(process.env.NODE_ENV === 'test' && { open: true })}
    data-testid="status-selection"
      showSearch
      value={value}
      placeholder='Ziel...'
      onChange={data => {
        onChange(data);
      }}
      filterOption={(inputValue, option) => {
        return (
          !!option?.label
            ?.toString()
            .toLocaleLowerCase()
            .includes(inputValue.toLocaleLowerCase()) || option?.value == inputValue
        );
      }}
      style={{ width: '100%', minWidth: '100px' }}
      options={data.map((single: any) => {
        return {
          label: single.title,
          value: single.id
        };
      })}
    />
  );
};

export default FunnelSelectList;
