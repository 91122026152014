import { aiTokenUsage } from '../../constants';
import { SubscriptionInfo } from '../../UI/utils/userTypes';

export const isUserOutOfAiTokens = (
  type: keyof typeof aiTokenUsage,
  userSubscriptionInfo: SubscriptionInfo
) => {
  const { remainingChatAiTokens, remainingAdditionalChatAiTokens } = userSubscriptionInfo;

  const tokenUsage = aiTokenUsage[type] ?? 5; // Ensure a fallback value

  return {
    isBaseQoutaExceeded: remainingChatAiTokens <= tokenUsage,
    isOutOfAiTokens:
      remainingChatAiTokens <= tokenUsage && remainingAdditionalChatAiTokens <= tokenUsage
  };
};

export const calculateTokenVariables = (subscriptionInfo: any, usedTokens: number) => {
  const isBaseQuotaExceeded = subscriptionInfo.maxChatAiTokens <= 5;

  const remainingTokens = isBaseQuotaExceeded
    ? Math.max(subscriptionInfo.remainingAdditionalChatAiTokens - usedTokens, 0)
    : Math.max(subscriptionInfo.remainingChatAiTokens - usedTokens, 0);

  const variables: any = isBaseQuotaExceeded
    ? { remainingAdditionalChatAiTokens: remainingTokens }
    : { remainingChatAiTokens: remainingTokens };

  return variables;
};

export const getTokenInformation = (extraTokens: number, subscriptionInfo: any) => {
  const {
    maxChatAiTokens,
    remainingChatAiTokens,
    remainingAdditionalChatAiTokens
  } = subscriptionInfo;

  const totalMaxTokens = maxChatAiTokens + extraTokens;
  const usedTokens = remainingChatAiTokens;

  return {
    progress: (usedTokens / totalMaxTokens) * 100,
    currentTokens: usedTokens,
    maxTokens: totalMaxTokens
  };
};
