import { Avatar, Button, Carousel } from 'antd';
import React from 'react';
import { RiTeamFill } from 'react-icons/ri';
import { GrSpotify } from 'react-icons/gr';
import { SiApplepodcasts } from 'react-icons/si';

interface LoginTeaserCarouselProps {}

const LoginTeaserCarousel: React.FC<LoginTeaserCarouselProps> = () => {
  return (
    <Carousel autoplay speed={2000} pauseOnHover autoplaySpeed={15000}>
      <div className="login__carousel__item">
        <div className="login__carousel__item__top">
          <img className="podcast-cover" src="/dashboard/login/podcast.png" />
        </div>
        <h2>Social Recruiting Insider Podcast</h2>
        <p>
          Der Podcast für Recruiting-Agenturen und Personalverantwortliche. Erfahre von
          Branchenexperten ihre Erfolge und Strategien, um deine Recruiting-Kampagnen zu bauen und
          zu optimieren. So setzt du das um, was wirklich funktioniert.
        </p>
        <Button
          className="mx-2"
          icon={<GrSpotify />}
          target="_blank"
          href="https://open.spotify.com/show/1V30c4wEestUeNY3WIJrvH"
        >
          Spotify Podcast
        </Button>
        <Button
          icon={<SiApplepodcasts />}
          target="_blank"
          href="https://podcasts.apple.com/us/podcast/social-recruiting-insider/id1674631332"
        >
          Apple Podcast
        </Button>
      </div>
      <div className="login__carousel__item">
        <div className="login__carousel__item__top">
          <div className="avatar-group">
            <div className="avatar-group__inner">
              <Avatar size={40} src="/dashboard/login/community-member-1.jpg" />
              <Avatar size={40} src="/dashboard/login/community-member-5.jpg" />
              <Avatar size={40} src="/dashboard/login/community-member-3.jpg" />
              <Avatar size={40} src="/dashboard/login/community-member-4.jpg" />
              <Avatar size={40} src="/dashboard/login/community-member-2.jpg" />
              <Avatar size={40} src="/dashboard/login/community-member-6.jpg" />
            </div>
            <span className="avatar-group__count">+250 Mitglieder</span>
          </div>
        </div>
        <h2>Social Recruiting Insiders Community</h2>
        <p>
          Für Agenturen & Recruiter: Hol dir die neusten Insights, um Top-Talente zu gewinnen.
          Stelle Fragen rund um das Thema Recruiting und profitiere vom Wissen der Community.
        </p>
        <Button
          icon={<RiTeamFill />}
          target="_blank"
          href="https://www.skool.com/social-recruiting"
        >
          Jetzt kostenfrei beitreten
        </Button>
      </div>
    </Carousel>
  );
};

export default LoginTeaserCarousel;
