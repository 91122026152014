import { BuilderBase, CardTypes } from '../../config/builderUi';
import { DraggingElement, IndexedElement, MetaData } from '../../interfaces/builderSliceTypes';

export interface AgencyOSBuilderPageDataType {
  id: number;
  craftState: string;
  type: string;
}

export interface SetAgencyOSBuilderPageInput {
  id: number;
  craftState: string;
}

export interface AgencyDashboardBase {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  uniqueIdentifier: string;
}

export interface AgencyDashboard extends AgencyDashboardBase {
  isEditing: boolean;
}

export interface AgencyOSBuilderStateChecksums {
  agencyOSBuilderPages: string | null;
  agencyDashboard: string | null;
  metaData: string | null;
  agencyOSTheme: string | null;
}

export interface SaveCompleteAgencyOSBuilderThunkPayloadType {
  callbackOnSuccess?: () => void;
}

export interface AgencyOSThemeType {
  primaryColor: string;
  primaryContrastColor: string;
  accentColor: string;
  headlineColor: string;
  textColor: string;
  fontFamily: string;
  fontLink?: string;
  headlineFontLink?: string;
  headlineFontFamily?: string;
  cardTextColor?: string;
}
export interface AllDomains {
  name: string;
  agencyDashboardId: number;
}

export enum AGENCY_OS_THEME_KEYS {
  PRIMARY_COLOR = 'primaryColor',
  PRIMARY_CONTRAST_COLOR = 'primaryContrastColor',
  ACCENT_COLOR = 'accentColor',
  HEADLINE_COLOR = 'headlineColor',
  TEXT_COLOR = 'textColor',
  FONT_FAMILY = 'fontFamily',
  DEFAULT_GRADIENT = 'defaultGradient',
}

export interface AgencyOSBuilderState {
  configData: Array<BuilderBase>;
  selectedBase: BuilderBase & IndexedElement;
  selectedCard: CardTypes | null;
  selectedElementTab: string;
  agencyOSBuilderPages: AgencyOSBuilderPageDataType[];
  allDomains: AllDomains[];
  selectedAgencyBuilderPageId: number | null;
  draggingElement: DraggingElement;
  agencyDashboard: AgencyDashboard;
  loaders: Loaders;
  lastSaveSuccessful: boolean;
  shouldSaveAgencyOSBuilder: boolean;
  ui: {
    agencyOSBuilderIsEditingModalVisibility: boolean;
  };
  checksums: AgencyOSBuilderStateChecksums;
  agencyOSTheme: AgencyOSThemeType;
  currentlyEditingTheme: AgencyOSThemeType;
  metaData: MetaData;
}

export interface Loaders {
  isAgencyOSBuilderSaved: boolean;
  mainAgencyOSBuilderLoader: boolean;
}
