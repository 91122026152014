import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDeleteAdMedialTemplateLoading } from '../../../../redux/adBuilderSlice';
import BuilderSettingsTitleWithDelete from '../../../../../components/sharedUI/BuilderSettingsTitleWithDelete';

const SingleMediaTemplate: React.FC<{
  template: {
    imageUrl: string;
    id: number;
    craftState: string;
    name: string;
  };
  handleReplaceTemplate: () => void;
  handleDelete?: () => void;
  isActive: boolean;
}> = ({ handleDelete, isActive, template, handleReplaceTemplate }) => {
  const { imageUrl, name } = template;
  const [deleteLocalLoading, setDeleteLocalLoading] = useState(false);
  const deleteAdMedialTemplateLoading = useDeleteAdMedialTemplateLoading();

  useEffect(() => {
    !deleteAdMedialTemplateLoading && deleteLocalLoading && setDeleteLocalLoading(false);
  }, [deleteAdMedialTemplateLoading]);

  return (
    <div className="template-block py-3 px-4">
      {name && (
        <BuilderSettingsTitleWithDelete
          title={name || ''}
          classNames="template-block__name small-label"
          handleDelete={() => {
            setDeleteLocalLoading(true);
            handleDelete && handleDelete();
          }}
          loading={deleteLocalLoading}
          disabled={template.id >= 100000}
        />
      )}

      <div className="template-block__inner default-opacity-hover" onClick={handleReplaceTemplate}>
        {isActive && (
          <div className="template-block__inner__active-overlay">
            <CheckCircleOutlined />
          </div>
        )}
        <img width="100%" src={imageUrl} style={{ height: 'initial' }} />
      </div>
    </div>
  );
};

export default SingleMediaTemplate;
