import { Col, Row } from 'antd';
import React from 'react';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import { getElementColor } from '../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

interface VoiceMessagePresetProps {
  element: string;
  onChange: (key: string, value: any) => void;
  values: {
    color: string;
  };
}

const VoiceMessagePreset = ({ onChange, values }: VoiceMessagePresetProps) => {
  const theme = useCurrentlyEditingBuilderTheme();

  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title="Farbe" />
        <BuilderColorPickerButton
          color={getElementColor(values.color, theme)}
          onChange={(color, colorKey) => {
            onChange("color", colorKey);
          }}
          showColorTypeBtns={false}
        />
      </Col>
    </Row>
  );
};

export default VoiceMessagePreset;
