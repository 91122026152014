import { RatingInterface } from '../components/Event/BookingRating';

export const getColorForRating = (rating: number) => {
  if (rating >= 4) {
    return '#4ece3d';
  } else if (rating >= 3) {
    return '#FFD700';
  } else {
    return 'red';
  }
};

export const calculateAverageRating = (ratings: RatingInterface[]): RatingInterface => {
  if (!ratings || ratings.length === 0) {
    return {
      competancy: 0,
      communicationSkills: 0,
      teamCompatibility: 0
    };
  }

  const total = ratings.reduce(
    (acc, rating) => {
      acc.competancy += rating.competancy;
      acc.communicationSkills += rating.communicationSkills;
      acc.teamCompatibility += rating.teamCompatibility;
      return acc;
    },
    { competancy: 0, communicationSkills: 0, teamCompatibility: 0 }
  );

  return {
    competancy: total.competancy / ratings.length,
    communicationSkills: total.communicationSkills / ratings.length,
    teamCompatibility: total.teamCompatibility / ratings.length
  };
};
