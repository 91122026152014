import { FAQ } from "../AdBuilder/interfaces/TextTypeInterface";



export const getRawState = (injectable: string, type: string) => {
    const content = type === FAQ.QUESTION ?
        `<p><span style="font-size: 1.5em"><span class="text-style" style="color: ;"><strong>${injectable}</strong></span></span></p>` :
        `<p><span class="text-style" style="color: ;">${injectable}</span></p>`;

    return content;
};
