import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
      userInfo {
        coachId
        firstName
        lastName
        email
      }
    }
  }
`;

export const REGISTRATION = gql`
  mutation registerViaEmailAndPassword($input: EmailRegisterProperties!) {
    registerViaEmailAndPassword(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
      userInfo {
        coachId
        firstName
        lastName
        email
      }
    }
  }
`;

export const SEND_CHANGE_PASSWORD_TOKEN = gql`
  mutation sendPasswordChangeToken($email: String!) {
    sendPasswordChangeToken(email: $email) {
      email
    }
  }
`;

export const CHANGE_PASSWORD_VIA_TOKEN = gql`
  mutation changePasswordViaToken($token: String!, $password: String!) {
    changePasswordViaToken(token: $token, password: $password) {
      email
    }
  }
`;
