import { TEMPLATE_KEYS } from '../components/elements/Container/helper';
import { FUNNEL_THEME_KEYS } from './builderSliceTypes';
import { defaultVoiceMessageSize } from './VoiceMessageSizes';

export const buttonPresetsInitialValues = {
  italic: false,
  underline: false,
  boldText: false,
  borderRadius: '30px',
  size: 50,
  isDefaultGradient: false,
  gradientSettings: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT
};

export const imagePresetsInitialValues = {
  borderRadius: '0px'
};

export const videoPresetsInitialValues = {
  borderRadius: '0px'
};

export const voiceMessagePresetsInitialValues = {
  width: defaultVoiceMessageSize.value,
  color: FUNNEL_THEME_KEYS.ACCENT_COLOR
};

export const imageCarousalPresetsInitialValues = {
  borderRadius: '0px'
};

export const choicePresetsInitialValues = {
  templateKey: TEMPLATE_KEYS.ICON_ON_RIGHT,
  borderRadius: '10px',
  isDefaultGradient: false,
  gradientSettings: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT,
  boldText: false,
  italic: false,
  underline: false,
};

export const faqPresetsInitialValues = {
  borderRadius: '10px',
  backgroundColor: FUNNEL_THEME_KEYS.CARD_BACKGROUND_COLOR,
  cardIconColor: FUNNEL_THEME_KEYS.CARD_ICON_COLOR,
  CardTextColor: FUNNEL_THEME_KEYS.CARD_TEXT_COLOR,
  icon: 'IoIosArrowUp'
};
