import { Divider, Steps } from 'antd';
import React, { FC } from 'react';
import { CSVUploadStepsEnum } from './CSVUploadModal';

const steps = [
  { title: 'Ziel', description: "Wähle Funnel und Status für den Import"},
  { title: 'Hochladen', description: 'Wähle die CSV Datei aus' },
  { title: 'Zuordnung', description: 'Ordne die Felder zu.' }
];

interface CSVStepsProps {
  currentStep: CSVUploadStepsEnum;
}

const CSVSteps: FC<CSVStepsProps> = ({ currentStep }) => {
  const { Step } = Steps;

  return (
    <Steps current={currentStep} className="mb-2" direction="horizontal">
      {steps.map((step, index) => (
        <Step key={index} title={step.title} description={step.description} />
      ))}
    </Steps>
  );
};

export default CSVSteps;
