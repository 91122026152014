import React, { useState } from 'react';
import { UploadProps } from 'antd/es/upload';
import { Button, Col, message, Row, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { uploadFileMessage } from '../../../config/messages';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';
import { BookingInterface } from '../../container/Events';
import { UploadFile as Utype } from 'antd/es/upload/interface';
import { useMutation } from '@apollo/react-hooks';

export const UPLOAD_FILE = gql`
  mutation($files: [FileData!]!, $areSignedUrlsRequired: Boolean, $homeDirectory: String) {
    uploadFile(
      input: $files
      areSignedUrlsRequired: $areSignedUrlsRequired
      homeDirectory: $homeDirectory
    ) {
      id
      name
      fileType
      fileLink
      expirationDate
      awsKey
      signedUrl
    }
  }
`;

interface UploadFileProps {
  accept: string[];
  multiple: boolean;
  selectedEvent?: { event: BookingInterface };
  innerText?: string;
  title?: string;
  handleSubmit?: (files: File[]) => void;
  onSelectFile?: (File: File) => void;
  disabled?: boolean;
  loading?: boolean;
  fileInputChangeEvent?: (files: File[] | null) => void;
  refetch?: () => void;
  shouldDefaultSubmit?: boolean;
}

const UploadFile = ({
  accept,
  multiple,
  selectedEvent,
  innerText,
  title,
  handleSubmit,
  onSelectFile,
  disabled,
  loading: loadingProp,
  fileInputChangeEvent,
  refetch,
  shouldDefaultSubmit
}: UploadFileProps) => {
  const [fileList, setFileList] = useState<Utype[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadFiles] = useMutation(UPLOAD_FILE);
  const subscriptionInfo = useSubscriptionInfo();
  const maxSize = subscriptionInfo?.fileSizeLimitPerUploadInMB || 10;
  const defaultExpiry = new Date().setMonth(new Date().getMonth() + 3); // 3 months

  const props: UploadProps = {
    name: 'file',
    accept: accept.join(','),
    fileList,
    disabled,
    multiple: multiple,
    onRemove: file => {
      const newFileList = fileList.filter(f => f.uid !== file.uid);
      setFileList(newFileList);
      if (fileInputChangeEvent) {
        fileInputChangeEvent(
          newFileList.length > 0
            ? (newFileList.map(f => f.originFileObj).filter(Boolean) as File[])
            : null
        );
      }
      return true;
    },
    onChange: ({ file, fileList: newFileList }) => {
      if (file.status === 'uploading') {
        setFileList(multiple?newFileList:[file]);
      }
      if (file.status === 'done') {
        setFileList(multiple?newFileList:[file]);
        const validFiles = newFileList
          .filter(f => f.status === 'done' && f.originFileObj instanceof File)
          .map(f => f.originFileObj as File);

        if (validFiles.length > 0) {
          if (fileInputChangeEvent) {
            fileInputChangeEvent(validFiles);
          }

          if (shouldDefaultSubmit) {
            handleSubmit?.(validFiles);
          }

          if (onSelectFile && !multiple) {
            onSelectFile(validFiles[0]);
          }
        }
      }
      if (file.status === 'error') {
        if (multiple) {
          const filteredFiles = newFileList.filter(f => f.uid !== file.uid);
          setFileList(filteredFiles);
        } else {
          setFileList([]);
        }
      }
    },
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    beforeUpload: file => {
      const isValidFormat = accept.includes(file.type);
      if (!isValidFormat) {
        message.error(uploadFileMessage.uploadFileTypeErrorMessage(accept.join(', ')));
        return false;
      }

      const isValidSize = file.size <= maxSize * 1024 * 1024;
      if (!isValidSize) {
        message.error(uploadFileMessage.uploadFileSizeErrorMessage(maxSize));
        return false;
      }

      return true;
    }
  };

  const getOriginalFileList = () =>
    fileList
      .filter(f => f.status === 'done' && f.originFileObj instanceof File)
      .map(f => f.originFileObj as File);

  const handleDefaultSubmit = async (): Promise<void> => {
    if (fileList.length === 0) {
      message.error(uploadFileMessage.uploadFileErrorMessage);
      return;
    }

    try {
      setLoading(true);

      const filesData = fileList.map(file => ({
        file: file.originFileObj,
        name: file.name,
        expirationDate: new Date(defaultExpiry),
        bookingId: selectedEvent?.event?.id
      }));

      const resFile = await uploadFiles({
        variables: {
          files: filesData,
          areSignedUrlsRequired: true
        }
      });
      setLoading(false);
      message.success(uploadFileMessage.uploadSuccessfulMessage);
      refetch?.();
      setFileList([]);
    } catch (error) {
      console.error(error);
      message.error(uploadFileMessage.uploadFileSizeGeneralMessage);
      setLoading(false);
    }
  };

  return (
    <div className="upload-file-container" data-testid="upload-file-container">
      <Row justify="center" className="mb-4" style={{ width: '100%' }}>
        <Col xs={23} sm={12}>
          <Upload.Dragger {...props} listType="text">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <h3>{title || 'Datei Hochladen'}</h3>
            <p className="ant-upload-text" data-testid="upload-text">
              {innerText || uploadFileMessage.uploadMessage}
            </p>
          </Upload.Dragger>
        </Col>
      </Row>
      {!!fileList.length && !shouldDefaultSubmit && (
        <Row justify="end" className="mb-4 d-flex">
          <Button
            className="rounded"
            type="primary"
            disabled={disabled}
            onClick={() => {
              handleSubmit ? handleSubmit(getOriginalFileList()) : handleDefaultSubmit();
            }}
            loading={loadingProp || loading}
            data-testid="upload-button"
          >
            Hochladen
          </Button>
        </Row>
      )}
    </div>
  );
};

export default UploadFile;
