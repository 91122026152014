import React from 'react';
import { Card } from 'antd';
import { EventTable } from '../components/EventTable';
import { ActionEvents } from '../../Builder/hooks/useBookings';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useBookingState } from '../redux/slice';

export interface BookingStatus {
  color: string;
  status: string;
  value: string;
  id: number;
}

export interface BookingTag {
  id?: number;
  name: string;
  color: string;
  coachId?: number;
  agencyCoachId?: number;
}

export interface BookingActionEventInterface {
  id: number;
  eventTime: Date;
  actionEventType: ActionEvents;
}

export interface BookingInterface {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  eventStart: string;
  eventEnd: string;
  choices: BookingChoiceInterface[];
  createdAt: string;
  updatedAt: string;
  version: string;
  sortOrder: number;
  funnelId?: number;
  funnelName?: string;
  bookingActionEvent: BookingActionEventInterface;
  tags: BookingTag[];
  type: string;
  bookingStatus?: {
    id: number;
    status: string;
    value: string;
    color: string;
  };
  file: {
    fileLink: string;
    name: string;
    expirationDate: Date;
  }[];
  bookingRatings: [
    {
      coachId: number;
      communicationSkills: number;
      competancy: number;
      teamCompatibility: number;
    }
  ];
}

export interface SelectedEvent {
  event: BookingInterface;
  index: number;
}

export interface ProgressCollectorInterface {
  key: string;
  firstActivity: string;
  leadQualifierId?: number;
  durationInSeconds: number;
  choices: ProgressCollectorChoiceInterface[];
}

export interface BookingChoiceInterface extends ProgressCollectorChoiceInterface {
  leadQualifierId: number;
}

export interface ProgressCollectorChoiceInterface {
  choiceId: number;
  stringValue: string;
  numberValue: number;
  voiceMessageAWSKey?: string;
}

export interface LeadQualifierInterface {
  id?: number;
  question: string;
  messageTitle: string;
  type: string;
  choices: LeadQualifierChoiceInterface[];
}

export interface LeadQualifierChoiceInterface {
  id?: number;
  title?: string;
  unit?: string;
}

export interface StateInterface {
  bookingFilter: BookingFilterInterface;
  initiated: boolean;
}

export interface StatusInterface {
  color: string;
  id: number;
  sortOrder: number;
  status: string;
  value: string;
}

export interface BookingFilterInterface {
  start: string;
  end: string;
  funnelIds: number[];
  tagIds: number[];
  type: string;
  searchQuery: string;
  statusId: number[];
}

export interface EventStatus {
  status: string;
  value: string;
  color: string;
}

function Events() {
  const funnels = useAllFunnels();
  const {
    loaders: { bookingLoading }
  } = useBookingState();

  return (
    <div className="events">
      <Card className="events-container">
        <EventTable loading={bookingLoading} funnels={funnels} />
      </Card>
    </div>
  );
}

export enum BookingType {
  NORMAL = 'NORMAL',
  MANUAL = 'MANUAL',
  CSV = 'CSV'
}

export default Events;
