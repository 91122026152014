import { BookingFilterInterface } from '../container/Events';

export const getFilters = (bookingFilters: BookingFilterInterface) => {
  const filter = {
    start: bookingFilters.start,
    end: bookingFilters.end,
    funnelIds: bookingFilters?.funnelIds,
    type: bookingFilters.type
  };

  const { type: dummyType, ...filterForTracking } = filter;

  const variables = {
    filter1: {
      ...filter,
      includeChoices: true
    },
    filter2: {
      ...filterForTracking,
      minProgressCollectorCount: 3,
      funnelIds: [0]
    },
    filter3: {
      showAll: !bookingFilters?.funnelIds?.length,
      funnelIds: bookingFilters.funnelIds
    }
  };

  return { variables };
};
