import { Alert, Button, Checkbox, Form, Input, message, Modal, Select, Switch, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { compressBase64Image, getImageDimensionsFromFile } from './helper/cropImage';
import { getObjectId } from '../helper/id';
import { ImageUploadV2Messages } from '../config/messages';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGE } from '../Funnel/helper/uploadImages';
import DefaultSmallLoader from '../SharedUI/components/DefaultSmallLoader';
import {
  CropperRef,
  Cropper,
  CircleStencil,
  RectangleStencil,
  ImageRestriction,
  Priority
} from 'react-advanced-cropper';
import { CloseOutlined } from '@ant-design/icons';
import 'react-advanced-cropper/dist/style.css';
import ImageSizeSelectorV3 from './CropSizeSelectors/ImageSizeSelectorV3';
import { defaultFileFieldValidation } from '../UI/utils/formValidation';
import { useAppDispatch } from '../redux/hooks';
import { getAllImagesTagsThunk, getImagesWithPaginationThunk } from '../Library/redux/libraryThunk';
import { useAllTags } from '../Library/redux/librarySlice';
import ConfirmationForCropper from './ConfirmationForCropper';
import { pixelImageQuality } from '../constants';
import { FaImages } from 'react-icons/fa';
import ImageSelectModal from '../Library/components/ImageSelectModal';

export enum CropperShape {
  RECTANGLE = 'rect',
  ROUND = 'round'
}

export interface CropperSettings {
  aspectRatio?: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  imageRestriction?: ImageRestriction;
  stencilType: CropperShape;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  scaleImage?: boolean;
  grid?: boolean;
}

type Props = {
  className?: string;
  onChange: (
    data: string | any,
    imageShape: CropperShape,
    aspectRatio?: { height: number; width: number }
  ) => any;
  maxWidth?: number;
  maxHeight?: number;
  previewImage?: string;
  quality?: number;
  shape?: CropperShape;
  grid?: boolean;
  uploadedImage?: boolean;
  loading?: boolean;
  restrictPosition?: boolean;
  existingImageURL?: string | undefined;
  minZoom?: number;
  onModalCancel?: () => void;
  minHeight: number;
  minWidth: number;
  possibleAspectRatios?: { height: number; width: number }[];
  circleEnabled?: boolean;
  rectangleEnabled?: boolean;
  freeSelectionEnabled?: boolean;
  showRatioSelector?: boolean;
  setImageUploadLoadingForParent?: (loading: boolean) => void;
  cropperIsNotMandatoryAfterSelectionFromLibrary?: boolean;
  fixWidth?: boolean
};
export interface ImageCropperUITypes {
  shape: 'rect' | 'round';
  aspectRatio: { height: number; width: number };
}

const ImageUploadV3 = ({
  className,
  onChange,
  maxWidth: propMaxWidth = 1365,
  maxHeight: propMaxHeight = 2000,
  previewImage,
  quality,
  shape = CropperShape.RECTANGLE,
  grid,
  uploadedImage,
  loading,
  existingImageURL,
  minHeight: propMinHeight,
  minWidth: propMinWidth,
  onModalCancel = () => {},
  circleEnabled = true,
  rectangleEnabled = true,
  freeSelectionEnabled = true,
  possibleAspectRatios = [],
  showRatioSelector = true,
  setImageUploadLoadingForParent,
  cropperIsNotMandatoryAfterSelectionFromLibrary = true,
  fixWidth = false
}: Props) => {
  const cropperRef = useRef<CropperRef>(null);
  const [cropLoading, setCropLoading] = useState(false);
  const [uploadImage, { loading: imageUploadLoading }] = useMutation(UPLOAD_IMAGE);
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(previewImage);
  const [uid] = useState(getObjectId());
  const [images, setImages] = useState<{ fileList: any[] }>({
    fileList: []
  });
  const [imageToCrop, setImageToCrop] = useState<string>('');
  const [originalFileType, setOriginalFileType] = useState('');
  const [isImageCropRequiredModalVisible, setIsImageCropRequiredModalVisible] = useState<boolean>(
    false
  );
  const [selectedImageFromLibrary, setSelectedImageFromLibrary] = useState<string>('');
  const [showFieldsNeededToSaveImageInLibrary, setShowFieldsNeededToSaveImageInLibrary] = useState(
    false
  );
  const [cropLoadingImage, setCropLoadingImage] = useState<boolean>(true);
  const [pixelImage, setPixelImage] = useState<string>('');
  const checkIfExistingImage = existingImageURL || pixelImage;
  const [settings, setSettings] = useState<CropperSettings>({
    aspectRatio: propMinWidth / propMinHeight,
    minAspectRatio: undefined,
    maxAspectRatio: undefined,
    imageRestriction: ImageRestriction.fitArea,
    minWidth: undefined,
    maxWidth: undefined,
    minHeight: undefined,
    maxHeight: undefined,
    scaleImage: true,
    grid: true,
    stencilType: shape || CropperShape.RECTANGLE
  });

  const [imageSelectionVisible, setImageSelectionVisible] = useState(false);

  const [state, setState] = useState({ arbitraryUrlParameter: '', retriesLeft: 10 });
  const { arbitraryUrlParameter } = state;
  const imageUploadRef = useRef<HTMLDivElement>(null);

  const handleSetArbitraryUrlParameter = () => {
    if (state.retriesLeft < 1) return;

    setState({
      arbitraryUrlParameter: `?arbitraryUrlParameter=${Date.now() +
        Math.floor(Math.random() * 1000)}`,
      retriesLeft: state.retriesLeft - 1
    });
  };

  useEffect(() => {
    if (!imageToCrop) {
      setShowFieldsNeededToSaveImageInLibrary(false);
    }
  }, [imageToCrop]);

  useEffect(() => {
    setCropLoadingImage(true);
    setImageToCrop(existingImageURL || '');
  }, [existingImageURL]);

  useEffect(() => {
    if (setImageUploadLoadingForParent) setImageUploadLoadingForParent(imageUploadLoading);
  }, [imageUploadLoading]);

  useEffect(() => {
    if (previewImage) {
      setImages({
        fileList: [
          {
            uid,
            name: `preview-image__${uid}`,
            status: 'done',
            url: previewImage
          }
        ]
      });
      setImageUrl(previewImage);
    } else {
      setImages({
        fileList: []
      });
    }
  }, [previewImage]);

  useEffect(() => {
    if (!!imageUrl && !!imageUploadRef.current) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        if (imageUploadRef.current) {
          const dimensions = imageUploadRef.current.getBoundingClientRect();

          imageUploadRef.current.setAttribute('data-fix-width', fixWidth.toString());
          
          if (fixWidth) {
            const fitsContainer = img.width <= dimensions.width && img.height <= dimensions.height;
            const widerRatio = img.width / img.height > dimensions.width / dimensions.height;
            
            imageUploadRef.current.setAttribute('data-fits-container', fitsContainer.toString());
            imageUploadRef.current.setAttribute('data-wider-ratio', widerRatio.toString());
            imageUploadRef.current.setAttribute('data-taller-ratio', (!widerRatio).toString());
          }
          
          imageUploadRef.current.style.backgroundImage = `url(${imageUrl})`;
        }
      };
    }
  }, [imageUrl]);

  const dispatch = useAppDispatch();

  const availableTags = useAllTags();

  useEffect(() => {
    if (!availableTags.length) dispatch(getAllImagesTagsThunk());
  }, []);

  const handleUpload = async (image: any) => {
    const name = form.getFieldValue('name');
    const tags = form.getFieldValue('tags');
    uploadImage({
      variables: {
        input: [
          {
            imageURL: image,
            ...(showFieldsNeededToSaveImageInLibrary && { name, tags, showInLibrary: true })
          }
        ]
      }
    })
      .then(res => {
        const imageLink = res.data.uploadImages[0].imageLink;
        if (imageLink) {
          setImageUrl(imageLink);
          onChange(imageLink, stencilType);
        }
        if (showFieldsNeededToSaveImageInLibrary) {
          dispatch(getImagesWithPaginationThunk({}));
          dispatch(getAllImagesTagsThunk());
        }
      })
      .catch(e => {
        message.error('Das Bild konnte nicht hochgeladen werden. Bitte versuche es erneut.');
      });
  };

  const handleReplace = async () => {
    onChange('', CropperShape.RECTANGLE);
  };

  const handleChange = async ({ fileList }: any) => {
    if (!fileList[0]) {
      onChange('', settings.stencilType);
      setImages({ fileList: [] });
      return;
    }
    const file = fileList[fileList.length - 1];
    if (file) {
      form.setFieldsValue({ name: file.name });
    }
    const sizeInMb = file.size / 1024 ** 2;
    if (sizeInMb > 8) {
      message.error(ImageUploadV2Messages.max8MbImage);
      return false;
    }
    const dimensions = await getImageDimensionsFromFile(file.originFileObj);
    if (dimensions.width > propMinWidth && dimensions.height < propMinWidth) {
      message.error(ImageUploadV2Messages.imageNotBigEnough(propMinWidth, propMinHeight));
      return false;
    }
    setOriginalFileType(file.originFileObj.type);
    const imageFile = URL.createObjectURL(file.originFileObj);
    setImageToCrop(imageFile);
    return;
  };

  const handleCancel = () => {
    setImageToCrop('');
    onModalCancel();
    form.resetFields();
    setCropLoadingImage(true);
    setImageSelectionVisible(false);
  };
  const handleCropImage = async () => {
    if (cropperRef.current) {
      setCropLoading(true);
      const { height, left, top, width } = cropperRef.current.getCoordinates() || {};
      if (checkIfExistingImage) {
        let imgUrl = `${checkIfExistingImage}&rect=${left},${top},${width},${height}&fit=fill&fill=solid&fill-color=${'transparent'}`;
        if (settings.stencilType === CropperShape.ROUND) {
          imgUrl = imgUrl.replace('fm=jpg', 'fm=webp');
          imgUrl +=
            '&border=1,FFFFFF&border-radius=100000,100000,100000,100000&border-radius-inner=0,0,0,0';
        }
        imgUrl = imgUrl.replace(`q=${pixelImageQuality}`, 'q=80');
        onChange(imgUrl, settings.stencilType);
        setImageUrl(imgUrl);
        setCropLoading(false);
        setImages({
          fileList: []
        });
        setImageToCrop('');
        setPixelImage('');
        setImageSelectionVisible(false);
        return;
      }

      const url = await compressBase64Image(
        cropperRef.current.getCanvas()?.toDataURL(originalFileType) || '',
        quality || 1,
        propMaxWidth,
        propMaxHeight
      );
      setImageUrl(url);
      setCropLoading(false);
      if (uploadedImage) {
        handleUpload(url);

        form.resetFields();
      } else {
        onChange(url, stencilType);
        form.resetFields();
      }
      handleCancel();
    }
  };

  const handleModalClose = () => {
    setCropLoadingImage(true);
    if (!checkIfExistingImage) {
      return form.submit();
    } else {
      return handleCropImage();
    }
  };

  const handleImageRemove = () => {
    setImageUrl('');
    onChange('', settings.stencilType);
    setImages({ fileList: [] });
    if (imageUploadRef.current) {
      imageUploadRef.current.style.backgroundImage = '';
      imageUploadRef.current.style.height = '200px';
    }
  };

  const handleOkOfConfirmationForCropper = () => {
    setCropLoadingImage(true);
    setImageToCrop(selectedImageFromLibrary);
    setIsImageCropRequiredModalVisible(false);
  };

  const handleCancelOfConfirmationForCropper = () => {
    setImageUrl(selectedImageFromLibrary);
    onChange(selectedImageFromLibrary, stencilType);
    setIsImageCropRequiredModalVisible(false);
  };

  const { fileList } = images;

  const uploadProps = {
    showUploadList: false,
    response: false
  };

  const imageLoading = loading || imageUploadLoading;

  const {
    minHeight,
    minWidth,
    maxWidth,
    maxHeight,
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    imageRestriction,
    stencilType,
    scaleImage
    // grid
  } = settings;

  const stencilProps = {
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    grid
  };

  const tempImageToCrop = imageToCrop.includes('blob')
    ? imageToCrop
    : imageToCrop + (!!imageToCrop ? arbitraryUrlParameter : '');

  const handleUploading = async ({ fileList }: any) => {
    setShowFieldsNeededToSaveImageInLibrary(true);
    return handleChange({ fileList });
  };

  return (
    <div
      className={`image-upload-v4 ${className} ${
        !!imageUrl ? 'image-upload-v4__hover-active' : ''
      }`}
      ref={imageUploadRef}
    >
      {imageUrl && (
        <DeleteOutlined onClick={handleImageRemove} className="image-upload-v4__trash_icon" />
      )}
      <div
        className={`image-upload-v4__overlay ${imageUrl ? 'image-upload-v4__overlay-hover' : ''}`}
      >
        <ImageSelectModal
          visible={imageSelectionVisible}
          toggleVisible={setImageSelectionVisible}
          fileList={fileList}
          handleChange={handleUploading}
          stockImageQuality={pixelImageQuality}
          handleLibraryImage={image => {
            if (cropperIsNotMandatoryAfterSelectionFromLibrary) {
              setSelectedImageFromLibrary(image?.imageLink);
              setIsImageCropRequiredModalVisible(true);
            } else {
              setCropLoadingImage(true);
              setImageToCrop(image?.imageLink);
            }
          }}
          trigger={
            <div className="image-upload-v4__archive_button">
              <div className="image-upload-v4__archive_button__inner">
                <FaImages className="image-upload-v4__archive_button__inner__icon" />
                <p className="image-upload-v4__text">Archiv</p>
              </div>
            </div>
          }
          handleStockImage={img => {
            setPixelImage(img);
            setImageToCrop(img);
          }}
          classes="image-upload-v4__width-left cursor-pointer image-upload-v4__button_wrapper"
        />
        <Upload
          {...uploadProps}
          accept="gif,.jpg,.png,.jpeg"
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          className={'h-100 image-upload-v4__width-right image-upload__upload-field-' + uid}
        >
          {imageLoading ? (
            <DefaultSmallLoader loading={true} />
          ) : (
            <div>
              <CloudUploadOutlined className="image-upload-v4__archive_button__inner__icon" />
              <p className="image-upload-v4__text">Hochladen</p>
            </div>
          )}
        </Upload>
      </div>

      <Modal
        title="Zuschneiden"
        centered
        visible={!!imageToCrop}
        onOk={handleModalClose}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
        width={600}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Abbrechen
          </Button>,
          <Button key="submit" type="primary" loading={cropLoading} onClick={handleModalClose}>
            Speichern
          </Button>
        ]}
      >
        <>
          {!cropLoadingImage && showRatioSelector && (
            <ImageSizeSelectorV3
              settings={settings}
              setSettings={setSettings}
              circleEnabled={circleEnabled}
              rectangleEnabled={rectangleEnabled}
              freeSelectionEnabled={freeSelectionEnabled}
              possibleAspectRatios={possibleAspectRatios}
            />
          )}
          <div className="image-cropper-v3">
            {cropLoadingImage && <DefaultSmallLoader loading />}
            <Cropper
              onError={handleSetArbitraryUrlParameter}
              stencilComponent={
                stencilType === CropperShape.RECTANGLE ? RectangleStencil : CircleStencil
              }
              ref={cropperRef}
              src={tempImageToCrop}
              onReady={() => {
                setCropLoadingImage(false);
              }}
              style={{
                maxHeight: '500px'
              }}
              minHeight={minHeight}
              minWidth={minWidth}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              priority={
                imageRestriction === ImageRestriction.fillArea
                  ? Priority.visibleArea
                  : Priority.coordinates
              }
              stencilProps={stencilProps}
              transformImage={{
                adjustStencil: imageRestriction !== 'stencil' && imageRestriction !== 'none'
              }}
              backgroundWrapperProps={{
                scaleImage
              }}
              imageRestriction={imageRestriction}
            />
          </div>
          {!cropLoadingImage && (
            <Alert
              message="Tipp: Zum Zoomen nutze das Scrollrad an deiner Maus oder zwei finger an deinem Touchpad."
              type="info"
              showIcon
            />
          )}
          {!cropLoadingImage && !checkIfExistingImage && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <p>Dieses Bild im Archiv speichern</p>

                <Switch
                  size='small'
                  checked={showFieldsNeededToSaveImageInLibrary}
                  onChange={() => {
                    setShowFieldsNeededToSaveImageInLibrary(!showFieldsNeededToSaveImageInLibrary);
                  }}
                />
              </div>
            </>
          )}
          {!checkIfExistingImage && (
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={data => {
                handleCropImage();
              }}
              autoComplete="off"
            >
              {showFieldsNeededToSaveImageInLibrary && (
                <>
                  <Form.Item
                    label="Name"
                    name="name"
                    className="mb-1"
                    rules={defaultFileFieldValidation}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Tags" name="tags" className="mb-1">
                    <Select
                      mode="tags"
                      placeholder="z.B. Vorteile, Kunde XYZ ..."
                      options={availableTags.map((tag: any) => ({
                        label: tag.name,
                        value: tag.name
                      }))}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          )}
        </>
      </Modal>

      <ConfirmationForCropper
        visible={!!isImageCropRequiredModalVisible}
        onYes={handleOkOfConfirmationForCropper}
        onCancel={handleCancelOfConfirmationForCropper}
      />
    </div>
  );
};

export default ImageUploadV3;
