import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useBookingState } from '../redux/slice';
import { BookingInterface } from '../container/Events';

export const useFilteredBookings = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { bookings } = useBookingState();

  return useMemo(() => {
    let filtered = bookings;

    const statusIdParam = queryParams.get('statusId');
    const tagIdParam = queryParams.get('tagIds');
    const typeParam = queryParams.get('type');
    const searchQueryParam = queryParams.get('searchQuery');

    if (statusIdParam) {
      const selectedStatusIds = new Set(statusIdParam.split(',').map(Number));
      if (!selectedStatusIds.has(0)) {
        filtered = filtered.filter(
          (booking: BookingInterface) =>
            booking.bookingStatus && selectedStatusIds.has(booking.bookingStatus.id)
        );
      }
    }

    if (tagIdParam) {
      const selectedTagIds = new Set(tagIdParam.split(',').map(Number));

      filtered = filtered.filter(booking =>
        booking.tags?.some(tag => tag.id !== undefined && selectedTagIds.has(tag.id))
      );
    }

    if (typeParam === 'WITH_APPOINTMENT') {
      filtered = filtered.filter(
        booking => booking.eventStart !== null && booking.eventEnd !== null
      );
    } else if (typeParam === 'WITHOUT_APPOINTMENT') {
      filtered = filtered.filter(
        booking => booking.eventStart === null && booking.eventEnd === null
      );
    }

    if (searchQueryParam) {
      const lowerCaseSearch = searchQueryParam.toLowerCase();

      filtered = filtered.filter(
        booking =>
          (booking.name && booking.name.toLowerCase().includes(lowerCaseSearch)) ||
          (booking.email && booking.email.toLowerCase().includes(lowerCaseSearch))
      );
    }

    return filtered;
  }, [bookings, location]);
};
