import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_SUBSCRIPTION_INFO = gql`
  query getSubscriptionInfo {
    getSubscriptionInfo {
      status
      paddle_cancel_url
      paddle_update_url
      paddle_next_bill_date
      paddle_cancellation_effective_date
      paddle_unit_price
      paddle_subscription_plan_id
      paddle_user_id
      maxFunnels
      remainingFunnels
      maxVisits
      remainingVisits
      maxDomains
      remainingDomains
      remainingSubdomains
      agencyDashboardEnabled
      brandingEnabled
      customBrandingEnabled
      maxAgencyCustomers
      remainingAgencyCustomers
      fileSizeLimitPerUploadInMB
      maxSmsCredits
      remainingSmsCredits
      maxWidgets
      remainingWidgets
      maxGoogleJobs
      remainingGoogleJobs
      trialEndDate
      remainingChatAiTokens
      maxChatAiTokens
      remainingAdditionalChatAiTokens
    }
  }
`;

export const GET_AGENCY_CUSTOMER_SUBSCRIPTION_INFO = gql`
  query getAgencyCustomerSubscriptionInfo {
    getSubscriptionInfo {
      status
      paddle_unit_price
      paddle_subscription_plan_id
      paddle_user_id
      maxFunnels
      remainingFunnels
      maxVisits
      remainingVisits
      maxDomains
      remainingDomains
      remainingSubdomains
      agencyDashboardEnabled
      brandingEnabled
      customBrandingEnabled
      maxAgencyCustomers
      remainingAgencyCustomers
      fileSizeLimitPerUploadInMB
      maxSmsCredits
      remainingSmsCredits
      maxWidgets
      remainingWidgets
      maxGoogleJobs
      remainingGoogleJobs
      trialEndDate
      remainingChatAiTokens
      maxChatAiTokens
    }
  }
`;

const useSubscriptionInfo = (props?: { disableCache?: boolean }) => {
  const { disableCache } = props || {};

  const subscriptionQeury = useQuery(
    GET_SUBSCRIPTION_INFO,
    disableCache
      ? {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'meetovo-cache-disabled': 'true'
            }
          }
        }
      : {}
  );
  return subscriptionQeury;
};

export const getSubscriptionInfo = (props?: { disableCache?: boolean }) => {
  const { disableCache } = props || {};

  return window.apolloClient.query({
    query: GET_SUBSCRIPTION_INFO,
    ...(disableCache
      ? {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'meetovo-cache-disabled': 'true'
            }
          }
        }
      : {})
  });
};

export const getAgencyCustomerSubscriptionInfo = () => {
  return window.apolloClient.query({
    query: GET_AGENCY_CUSTOMER_SUBSCRIPTION_INFO,
    fetchPolicy: 'no-cache'
  });
};

export default useSubscriptionInfo;
