import React, { useState, useEffect, ChangeEvent } from 'react';
import { Card, Col, Row, Spin, Form, Modal, Button, message, Input, Switch, Alert } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  RocketOutlined
} from '@ant-design/icons';
import {
  getNewId,
  softRemoveById,
  mergeFormValuesToNormalData,
  mutateArrayOfObjectsKeysWithIdSuffix,
  getSubmissionDataFromTempData
} from '../../Funnel/helper/dynamicFormHelper';
import FunnelSelector from '../../SharedUI/components/FunnelSelector';
import { debounce } from 'debounce';
import { Store } from 'antd/lib/form/interface';
import { urlFormValidator } from '../../helper/url';
import { Rule } from 'rc-field-form/lib/interface';
import { ConnectSettingsMessages } from '../../config/messages';
import { FunnelInterface } from '../../Funnel/components/GeneralSettingsBuilder';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import { useAllActiveFunnelsIDs, useAllFunnels } from '../../Funnel/redux/funnelSlice';
import { EDIT_CONTENT_TYPE, useEditResourceContext } from '../context/ResourceEditContext';
import { factorsAiTrackEvent } from '../../helper/factors-ai';
import { connectCardTitlesAndCategories } from '../utils/types';
import { FunnelSelectionValidator } from '../utils/domainAndSlugValidation';

interface RelevantPropsInterface {
  value: string;
  valueLabel: string;
  valuePlaceholder: string;
  valueIsLink?: boolean;
  valueValidators?: Rule[];
  state?: string;
  disabled?: boolean;
  customValue?: string;
  customValueLabel?: string;
  customValuePlaceholder?: string;
  customValueRules?: Rule[];
}

type FunnelSearchProps = {
  value: string;
  alteredSettings: any[];
  funnels?: FunnelInterface[];
  stateHandler: (newSettings: any[]) => void;
};

interface SettingsModalInterface {
  title: string;
  open: boolean;
  funnelSelectorLabel?: string;
  funnelSelectorPlaceholder?: string;
  allFunnelsSelectable?: boolean;
  popupHeader?: JSX.Element;
  searchable?: boolean;
  onCancel: Function;
  onSubmit: Function;
  settings: any[];
  emptySettings: object;
  relevantProps: RelevantPropsInterface;
  footerElement: (value: any) => JSX.Element;
  funnels: FunnelInterface[];
  singleSelect: boolean;
  loading: boolean;
  validateSubmitData: (
    values: string[]
  ) => {
    hasError: boolean;
    errorMessage: string;
  };
}

interface ConnectSettingsChangerProps {
  title: string;
  children?: JSX.Element;
  logoFileName?: string;
  singleSelect?: boolean;
  searchable?: boolean;
  logoURL?: string;
  loading: boolean;
  relevantProps: RelevantPropsInterface;
  funnelSelectorLabel?: string;
  funnelSelectorPlaceholder?: string;
  allFunnelsSelectable?: boolean;
  refetchConnectSettings?: Function;
  settings: any[];
  emptySettings: {
    provider: string;
    providerId?: string;
    enabled?: boolean;
    activated?: boolean;
    private?: boolean;
    profileURL?: string;
  };
  popupHeader?: JSX.Element;
  footerElement?: (value: any) => JSX.Element;
  validateSubmitData?: (
    values: string[]
  ) => {
    hasError: boolean;
    errorMessage: string;
  };
  onSubmit: (data: { submissionData: any[]; idsToDelete: any[] }, resource: string) => void;
  disabled?: boolean;
  disabledMessage?: string;
  customFunnels?: any[];
  resourceType: EDIT_CONTENT_TYPE;
  customValueRules?: Rule[];
}

const Rules = {
  value: [{ required: true, message: 'Es muss ein valider Wert angegeben werden.' }]
};

const SettingsModal = ({
  title,
  open,
  onCancel,
  onSubmit,
  settings,
  popupHeader,
  emptySettings,
  relevantProps,
  searchable = false,
  footerElement,
  validateSubmitData,
  funnels = [],
  singleSelect = false,
  allFunnelsSelectable = true,
  funnelSelectorPlaceholder,
  funnelSelectorLabel,
  loading
}: SettingsModalInterface) => {
  const [form] = Form.useForm();
  const [alteredSettings, setAlteredSettings] = useState(settings || []);
  const addable =
    alteredSettings.filter(({ toDelete }) => !toDelete).length < funnels.length &&
    !alteredSettings.some(({ funnelIds, toDelete }) => funnelIds.includes(0) && !toDelete);

  const funnelsToExcludeFromSelection = funnels.reduce((acc: any, { id }: any) => {
    return alteredSettings?.some(
      ({ funnelIds, toDelete }: any) => funnelIds.includes(id) && id != -1 && !toDelete
    )
      ? [...acc, id]
      : acc;
  }, []);

  useEffect(() => {
    form.resetFields();
    syncStateAndFields(settings);
  }, [settings]);

  const activeFunnelIds = useAllActiveFunnelsIDs();
  const syncStateAndFields = (newSettings: any[]) => {
    if (newSettings) {
      const mutatedData = mutateArrayOfObjectsKeysWithIdSuffix({
        data: (newSettings || []).filter(({ toDelete }) => !toDelete)
      });
      form.setFieldsValue(mutatedData);
    } else {
      form.resetFields();
    }

    setAlteredSettings(newSettings || []);
  };

  const handleAddSettings = () => {
    factorsAiTrackEvent('CONNECT_SETTINGS_ADDED');

    form
      .validateFields()
      .then(() => {
        const newId = getNewId(alteredSettings);
        syncStateAndFields([
          ...alteredSettings,
          { ...emptySettings, id: newId, tempId: true, funnelIds: [] }
        ]);

        const inner = document.querySelector('.connect-settings-changer__modal-inner');
        inner?.scrollTo(0, inner?.scrollHeight || 0);
      })
      .catch(() => message.error(ConnectSettingsMessages.couldNotAddDomainBeforeIsInvalid));
  };

  const handleRemoveSettings = (id: number) => {
    const newData = softRemoveById(id, alteredSettings);
    syncStateAndFields(newData);
  };

  const handleValuesChange = debounce(
    (changedValues: Store) => {
      const mergedData = mergeFormValuesToNormalData(changedValues, alteredSettings);

      syncStateAndFields(mergedData);
    },
    process.env.NODE_ENV === 'test' ? 0 : 200
  );
  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const anyOldSettingsFunnelsWronglyUpdated =
          alteredSettings.filter(({ funnelIds, toDelete, tempId, id }) => {
            if (tempId || toDelete) return false;
            const previousState = settings?.find((s: any) => s.id === id);
            return !toDelete && previousState?.funnelIds?.length > 0 && funnelIds.length === 0;
          }).length > 0;

        const newSettingsWithoutFunnelIds = alteredSettings.some(
          ({ funnelIds, toDelete, tempId }) => tempId && !funnelIds.length && !toDelete
        );

        if (newSettingsWithoutFunnelIds || anyOldSettingsFunnelsWronglyUpdated) {
          message.error('Es müssen in allen Feldern Funnels angegeben werden.');
          return;
        }

        alteredSettings.forEach(
          (as, i) => (alteredSettings[i][relevantProps.value] = as[relevantProps.value]?.trim())
        );

        let { submissionData, idsToDelete } = getSubmissionDataFromTempData(alteredSettings);

        const submissionDataValues: string[] = submissionData.map((data: any) => {
          return data[relevantProps.value];
        });

        const validationResult = validateSubmitData(submissionDataValues);

        if (validationResult.hasError) {
          message.error(validationResult.errorMessage);

          return;
        }

        onSubmit({ submissionData, idsToDelete });
      })
      .catch(e => {
        const errorField = e.errorFields[0].name[0];
        const errorFieldId = 'connect-settings-card-' + errorField.split('_')[1];
        const errorElement = document.getElementById(errorFieldId);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth' });
        }
        console.log(e);
      });
  };

  const visibleSettings = alteredSettings.filter((as: any) => !as.toDelete);

  const customValidators = relevantProps.valueValidators || [];
  const formRules = relevantProps.valueIsLink
    ? [...Rules.value, urlFormValidator, ...customValidators]
    : [...Rules.value, ...customValidators];

  const handleSelectFunnel = (selectFunnelIds: number | number[], settingId: number) => {
    const funnelIds = Array.isArray(selectFunnelIds) ? selectFunnelIds : [selectFunnelIds];

    handleValuesChange({
      [`funnelIds_${settingId}`]: Array.isArray(funnelIds)
        ? funnelIds.includes(0)
          ? [0]
          : funnelIds
        : funnelIds
    });
  };

  const shouldShowSearchBar = searchable && alteredSettings.length > 5;

  return (
    <Modal
      className="connect-settings-changer"
      width={650}
      closeIcon
      closable
      title={title}
      visible={open}
      onCancel={() => onCancel()}
      footer={
        <Button
          data-testid="connectSaveButton"
          type="primary"
          htmlType="submit"
          onClick={() => handleSubmit()}
        >
          Speichern
        </Button>
      }
      destroyOnClose
    >
      {loading ? (
        <DefaultSmallLoader loading align-items-center />
      ) : (
        <>
          <div className="connect-settings-changer__modal-inner">
            <Form
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              onValuesChange={handleValuesChange}
              initialValues={{ initial: '' }}
            >
              {popupHeader ? (
                <>
                  {popupHeader}
                  <div style={{ marginTop: '25px' }}></div>
                </>
              ) : null}

              {shouldShowSearchBar && (
                <FunnelValueSearchContainer
                  alteredSettings={alteredSettings}
                  value={relevantProps.value}
                  stateHandler={syncStateAndFields}
                  funnels={funnels}
                />
              )}
              {visibleSettings
                .filter(setting => {
                  return setting['isPartialMatch'] === undefined || setting['isPartialMatch'];
                })
                .map((as: any, index) => {
                  const { id, funnelIds } = as;

                  const hasTempId = !!as.tempId;

                  const footerContent = footerElement(as);
                  const hasOnlyOneFunnelAttached = funnelIds.length == 1;

                  if (
                    !settings?.find(single => single.id === id)?.label &&
                    !form.isFieldTouched(`${relevantProps.customValue}_${id}`) &&
                    !form.getFieldValue(`${relevantProps.customValue}_${id}`)
                  ) {
                    handleValuesChange({
                      [`${relevantProps.customValue}_${id}`]: `Meine "${title}" Verbindung`
                    });
                  }

                  return (
                    <Card
                      key={id}
                      className="ui__white-round-wrapper"
                      style={{ marginBottom: '1em' }}
                      id={`connect-settings-card-${id}`}
                    >
                      {relevantProps.customValue && (
                        <Form.Item
                          rules={relevantProps.customValueRules || formRules}
                          label={relevantProps.customValueLabel}
                          name={`${relevantProps.customValue}_${id}`}
                        >
                          <Input
                            data-testid="TitleInput"
                            placeholder={relevantProps.customValuePlaceholder}
                            disabled={!!relevantProps.disabled && !hasTempId}
                          />
                        </Form.Item>
                      )}

                      <Form.Item
                        rules={formRules}
                        label={relevantProps.valueLabel}
                        name={`${relevantProps.value}_${id}`}
                      >
                        <Input
                          data-testid="PixelIdInput"
                          placeholder={relevantProps.valuePlaceholder}
                          disabled={!!relevantProps.disabled && !hasTempId}
                        />
                      </Form.Item>

                      <Form.Item required name={`funnelIds_${id}`} rules={FunnelSelectionValidator} label={funnelSelectorLabel || 'Funnel'}>
                        <FunnelSelector
                          showActiveFunelsOnly={true}
                          placeholder={funnelSelectorPlaceholder}
                          defaultValue={funnelIds}
                          allSelectdable={visibleSettings.length <= 1 && allFunnelsSelectable}
                          onChange={selectedFunnel => handleSelectFunnel(selectedFunnel, id)}
                          initialData={funnels}
                          idsToExclude={funnelsToExcludeFromSelection}
                          mode={singleSelect ? undefined : 'multiple'}
                        />
                      </Form.Item>

                      {relevantProps.state && (
                        <label className="ui__label-switch" style={{ marginBottom: 0 }}>
                          <Form.Item
                            valuePropName="checked"
                            name={`${relevantProps.state}_${id}`}
                            style={{ margin: 0 }}
                          >
                            <Switch size="small" />
                          </Form.Item>
                          Aktiviert
                        </label>
                      )}
                      {!hasTempId && footerContent}
                      <div className="ui__white-round-wrapper__action-bar">
                        <DeleteOutlined
                          data-testid={'connectDeleteButton'}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveSettings(id);
                          }}
                        />
                      </div>
                    </Card>
                  );
                })}
            </Form>
          </div>

          {addable && (
            <div
              className="connect-settings-changer__add-button-wrapper"
              style={visibleSettings.length < 2 ? { border: 'none' } : {}}
            >
              <Button
                data-testid="connectAddButton"
                onClick={handleAddSettings}
                type="ghost"
                className="lead-qualifier__add icon-circle-button-with-label"
              >
                <span className="ui__icon-circle">
                  <PlusOutlined />
                </span>
                Hinzufügen
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

function ConnectSettingsChanger({
  title,
  children,
  logoFileName,
  logoURL,
  loading,
  relevantProps,
  settings,
  emptySettings,
  onSubmit,
  refetchConnectSettings,
  popupHeader,
  disabled,
  disabledMessage,
  allFunnelsSelectable = true,
  singleSelect = false,
  searchable = false,
  funnelSelectorPlaceholder,
  funnelSelectorLabel,
  resourceType,
  footerElement = value => <div></div>,
  validateSubmitData = (values: string[]) => {
    return {
      hasError: false,
      errorMessage: ''
    };
  },
  customFunnels = []
}: ConnectSettingsChangerProps) {
  const [open, setOpen] = useState(false);
  const {
    toggleResourceUsage,
    resourceEditState,
    resourceEditSocketLoading
  } = useEditResourceContext();
  const funnels = useAllFunnels();
  loading = loading || resourceEditSocketLoading;

  const handleSubmit = async (data: any) => {
    if (data?.submissionData?.length || data?.idsToDelete?.length)
      onSubmit(data, emptySettings.provider);

    setOpen(false);
    await toggleResourceUsage(resourceType, false);
  };

  const handleOpen = async () => {
    if (resourceEditState[resourceType] === true && !disabled) {
      message.info(ConnectSettingsMessages.duplicateResources);
      return;
    }

    if (!funnels.length) {
      message.info('Du musst erst einen Funnel anlegen.');
      return;
    }

    if (refetchConnectSettings) await refetchConnectSettings();
    if (!disabled && !loading) {
      setOpen(true);
      toggleResourceUsage(resourceType, true);
    } else message.info(disabledMessage);
  };

  return (
    <>
      <SettingsModal
        title={title}
        open={open}
        popupHeader={popupHeader}
        onCancel={() => {
          toggleResourceUsage(resourceType, false);
          setOpen(false);
        }}
        onSubmit={handleSubmit}
        searchable={searchable}
        settings={settings}
        footerElement={footerElement}
        emptySettings={emptySettings}
        relevantProps={relevantProps}
        singleSelect={singleSelect}
        funnels={funnels.concat(customFunnels)}
        validateSubmitData={validateSubmitData}
        allFunnelsSelectable={allFunnelsSelectable}
        funnelSelectorPlaceholder={funnelSelectorPlaceholder}
        funnelSelectorLabel={funnelSelectorLabel}
        loading={loading}
      />

      <div className="card-with-hover">
        <Card
          onClick={handleOpen}
          hoverable
          className="card-container"
          data-testid="connectEditButton"
          cover={<img src={logoURL || process.env.PUBLIC_URL + '/connect/' + logoFileName} />}
        >
          <Card.Meta title={title} className="card-title" />
          <div>{connectCardTitlesAndCategories[resourceType].category}</div>
        </Card>
      </div>
    </>
  );
}

const FunnelValueSearchContainer = ({
  alteredSettings,
  value,
  stateHandler,
  funnels
}: FunnelSearchProps) => {
  const includesLowercase = (value1: string, value2: string) =>
    value1
      ?.toString()
      .toUpperCase()
      .includes(value2.toUpperCase());

  const valuesIsInFunnelTitle = (value: string, funnelIds: number[]) => {
    if (!funnels) return false;

    return funnels
      .filter(funnel => funnelIds.some(id => funnel.id === id))
      .some(({ title }) => includesLowercase(title, value));
  };

  const searchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    if (!newValue) {
      const newData = alteredSettings.map(altSetting => {
        altSetting['isPartialMatch'] = true;
        return altSetting;
      });

      return stateHandler(newData);
    }

    const newData = alteredSettings.map(altSetting => {
      const isPartialMatch = includesLowercase(altSetting[value], newValue);

      const isValueInLabel = includesLowercase(altSetting.label, newValue);
      const isPartialMatchOnFunnel = valuesIsInFunnelTitle(newValue, altSetting.funnelIds);
      altSetting['isPartialMatch'] = isPartialMatch || isPartialMatchOnFunnel || isValueInLabel;

      return altSetting;
    });

    return stateHandler(newData);
  };

  return (
    <>
      <Form.Item>
        <Input placeholder="Suche..." onChange={searchChangeHandler} />
      </Form.Item>
    </>
  );
};

export default ConnectSettingsChanger;
