import React, { useState } from 'react';
import { message, Alert, Checkbox, Button } from 'antd';
import { getValueByPropString } from '../../helper/objectAccess';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { EmailLoginAndRegistrationMessages, LoginMessages } from '../../config/messages';
import { getEnvironment } from '../../helper/environment';
import LoginSelector from '../components/LoginSelector/LoginSelector';
import AlternativeEmailInput from '../components/AlternativeEmailInput';
import { getUrlParam } from '../../helper/url';
import { getCookie, setCookie } from '../../helper/cookies';
import mixpanel from 'mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';
import GoogleConnector from '../components/GoogleConnector';
import { isFacebookInApp } from '../../VoiceRecorder/helper/deviceHelper';
import { useNavigate } from 'react-router';
import { LocalStorageKeys } from '../../Share/constants/localstorageKeys';
import LinkedInTag from 'react-linkedin-insight';
import { Col, Row, Form } from 'antd';
import { loginSocialIcons, logoWhiteURL } from '../utils/ImageConstants';
import { AUTH_ROUTES, ExistingUsers, TrustedLogos } from '../utils/LoginContent';
import { navigateAndPreventSameRoute } from '../../helper/navigation';
import { FormInstance } from 'antd/lib/form';
import { factorsAiTrackEvent } from '../../helper/factors-ai';
import { defaultRoute } from '../../UI/routes';
import { REGISTRATION, SEND_CHANGE_PASSWORD_TOKEN } from '../graphql/loginAndRegisterMutations';
import FAQAccordion from '../components/FAQAccordion';
import { faqData } from '../helper/FAQ-config';
import { ArrowRightOutlined } from '@ant-design/icons';
import { SessionStorageKeys } from '../../Share/constants/sessionStorageKeys';
import { getSessionStorageData } from '../helper/login';
import TrustElements from '../components/TrustElements';
import LoginTeaserCarousel from '../components/LoginSelector/LoginTeaserCarousel';
import {
  loginHeadline,
  loginParagraph,
  loginSwitchLink,
  loginSwitchQuestion
} from '../utils/loginText';

export const LOGIN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
    }
  }
`;

export type LoginMode = 'google-login' | 'login' | 'register' | 'reset-password';

interface LoginProps {
  onLogin(): any;
  children?: any;
  loginMode: LoginMode;
  setLoginMode?: (mode: LoginMode) => void;
  form?: FormInstance;
}

var loginCheckInterval: any;

export function getAuthData(propString?: string) {
  if (getMeetovoAuthData())
    return getValueByPropString(JSON.parse(getMeetovoAuthData()), propString);
}

export function closeSession() {
  clearInterval(loginCheckInterval);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_AUTH_DATA);
  localStorage.removeItem('meetovoFunnelOwner');
  sessionStorage.removeItem(LocalStorageKeys.MEETOVO_AUTH_DATA);
}

export function isTokenValid() {
  let valid = false;
  let expirationDate = getAuthData('expirationDate');
  const token = getAuthData('token');

  valid = !!token && moment(expirationDate).isAfter(moment());

  return valid;
}

interface LocalStorageAuthProps {
  expirationDate: Date;
  token: string;
  activated: boolean;
  lastLogin: Date;
  isEmployee: boolean;
  isAdmin: boolean;
}

export const populateLocalStorageWithAuth = (props: LocalStorageAuthProps) => {
  sessionStorage.setItem(LocalStorageKeys.MEETOVO_AUTH_DATA, JSON.stringify(props));
  localStorage.setItem(LocalStorageKeys.MEETOVO_AUTH_DATA, JSON.stringify(props));
};

export const getMeetovoAuthData = (): any =>
  sessionStorage.getItem(LocalStorageKeys.MEETOVO_AUTH_DATA) ||
  localStorage.getItem(LocalStorageKeys.MEETOVO_AUTH_DATA);

let analytics: any;

export function startTokenExpirationCheck(onInvalid?: any) {
  if (!loginCheckInterval) {
    loginCheckInterval = setInterval(() => {
      if (!isTokenValid()) {
        onInvalid && onInvalid();
        closeSession();
      }
    }, 10000);
  }
}

const AuthRoutesHOC = ({ onLogin, children, loginMode, form }: LoginProps) => {
  const isDev = getEnvironment().isDev;
  const [getMeetovoToken, { loading }] = useMutation(LOGIN);
  const [errorMessage, setErrorMessage] = useState<{ description: string; message: string }>({
    description: '',
    message: ''
  });
  const [showLoginSelector, setShowLoginSelector] = useState(false);
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [showAlternativeEmailAdressInput, setShowAlternativeEmailAdressInput] = useState(false);
  const partnerId = getUrlParam('pid') || getCookie('meetovoPartnerId');
  const cookieReferrer = getCookie('meetovoFirstUserReferrer');
  const source = JSON.stringify({
    UTMParams: getSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS),
    CookiesReferrer: cookieReferrer
  });
  const showGoogleLoginButton =
    !errorMessage.message && !showAlternativeEmailAdressInput && !isFacebookInApp();
  const [infoMessage, setInfoMessage] = useState<{
    description: string;
    message: string;
    type: string;
  }>({
    type: 'error',
    description: '',
    message: ''
  });

  const navigate = useNavigate();

  if (partnerId !== getCookie('meetovoPartnerId')) setCookie('meetovoPartnerId', partnerId, 7);

  const handleLegalAgreement = () => {
    setLegalAgreement(!legalAgreement);
  };

  const handleAfterLogin = (loginData: any) => {
    const {
      expirationDate,
      token,
      activated,
      lastLogin,
      isAdmin,
      alternativeEmailEnabled,
      provider,
      connectedToGoogle,
      isEmployee
    } = loginData;

    if (provider === 'google' && !connectedToGoogle) {
      setErrorMessage({
        message: 'Autorisierung fehlgeschlagen',
        description: LoginMessages.hasNoRefreshTokenError
      });
      return;
    }

    if (!activated) {
      setErrorMessage({
        message: 'Account deaktiviert',
        description: LoginMessages.accountDisabledMEssage
      });
      return;
    }
    mixpanel.track(loginMode);
    populateLocalStorageWithAuth({
      expirationDate,
      token,
      activated,
      lastLogin,
      isEmployee,
      isAdmin
    });

    if (isAdmin) {
      setShowLoginSelector(true);
    } else {
      if (provider != 'google' || alternativeEmailEnabled != null) onLogin();
      else setShowAlternativeEmailAdressInput(true);

      if (!isDev) {
        if (!lastLogin) {
          LinkedInTag.track('15797017');
          if (analytics) analytics.track('sign_up');
          ReactPixel.track('StartTrial', {});
        } else {
          if (analytics) analytics.track('login');
          ReactPixel.track('Login', {});
        }
      }
      navigateAndPreventSameRoute(navigate, defaultRoute);
    }
  };

  function setAuthData(data: any) {
    getMeetovoToken({
      variables: { input: { code: data.code, activated: true, partnerId, source: cookieReferrer } }
    })
      .then(res => {
        handleAfterLogin(res.data.login);
      })
      .catch(e => {
        if (e.message.includes('User is already registered via E-Mail'))
          message.error(LoginMessages.couldntLoginToGoogleAccountalreadyRegisteredViaEmail, 8);
        else showGeneralLoginError();
      });
  }

  function setAuthDataByAdmin(coachId: number) {
    getMeetovoToken({ variables: { input: { coachId } } })
      .then(res => {
        const { expirationDate, token, activated } = res.data.login;

        const currentAuthData = JSON.parse(getMeetovoAuthData() || '{}');

        populateLocalStorageWithAuth({
          ...(currentAuthData || {}),
          expirationDate,
          token,
          activated
        });

        onLogin();

        navigateAndPreventSameRoute(navigate, defaultRoute);
        setShowLoginSelector(false);
      })
      .catch(e => {
        message.error(LoginMessages.loginByAdminError);
      });
  }

  function showGeneralLoginError() {
    message.error(LoginMessages.generalLoginError);
  }

  function toggleLoginMode(e: any) {
    e.preventDefault();
    const { search } = location;
    let goToRoute;
    switch (loginMode) {
      case 'login':
        goToRoute = AUTH_ROUTES.REGISTER;
        break;
      default:
        goToRoute = AUTH_ROUTES.LOGIN;
    }
    navigateAndPreventSameRoute(navigate, goToRoute + (search || ''), { replace: true });
  }

  function validateLegalCheckbox() {
    return legalAgreement || loginMode === 'login';
  }

  const [login, { loading: loadingLogin }] = useMutation(LOGIN);
  const [registration, { loading: loadingRegistration }] = useMutation(REGISTRATION);
  const [sendPasswordChangeToken, { loading: loadingSendPasswordChangeToken }] = useMutation(
    SEND_CHANGE_PASSWORD_TOKEN
  );

  const isLoading = loadingLogin || loadingRegistration || loadingSendPasswordChangeToken;

  function handleLogin(email: string, password: string) {
    factorsAiTrackEvent('LOGIN');

    login({
      variables: { input: { email, password } }
    })
      .then(res => {
        handleAfterLogin(res.data.login);
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.login.error.message,
          description: EmailLoginAndRegistrationMessages.login.error.description
        });
      });
  }

  function validator() {
    return legalAgreement;
  }

  const isRegistrationAllowed =
    !isDev ||
    !!localStorage.getItem('meetovoRegistrationAllowed') ||
    window.location.host.includes('localhost');

  function handleRegistration(
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) {
    factorsAiTrackEvent('REGISTRATION');

    if (validator ? !validator() : !legalAgreement) {
      message.error(LoginMessages.checkboxNotChecked);
      return;
    }

    if (!isRegistrationAllowed) return;

    firstName = firstName?.trim() || firstName;
    lastName = lastName?.trim() || lastName;

    registration({
      variables: { input: { firstName, lastName, email, password, source, partnerId } }
    })
      .then(res => {
        handleAfterLogin(res.data.registerViaEmailAndPassword);
      })
      .catch(e => {
        if (e.message.includes('Email is a temp mail')) {
          setInfoMessage({
            type: 'error',
            message: EmailLoginAndRegistrationMessages.registration.error.isTempMail.message,
            description: EmailLoginAndRegistrationMessages.registration.error.isTempMail.description
          });
        } else {
          setInfoMessage({
            type: 'error',
            message: EmailLoginAndRegistrationMessages.registration.error.emailExisting.message,
            description:
              EmailLoginAndRegistrationMessages.registration.error.emailExisting.description
          });
        }
      });
  }

  function handleSendPasswordChangeToken(email: string) {
    sendPasswordChangeToken({
      variables: { email }
    })
      .then(res => {
        setInfoMessage({
          type: 'success',
          message: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.success.message,
          description: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.success.description
        });
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.error.message,
          description: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.error.description
        });
      });
  }

  const handleSubmit2 = ({
    email,
    password,
    firstName,
    lastName
  }: {
    email: string;
    password?: string;
    firstName?: string;
    lastName?: string;
  }) => {
    if (loginMode === 'login' && password) {
      handleLogin(email, password);
    } else if (loginMode === 'register' && password && firstName && lastName) {
      handleRegistration(firstName, lastName, email, password);
    } else if (loginMode === 'reset-password') {
      handleSendPasswordChangeToken(email);
    }
  };

  return (
    <div className="page login">
      <section className="section section-layout-3-outer">
        <Row>
          <Col xs={24} md={24} lg={12} className="login__left">
            <div className="d-flex flex-column w-100 overflow-auto hide-scroll-bar login__left__inner scroll-shadow-wrapper">
              <div className="login__left__top">
                <img src={logoWhiteURL} />
                <h2>{loginHeadline[loginMode]}</h2>
                <p>{loginParagraph[loginMode]}</p>
              </div>
              {(showGoogleLoginButton && loginMode === 'login') || loginMode === 'register' ? (
                <>
                  <GoogleConnector
                    onSuccess={authData => setAuthData(authData)}
                    onFailure={() => showGeneralLoginError()}
                    validator={validateLegalCheckbox}
                    validationFailedMessage={LoginMessages.checkboxNotChecked}
                    trigger={
                      <a className="button__google">
                        {loading ? 'Wird geladen...' : 'Jetzt mit Google einloggen'}
                      </a>
                    }
                  />
                  <div className="divider-container">
                    <div className="divider-line" />
                    <div className="divider">oder</div>
                    <div className="divider-line" />
                  </div>
                </>
              ) : null}
              <Form
                layout="vertical"
                name="email-login"
                onFinish={({ email, password, firstName, lastName }) =>
                  handleSubmit2({ email, password, firstName, lastName })
                }
                onValuesChange={() => {
                  if (infoMessage.message) {
                    setInfoMessage({ type: 'error', description: '', message: '' });
                  }
                }}
                form={form}
              >
                {children}
                {infoMessage.message && (
                  <Alert
                    className={
                      infoMessage.type === 'error' ? 'alert-error-message' : 'alert-success-message'
                    }
                    message={infoMessage.message}
                    description={infoMessage.description}
                    type={infoMessage.type as any}
                    showIcon
                  />
                )}
                {showLoginSelector && <LoginSelector setAuthDataByAdmin={setAuthDataByAdmin} />}
                <SubmitButtonWithHelpers
                  isLoading={isLoading}
                  loginMode={loginMode}
                  handleLegalAgreement={handleLegalAgreement}
                />
              </Form>
              <div className="register-or-login">
                <span>
                  {loginSwitchQuestion[loginMode]}
                  &nbsp;
                </span>
                <a
                  onClick={e => {
                    toggleLoginMode(e);
                  }}
                >
                  {loginSwitchLink[loginMode]}
                </a>
              </div>
              {showAlternativeEmailAdressInput && <AlternativeEmailInput onSave={onLogin} />}
              {loginMode === 'register' && <FAQAccordion data={faqData} />}
              <div className="scroll-shadow__overlay" />
            </div>
          </Col>
          <Col xs={24} md={24} lg={12} className="login__right">
            <div className="login__right__inner">
              {(loginMode === 'login' || loginMode === 'reset-password') && <LoginTeaserCarousel />}
              {loginMode === 'register' && <TrustElements />}
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};

const SubmitButtonWithHelpers = ({
  loginMode,
  isLoading,
  handleLegalAgreement
}: {
  loginMode: string;
  isLoading?: boolean;
  handleLegalAgreement?: (e?: any) => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {loginMode === 'login' ? (
        <Form.Item className="form-input">
          <Button
            className="button__gradient"
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
          >
            {isLoading ? 'Wird geladen...' : 'Einloggen'}
          </Button>
        </Form.Item>
      ) : loginMode === 'register' ? (
        <>
          <Form.Item
            className="check-box"
            name="terms"
            rules={[
              {
                required: true,
                message: 'Bitte akzeptiere die Nutzungsbedingungen und die Datenschutzerklärung.',
                validator: (_, value) => {
                  if (value == true) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Terms not validated!'));
                  }
                }
              }
            ]}
            valuePropName="checked"
          >
            <Checkbox className="login__legal-checkbox" onChange={handleLegalAgreement}>
              Ich akzeptiere die{' '}
              <a
                href="https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/vertraege/MEETOVO-Nutzungsbedingungen-V3.pdf"
                target="_blank"
              >
                Nutzungsbedingungen
              </a>{' '}
              und die{' '}
              <a href="https://www.meetovo.de/datenschutz" target="_blank">
                Datenschutzerklärung
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="button__gradient"
              htmlType="submit"
              type="primary"
            >
              Jetzt 14 Tage kostenlos testen
            </Button>
          </Form.Item>
        </>
      ) : (
        <Form.Item className="form-input">
          <Button
            className="button__gradient"
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
          >
            {isLoading ? 'Wird geladen...' : 'Passwort zurücksetzen'}
          </Button>
        </Form.Item>
      )}
    </>
  );
};

export default AuthRoutesHOC;
