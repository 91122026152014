import { Form, Input, Modal } from 'antd';
import React from 'react';
import { defaultStringFieldValidation } from '../../../../../UI/utils/formValidation';

export default React.memo(
  function SaveTemplateNameModal({
    visible,
    setVisible,
    onSubmit
  }: {
    visible: boolean;
    setVisible: (state: boolean) => void;
    onSubmit: (state: any) => void;
  }) {
    const [form] = Form.useForm();

    return (
      <Modal
        title="Als Vorlage speichern"
        okText="Hinzufügen"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={() => setVisible(false)}
        width={500}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            label="Titel"
            name="name"
            className=""
            rules={defaultStringFieldValidation}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
  ({ visible }, { visible: nextVisible }) => {
    return visible === nextVisible;
  }
);
