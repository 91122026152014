import React, { useEffect, useState } from 'react';
import { useAd } from '../redux/adBuilderSlice';
import { BuilderPreviewBase } from '../../components/ui/BuilderPreviewBase';
import { appendStylesAccordingToColorTheme } from '../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { useConnectedFonts } from '../../redux/builderSlice';
import { useEditor } from '@craftjs/core';

export default function AdBuilderPreview() {
  const theme = useCurrentlyEditingBuilderTheme();
  const connectedFonts = useConnectedFonts();
  const userAd = useAd();
  const { query } = useEditor();
  const nodesLength = Object.keys(query.getSerializedNodes()).length > 2;

  useEffect(() => {
    appendStylesAccordingToColorTheme(theme, false, connectedFonts);
  }, [theme, connectedFonts]);

  return (
    <BuilderPreviewBase
      selectedPage={userAd}
      pagesExisting={Boolean(userAd.craftState)}
      className={`ad-builder-preview`}
      styles={{ margin: 'auto' }}
    />
  );
}
