import React from 'react';
import { useNavigate } from 'react-router';
import useAgencyDashboard from '../../hooks/useAgencyDashboard';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Role } from '../utils/roleTypes';
import meetovoLogo from '../resources/meetovo-logo-text-white-p-500.png';
import { useSearchParams } from 'react-router-dom';
import { logoWhiteURL } from '../../Login/utils/ImageConstants';

const Logo = ({
  iconOnly,
  redirectionNeeded = true,
  collapsed,
  isBuilderScreen
}: {
  iconOnly?: boolean;
  redirectionNeeded?: boolean;
  collapsed?: boolean;
  isBuilderScreen?: boolean;
}) => {
  const navigate = useNavigate();
  const { data } = useAgencyDashboard();
  const { role } = useCurrentUser();

  const [searchParams] = useSearchParams();
  const returnToParam = searchParams.get('returnTo');

  let logo =
    role === Role.AGENCY_CUSTOMER && data?.getAgencyDashboard.logoURL
      ? data?.getAgencyDashboard.logoURL
      : process.env.PUBLIC_URL + `/meetovo-${iconOnly ? 'icon-256' : 'logo'}.png`;

  if (isBuilderScreen) {
    logo = collapsed ? process.env.PUBLIC_URL + '/meetovo-icon-256.png' : meetovoLogo;
  }
  if (
    location.pathname.includes('/onboarding-einfuehrung') ||
    location.pathname.includes('/onboarding-fragen')
  ) {
    logo = logoWhiteURL;
  }

  const reDirectLink = role === Role.AGENCY_CUSTOMER ? '/bewerbungen' : '/meine-funnels';

  const imageConfig = iconOnly
    ? {
        width: '45px',
        height: '45px'
      }
    : {};

  return (
    <div className={`ui__logo cursor-pointer ${iconOnly ? 'without-name' : ''}`}>
      <img
        style={imageConfig}
        src={logo}
        onClick={() =>
          redirectionNeeded && navigate(`${returnToParam ? returnToParam : reDirectLink}`)
        }
      />
    </div>
  );
};

export default Logo;
