import React, { useEffect, useRef } from 'react';
import { Row, Col, Layout } from 'antd';
import { useDispatch } from 'react-redux';

import CreateTemplateTabs from '../components/emailTemplate/CreateTemplateTabs';
import SenderDetail from '../components/emailTemplate/SenderDetail';
import { updateChannelGeneralSettings, useChannelTemplatesData, useFunnelTheme } from '../redux/builderSlice';
import EmailBrandingSwitch from '../components/emailTemplate/EmailBrandingSwitch';
import MessageChannelSelector from './messageSender/MessageChannelSelector';
import MessagingSendingInputs from './messageSender/MessagingSendingInputs';
import { CHANNEL_DATA_INPUTS, CHANNEL_TYPE } from '../interfaces/builderSliceTypes';
import { appendStylesAccordingToColorTheme } from '../helper/craftJs';
const { Content, Sider } = Layout;

const CreateEmailTemplate = () => {
  const { templates, activeTab, sender, brandingEnabled } = useChannelTemplatesData();
  const dispatch = useDispatch();
  const theme = useFunnelTheme();
  const sendingChannelCurrentTemplate = templates[parseInt(activeTab)]?.sendingChannel;

  const valuesRef = useRef({ sender, brandingEnabled });

  useEffect(() => {
   appendStylesAccordingToColorTheme(theme, true)
  }, [])

  useEffect(() => {
    if (!valuesRef.current.sender) {
      valuesRef.current = { sender, brandingEnabled };
    }
  }, [sender, brandingEnabled]);

  const handleUpdateEmailSenderName = (name: string) => {
    dispatch(updateChannelGeneralSettings({ type: CHANNEL_DATA_INPUTS.SENDER, value: name }));
  };

  return (
    <>
      <Sider theme="light" className="builder__settings-sidebar" style={{ overflow: 'auto' }}>
        <Col span={24} className="builder__settings-sidebar__container">
          <label className="builder__settings-sidebar__container__simple-title">E-Mails</label>
          <MessageChannelSelector />
          {sendingChannelCurrentTemplate === CHANNEL_TYPE.MESSAGE ? (
            <MessagingSendingInputs />
          ) : (
            <>
              <SenderDetail value={sender} onChange={handleUpdateEmailSenderName} />
              <EmailBrandingSwitch />
            </>
          )}
        </Col>
      </Sider>
      <Content className="builder__content">
        <div className="preview-section blank-component h-100">
          <Row className="create-email-template__wrapper">
            <Col span={24} className="max-width__1000px-centered d-block">
              <CreateTemplateTabs />
            </Col>
          </Row>
        </div>
      </Content>
    </>
  );
};

export default CreateEmailTemplate;
