import React, { useState, ReactElement, useEffect, useContext, useMemo } from 'react';
import useThirdPartyConnectSocket from '../../Builder/hooks/socket';
import useContentEditStatus, { ContentEditSessionType } from '../../hooks/useContentEditStatus';

export enum EDIT_CONTENT_TYPE {
  MY_DOMAINS = 'MY_DOMAINS',
  FACEBOOK_PIXELS = 'FACEBOOK_PIXELS',
  FACEBOOK_CONVERSATION_API = 'FACEBOOK_CONVERSATION_API',
  GOOGLE_TAG = 'GOOGLE_TAG',
  TIKTOK_PIXELS = 'TIKTOK_PIXELS',
  LINKEDIN_TAG = 'LINKEDIN_TAG',
  TRUST_PILOT = 'TRUST_PILOT',
  PROVEN_EXPERT = 'PROVEN_EXPERT',
  WEBHOOK = 'WEBHOOK',
  PROVEN_EMPLOYER = 'PROVEN_EMPLOYER'
}

interface propType {
  resourceEditState: { [key in EDIT_CONTENT_TYPE]: boolean };
  toggleResourceUsage: (resource: EDIT_CONTENT_TYPE, isEditing: boolean) => {};
  resourceEditSocketLoading: boolean;
}

interface StatusChangedData {
  resource: EDIT_CONTENT_TYPE;
  isEditing: boolean;
}

const initialState = {
  resourceEditState: {
    [EDIT_CONTENT_TYPE.MY_DOMAINS]: false,
    [EDIT_CONTENT_TYPE.FACEBOOK_PIXELS]: false,
    [EDIT_CONTENT_TYPE.FACEBOOK_CONVERSATION_API]: false,
    [EDIT_CONTENT_TYPE.GOOGLE_TAG]: false,
    [EDIT_CONTENT_TYPE.TIKTOK_PIXELS]: false,
    [EDIT_CONTENT_TYPE.LINKEDIN_TAG]: false,
    [EDIT_CONTENT_TYPE.TRUST_PILOT]: false,
    [EDIT_CONTENT_TYPE.PROVEN_EXPERT]: false,
    [EDIT_CONTENT_TYPE.WEBHOOK]: false,
    [EDIT_CONTENT_TYPE.PROVEN_EMPLOYER]: false
  },
  toggleResourceUsage: () => {
    return true;
  },
  resourceEditSocketLoading: true
};

export const ResourceEditContext = React.createContext<propType>(initialState);

// ----------------------------------------------------------------------

export function ResourceEditContextProvider({ children }: { children: React.ReactNode }) {
  const { socket } = useThirdPartyConnectSocket();

  const [resourceEditState, setResourceEditState] = useState(initialState.resourceEditState);

  const { data, loading: resourceEditSocketLoading } = useContentEditStatus();

  useEffect(() => {
    if (data) {
      const tempData: { [key in EDIT_CONTENT_TYPE]: boolean } = {
        ...initialState.resourceEditState
      };

      data.getAllContentEditSession.forEach((item: ContentEditSessionType) => {
        tempData[item.resource] = item.isEditing;
      });

      setResourceEditState(tempData);
    }
  }, [data]);

  const toggleResourceUsage = async (resource: EDIT_CONTENT_TYPE, isEditing: boolean) => {
    await socket.emit('changeEditStatus', {
      resource: resource,
      isEditing: isEditing
    });

    return true;
  };

  useMemo(() => {
    socket.on('statusChanged', ({ data }: { data: StatusChangedData }) => {
      setResourceEditState(prevState => ({ ...prevState, [data.resource]: data.isEditing }));
    });
  }, [socket]);

  return (
    <ResourceEditContext.Provider
      value={{
        resourceEditState: resourceEditState,
        toggleResourceUsage,
        resourceEditSocketLoading
      }}
    >
      {children}
    </ResourceEditContext.Provider>
  );
}

export const useEditResourceContext = () => {
  return useContext(ResourceEditContext);
};
