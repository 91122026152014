import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert, message, Checkbox } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { getUrlParam } from '../../helper/url';
import { getCookie } from '../../helper/cookies';
import { EmailLoginAndRegistrationMessages } from '../../config/messages';
import LoginValidationRules from '../utils/LoginValidationRules';
import { useNavigate } from 'react-router';
import { getEnvironment } from '../../helper/environment';
import { forceLowerCase } from '../../Connect/utils/domainInput';
import { factorsAiTrackEvent } from '../../helper/factors-ai';
import { getSessionStorageData } from '../helper/login';
import { SessionStorageKeys } from '../../Share/constants/sessionStorageKeys';
import LoginForm from './Forms/LoginForm';
import { CHANGE_PASSWORD_VIA_TOKEN, LOGIN } from '../graphql/loginAndRegisterMutations';

interface EmailLoginAndRegistrationProps {
  mode: string;
  afterLogin: Function;
  registerButtonText?: string;
  loginButtonText?: string;
  validator?(): any;
  validationFailedMessage?: string;
  disableModeSwitch?: boolean;
  hidePolicyCheckbox?: boolean;
  emailIcon?: any;
  passwordIcon?: any;
  disabledLoginButton?: boolean;
  handleLegalAgreement?: () => void;
}

enum MODES {
  REGISTER = 'email-register',
  LOGIN = 'email-login',
  RESET_PASSWORD = 'email-reset-password',
  LOGIN_REGISTER = 'email-login-and-registration-register',
  SEND_RESET_PASSWORD = 'email-send-password-reset'
}
const EmailLoginAndRegistration = ({
  mode,
  afterLogin,
  loginButtonText = 'Einloggen',
  validator,
  validationFailedMessage = 'Die Angaben sind nicht vollständig.',
  disableModeSwitch,
  hidePolicyCheckbox,
  emailIcon,
  passwordIcon,
  disabledLoginButton,
  handleLegalAgreement
}: EmailLoginAndRegistrationProps) => {
  const { isDev } = getEnvironment();

  const navigate = useNavigate();
  const [login, { loading: loadingLogin }] = useMutation(LOGIN);
  const [changePasswordViaToken, { loading: loadingChangePasswordViaToken }] = useMutation(
    CHANGE_PASSWORD_VIA_TOKEN
  );
  const [infoMessage, setInfoMessage] = useState<{
    description: string;
    message: string;
    type: string;
  }>({
    type: 'error',
    description: '',
    message: ''
  });
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [currentMode, setCurrentMode] = useState(mode || MODES.LOGIN);

  const isLoading = loadingLogin || loadingChangePasswordViaToken;
  const passwordResetToken = getUrlParam('passwordResetToken');

  const source = JSON.stringify({
    UTMParams: getSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS),
    CookiesReferrer: getCookie('meetovoFirstUserReferrer')
  });

  useEffect(() => {
    if (mode != currentMode) setCurrentMode(mode);
  }, [mode]);

  useEffect(() => {
    if (passwordResetToken) setCurrentMode(MODES.RESET_PASSWORD);
  }, []);

  function handleLogin(email: string, password: string) {
    factorsAiTrackEvent('LOGIN');

    login({
      variables: { input: { email, password } }
    })
      .then(res => {
        afterLogin(res.data.login, currentMode);
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.login.error.message,
          description: EmailLoginAndRegistrationMessages.login.error.description
        });
      });
  }

  function handleChangePasswordViaToken(password: string) {
    changePasswordViaToken({
      variables: { token: passwordResetToken, password }
    })
      .then(res => {
        message.success(EmailLoginAndRegistrationMessages.changePasswordViaToken.success.message);
        navigate('/login');
        setCurrentMode(MODES.LOGIN);
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.changePasswordViaToken.error.message,
          description: EmailLoginAndRegistrationMessages.changePasswordViaToken.error.description
        });
      });
  }

  return (
    <div
      className={`email-login-and-registration ${
        currentMode === MODES.REGISTER ? MODES.LOGIN_REGISTER : ''
      }`}
    >
      {infoMessage.message && (
        <Alert
          message={infoMessage.message}
          description={infoMessage.description}
          type={infoMessage.type as any}
          showIcon
        />
      )}

      {currentMode === MODES.LOGIN && (
        <Form
          layout="vertical"
          name="email-login"
          onFinish={({ email, password }) => {
            disabledLoginButton || handleLogin(email, password);
          }}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            //@ts-ignore
            rules={LoginValidationRules.email}
          >
            <Input autoCapitalize="none" prefix={emailIcon} onInput={forceLowerCase} />
          </Form.Item>

          <Form.Item label="Passwort" name="password" rules={LoginValidationRules.password}>
            <Input.Password prefix={passwordIcon} />
          </Form.Item>

          <Form.Item>
            <Button
              className="login_button"
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              {isLoading ? 'Wird geladen...' : loginButtonText}
            </Button>
            {!disableModeSwitch && (
              <Button type="link" onClick={() => setCurrentMode(MODES.REGISTER)}>
                Noch nicht registriert?
              </Button>
            )}
            <Button
              className="forget-password"
              type="link"
              onClick={() => setCurrentMode(MODES.SEND_RESET_PASSWORD)}
            >
              Passwort vergessen?
            </Button>
          </Form.Item>
        </Form>
      )}
      {currentMode === MODES.SEND_RESET_PASSWORD && (
        <>
          <LoginForm />
        </>
      )}

      {currentMode == MODES.REGISTER && !hidePolicyCheckbox && (
        <Checkbox
          className="login__legal-checkbox"
          value={legalAgreement}
          onChange={() => setLegalAgreement(!legalAgreement)}
        >
          Ich akzeptiere die{' '}
          <a
            href="https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/vertraege/MEETOVO-Nutzungsbedingungen-V3.pdf"
            target="_blank"
          >
            Nutzungsbedingungen
          </a>{' '}
          und die{' '}
          <a href="https://www.meetovo.de/datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
          .
        </Checkbox>
      )}
    </div>
  );
};

export default EmailLoginAndRegistration;
