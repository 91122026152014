import { Collapse } from 'antd';
import React, { useRef } from 'react';
import { useUserMediaTemplates } from '../../../../redux/adBuilderSlice';
import { useDispatch } from 'react-redux';
import { useNode } from '@craftjs/core';
import { deleteAdMediaTemplateThunk } from '../../../../redux/thunk';
import SwitchMediaTemplate from './SwitchMediaTemplate';
import SingleMediaTemplate from './SingleMediaTemplate';
const { Panel } = Collapse;

const MediaSuggestionsSideBar = () => {
  const { props } = useNode(node => ({
    props: node.data.props
  }));

  const { templateId } = props;

  const handleCheckIfChangedRef = useRef();

  const userTemplate = useUserMediaTemplates();

  const handleCardClick = ({ id, craftState }: any) => {
    // @ts-ignore
    handleCheckIfChangedRef.current({ id, craftState });
  };
  const dispatch = useDispatch();
  const handleDelete = (id: number) => {
    dispatch(deleteAdMediaTemplateThunk(id));
  };
  return (
    <div className="ad-suggestion__container w-100">
      <SwitchMediaTemplate handleCheckIfChangedRef={handleCheckIfChangedRef} />
      <div className="builder__settings-sidebar__row">
        {userTemplate.map(({ id, imageUrl, name, craftState }, i) => {
          return (
            <SingleMediaTemplate
              handleDelete={() => {
                handleDelete(id);
              }}
              template={{ id, imageUrl, name, craftState }}
              handleReplaceTemplate={() => {
                handleCardClick({ id, craftState });
              }}
              key={id + i}
              isActive={templateId === id}
            />
          );
        })}
        {userTemplate.length === 0 && (
          <p className="p-3">
            Es sind keine eigenen Templates vorhanden. Klicke auf die Diskette über einem Text um
            ihn zu Speichern.
          </p>
        )}
      </div>
    </div>
  );
};

export default MediaSuggestionsSideBar;
