import { BookingTag } from '../container/Events';

export const getTagsFromHashMap = (tags: BookingTag[], tagsHashMap: any) => {
  return tags
    .map((tag: BookingTag) => {
      if (tag.id) {
        return tagsHashMap[tag.id];
      }
    })
    .filter((tag: BookingTag) => tag !== undefined);
};
