import React, { useEffect } from 'react';
import { TrustedLogos } from '../utils/LoginContent';
import { injectScripts } from '../../Funnel/helper/elements';

interface TrustElementsProps {}

const TrustElements: React.FC<TrustElementsProps> = () => {
  useEffect(() => {
    injectScripts([
      {
        src: 'https://widget.senja.io/widget/efbd01b5-fd4c-482d-b3d2-2c8700146f49/platform.js',
        appendTo: 'body'
      },
      {
        src: 'https://widget.senja.io/widget/ee0bd54e-047f-4a33-b41c-12f893dfc80b/platform.js',
        appendTo: 'body'
      }
    ]);
  }, []);

  return (
    <>
      <h1 className="trusted__title">
        <b>+600.000 Bewerbungen</b> und <b>+5.000 Nutzer</b> <br /> können sich nicht täuschen!
      </h1>

      <div className="intro-text">
        <div
          className="senja-embed"
          data-id="efbd01b5-fd4c-482d-b3d2-2c8700146f49"
          data-mode="shadow"
          data-lazyload="false"
          style={{ display: 'block' }}
        ></div>
      </div>

      <div className="senja__rating-average">
        <div
          className="senja-embed"
          data-id="ee0bd54e-047f-4a33-b41c-12f893dfc80b"
          data-mode="shadow"
          data-lazyload="false"
          style={{ display: 'block' }}
        ></div>
      </div>

      <div className="trusted">
        <ul className="horizontal-list-of-logos">
          {TrustedLogos.map(url => (
            <li>
              <img src={url} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TrustElements;
