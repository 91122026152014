import { createAsyncThunk } from '@reduxjs/toolkit';
import gql from 'graphql-tag';
import { getApplicationFiltersFromQueryParams } from '../helper/getApplicationFiltersFromQueryParams';
import {
  BookingFilterInterface,
  EventStatus,
  BookingStatus,
  BookingTag,
  LeadQualifierChoiceInterface,
  BookingInterface
} from '../container/Events';
import { getSingleBooking } from '../../Builder/hooks/useSingleBooking';
import { deleteBookingApi } from '../../hooks/mutateDeleteBooking';
import { deleteApplicationApi } from '../../hooks/mutateDeleteApplication';
import { deleteTrackingApi } from '../../hooks/mutateDeleteTracking';
import { EventsMessages } from '../../config/messages';
import { Role } from '../../UI/utils/roleTypes';
import { message } from 'antd';
import {
  CHANGE_BOOKING_STATUS_AND_ORDER,
  CREATE_BOOKING_STATUS,
  UPDATE_BOOKING_STATUS,
  DELETE_BOOKING_STATUS,
  CREATE_BOOKING_TAGS,
  UPDATE_BOOKINGS_TAGS,
  DELETE_BOOKING_TAGS,
  UPDATE_BOOKING_STATUS_ORDER,
  GET_BOOKING_INFO,
  CHANGE_BOOKING_STATUS,
  UPDATE_BOOKING_STATUS_AND_ADD_NOTE,
  CREATE_OR_UPDATE_BOOKING_RATING,
  DELETE_BOOKING_RATING,
  CHANGE_BULK_BOOKING_STATUS,
  UPDATE_APPLICANT_DATA,
  GET_LEAD_QUALIFIER,
  GET_BOOKINGS_PAGINATED
} from '../graphql/bookingQueries';
import { ActionInterface } from '../components/EventTable';
import { EventState } from '../components/EventKanban/types';
import { SET_MANUAL_BOOKING } from '../../hooks/mutateManualBooking';
import { DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { getFilters } from '../helper/bookingFilter';
import { UserState } from '../../UI/redux/userSlice';
import { OldLeadQualifier } from '../../Builder/interfaces/builderSliceTypes';
import {
  handleBookingChange,
  setAllBookingTags,
  setBookingMetadata,
  setPaginatedBookings,
  updatePaginatedBookingsLoading
} from './slice';

export const getAllBookingsThunk = createAsyncThunk(
  'get-all-bookings',
  async (_, { getState, dispatch }) => {
    try {
      const { bookings } = getState() as { bookings: EventState };
      if (bookings.loaders.paginatedBookingsLoading) {
        return;
      }
      await dispatch(updatePaginatedBookingsLoading(true));
      const metadata = await fetchBookingMetaData();
      await dispatch(setBookingMetadata(metadata));
      dispatch(fetchAllBookings());
      return metadata;
    } catch (error) {
      throw error;
    }
  }
);

const fetchBookingMetaData = async () => {
  const bookingFilters: BookingFilterInterface = getApplicationFiltersFromQueryParams();
  const { variables } = getFilters(bookingFilters);

  // Query without getBookings (only metadata)
  const response = await window.apolloClient.query({
    query: gql`
      query getBookingMetadata($filter2: TrackingFilter!) {
        getTrackings(filter: $filter2) {
          id
          completed
          createdAt
          source
          totalDurationInSeconds
          funnelId
        }
        getAllBookingStatus {
          id
          status
          value
          color
          sortOrder
        }
        getAllBookingTags {
          color
          id
          name
          coachId
          agencyCoachId
        }
      }
    `,
    variables,
    fetchPolicy: 'no-cache'
  });

  return response.data; // Return metadata
};

export const fetchPaginatedBookings = createAsyncThunk(
  'bookings/fetchPaginated',
  async (page: number = 1, { getState, dispatch }) => {
    const pageSize = 100;
    const bookingFilters: BookingFilterInterface = getApplicationFiltersFromQueryParams();
    const { variables } = getFilters(bookingFilters);

    (variables.filter1 as any)['offset'] = (page - 1) * pageSize;
    (variables.filter1 as any)['limit'] = pageSize;

    try {
      const response = await window.apolloClient.query({
        query: GET_BOOKINGS_PAGINATED,
        variables,
        fetchPolicy: 'no-cache'
      });

      const paginatedBookings = response?.data?.getBookings ?? [];
      return paginatedBookings;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAllBookings = createAsyncThunk(
  'bookings/fetchAll',
  async (_, { dispatch, getState }) => {
    try {
      let page = 1;
      let hasMore = true;

      const fetchBookingsInBackground = async () => {
        while (hasMore) {
          const paginatedBookings = await dispatch(fetchPaginatedBookings(page)).unwrap();
          await dispatch(setPaginatedBookings(paginatedBookings));

          if (paginatedBookings.length === 0) {
            hasMore = false;
          } else {
            page++;
          }
        }
        await dispatch(updatePaginatedBookingsLoading(false));
      };

      fetchBookingsInBackground(); // Run in the background
    } catch (error) {
      throw error;
    }
  }
);

export const updateBookingRating = createAsyncThunk('update-booking-rating', async (data: any) => {
  try {
    return window.apolloClient.mutate({
      mutation: CREATE_OR_UPDATE_BOOKING_RATING,
      variables: { input: data }
    });
  } catch (error) {
    throw error;
  }
});

export const changeBookingStatusAndOrder = createAsyncThunk(
  'change-booking-status-and-order',
  async (data: any) => {
    try {
      const response = await window.apolloClient.mutate({
        mutation: CHANGE_BOOKING_STATUS_AND_ORDER,
        variables: data
      });

      return response.data.changeBookingStatus;
    } catch (error) {
      throw error;
    }
  }
);

export const updateBookingStatusOrder = createAsyncThunk(
  'update-booking-status-order',
  async (data: any) => {
    try {
      const response = await window.apolloClient.mutate({
        mutation: UPDATE_BOOKING_STATUS_ORDER,
        variables: { input: data }
      });

      return response.data.changeBookingStatus;
    } catch (error) {
      throw error;
    }
  }
);

export const manualBooking = createAsyncThunk(
  'create-manual-booking',
  async (data: any, { dispatch }) => {
    const { input, shouldCreate } = data;
    const response = await window.apolloClient.mutate({
      mutation: SET_MANUAL_BOOKING,
      variables: { input }
    });

    const newBookingId = response.data.setManualBooking.id;
    const singleBooking = await getSingleBooking(newBookingId, data?.funnelId);
    return { manualBooking: singleBooking.data.getSingleBooking, shouldCreate };
  }
);

export const fetchSingleBooking = createAsyncThunk(
  'bookings/fetchSingleBooking',
  async ({ bookingId, funnelId }: { bookingId: number; funnelId: number }) => {
    const singleBooking = await getSingleBooking(bookingId, funnelId);
    return singleBooking.data.getSingleBooking;
  }
);

export const addNewColumn = createAsyncThunk(
  'kanban-add-status-column',
  async ({ status, newColumn, color }: { status: string; newColumn: string; color: string }) => {
    const createdBookingStatus = await window.apolloClient.mutate({
      mutation: CREATE_BOOKING_STATUS,
      variables: {
        input: { status, value: newColumn, color }
      }
    });

    return createdBookingStatus.data.createBookingStatus;
  }
);

export const updateStatusColumn = createAsyncThunk(
  'kanban-update-status-column',
  async ({
    status,
    newColumn,
    color
  }: {
    status: BookingStatus;
    newColumn: string;
    color: string;
  }) => {
    const updatedStatus = await window.apolloClient.mutate({
      mutation: UPDATE_BOOKING_STATUS,
      variables: {
        input: {
          id: status?.id,
          ...(newColumn && { status: newColumn }),
          ...(color && { color: color })
        }
      }
    });

    return updatedStatus.data.updateBookingStatus;
  }
);

export const deleteStatusColumn = createAsyncThunk(
  'kanban-delete-status-column',
  async ({ status }: { status: BookingStatus }) => {
    const deleteStatus = await window.apolloClient.mutate({
      mutation: DELETE_BOOKING_STATUS,
      variables: {
        input: status.id
      }
    });

    return status;
  }
);

export const createBookingTagsThunk = createAsyncThunk(
  'bookings-createBookingTags',
  async (
    { bookingId, tags }: { bookingId: number; tags: BookingTag[] },
    { rejectWithValue, getState, dispatch }
  ) => {
    const { bookings } = getState() as { bookings: EventState };

    if (!bookings.isBookingTagsChanged) {
      return {
        response: null,
        createdTagsWithoutIds: []
      };
    }

    const createdTagsInput = bookings.selectedEvent?.event.tags
      ?.filter((tag: BookingTag) => tag !== undefined)
      .map(({ coachId, agencyCoachId, ...rest }: BookingTag) => ({
        ...rest
      }));

    const response = await window.apolloClient.mutate({
      mutation: CREATE_BOOKING_TAGS,
      variables: {
        input: { bookingId, tags: createdTagsInput }
      }
    });

    const selectedTagsNameWithoutIds = createdTagsInput
      .filter((tag: any) => !tag.id)
      .map((tag: any) => tag.name);

    const selectedTagsWithIds = createdTagsInput.filter((tag: any) => tag.id);

    const responseWithTagIds = response.data.createBookingTags.filter((tag: BookingTag) =>
      selectedTagsNameWithoutIds.includes(tag.name)
    );

    return {
      response,
      selectedTagsWithIds,
      responseWithTagIds
    };
  }
);

// @ts-ignore
export const updateBookingTags = createAsyncThunk(
  'bookings-updateBookingTags',
  async (
    {
      oldTag,
      updatedTag,
      updatedAllBookingTags,
      updatedTags
    }: { oldTag: BookingTag; updatedTag: BookingTag; updatedAllBookingTags: any; updatedTags: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await dispatch(setAllBookingTags(updatedAllBookingTags));
      if (updatedTag.id) {
        const { coachId, agencyCoachId, ...rest } = updatedTag;
        const response = await window.apolloClient.mutate({
          mutation: UPDATE_BOOKINGS_TAGS,
          variables: {
            input: {
              ...rest
            }
          }
        });
        await dispatch(handleBookingChange({ field: 'tags', value: updatedTags }));

        return {
          updatedTag: response.data.updateBookingTags,
          oldTag
        };
      } else {
        await dispatch(handleBookingChange({ field: 'tags', value: updatedTags }));
        return {
          updatedTag: updatedTag,
          oldTag
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// @ts-ignore
export const deleteBookingTags = createAsyncThunk(
  'bookings-deleteBookingTags',
  async (
    {
      filteredAllBookingTags,
      filteredSelectedBookingTags,
      id
    }: {
      filteredSelectedBookingTags: any[];
      filteredAllBookingTags: any[];
      id: number | undefined;
    },
    { dispatch, rejectWithValue }
  ) => {
    if (id) {
      try {
        await dispatch(
          handleBookingChange({
            field: 'tags',
            value: [...(filteredSelectedBookingTags as BookingTag[])]
          })
        );
        await dispatch(setAllBookingTags([...filteredAllBookingTags]));
        const response = await window.apolloClient.mutate({
          mutation: DELETE_BOOKING_TAGS,
          variables: {
            id
          }
        });
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return rejectWithValue('');
    }
  }
);

// @ts-ignore
export const handleDownload = createAsyncThunk(
  'bookings-handle-download',
  async (_, { rejectWithValue, getState }) => {
    const bookingFilters: BookingFilterInterface = getApplicationFiltersFromQueryParams();
    const { variables } = getFilters(bookingFilters);
    try {
      const response = await window.apolloClient.mutate({
        mutation: CREATE_BOOKING_TAGS,
        variables: {
          filter: variables.filter1
        }
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleSetSelectedEvent = createAsyncThunk(
  'events/handleSetSelectedEvent',
  async (evnt: any, { getState, dispatch }) => {
    const { bookings: bookingState } = getState() as { bookings: EventState };

    if (!evnt) {
      return { selectedEvent: undefined };
    }

    const id = evnt.event.id;
    const cachedEvent = bookingState.eventCache[id];

    if (cachedEvent) {
      return { selectedEvent: { ...evnt, event: cachedEvent } };
    }

    try {
      const { data } = await getSingleBooking(id, evnt.event.funnelId);
      const eventData = data.getSingleBooking;
      eventData.leadQualifiers = data.getLeadQualifier;

      return {
        selectedEvent: { ...evnt, event: eventData },
        eventCache: { [id]: eventData }
      };
    } catch (error) {
      console.error('Error fetching selected event:', error);
      throw error;
    }
  }
);

export const updateBookingStatusAndAddNote = createAsyncThunk(
  'events/updateBookingStatusAndAddNote',
  async ({ e, action }: { e: any; action: ActionInterface }, { getState, dispatch }) => {
    const response = await window.apolloClient.mutate({
      mutation: UPDATE_BOOKING_STATUS_AND_ADD_NOTE,
      variables: {
        status: { value: action?.action, id: action?.event.id },
        note: { bookingId: action?.event.id, note: e.note }
      }
    });
  }
);

export const deleteBulkEventThunk = createAsyncThunk(
  'events/deleteBulkEvents',
  async (
    {
      selectedBookings,
      userData,
      callBack
    }: { selectedBookings: BookingInterface[]; userData: UserState; callBack?: () => void },
    { dispatch }
  ) => {
    const promises = selectedBookings.map((booking: BookingInterface) => {
      if (booking.version === 'V1') {
        return deleteBookingApi(booking.id);
      } else {
        return deleteApplicationApi(booking.id);
      }
    });
    try {
      await Promise.allSettled(promises);
      message.success(EventsMessages.deleteTrackingSuccess);
      callBack?.();
    } catch (error) {
      message.error(
        userData.data.role === Role.AGENCY_CUSTOMER
          ? EventsMessages.deleteBookingNotPermitted
          : EventsMessages.deleteBookingError
      );
    }
  }
);

export const deleteEventThunk = createAsyncThunk<
  number, // Return type is number
  { id: number; version: string; callBack?: Function; userData: any },
  { rejectValue: string } // Define rejectValue type
>(
  'events/deleteEvent',
  async ({ id, version, callBack, userData }, { getState, dispatch, rejectWithValue }) => {
    try {
      let success = false;

      if (version === 'V1') {
        const res = await deleteBookingApi(id);
        success = res.data.deleteBooking;
      } else {
        const res = await deleteApplicationApi(id);
        success = res.data.deleteApplication;
      }

      if (success) {
        message.success(EventsMessages.deleteBookingSuccess);
        if (callBack) callBack(id);
        return id;
      } else {
        throw new Error();
      }
    } catch {
      const errorMessage =
        userData.role === Role.AGENCY_CUSTOMER
          ? EventsMessages.deleteBookingNotPermitted
          : EventsMessages.deleteBookingError;

      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const handleUpdateManualLeadQualifier = createAsyncThunk(
  'events/handleUpdateManualLeadQualifier',
  async (
    {
      values,
      leadQualifierData
    }: {
      values: any;
      leadQualifierData: OldLeadQualifier[];
    },
    { getState, dispatch }
  ) => {
    const { bookings: bookingState } = getState() as { bookings: EventState };
    const { selectedEvent, eventCache } = bookingState;

    if (!selectedEvent?.event) {
      throw new Error('Selected event is not defined');
    }

    const choices: any[] = [];
    Object.entries(values).forEach(([key, value]: [any, any]) => {
      const leadQualifier = leadQualifierData.find(item => item.id == key);
      if (leadQualifier) {
        switch (leadQualifier.type?.toUpperCase()) {
          case 'TEXT':
            leadQualifier.choices.forEach((choice: LeadQualifierChoiceInterface) => {
              choices.push({
                choiceId: choice.id,
                leadQualifierId: leadQualifier.id,
                stringValue: value
              });
            });
            break;
          case 'RANGE':
            leadQualifier.choices.forEach((choice: LeadQualifierChoiceInterface) => {
              choices.push({
                choiceId: choice.id,
                leadQualifierId: leadQualifier.id,
                numberValue: value
              });
            });
            break;
          case 'RADIO':
            leadQualifier.choices.forEach((choice: LeadQualifierChoiceInterface) => {
              if (value === choice.id) {
                choices.push({
                  choiceId: value,
                  leadQualifierId: leadQualifier.id
                });
              }
            });
            break;
          case 'MULTIPLE':
            leadQualifier.choices.forEach((choice: LeadQualifierChoiceInterface) => {
              if (value.includes(choice.id)) {
                choices.push({
                  choiceId: choice.id,
                  leadQualifierId: leadQualifier.id
                });
              }
            });
            break;
        }
      }
    });

    const response = await dispatch(
      manualBooking({
        input: {
          id: selectedEvent.event.id,
          funnelId: selectedEvent.event.funnelId,
          choices
        },
        shouldCreate: false
      })
    );

    return {
      updatedEvent: (response.payload as { manualBooking: any })?.manualBooking,
      eventId: selectedEvent.event.id
    };
  }
);

export const onEventDragEnd = createAsyncThunk(
  'events/onEventDragEnd',
  async (
    {
      result,
      shouldShowEmailPopup = true
    }: {
      result: DropResult;
      shouldShowEmailPopup: boolean;
    },
    { getState, dispatch }
  ) => {
    try {
      const { bookings: bookingState } = getState() as { bookings: EventState };
      if (result.combine || !result.destination || !bookingState.updateStatusPayload) {
        return;
      }

      const source: DraggableLocation = result.source;
      // @ts-ignore
      const destination: DraggableLocation = result.destination;

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      if (result.type === 'COLUMN') {
        await window.apolloClient.mutate({
          mutation: UPDATE_BOOKING_STATUS_ORDER,
          variables: bookingState.updateStatusPayload
        });
      } else {
        await window.apolloClient.mutate({
          mutation: CHANGE_BOOKING_STATUS_AND_ORDER,
          variables: bookingState.updateStatusPayload
        });
      }
    } catch (error) {
      console.error('Error in onEventDragEnd:', error);
      message.error('Something went wrong!');
    }
  }
);

export const changeBulkBookingStatusThunk = createAsyncThunk(
  'events/handleBulkStatusChange',
  async (
    {
      value,
      props
    }: {
      value: string;
      props: any;
    },
    { getState }
  ) => {
    const { bookings: bookingsState } = getState() as { bookings: EventState };
    const eventIds = bookingsState.selectedBookings.map(i => i.id);
    const { status, color, id } = props.data;

    await window.apolloClient.mutate({
      mutation: CHANGE_BULK_BOOKING_STATUS,
      variables: {
        input: {
          ids: eventIds,
          value
        }
      }
    });

    return {
      ids: eventIds,
      eventStatus: { status, value, color }
    };
  }
);

export const changeBookingStatusThunk = createAsyncThunk(
  'events/handleStatusChange',
  async (
    {
      value,
      props
    }: {
      value: string;
      props: any;
    },
    { getState, dispatch }
  ) => {
    const { bookings: bookingState } = getState() as { bookings: EventState };
    const eventId = bookingState.selectedEvent?.event.id;
    const { status, color } = props.data;

    await window.apolloClient.mutate({
      mutation: CHANGE_BOOKING_STATUS,
      variables: {
        input: { id: eventId, value }
      }
    });

    return {
      id: eventId,
      eventStatus: { status: status, value: value, color: color }
    };
  }
);

export const deleteBookingRating = createAsyncThunk(
  'kanban-delete-rating',
  async ({ id }: { id: number }) => {
    const reponse = await window.apolloClient.mutate({
      mutation: DELETE_BOOKING_RATING,
      variables: { id }
    });

    return reponse;
  }
);

export const createOrUpdateRating = createAsyncThunk(
  'kanban-create-or-update-rating',
  async ({ variables }: { variables: any }) => {
    const reponse = await window.apolloClient.mutate({
      mutation: CREATE_OR_UPDATE_BOOKING_RATING,
      variables: variables
    });

    return reponse;
  }
);
export const updateApplicantData = createAsyncThunk(
  'events/updateApplicantData',
  async (data: any) => {
    try {
      return window.apolloClient.mutate({
        mutation: UPDATE_APPLICANT_DATA,
        variables: { input: data }
      });
    } catch (error) {
      throw error;
    }
  }
);

export const getLeadQualifier = createAsyncThunk('bookings/getLeadQualifier', async (data: any) => {
  try {
    return window.apolloClient.mutate({
      mutation: GET_LEAD_QUALIFIER,
      variables: { input: data }
    });
  } catch (error) {
    throw error;
  }
});
