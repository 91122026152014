import React from 'react';
import FunnelSelectList from '../../../UI/components/FunnelSelectList';
import { Col, Row, Select } from 'antd';
import { StatusInterface } from '../../container/Events';
import { useBookingState } from '../../redux/slice';

interface CSVSubmitTabProps {
  selectedFunnelId: number | undefined;
  setSelectedFunnelId: (id: number) => void;
  defaultSelectedStatus?: StatusInterface;
  setDefaultSelectedStatus: (status: StatusInterface) => void;
  showStatusSelector?: boolean;
}

const CSVSubmitTab = ({
  selectedFunnelId,
  setSelectedFunnelId,
  setDefaultSelectedStatus,
  defaultSelectedStatus,
  showStatusSelector
}: CSVSubmitTabProps) => {
  const { availableStatus } = useBookingState();
  
  return (
    <Row  gutter={24} className="submit-tab mt-5 mb-4 pl-2 pr-2" align='middle'>
      <Col xs={24} sm={12}>
        <img 
          className="funnel-selection-img" 
          src={`${process.env.PUBLIC_URL}/csv-upload/meetovo-bms.png`} 
          alt="Funnel selection Image" 
        />
      </Col>
      <Col xs={24} sm={12}>
        <h2>Importziel für alle Bewerber</h2>
        <div className="w-100 mb-4">
          <h3 className="submit-tab__title">Funnel</h3>
          <FunnelSelectList
            onChange={id => {
              setSelectedFunnelId(id);
            }}
            value={selectedFunnelId || undefined}
            showActiveFunelsOnly
          />
        </div>
        {showStatusSelector && (
          <div className="w-100">
            <h3 className="submit-tab__title">Status</h3>
            <Select
              className="w-100"
              style={{ width: 120 }}
              options={availableStatus.map((status: StatusInterface) => ({
                label: status.status,
                value: status.value
              }))}
              placeholder='Status auswählen...'
              value={defaultSelectedStatus?.value}
              onChange={e => {
                setDefaultSelectedStatus(
                  availableStatus.find(status => status.value === e) as StatusInterface
                );
              }}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default CSVSubmitTab;
