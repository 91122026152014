import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import TemplateTypesView from './TemplateTypesView';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import RenderAdTemplates from './RenderAdTemplates';
import { BsImageFill } from 'react-icons/bs';
import MagicIcon from '../../../../UI/components/MagicIcon';
import { useCreateAdTemplateTypeLoading } from '../../redux/adBuilderSlice';

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  adGenerationData?: AdGeneration;
  hideDefaultTemplateTab?: Boolean;
  shouldGenerateAd?: Boolean;
}

const Templates = ({
  visible = false,
  toggleVisible,
  adGenerationData,
  hideDefaultTemplateTab,
  shouldGenerateAd
}: Props) => {
  const aiAdCreationLoading = useCreateAdTemplateTypeLoading();
  const [activeTab, setActiveTab] = useState('1');

  const handleCancel = () => {
    if (aiAdCreationLoading) return;
    toggleVisible(false);
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Modal
      className="ads-builder-template__modal funnel-block-template-wrapper"
      visible={visible}
      width={1200}
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div style={{ position: 'relative', top: '-30px' }}>
        <Tabs className="ads-builder-template__tab" activeKey={activeTab} onChange={onTabChange}>
          <TabPane
            tab={
              <span>
                <MagicIcon />
                KI-Erstellung
              </span>
            }
            key="1"
          />
          {hideDefaultTemplateTab || (
            <TabPane
              tab={
                <span>
                  <BsImageFill />
                  Eigene Vorlagen
                </span>
              }
              disabled={aiAdCreationLoading}
              key="2"
            />
          )}
        </Tabs>
      </div>

      {activeTab === '1' ? (
        <TemplateTypesView
          toggleVisible={toggleVisible}
          adGenerationData={adGenerationData as AdGeneration}
          shouldGenerateAd={shouldGenerateAd}
        />
      ) : (
        <RenderAdTemplates toggleVisible={toggleVisible} />
      )}
    </Modal>
  );
};
export default Templates;
