import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_COMPLETE_FUNNEL = gql`
  query getFunnelPages {
    getFunnelPages {
      name
      craftState
      redirectURL
      id
      type
      pageOrder
      facebookPixelEvent
      tikTokPixelEvent
      linkedInEvent
      googleTagTrigger
      customJs
    }
    getFunnelColorTheme {
      id
      headlineColor
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      accentColorContrast
      fontFamily
      fontLink
      isCustom
      name
      defaultGradient
    }
    getEmailTemplates {
      id
      subject
      html
      type
      emailSenderName
      enabled
      brandingEnabled
      otherEmailAddressesToSend
      scheduledTime
      timeInMinutesTillEvent
    }
    getLeadQualifier {
      id
      question
      type
      craftJsNodeId
      choices {
        id
        title
        unit
        craftJsNodeId
      }
    }
    getUserColorThemes {
      id
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      headlineColor
      accentColorContrast
      isCustom
      name
      defaultGradient
    }
    getFunnel {
      id
      uniqueIdentifier
      description
      calendarId
      primaryBrandColor
      secondaryBrandColor
      title
      serviceId
      calendarBlockMode
      logoURL
      type
      enabled
      domain {
        id
        name
        status
        fullLink
      }
      customJs
      templateId
      language
      metaTitle
      customMetaDataEnabled
      iconURL
      previewImageURL
      fontFamily
      funnelThemeId
      brandingEnabled
      cookieBanner
      builderVersion
      previewImageV2URL
      isEditing
      funnelFolderId
      updatedAt
      aiSettingsUpdatedAt
      connectedFonts {
        id
        type
        availableFont {
          id
          fontFamily
          fontLink
          type
        }
      }
      targetAudience {
        age
        goals
        experience
        experienceDuration
        education
        skills {
          skill
        }
      }
      companyDetail {
        name
        description
        benefits
        uniqueSellingPoint
      }
      communication {
        speech
        writingStyle
      }
      jobInformation {
        title
        category
        subCategory {
          id
          category
          categoryId
        }
        jobCategory {
          id
          category
          categoryId
        }
        employmentType
        salary
        salaryPlan
        workplaceModel
        address
      }
      thumbnail
      bookingCount
    }
  }
`;

export default function useGetCompleteFunnel() {
  return useQuery(GET_COMPLETE_FUNNEL, {
    fetchPolicy: 'cache-and-network'
  });
}

export function fetchCompleteFunnel() {
  return window.apolloClient.query({
    query: GET_COMPLETE_FUNNEL,
    fetchPolicy: 'no-cache'
  });
}
