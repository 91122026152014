import {
  Content,
  EditorOptions,
  useEditor as useTiptapEditor,
  Editor as TiptapEditor
} from '@tiptap/react';
import StarterKitExtension from '@tiptap/starter-kit';
import TextAlignExtension from '@tiptap/extension-text-align';
import PlaceholderExtension from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import LinkExtension from '@tiptap/extension-link';
import { CustomEnterBehavior, editorClasses, listItemColor } from './helpers/EditorHelpers';
import { FontSize } from '../../Builder/components/elements/RichTextComponent/utils';

export type UseEditorProps = Partial<EditorOptions> & {
  content?: string | Content;
  placeholder?: string;
  editable?: boolean;
  onChange?: (editor: TiptapEditor) => void;
  history?: boolean;
  disableBullets?: boolean;
};

export const useCustomEditor = ({
  content = '',
  placeholder = 'Write something awesome...',
  editable = true,
  onChange,
  history = true,
  disableBullets = false,
  ...other
}: UseEditorProps): TiptapEditor | null =>
  useTiptapEditor({
    content,
    editable,
    extensions: [
      StarterKitExtension.configure({
        history: history as false,
        codeBlock: false,
        heading: { HTMLAttributes: { class: editorClasses.content.heading }, levels: [1, 2, 3] },
        horizontalRule: { HTMLAttributes: { class: editorClasses.content.hr } },
        listItem: { HTMLAttributes: { class: editorClasses.content.listItem } },
        blockquote: { HTMLAttributes: { class: editorClasses.content.blockquote } },
        bulletList: disableBullets ? false : { HTMLAttributes: { class: editorClasses.content.bulletList } },
        orderedList: { HTMLAttributes: { class: editorClasses.content.orderedList } }
      }),
      TextAlignExtension.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right', 'justify'],
        defaultAlignment: 'left'
      }),
      PlaceholderExtension.configure({
        placeholder,
        emptyEditorClass: editorClasses.content.placeholder
      }),
      TextStyle.configure({
        HTMLAttributes: {
          class: 'text-style'
        }
      }),
      listItemColor.configure({
        HTMLAttributes: { class: editorClasses.content.listItem }
      }),
      CustomEnterBehavior,
      Color,
      Underline.configure({ HTMLAttributes: { class: editorClasses.content.underline } }),
      LinkExtension.configure({
        autolink: true,
        openOnClick: false,
        HTMLAttributes: { class: editorClasses.content.link }
      }),
      FontSize.configure({
        types: ['textStyle'],
        defaultSize: '1em'
      })
    ],
    onUpdate({ editor: _editor }) {
      onChange?.(_editor);
    },
    editorProps: {
      handlePaste: (view, event) => {
        event.preventDefault();
        const text = event.clipboardData?.getData('text/plain');
        if (text) {
          view.dispatch(view.state.tr.insertText(text));
        }
        return true;
      }
    },
    ...other
  });
