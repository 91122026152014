import React, { useEffect, useRef, useState } from 'react';
import { Col, message } from 'antd';
import { useEditor as useCraftEditor, useNode } from '@craftjs/core';
import { EditorContent, Editor } from '@tiptap/react';
import { ActionsController } from '../../../sharedUI/ActionsController';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { CraftElementBaseProps, sleep } from '../../../../helper/craftJs';
import { UserInteractingComponentsMessages } from '../../../../../config/messages';
import { BUILDER_VALIDATION_ERRORS } from '../../../../container/BuilderValidator';
import { trackInteraction } from '../../../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../../../DebugTracking/container/DebugTracker';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { selectGlobalSettingsFlags } from '../../../../../UI/redux/uiSlice';
import { setElementTabSelectMutex } from '../../../../redux/builderSlice';
import { getValueFromKeyValueArrayOfObjects } from '../../../../../helper/array';
import useCustomDebounce from '../../../../../hooks/useCustomDebounce';
import TextSuggestionSidebar from '../components/TextSuggestionSidebar';
import { FAQ, textTypesEnum } from '../../../../AdBuilder/interfaces/TextTypeInterface';
import { TemplateContent, useTextTemplateData } from '../../../../AdBuilder/data';
import { deleteJobTextTemplateThunk } from '../../../../AdJobBuilder/redux/thunk';
import { useUserContentTemplate } from '../../../../AdBuilder/redux/adBuilderSlice';
import DefaultEmptyViewForSettingsPanel from '../../../sharedUI/DefaultEmptyViewForSettingsPanel';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { SAVEABLE_ELEMENTS } from '../../../../AdJobBuilder/config/craftElements';
import {
  addBuilderValidationError,
  removeBuilderValidationError
} from '../../../../redux/builderSlice';
import { AI_PROMPT_TEXT_TYPES } from '../../../../hooks/ai-conversation/getAiTextResponse';
import RichTextSetting from '../../RichTextSetting';
import { useCustomEditor } from '../../../../../SharedUI/components/useEditor';
import { useEditorContext } from '../../../../context/EditorContext';
import { useSetSelectedElementTab } from '../../../../reduxCommonMutations/setSelectedElementTab';
import { htmlToPlainText } from '../helpers/textManipulators';

let richTextMissmatchErrorIsSent = false;

export const defaultTipTapEditorState =
  '<p style="text-align: center">🚀 Consectetur adipiscing elit.</p><h1 style="text-align: center"><span style="font-size: 2.5em">Lorem ipsum dolor sit amet, consectetur adipiscing</span></h1><p style="text-align: center">Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed magna dictum porta.</p>';

interface Props extends CraftElementBaseProps {
  html?: string;
  isLeadQualifier?: boolean;
  required?: boolean;
  elementType?: FAQ | textTypesEnum;
  showArrow?: boolean;
  saveable?: boolean;
  label?: string;
  settings?: {
    shouldShowSettings: boolean;
  };
  alignmentDisabled?: boolean;
  saveableElements?: SAVEABLE_ELEMENTS;
  textTypeOption?: AI_PROMPT_TEXT_TYPES;
  showAiTextSettings?: Boolean;
}

function RichTextComponent({
  isLeadQualifier = true,
  required,
  elementType,
  label,
  html,
}: Props) {
  const globalSettingsFlags = useAppSelector(selectGlobalSettingsFlags);
  const [isEditable, setIsEditable] = useState(true);
  const dispatch = useAppDispatch();
  const { query, actions } = useCraftEditor();
  const { nodeId, currentNode, nodeProps } = useNode(node => ({
    dragged: node.events.dragged,
    hasSelectedNode: node.events.selected,
    nodeId: node.id,
    currentNode: node,
    nodeProps: node.data.props
  }));
  const setElementsTab = useSetSelectedElementTab();
  const { registerEditor, unregisterEditor } = useEditorContext();
  const editorRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const oldContentRef = useRef('');

  const handleOnChange = (_editor: Editor) => {
    if (!_editor || _editor.isDestroyed) return;
    const newContent = _editor.getHTML();
    actions.setProp(nodeId, (prop: any) => {
      if (prop.rawState) {
        delete prop.rawState;
      }
      prop.html = _editor.getHTML();
    });
    if (newContent !== oldContentRef.current) {
      const trackAllRichTextInteractions = getValueFromKeyValueArrayOfObjects(
        globalSettingsFlags,
        'trackAllRichTextInteractions'
      );
      if (trackAllRichTextInteractions) {
        trackInteraction({
          type: 'FUNCTION_CALL',
          customEventName: TRACKINGS.ON_CHANGE_IN_RICH_TEXT_COMPONENT,
          additionalData: {
            nodeId,
            editorContent: newContent,
            editorState: _editor.getJSON()
          }
        });
      }
    }
    oldContentRef.current = newContent;
  };

  const editor = useCustomEditor({
    content: html || (nodeProps.html ?
        nodeProps.html :
        defaultTipTapEditorState),
    onChange: handleOnChange,
    editable: isEditable,
    history: false
  });

  useEffect(() => {
    if (editor) {
      registerEditor(nodeId, editor);
    }
    return () => {
      if (editor) {
        unregisterEditor(nodeId);
      }
    }
  }, [editor, nodeId, registerEditor, unregisterEditor]);

  useEffect(() => {
    if (!editor || !nodeProps.html) return;

    const { from, to } = editor.state.selection;
    const stripToComparable = (text: string) => {
      return text
        .toLowerCase()
        .replace(/[\s\x00-\x1F\x7F]/g, '')
        .replace(/[\u2000-\u200F\u2028-\u202F\u205F-\u206F]/g, '')
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9!.,?€$%]/g, '');
    };

    const currentText = stripToComparable(editor.getText());
    const newText = stripToComparable(htmlToPlainText(nodeProps.html));

    editor.commands.setContent(nodeProps.html);

    if (from !== undefined && to !== undefined && currentText === newText) {
      editor.commands.setTextSelection({ from, to });
    }
  }, [nodeProps.html]);

  const debouncedTrackInteraction = useCustomDebounce((interaction: any) => {
    trackInteraction(interaction);
  }, 300);

  const isDomTextDifferentToEditorState = () => {
    if (!editor || !editorRef.current) return { isDifferent: false };

    const editorStateText = editor.getText().replace(/[\s\x00-\x1F\x7F]/g, '');
    const domText = (editorRef.current as HTMLElement).textContent?.replace(
      /[\s\x00-\x1F\x7F]/g,
      ''
    );

    return {
      editorStateText,
      domText,
      isDifferent: editorStateText !== domText
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { isDifferent } = isDomTextDifferentToEditorState();
      if (isDifferent) {
        sleep(300).then(() => {
          const {
            isDifferent: isDifferent2,
            domText,
            editorStateText
          } = isDomTextDifferentToEditorState();

          if (isDifferent2 && !richTextMissmatchErrorIsSent) {
            trackInteraction({
              type: 'FUNCTION_CALL',
              customEventName: TRACKINGS.RICHTEXT_CONTENT_MISSMATCH,
              additionalData: {
                nodeId,
                domText,
                editorStateText,
                editorState: editor?.getJSON(),
                consoleLogs: (window as any).meetovoCapturedMessages
              },
              onSuccess: () => {
                richTextMissmatchErrorIsSent = true;
              }
            });
            (window as any).meetovoCapturedMessages = [];

            const richTextComponentShouldShowMissmatchWarning = getValueFromKeyValueArrayOfObjects(
              globalSettingsFlags,
              'richTextComponentShouldShowMissmatchWarning'
            );

            if (
              richTextComponentShouldShowMissmatchWarning &&
              !(window as any).richTextMissmatchErrorIsShowingUp
            ) {
              message.warning(
                'Der Inhalt in diesem Textfeld konnte nicht gespeichert werden. Bitte speichere und aktualisiere die Seite und melde dich anschließend im Support-Chat.',
                10
              );

              (window as any).richTextMissmatchErrorIsShowingUp = true;
            }
          }
        });
      }
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [editor, globalSettingsFlags, nodeId]);

  const [disableDrag, setDisableDrag] = useState(true);

  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });

  const isEmptyEditor = editor && !editor.getText().trim();
  const makeInvalid = required && isEmptyEditor;

  useEffect(() => {
    if (required) {
      makeInvalid
        ? dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT))
        : dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT));
    }
    return () => {
      dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT));
    };
  }, [makeInvalid, required]);

  if (!editor) {
    return null;
  }

  return (
    <ActionsController
      className={`rich-text__wrapper ${(nodeProps.elementType == FAQ.QUESTION ||
        nodeProps.elementType == FAQ.ANSWER) &&
        'faq-override'} disable-builder`}
      data-testid="rich-text-test-id"
      canDrag={disableDrag}
      label={
        CRAFT_ELEMENTS_LABEL[nodeProps.elementType ?? ''] ||
        CRAFT_ELEMENTS_LABEL[currentNode?.data?.displayName ?? '']
      }
    >
      <div
        id={nodeId}
        ref={wrapperRef}
        className={`rich-text__inner ${makeInvalid ? 'error-border' : ''}`}
        onMouseDown={e => {
          setDisableDrag(true);
          dispatch(setElementTabSelectMutex(true));

          const handleGlobalMouseUp = (upEvent: MouseEvent) => {
            const selection = window.getSelection();
            const hasTextSelected = selection && selection.toString().length > 0;
            if (hasTextSelected) {
              actions.selectNode(nodeId);
              setElementsTab({ payload: '2' });
              setTimeout(() => {
                dispatch(setElementTabSelectMutex(false));
              }, 0);
            } else {
              dispatch(setElementTabSelectMutex(false));
            }
            document.removeEventListener('mouseup', handleGlobalMouseUp);
          };
          document.addEventListener('mouseup', handleGlobalMouseUp);
        }}
        style={{ height: '100%', textAlign: 'left' }}
      >
        <EditorContent editor={editor} ref={editorRef} readOnly={!isEditable} spellCheck={false} />
      </div>
      {makeInvalid && (
        <span className="error-message">{UserInteractingComponentsMessages.noEmptyQuestion}</span>
      )}
    </ActionsController>
  );
}

export function RichTextSettings() {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const dispatch = useAppDispatch();
  const userTemplate: TemplateContent[] = useUserContentTemplate(props.elementType);

  const content = useTextTemplateData(props.elementType);
  const handleDelete = (id: number) => {
    dispatch(deleteJobTextTemplateThunk({ type: props.elementType, id }));
  };

  return (
    <>
      {props.showArrow ? (
        <Col span={24} className="builder__settings-sidebar__container">
          <TextSuggestionSidebar
            userTemplate={userTemplate}
            content={content}
            handleDelete={handleDelete}
          />
        </Col>
      ) : props?.canHide?.hideSwich ? (
        <Col span={24} className="builder__settings-sidebar__container" style={{ height: '100%' }}>
          <div className="empty-page-list--container">
            <EyeInvisibleOutlined
              className="mb-3"
              style={{ color: '#e0e0e0', fontSize: 60 }}
              onClick={() =>
                setProp((props: any) => {
                  props.canHide.hideSwich = !props?.canHide?.hideSwich;
                })
              }
            />
          </div>
        </Col>
      ) : props.settings.shouldShowSettings ? (
        <RichTextSetting />
      ) : (
        <Col span={24} className="builder__settings-sidebar__container" style={{ height: '100%' }}>
          <DefaultEmptyViewForSettingsPanel
            title="Einstellungen deaktiviert"
            description="Dieser Text wird durch Standardeinstellungen formatiert die du nicht ändern kannst."
          />
        </Col>
      )}
    </>
  );
}

RichTextComponent.craft = {
  name: CRAFT_ELEMENTS.RICH_TEXT,
  props: {
    isLeadQualifier: true,
    showArrow: false,
    settings: {
      shouldShowSettings: true
    },
    alignmentDisabled: false,
    showAiTextSettings: true,
    html: defaultTipTapEditorState
  },
  related: {
    settings: RichTextSettings
  }
};

export default RichTextComponent;
