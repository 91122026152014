import React from 'react';
import {
  FaCode,
  FaLaptop,
  FaPenNib,
  FaBriefcaseMedical,
  FaHammer,
  FaUtensils,
  FaShoppingCart,
  FaChartLine,
  FaLandmark,
  FaTruck,
  FaSeedling,
  FaIndustry,
  FaLaptopCode,
  FaPaintBrush,
  FaBook,
  FaGlobe,
  FaSolarPanel,
  FaBrain,
  FaBalanceScale,
  FaScrewdriver,
  FaWrench,
  FaUserTie,
  FaUsers,
  FaComments,
  FaClipboardList,
  FaCamera,
  FaShip,
  FaPlane,
  FaPaw,
  FaGamepad,
  FaRecycle,
  FaHardHat,
  FaRss,
  FaBuilding,
  FaCar,
  FaBriefcase,
  FaHeart,
  FaMicrochip,
  FaFlask,
  FaGavel,
  FaWineBottle,
  FaSpaceShuttle,
  FaSitemap,
  FaBookOpen,
  FaMusic,
  FaTheaterMasks,
  FaOilCan,
  FaSocks,
  FaMountain,
  FaWater,
  FaSatellite,
  FaRegPaperPlane,
  FaPills,
  FaClipboardCheck,
  FaBroadcastTower,
  FaRegLightbulb,
  FaHandHoldingUsd,
  FaRobot,
  FaUserNurse,
  FaChalkboardTeacher,
  FaTools,
  FaGlasses
} from 'react-icons/fa';
import { HiViewGridAdd } from 'react-icons/hi';
import { MdCategory, MdSchool, MdWork } from 'react-icons/md';
import { Funnel } from '../../interfaces/builderSliceTypes';
import { formatNumber } from './validateFunnelJobPresets';
import {
  educationRequirementCategories,
  TARGET_GROUP_ELEMENTS
} from '../../components/generalSettings/TargetGroup/components/TargetGroupHelper';
import { COMPANY_INFORMATION_ELEMENTS } from '../../components/generalSettings/AiDataTab/components/AiDataHelper';

// to be updated at the right places
export enum WorkplaceModel {
  ONSITE = 'ONSITE',
  HYBRID = 'HYBRID',
  REMOTE = 'REMOTE'
}

export enum SalaryPlan {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum Writing_Style {
  Formal = 'Formal',
  Youth = 'Youth',
  Happy = 'Happy',
  Visionary = 'Visionary'
}

export enum Speech {
  Duzen = 'Duzen',
  Siezen = 'Siezen'
}

export enum ExperienceDuration {
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export enum JOB_ATTRIBUTES_IDENTIFIER {
  JOB_TITLE = 'title',
  JOB_ADDRESS = 'address',
  EMPLOYMENT_TYPE = 'employmentType',
  CATEGORY = 'category',
  JOB_CATEGORY = 'jobCategory',
  SUB_CATEGORY = 'subCategory',
  SALARY = 'salary',
  SALARY_PLAN = 'salaryPlan',
  WORKPLACE_MODEL = 'workplaceModel'
}

export enum LEGACY_JOB_ATTRIBUTES_IDENTIFIER {
  SALARY = 'baseSalary',
  JOB_TITLE = 'jobTitle',
  COMPANY_NAME = 'companyName',
  JOB_ADDRESS = 'jobAddress',
  EMPLOYMENT_TYPE = 'employmentType'
}

export enum JOB_RELATED_DATA_TYPES {
  JobAttributesIdentifier = 'JobAttributesIdentifier',
  TARGET_GROUP_ELEMENTS = 'TARGET_GROUP_ELEMENTS'
}

export const requiredFieldsForJob = [
  COMPANY_INFORMATION_ELEMENTS.NAME,
  JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE,
  JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS,
  JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE,
  JOB_ATTRIBUTES_IDENTIFIER.JOB_CATEGORY,
  JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL,
  JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN,
  JOB_ATTRIBUTES_IDENTIFIER.SALARY,
  TARGET_GROUP_ELEMENTS.EDUCATION,
  TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION,
  TARGET_GROUP_ELEMENTS.EXPERIENCE
];

export const experienceDurationOptions = [ExperienceDuration.MONTHS, ExperienceDuration.YEARS];

export type JobAttributeOption =
  | JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE
  | JOB_ATTRIBUTES_IDENTIFIER.CATEGORY
  | TARGET_GROUP_ELEMENTS.EDUCATION
  | JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL
  | JOB_ATTRIBUTES_IDENTIFIER.SALARY_PLAN
  | JOB_ATTRIBUTES_IDENTIFIER.SUB_CATEGORY;

export const getJobOptions = (key: JobAttributeOption) => {
  const obj = {
    employmentType: {
      icon: <MdWork />,
      options: [
        { label: 'Vollzeit', value: 'full-time' },
        { label: 'Teilzeit', value: 'part-time' },
        { label: 'Freiberufler', value: 'contractor' },
        { label: 'Befristet', value: 'temporary' },
        { label: 'Praktikum', value: 'internship' },
        { label: 'Ehrenamtlich', value: 'volunteer' },
        { label: 'Tageweise', value: 'per-diem' },
        { label: 'Andere', value: 'other' }
      ]
    },
    education: {
      icon: <MdSchool />,
      options: [
        { label: 'Schule', value: educationRequirementCategories.HIGH_SCHOOL },
        { label: 'Fachschule', value: educationRequirementCategories.ASSOCIATE_DEGREE },
        { label: 'Studium', value: educationRequirementCategories.BACHELOR_DEGREE },
        {
          label: 'Ausbildung',
          value: educationRequirementCategories.PROFESSIONAL_CERTIFICATE
        },
        {
          label: 'Promoviert',
          value: educationRequirementCategories.POSTGRADUATE_DEGREE
        }
      ]
    },
    category: {
      icon: <HiViewGridAdd />,
      options: []
    },
    subCategory: {
      icon: <MdCategory />,
      options: [
        { icon: <FaLaptop />, label: 'Ingenieurwesen', value: 'engineering' },
        { icon: <FaPenNib />, label: 'Design', value: 'design' },
        { icon: <FaCode />, label: 'Produkt', value: 'product' },
        { icon: <FaBriefcaseMedical />, label: 'Gesundheitswesen', value: 'healthcare' },
        { icon: <FaHammer />, label: 'Bauwesen', value: 'construction' },
        { icon: <FaUtensils />, label: 'Gastronomie', value: 'hospitality' },
        { icon: <FaShoppingCart />, label: 'Einzelhandel', value: 'retail' },
        { icon: <FaChartLine />, label: 'Finanzwesen', value: 'finance' },
        { icon: <FaLandmark />, label: 'Öffentlicher Dienst', value: 'public-service' },
        { icon: <FaTruck />, label: 'Logistik', value: 'logistics' },
        { icon: <FaSeedling />, label: 'Landwirtschaft', value: 'agriculture' },
        { icon: <FaIndustry />, label: 'Produktion', value: 'manufacturing' },
        { icon: <FaLaptopCode />, label: 'IT & Telekommunikation', value: 'it-telecom' },
        { icon: <FaPaintBrush />, label: 'Kunst & Kultur', value: 'arts-culture' },
        { icon: <FaBook />, label: 'Bildung', value: 'education' },
        { icon: <FaGlobe />, label: 'Tourismus', value: 'tourism' },
        { icon: <FaSolarPanel />, label: 'Energie', value: 'energy' },
        { icon: <FaBrain />, label: 'Forschung & Entwicklung', value: 'research-development' },
        { icon: <FaBalanceScale />, label: 'Recht', value: 'legal' },
        { icon: <FaScrewdriver />, label: 'Handwerk', value: 'crafts' },
        { icon: <FaWrench />, label: 'Technischer Service', value: 'technical-service' },
        { icon: <FaUserTie />, label: 'Management', value: 'management' },
        { icon: <FaUsers />, label: 'Personalwesen', value: 'hr' },
        { icon: <FaComments />, label: 'Kundenservice', value: 'customer-service' },
        { icon: <FaClipboardList />, label: 'Verwaltung', value: 'administration' },
        { icon: <FaCamera />, label: 'Medien & Kommunikation', value: 'media-communication' },
        { icon: <FaShip />, label: 'Maritim', value: 'maritime' },
        { icon: <FaPlane />, label: 'Luftfahrt', value: 'aviation' },
        { icon: <FaPaw />, label: 'Tierpflege', value: 'animal-care' },
        { icon: <FaGamepad />, label: 'Unterhaltung & Spiele', value: 'entertainment-games' },
        {
          icon: <FaRecycle />,
          label: 'Umwelt & Nachhaltigkeit',
          value: 'environment-sustainability'
        },
        { icon: <FaHardHat />, label: 'Sicherheit & Schutz', value: 'safety-security' },
        { icon: <FaRss />, label: 'Digital Marketing', value: 'digital-marketing' },
        { icon: <FaBuilding />, label: 'Immobilien', value: 'real-estate' },
        { icon: <FaCar />, label: 'Automobilindustrie', value: 'automotive' },
        { icon: <FaBriefcase />, label: 'Beratung', value: 'consulting' },
        { icon: <FaHeart />, label: 'Soziale Dienste', value: 'social-services' },
        {
          icon: <FaMicrochip />,
          label: 'Elektronik & Halbleiter',
          value: 'electronics-semiconductors'
        },
        { icon: <FaFlask />, label: 'Chemie', value: 'chemistry' },
        { icon: <FaGavel />, label: 'Justizvollzug', value: 'corrections' },
        { icon: <FaWineBottle />, label: 'Lebensmittel & Getränke', value: 'food-beverages' },
        { icon: <FaSpaceShuttle />, label: 'Luft- & Raumfahrt', value: 'aerospace' },
        { icon: <FaSitemap />, label: 'Netzwerk & IT-Sicherheit', value: 'network-it-security' },
        { icon: <FaBookOpen />, label: 'Verlagswesen', value: 'publishing' },
        { icon: <FaMusic />, label: 'Musikindustrie', value: 'music-industry' },
        {
          icon: <FaTheaterMasks />,
          label: 'Theater & Schauspiel',
          value: 'theatre-performing-arts'
        },
        { icon: <FaOilCan />, label: 'Öl & Gas', value: 'oil-gas' },
        { icon: <FaSocks />, label: 'Mode & Textil', value: 'fashion-textile' },
        { icon: <FaMountain />, label: 'Bergbau & Metallurgie', value: 'mining-metallurgy' },
        { icon: <FaWater />, label: 'Wasserwirtschaft', value: 'water-management' },
        { icon: <FaSatellite />, label: 'Telekommunikation', value: 'telecommunications' },
        { icon: <FaRegPaperPlane />, label: 'Transport & Logistik', value: 'transport-logistics' }, // Alternative zu bereits vorhandenem Logistik-Eintrag
        { icon: <FaPills />, label: 'Pharmaindustrie', value: 'pharmaceuticals' },
        { icon: <FaClipboardCheck />, label: 'Qualitätsmanagement', value: 'quality-management' },
        { icon: <FaBroadcastTower />, label: 'Rundfunk & Fernsehen', value: 'broadcasting' },
        { icon: <FaRegLightbulb />, label: 'Innovation & Startups', value: 'innovation-startups' },
        {
          icon: <FaHandHoldingUsd />,
          label: 'Fundraising & Entwicklungshilfe',
          value: 'fundraising-development'
        },
        { icon: <FaRobot />, label: 'Robotik & Automation', value: 'robotics-automation' },
        { icon: <FaSeedling />, label: 'Erneuerbare Energien', value: 'renewable-energy' }, // Erweiterung zu bereits vorhandenem Energie-Eintrag
        { icon: <FaUserNurse />, label: 'Pflege & Betreuung', value: 'nursing-care' },
        {
          icon: <FaChalkboardTeacher />,
          label: 'Weiterbildung & Training',
          value: 'education-training'
        },
        { icon: <FaTools />, label: 'Handel & Reparatur', value: 'trade-repair' },
        { icon: <FaGlasses />, label: 'Optik & Augenheilkunde', value: 'optics-ophthalmology' }
      ]
    },
    workplaceModel: {
      icon: <MdCategory />,
      options: [
        { label: 'Vor Ort', value: WorkplaceModel.ONSITE },
        { label: 'Hybrid', value: WorkplaceModel.HYBRID },
        { label: 'Remote', value: WorkplaceModel.REMOTE }
      ]
    },
    salaryPlan: {
      icon: <></>,
      options: [
        { label: 'Monatlich', value: SalaryPlan.MONTHLY },
        { label: 'Jährlich', value: SalaryPlan.YEARLY }
      ]
    }
  };

  return obj[key] || obj[JOB_ATTRIBUTES_IDENTIFIER.CATEGORY];
};

export function getOptionsValuesInList(type: JOB_ATTRIBUTES_IDENTIFIER, values: string[]) {
  const options = getJobOptions(type as JobAttributeOption).options;
  const labelsOrValues = values?.reduce((acc, value) => {
    const label = options.find(({ value: _value }) => _value === value)?.label;

    return [...acc, label ? label : value];
  }, [] as string[]);

  return labelsOrValues?.join(', ') || 'Wähle eine Option';
}

type JobRelatedData = {
  salary: any;
  experience: any;
  experienceDuration: any;
  salaryPlan: any;
};

export const replaceJobPlaceHoldersWithValues = (
  template: {
    id: number;
    text: string;
    name?: string;
  },
  jobRelatedData?: JobRelatedData
) => {
  const { salary, experience, experienceDuration, salaryPlan } = jobRelatedData || {};
  let text = template.text;
  Object.keys(jobPlaceHolders).map((key: any) => {
    let value = jobRelatedData?.[key as keyof JobRelatedData];
    if (!value && key !== TARGET_GROUP_ELEMENTS.EXPERIENCE) return null;
    switch (key) {
      case JOB_ATTRIBUTES_IDENTIFIER.SALARY:
      case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.SALARY:
        value = `${formatNumber(salary[0])}€ - ${formatNumber(salary[1])}€ pro ${
          salaryPlanPlaceHolders[salaryPlan]
        }`;
        break;
      case TARGET_GROUP_ELEMENTS.EXPERIENCE:
        value = `${experience} ${experienceDurationPlaceHolders[experienceDuration]} Erfahrung`;
        break;
      case JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS:
      case LEGACY_JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS:
        //@ts-ignore
        value = value?.place;
        break;
      default:
        if (Array.isArray(value)) {
          const label = getJobOptions(key)
            .options.filter(option => {
              //@ts-ignore
              return value.includes(option.value);
            })
            .map(option => option.label);
          value = label.join(', ');
        } else {
          const label = getJobOptions(key)
            .options.filter(option => {
              //@ts-ignore
              return value === option.value;
            })
            .map(option => option.label);
          if (label.length > 0) {
            value = label.join(',');
          }
        }
    }

    // @ts-ignore
    text = text.replaceAll(jobPlaceHolders[key], value);
  });
  return { ...template, text };
};

export const experienceDurationPlaceHolders: { [key: string]: string } = {
  [ExperienceDuration.MONTHS]: 'Monate',
  [ExperienceDuration.YEARS]: 'Jahre'
};

export const salaryPlanPlaceHolders: { [key: string]: string } = {
  [SalaryPlan.MONTHLY]: 'Monatlich',
  [SalaryPlan.YEARLY]: 'Jährlich'
};

export const jobPlaceHolders = {
  [JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE]: '[UNTERNEHMENSNAME]',
  [JOB_ATTRIBUTES_IDENTIFIER.CATEGORY]: '[KATEGORIE]',
  [JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS]: '[STANDORT]',
  [TARGET_GROUP_ELEMENTS.EXPERIENCE]: '[ERFAHRUNG]',
  [JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE]: '[BESCHAEFTIGUNGSART]',
  [TARGET_GROUP_ELEMENTS.EDUCATION]: '[BILDUNGSGRAD]',
  [JOB_ATTRIBUTES_IDENTIFIER.SALARY]: '[GEHALT]',
  [JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL]: '[ARBEITSPLATZMODELL]'
};
