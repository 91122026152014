import React, { useEffect, PropsWithChildren, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useFunnel, useMainFunnelLoader } from '../Builder/redux/builderSlice';
import { useFunnelEditingSocket } from '../Builder/hooks/socket';

function AppHOC({ children }: PropsWithChildren<unknown>): ReactElement {
  const { isEditing } = useFunnel();
  const location = useLocation();
  const mainFunnelLoader = useMainFunnelLoader();

  const isBuilderPath = location.pathname.includes('builder');
  const shouldEnableSocket = isBuilderPath && !isEditing && !mainFunnelLoader;

  const { socket } = useFunnelEditingSocket({
    enabled: shouldEnableSocket
  });

  useEffect(
    () => () => {
      if (socket) {
        socket.disconnect();
      }
    },
    [socket]
  );

  return <>{children}</>;
}

export default AppHOC;
