import React from 'react';
import {
  FaFont,
  FaFillDrip,
  FaImage,
  FaPlayCircle,
  FaSmileWink,
  FaRulerHorizontal,
  FaMicrophoneAlt,
  FaTable,
  FaCode,
  FaRegImage,
  FaIdCard
} from 'react-icons/fa';
import { BiCarousel, BiLink } from 'react-icons/bi';
import { FiLogIn } from 'react-icons/fi';
import ElementTemplate from '../container/ElementTemplate';
import ImageComponent from '../../components/elements/ImageComponent';
import VideoComponent from '../../components/elements/VideoComponent';
import RichTextComponent from '../../components/elements/RichTextComponent/container/RichTextComponent';
import IconComponent from '../../components/elements/IconComponent';
import { DividerComponent } from '../../components/elements/DividerComponent/container/DividerComponent';
import VoiceMessageComponent from '../../components/elements/VoiceMessageComponent';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import ContainerComponent from '../../components/elements/Container/container/ContainerComponent';
import HTMLWidget from '../../components/elements/Widgets/HTMLWidget';
import CarouselContainer from '../../components/elements/Carousel/Container/CarouselContainer';
import DefaultEmptyViewForSettingsPanel from '../../components/sharedUI/DefaultEmptyViewForSettingsPanel';
import DesignSettings from '../container/DesignSettings';
import { CardsWithCategory } from '../../config/builderUi';
import { BuilderBase } from '../../config/builderUi';
import { AGENCY_OS_BUILDER_ROUTES, AGENCY_OS_BUILDER_PATH } from '../../../UI/paths';
import { BannerComponent } from '../../components/elements/BannerComponent';
import { FlagOutlined, LoginOutlined } from '@ant-design/icons';
import { LoginContainer } from '../../components/elements/LoginContainer';
import LinkBoxComponent from '../../components/elements/LinkBoxComponent';
import SupportCardComponent from '../../components/elements/SupportCardComponent';

export const builderConfig: Array<BuilderBase> = [
  {
    type: 'BASE',
    icon: <FiLogIn />,
    title: 'Login',
    description: 'Login',
    onClickRoute: AGENCY_OS_BUILDER_PATH.loginSettings,
    item: {
      type: 'COMPONENT',
      title: AGENCY_OS_BUILDER_ROUTES.loginSettings,
      route: AGENCY_OS_BUILDER_ROUTES.loginSettings,
      item: <DefaultEmptyViewForSettingsPanel />
    }
  },
  {
    type: 'BASE',
    icon: <FaFillDrip />,
    title: 'Design',
    description: 'Design',
    onClickRoute: AGENCY_OS_BUILDER_PATH.designSettings,
    item: {
      type: 'COMPONENT',
      title: AGENCY_OS_BUILDER_ROUTES.designSettings,
      route: AGENCY_OS_BUILDER_ROUTES.designSettings,
      item: <DesignSettings />
    }
  },
  {
    type: 'BASE',
    icon: <FaRegImage />,
    title: 'Hub',
    description: 'Hub',
    onClickRoute: AGENCY_OS_BUILDER_PATH.hubSettings,
    item: {
      type: 'COMPONENT',
      title: AGENCY_OS_BUILDER_ROUTES.hubSettings,
      route: AGENCY_OS_BUILDER_ROUTES.hubSettings,
      item: <ElementTemplate />
    }
  }
];

export const cardsWithCategory: CardsWithCategory[] = [
  {
    category: 'Darstellung',
    elements: [
      {
        icon: <FaTable />,
        title: 'Abschnitt',
        element: <ContainerComponent />,
        key: CRAFT_ELEMENTS.CONTAINER,
        variant: 'contained',
        data: 'element-container'
      },
      {
        icon: <FaFont />,
        title: 'Text',
        element: <RichTextComponent isLeadQualifier={false} />,
        key: CRAFT_ELEMENTS.RICH_TEXT,
        variant: 'contained',
        data: 'element-text'
      },
      {
        icon: <FaImage />,
        title: 'Bild',
        element: <ImageComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.IMAGE,
        data: 'element-image'
      },
      {
        icon: <FaSmileWink />,
        title: 'Icon',
        element: <IconComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.ICON,
        data: 'element-image'
      },
      {
        icon: <FaPlayCircle />,
        title: 'Video',
        element: <VideoComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.VIDEO,
        data: 'element-video'
      },
      {
        icon: <FaMicrophoneAlt />,
        title: 'Sprachnachricht',
        element: <VoiceMessageComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.VOICE_MESSAGE,
        data: 'element-button'
      },
      {
        icon: <FaRulerHorizontal />,
        title: 'Abstandhalter',
        element: <DividerComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.DIVIDER,
        data: 'element-button'
      },
      {
        icon: <FaCode />,
        title: 'HTML',
        element: <HTMLWidget />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.HTML_WIDGET,
        data: 'element-button'
      },
      {
        icon: <BiCarousel />,
        title: 'Bild-Slider',
        element: <CarouselContainer />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.CAROUSEL_CONTAINER,
        data: 'element-button'
      },
      {
        icon: <FlagOutlined />,
        title: 'Banner',
        element: <BannerComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.BANNER,
        data: 'element-button'
      },
      {
        icon: <LoginOutlined />,
        title: 'LoginComponent',
        element: <LoginContainer onlySettingsReq showPlusIcon={false} />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.LOGIN_CONTAINER,
        data: 'element-button'
      },
      {
        icon: <BiLink />,
        title: 'Link-Box',
        element: <LinkBoxComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.LINK_BOX,
        data: 'element-button'
      },
      {
        icon: <FaIdCard />,
        title: 'Support-Karte',
        element: <SupportCardComponent />,
        variant: 'contained',
        key: CRAFT_ELEMENTS.SUPPORT_CARD,
        data: 'element-button'
      }
    ]
  }
];

export const getDefaultBase = (builderConfig: BuilderBase[]) => ({
  ...builderConfig[0],
  index: 0
});
