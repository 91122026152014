import React, { useEffect, useState } from 'react';
import { Col, Form, Input, message, Modal, Row } from 'antd';
import * as htmlToImage from 'html-to-image';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGE } from '../../../../../../Funnel/helper/uploadImages';
import DefaultSmallLoader from '../../../../../../SharedUI/components/DefaultSmallLoader';
import { CreateNewTemplateMessages } from '../../../../../../config/messages';
import { appendStyles, getUniqueId, useCraftSerializedState } from '../../../../../helper/craftJs';
import { useDispatch } from 'react-redux';
import { resetStylesDuringTemplateScreenshot } from '../../../../../components/TemplatesPanel/resetStylesDuringScreenshot';
import { useEditor, useNode } from '@craftjs/core';
import { getNodeIdsForTree, removeUnnecessaryNodes } from '../../../../../helper/elementTemplate';
import lz from 'lzutf8';
import LoginValidationRules from '../../../../../../Login/utils/LoginValidationRules';
import { createAdMediaTemplateThunk } from '../../../../redux/thunk';

const CreateNewTemplate: React.FC<{
  onSubmit?: () => void;
  visible: boolean;
  toggleVisible: (data: boolean) => void;
}> = ({ children, visible, toggleVisible = (data: boolean) => {}, onSubmit = () => {} }) => {
  const { currentNode, containerRef } = useNode(node => ({
    containerRef: node.dom,
    currentNode: node
  }));
  const { query, actions } = useEditor();

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const backgroundColor = '#fff';
  const dispatch = useDispatch();

  const getTemplateCraftState = () => {
    const json = useCraftSerializedState(query);
    const filteredJson = removeUnnecessaryNodes(json, getNodeIdsForTree(json, currentNode.id));

    return lz.encodeBase64(
      lz.compress(
        JSON.stringify({
          id: currentNode.id,
          nodes: filteredJson
        })
      )
    );
  };

  const handleOk = async ({ name }: any) => {
    setLoading(true);
    uploadImage({ variables: { input: [{ imageURL: imageSrc }] } })
      .then(async res => {
        const imageLink = res.data.uploadImages[0].imageLink;
        if (imageLink) {
          const tempId = getUniqueId();
          dispatch(
            createAdMediaTemplateThunk({
              craftState: getTemplateCraftState(),
              imageUrl: imageLink,
              name
            })
          );
          actions.selectNode(currentNode.id);
          message.success(CreateNewTemplateMessages.templateBlockCreatedSuccessfully);
          setProp((state: any) => {
            state.templateId = tempId;
          });
          onSubmit();
          toggleVisible(false);
        }
      })
      .catch(e => {
        setLoading(false);
        // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (containerRef && visible) {
      setImageLoading(true);
      appendStyles({
        _document: document,
        id: resetStylesDuringTemplateScreenshot.id,
        styles: resetStylesDuringTemplateScreenshot.style
      });

      setTimeout(() => {
        htmlToImage
          .toJpeg(containerRef, { backgroundColor, cacheBust: true })
          .then(function(dataUrl) {
            setImageSrc(dataUrl);
            setImageLoading(false);
          })
          .catch(e => {
            // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
            setImageLoading(false);
          });
      }, 0);
    }

    if (!visible) document.getElementById(resetStylesDuringTemplateScreenshot.id)?.remove();
  }, [visible]);

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);
  };
  return (
    <>
      <span onClick={() => toggleVisible(!visible)}>{children}</span>
      <Modal
        title="Als Vorlage speichern"
        okText="Speichern"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        width={600}
        confirmLoading={imageLoading || loading}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleOk}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item label={'Titel'} rules={LoginValidationRules.name} name={'name'}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className="loading-wrapper">
            <DefaultSmallLoader loading={imageLoading} />
          </div>
          {!imageLoading && <img src={imageSrc} />}
        </Form>
      </Modal>
    </>
  );
};
export default CreateNewTemplate;
